import React, {useEffect} from "react";
import {PageLoad} from "../../common/PageLoadSellerApp";
import { getCurrentURIParams} from "../../../services/common";
import {openViewExit} from "../../common/openViewExit";

const InvalidLink = ({checkedLoggedIn}) => {
    useEffect(()=>{
        //Page load for source 51, 53
        try{
        if(checkedLoggedIn){
            let r = getCurrentURIParams();
              if(r.source!=53){
                let logInJson = window.JdLiteInterface.getLoginData();
                if(!logInJson) return;
                logInJson = JSON.parse(logInJson);
                  if(logInJson.source=="51"){
                    PageLoad(logInJson.source);
                 }
              }else{
                PageLoad(r.source);
          //      HideIOSAppheader();
              }
        }
        }catch(e){
          console.log("Page Load err for approval...", e);
        }
        },[checkedLoggedIn]);

      const handleBack = () =>{
        let r = getCurrentURIParams();
        if(r.source==51 && (r.mobile=="7892197015" || r.mobile=="9226108471")){
          openViewExit(r.source);
          return false;
        }
      }
    return (
      <>
        <div class="packmovecontainer">
        <div class="stockaddedwrap">
            <a class="crosswrap" href="javascript:void(0);"><i class=""></i></a>
        <div class="innerstockwrap">
            <span class="dblock">
                <span class="circlediv animated zoomIn" onClick={() => handleBack()}><i class="crossic"></i></span>
            </span>
            <span class="thankstext">Sorry</span>
            <span class="stocktext font14">The link is expired.</span>
        </div>
    </div>
     </div>
     </>
    )
}

export default InvalidLink;