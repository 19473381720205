import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BusinessDet from "./pages/BusinessDet";
import LocationDet from "./pages/LocationDet";
import ContactDet from "./pages/ContactDet";
import StoreTimeDet from "./pages/storeTimingDet";
import PickupDet from "./pages/PickupDet";
import DeliveryPriceDet  from "./pages/DeliveryPriceDet";
import MyHeader from "./common/MyHeader";
import StepTab from "./common/StepTab";
import {
  setCurrentLocationAction,
  loadPrefilledBusinessDataAction,
  saveUrlParamsAction,
  setLoadedDataAction,
  hideShimmerAction,
  showShimmerAction,
  hideLoaderAction,
  loadInitialBusinessDataAction,
  setDocIdAction,
  setSelectedBusinessDataAction,
  showToastAction,
  hideToastAction,
  setNoBussDetails,
  setLoadedBussDetails,
  setLoadedTemData,
  setNoTemData,
  setIsPaidContract
} from "../actions/actions";
import {
  getURIParams,
  LoadPrefilledData,
  LoadBusinesses,
  businessDetails,
  getCurrentURIParams,
  getDboardLink,
  getResultsByRefId,
} from "../services/common";
import {checkIfPaidContract} from "../services/paidCon";
import { setCookie } from "./common/setCookie";
import { getCookie } from "./common/getCookie";
import ChoosePlan from "./pages/chooseplan";
import {PageLoad} from "./common/PageLoadSellerApp";
import DeliveryPerson from "./pages/DeliveryPerson";
import OffersDet from "./pages/OffersDet";

import {Steps} from "../config/consts";

const BusinessDetails = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const locationReducer = useSelector((state) => state.locationReducer);
  const LoaderReducer = useSelector((state) => state.LoaderReducer);
  const {
    loadedPreFilledData,
    urlParam,
    loggedIn,
    businessSelectedFlag,
    businessCreateFlag,
    jduid
  } = useSelector((state) => state.locationReducer);
  const initialBusinessDataReducer = useSelector(
    (state) => state.initialBusinessDataReducer
  );

  useEffect(()=>{
    //Code to check if it's a paid contract
    checkIfPaidContract().then((res)=>{
      if(res.data && res.data.paidStatus==1){
        dispatch(setIsPaidContract());
      }
     })
  },[0])
  
  useEffect(() => {
    let pn = location.pathname.split("/")[2];
    if (Steps.indexOf(pn) > 2) {
      if(pn=="deliveryDet"){
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        var lastStep = document.getElementById("lastStep");
        lastStep && lastStep.scrollIntoView(false);
      }, 800); 
      }else{
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          var lastStep = document.getElementById("lastStep");
          lastStep && lastStep.scrollIntoView(false);
        }, 100);
      }
    } else {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        var firstStep = document.getElementById("firstStep");
        firstStep && firstStep.scrollIntoView(false);
      }, 100);
    }
  }, [loadedPreFilledData, location.pathname]);
  useEffect(() => {
    let pn = location.pathname.split("/")[2];
    dispatch(setCurrentLocationAction(pn));
  }, [0]);
  useEffect(() => {
    console.log("Here in select Business*********************");
    let currentUriParams = getCurrentURIParams();
    let docid = currentUriParams["docid"];
    if(currentUriParams["source"] == 14){
      dispatch(hideLoaderAction());
    } else {
      if (docid && docid !== "undefined") {
        businessDetails(docid).then((resp) => {
          if(resp.data.error_code==1){
          dispatch(hideLoaderAction());
          dispatch(showToastAction("Error in processing the document. Please try again later."));
          setTimeout(function () {
            dispatch(hideToastAction());
          }, 3000);
          console.log("Error loading docdata*******************");
          console.log(resp.data.msg);
          dispatch(setNoBussDetails());
          }else{ 
        console.log("resp---on click", resp);
        const data = resp.data[docid];
        dispatch(setDocIdAction(docid));
        dispatch(setSelectedBusinessDataAction(data));
        LoadPreFilledDataEffect(docid);
        let urlParamsClone = locationReducer.urlParam;
        if (!urlParamsClone) urlParamsClone = getURIParams(docid);
        dispatch(hideLoaderAction());
        dispatch(setLoadedBussDetails());
          }
      });
    }else{
      dispatch(setNoBussDetails());
    }
}
  }, [0]);

  useEffect(() => {
    if (!urlParam) {
      let urlParams = window.location.href.split("?")[1];
      dispatch(saveUrlParamsAction(urlParams));
    }
  }, [0]);
  //Check if form is already filled
  function LoadPreFilledDataEffect() {
    if (!loadedPreFilledData && loggedIn) {
      let r = getCurrentURIParams();
      let docid = r.docid;
      let refId = r.refId;
      if (docid && docid !== "undefined") {
        LoadPrefilledData()
          .then((res) => {
            //     dispatch(hideLoaderAction());
            dispatch(setLoadedDataAction(true));
            let urlParamsClone = getURIParams();
            dispatch(setLoadedTemData());
            if (
              !res.data.results.data[0] ||
              res.data.results.data[0].length == 0
            ) {
              //       navigate(`/businessDetails/businessDet?${urlParamsClone}`);
            } else {
              if (res.data.results.data[0] && (res.data.results.data[0].docid || res.data.results.data[0].url || res.data.results.data[0].businessDetails )) {
                dispatch(
                  loadPrefilledBusinessDataAction(res.data.results.data[0])
                );
                //          navigate(`${res.data.results.data[0].url}?${urlParamsClone}`);
              } else {
                let currentUriParams = getCurrentURIParams();
                if (currentUriParams["input"] !== "account_settings") {
                  let urlParamsClone = getURIParams();
                  //     navigate(`/?${urlParamsClone}`);
                }
              }
            }
          })
          .catch((er) => {
            console.log(er);
          });
      } else if (refId && refId !== "undefined") {
        getResultsByRefId(refId).then((res) => {
          dispatch(setLoadedTemData());
          if (
            res.data &&
            res.data.results &&
            res.data.results.data &&
            res.data.results.data[0]
          ) {
            dispatch(loadPrefilledBusinessDataAction(res.data.results.data[0]));
          } else {
            function replaceQueryParam(param, newval, search) {
              var regex = new RegExp("([?;&])" + param + "[^&;]*[;&]?");
              var query = search.replace(regex, "$1").replace(/&$/, "");

              return (
                (query.length > 2 ? query + "&" : "?") +
                (newval ? param + "=" + newval : "")
              );
            }
            var str = window.location.search;
            str = replaceQueryParam("refId", undefined, str);
            window.location = window.location.pathname + str;
          }
        });
      }else{
        dispatch(setNoTemData());
      }
    }
  }
  function LoadPreFilledDataForFirstDocEffect() {
    if (!loadedPreFilledData && loggedIn) {
      //    dispatch(showLoaderAction());
      LoadPrefilledData()
        .then((res) => {
          //     dispatch(hideLoaderAction());
          let urlParamsClone = urlParam;
          dispatch(setLoadedDataAction(true));
          if (!urlParam) {
            urlParamsClone = window.location.href.split("?")[1];
          }
          let docidToPass;
          if (
            res.data.results.data[0] &&
            res.data.results.data[0].loadDocData
          ) {
            docidToPass = res.data.results.data[0].loadDocData.docid;
          }
          if (!urlParamsClone) {
            urlParamsClone = getURIParams(docidToPass);
          }
          if (
            !res.data.results.data[0] ||
            res.data.results.data[0].length == 0
          ) {
            //         navigate(`/businessDetails/businessDet?${urlParamsClone}`);
          } else {
            if (
              res.data.results.data[0].url &&
              (!res.data.results.data[0].doneFinal ||
                res.data.results.data[0].doneFinal == "0")
            ) {
              dispatch(
                loadPrefilledBusinessDataAction(res.data.results.data[0])
              );
              //          navigate(`${res.data.results.data[0].url}?${urlParamsClone}`);
            } else {
              let currentUriParams = getCurrentURIParams();
              if (currentUriParams["input"] !== "account_settings") {
                navigate(`/?${urlParamsClone}`);
              }
            }
          }
        })
        .catch((er) => {
          console.log(er);
        });
    }
  }
  //Check if form is already filled
  useEffect(() => {
    if (loggedIn && jduid) {
      LoadPreFilledDataEffect();
    }
  }, [loggedIn, jduid]);

  useEffect(() => {
    let r = getCurrentURIParams();
    let docid = r.docid;
    if (
      loggedIn &&
      !locationReducer.businessesLoadedFlag &&
      (!docid || docid == "undefined")
    ) {
      // dispatch(showLoaderAction());
      dispatch(showShimmerAction());
      LoadBusinesses()
        .then((res) => {
          let urlParamsClone = getURIParams();
          if (res.data.results) {
          //  dispatch(loadInitialBusinessDataAction(res.data.results));
            if (res.data.results.length > 0) {
              let dUri = getDboardLink();
              let pn = location.pathname.split("/")[2];
              console.log(dUri);
              if (Steps.indexOf(pn) > -1) {
                dispatch(hideShimmerAction());
              } else {
                window.location.href = dUri;
              }
            } else {
              navigate(`/businessDetails/businessDet?${urlParamsClone}`);
              dispatch(hideShimmerAction());
            }
          } else {
            navigate(`/businessDetails/businessDet?${urlParamsClone}`);
            dispatch(hideShimmerAction());
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }else{
      let urlParamsClone = getURIParams();
      let pn = location.pathname.split("/")[2];
      if(r["input"]!=="account_settings"){
        if (Steps.indexOf(pn) > -1) {
          navigate(`/businessDetails/${pn}?${urlParamsClone}`);
        }else{
          navigate(`/businessDetails/businessDet?${urlParamsClone}`);
        }
      }
      dispatch(hideShimmerAction());
    }
  }, [loggedIn]);

  const isPackagePage = () => {
    return window.location.pathname.includes("chooseplan");
  };

  return (
    <>
    {!LoaderReducer.show &&
        !LoaderReducer.showShimmer && 
          (!locationReducer.hide_header || locationReducer.hide_header=="0") &&
        <MyHeader headerText={isPackagePage()?"Payment Plan":""}/>}
    <div className="onboarding_outer">
      {/* Common Header */}
      {!LoaderReducer.show &&
        !LoaderReducer.showShimmer &&
        !isPackagePage() && <StepTab />}
      {
        <Routes>
          {/* <Route path="/" element={<BusinessDet />} />{" "} */}
          {/*Assuming to redirect to initial when path is wrong*/}
          <Route path="/businessDet" element={<BusinessDet />} />
          <Route path="/locationDet" element={<LocationDet />} />
          <Route path="/contactDet" element={<ContactDet />} />
          <Route path="/pickupDet" element={<PickupDet />} />
          <Route path="/deliveryDet" element={<DeliveryPerson />} />
          <Route path="/storeTimeDet" element={<StoreTimeDet />} />
          <Route path="/chooseplan" element={<ChoosePlan />} />
          <Route path="/deliveryPriceDet" element={<DeliveryPriceDet />} />
          <Route path="/offerDet" element={<OffersDet />} />
        </Routes>
      }
    </div>
    </>
  );
};

export default BusinessDetails;
