    import React, { useState, useEffect } from "react";
import AddDeliveryBoy from "../common/AddDeliveryBoy";
import DeliveryBoyList from "../common/DeliveryBoyList";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {getDeliveryAgentList, deleteAgentFromList, addDeliveryBoy} from "../../services/delivery";
import {getURIParams} from "../../services/common";
import {
    showLoaderAction,
    hideLoaderAction
  } from "../../actions/actions";

const DeliveryPerson = () => {
    const [addDBoy, setAddDBoy] = useState(false);
    const [deliveryBoyList, setDeliveryBoyList] = useState([]);
    const [valuesEd, setValuesEd] = useState({});
    const [activeDot, setActiveDot] = useState("");
    const [toast, setToast] = useState({show: false, msg: ""});
    const [changedDFlag, setChangedDFlag] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(()=>{
        getDeliveryAgent();
    },[changedDFlag])

    const getDeliveryAgent = (edit) => {
        dispatch(showLoaderAction());
        getDeliveryAgentList().then((res)=>{
            let list = res.data.results;
            if(list && list.length>0)
            setDeliveryBoyList(list);
            if(edit){
                setAddDBoy(false); 
                setToast({show: true, msg:"Edited Successfully."});
                setTimeout(function () {
                    setToast({show: false, msg:""});
                }, 2000); 
            }
            dispatch(hideLoaderAction());
        })
    }
    const openAddDeliveryBoyPopUp = () => {
        setValuesEd({});
        setAddDBoy(true);
    }

    const closeAddDeliveryBoyPopUp = () => {
        setAddDBoy(false);
    }

    const deleteAgent = (ind, mobile) => {
        dispatch(showLoaderAction());
        deleteAgentFromList(mobile).then((res)=>{
            // let dl = deliveryBoyList.splice()
            let dl = [...deliveryBoyList];
            dl.splice(ind, 1);
            setDeliveryBoyList(dl);
            var today = new Date();
            setChangedDFlag(today);
            dispatch(hideLoaderAction());
            setToast({show: true, msg:"Deleted Successfully."});
            setTimeout(function () {
                setToast({show: false, msg:""});
            }, 2000); 
         })
    }


    const handleAddDeliveryBoy = (name, mobile, imgs, edit) => {
        dispatch(showLoaderAction());
            addDeliveryBoy(name, mobile, imgs, edit).then((res) => {
                if(res.data.errors && res.data.errors.code==1){
                    setToast({show: true, msg: `Error: ${res.data.errors.msg}`});
                    setTimeout(function () {
                        setToast({show: false, msg:""});
                    }, 2000); 
                    dispatch(hideLoaderAction());
                }else{
                    if(edit){
                        getDeliveryAgent(edit);
                    }else{
                        setAddDBoy(false);
                        let dl = [...deliveryBoyList];
                        dl.push({
                            name: name, mobile: mobile, image_url: imgs
                        })
                        var today = new Date();
                        setChangedDFlag(today);
                        setToast({show: true, msg: name+" added to delivery boys list."});
                        setTimeout(function () {
                            setToast({show: false, msg:""});
                        }, 2000);
                        setDeliveryBoyList(dl);
                        dispatch(hideLoaderAction());
                    }
                }
            });
    }

    const handleEditDBoy = (name, mobile, imgs) => {
        setAddDBoy(true);
        setActiveDot("");
        setValuesEd({
            name: name, mobile: mobile, img_url: imgs
        });
    }

    return (
        <>
            {
                deliveryBoyList.length > 0 &&
                <section class="pl-20 pr-20 pb-70">
                    <div class="deliverycount font12 color777 pb-10 mt-30">
                        {deliveryBoyList.length} delivery boys
                    </div>

                    <div class="deliveryboy_list">
                        <ul>
                            {
                                deliveryBoyList && deliveryBoyList.map((d,ind) =>
                                    <DeliveryBoyList 
                                        image_url={d.image_url}
                                        name={d.name}
                                        mobile={d.mobile}
                                        ind={ind}
                                        deleteAgent={deleteAgent}
                                        key={d.mobile+ind}
                                        handleEditDBoy={handleEditDBoy}
                                        activeDot={activeDot}
                                        setActiveDot={setActiveDot}
                                    />
                                )
                            }
                        </ul>
                    </div>

                    <button class="adddeliverybtn addedbtn mt-15" onClick={openAddDeliveryBoyPopUp}><i class="add_plus_btn"></i> Add Delivery Boy</button>

                </section>
            }
            {
                deliveryBoyList.length == 0 &&
                <section class="pl-20 pr-20 pb-70">
                    <div class="deliveryboy_wpr">
                        <div class="mt-50"><img src="https://akam.cdn.jdmagicbox.com/images/icontent/seller/delivery_img.svg" width="230" /></div>
                        <div class="font15 color111 fw600 mt-25">Add Your Delivery Boy</div>
                        <div class="font14 color777 mt-10">You can create your own delivery boy and assign your order for delivery.</div>
                        <button class="adddeliverybtn mt-15"  onClick={openAddDeliveryBoyPopUp}><i class="add_plus_btn"></i> Add Delivery Boy</button>
                    </div>
                </section>

            }
            <div class="ftr_btn delivery_skipbtn">
                <button class="font14 color111 fw600 skipbtn" onClick={()=>{
                    let uriParams = getURIParams();
                      navigate(
                        `/businessDetails/deliveryPriceDet?${uriParams}`
                      );
                }}>Skip</button>
                <button 
                class={`font14 fw600 colorfff ${deliveryBoyList.length == 0?"disabeld":""}`}
                onClick={()=>{
                    let uriParams = getURIParams();
                      navigate(
                        `/businessDetails/deliveryPriceDet?${uriParams}`
                      );
                }}>
                    Save &amp; Next
                </button>
            </div>
            {
                 <div className={`toastMesgcnewWrp ${toast.show ? "show" : "dn"}`} style={{bottom: "130px"}}>
                 <div className="toastMesgInr font12"> {toast.msg} </div>
               </div>                
            }

            {
                addDBoy &&
                <AddDeliveryBoy
                    closeAddDeliveryBoyPopUp={closeAddDeliveryBoyPopUp}
                    handleAddDeliveryBoy={handleAddDeliveryBoy}
                    valuesEd = {valuesEd}
                />
            }
        </>
    )
}

export default DeliveryPerson;
