import React, { useEffect, useState, useRef } from "react";

const OtpEnterComponent = ({
  mobile,
  sendOtp,
  verifyOtp,
  wrongOtp,
  closeOtpComp,
}) => {
  const input_0 = useRef(null);
  const input_1 = useRef(null);
  const input_2 = useRef(null);
  const input_3 = useRef(null);
  const input_4 = useRef(null);
  const input_5 = useRef(null);

  const [otpNum, setOtpNum] = useState({});
  const [doneShown, setDoneShown] = useState(true);
  const [showResendOtp, setShowResendOtp] = useState(false);
  useEffect(()=>{
    if ('OTPCredential' in window) {
        navigator.credentials.get({
          otp: { transport:['sms'] }
        }).then(otp => {
          let arr = String(otp.code) && String(otp.code).split("");
          setOtpNum({
            0: arr[0],
            1: arr[1],
            2: arr[2],
            3: arr[3],
            4: arr[4],
            5: arr[5],
            });
            if(arr.length==6){
              setTimeout(() => {
                handleAutoVerifyOtp(arr);
              }, 1000);
            }
        }).catch(err => {
          console.log(err);
        });
    } else {
      console.log('WebOTP not supported!.')
    }
  },[0])
  useEffect(() => {
    if (wrongOtp && doneShown) {
      setOtpNum({ ...otpNum, 5: undefined });
      setDoneShown(false);
    }
  }, [wrongOtp]);

  useEffect(() => {
    setTimeout(() => {
      setShowResendOtp(true);
    }, 10000);
  }, [0]);
  const handleSendOtp = () => {
	  if(showResendOtp){
		setOtpNum({
			0: "",
			1: "",
			2: "",
			3: "",
			4: "",
			5: "",
		  });
		  sendOtp(undefined, 1);
		  setShowResendOtp(false);
		  setTimeout(() => {
			setShowResendOtp(true);
		  }, 10000);
	  }
  };
  const handleVerifyOtp = (n5) => {
    if (n5.length == 1) {
      setOtpNum({ ...otpNum, 5: n5 });
      verifyOtp(
        `${otpNum[0]}${otpNum[1]}${otpNum[2]}${otpNum[3]}${otpNum[4]}${n5}`,
        mobile
      );
    } else {
      input_4.current.focus();
      setOtpNum({ ...otpNum, 5: undefined });
    }
  };
  const handleAutoVerifyOtp = (arr) => {
    if (arr && arr.length == 6) {
      verifyOtp(
        `${arr[0]}${arr[1]}${arr[2]}${arr[3]}${arr[4]}${arr[5]}`,
        mobile
      );
    }
  };
  const handleEnterOtp = (e, index) => {
    if (e.target.value.length == 1) {
      setOtpNum({ ...otpNum, [index]: e.target.value });
      switch (index) {
        case 0:
          input_1.current.focus();
          break;
        case 1:
          input_2.current.focus();
          break;
        case 2:
          input_3.current.focus();
          break;
        case 3:
          input_4.current.focus();
          break;
        case 4:
          input_5.current.focus();
          break;
        default:
          break;
      }
    } else if (e.target.value.length == 0) {
      setOtpNum({ ...otpNum, [index]: e.target.value });
      switch (index) {
        case 1:
          input_0.current.focus();
          break;
        case 2:
          input_1.current.focus();
          break;
        case 3:
          input_2.current.focus();
          break;
        case 4:
          input_3.current.focus();
          break;
        default:
          break;
      }
    } else if (e.target.value.length == 6) {
      let val = e.target.value.split("");
      setOtpNum({ ...otpNum, [0]:val[0], [1]:val[1], [2]:val[2], [3]:val[3], [4]:val[4], [5]:val[5]});
      verifyOtp(
        `${val[0]}${val[1]}${val[2]}${val[3]}${val[4]}${val[5]}`,
        mobile
      );
    }else if (e.target.value.length < 6 && (index+e.target.value.length<=6)) {
      let val = e.target.value.split("");
      let otpNumClone = {...otpNum};
      for(let i=0;i<e.target.value.length;i++){
        otpNumClone[index+i]=val[i];
      }
      setOtpNum(otpNumClone);
      if(index+e.target.value.length==6){
        verifyOtp(
          `${otpNumClone[0]}${otpNumClone[1]}${otpNumClone[2]}${otpNumClone[3]}${otpNumClone[4]}${otpNumClone[5]}`,
          mobile
        );
      }
    }
  };
  return (
    <div class="otp_poup">
      <div class="otp_outer p-20" id="except">
        <div class="font14 color1a1">OTP is sent to number</div>
        <div className="closeBx popup_hdr_close" onClick={closeOtpComp}>
          <i className="time_sob_closeicn"></i>
        </div>
        <div class="font16 fw600 color1a1 mt-5">{mobile}</div>
        <div class="inpt_sctn">
          <span>
            <input
              ref={input_0}
              onChange={(e) => {
                handleEnterOtp(e, 0);
              }}
              value={otpNum[0]}
              class="font16 fw600 color1a1"
              type="number"
              name=""
              min="0"
              max="1"
            />
          </span>
          <span>
            <input
              ref={input_1}
              onChange={(e) => {
                handleEnterOtp(e, 1);
              }}
              value={otpNum[1]}
              class="font16 fw600 color1a1"
              type="number"
              name=""
              min="0"
              max="1"
            />
          </span>
          <span>
            <input
              ref={input_2}
              onChange={(e) => {
                handleEnterOtp(e, 2);
              }}
              value={otpNum[2]}
              class="font16 fw600 color1a1"
              type="number"
              name=""
              min="0"
              max="1"
            />
          </span>
          <span>
            <input
              ref={input_3}
              onChange={(e) => {
                handleEnterOtp(e, 3);
              }}
              value={otpNum[3]}
              class="font16 fw600 color1a1"
              type="number"
              name=""
              min="0"
              max="1"
            />
          </span>
          <span>
            <input
              ref={input_4}
              onChange={(e) => {
                handleEnterOtp(e, 4);
              }}
              value={otpNum[4]}
              class="font16 fw600 color1a1"
              type="number"
              name=""
              min="0"
              max="1"
            />
          </span>
          <span>
            <input
              ref={input_5}
              onChange={(e) => {
                handleVerifyOtp(e.target.value);
              }}
              value={otpNum[5]}
              class="font16 fw600 color1a1"
              type="number"
              name=""
              min="0"
              max="1"
            />
          </span>
        </div>
        {wrongOtp && (
          <div class="dflex mt-15 ">
            <div class="font12 errorcls">Invalid OTP. Please try again.</div>
          </div>
        )}
        <div class="dflex mt-15">
            <div class="font12 color1a1">Didn’t receive the OTP?</div>
            <div
              class={`font12 color007 curs_pointer ${showResendOtp?"":"disabled"}`}
              id="resendButton"
              onClick={() => handleSendOtp()}
            >
              Resend OTP
            </div>
          </div>
      </div>
    </div>
  );
};

export default OtpEnterComponent;
