
let init = {
    show:false
};

const HelpReducer = (state = init, action) => {
    const data = action.data;
    switch (action.type) {
    case 'SET_HELP_SHOW': 
        return {...data, show: true};
    case 'SET_HELP_HIDE': 
        return {...data, show: false};
    default:
        return state;
    }
}

export default HelpReducer;