
let init = {
    show:false
};

const LoaderReducer = (state = init, action) => {
    const data = action.data;
    switch (action.type) {
    case 'SET_LOADER_SHOW': 
        return {...data, show: true};
    case 'SET_LOADER_HIDE': 
        return {...data, show: false};
    case 'SET_SHIMMER_SHOW':
        return {...data, showShimmer: true};
    case 'SET_SHIMMER_HIDE':
        return {...data, showShimmer: false};
    default:
        return state;
    }
}

export default LoaderReducer;