import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import App from "./App";
import "./assets/common.css";
import "./assets/onboarding.css";
import "./assets/introPage.css";
import store from "./MyStore";
import "react-calendar/dist/Calendar.css";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement
);
