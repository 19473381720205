import React, { useEffect, useState } from 'react';

const Shimmer = () => {
        return (
            <div class="dashboard_subcontainer_shimmer">
	<div class="dashboard_anaflex_shimmer">
		<div class="common_dflex_shimmer">
			<div class="common_dflex_left_shimmer shimmer"></div>
			<div class="common_dflex_right_shimmer shimmer"></div>
		</div>
		<div class="common_dflex_shimmer pt-30 pb-30">
			<div class="common_div shimmer"></div>
		</div>
		<div class="dashboard_forbars_shimmer shimmer"></div>
		<div class="dashboard_anaright_shimmer">
			<div class="dashboard_anlyflex_shimmer">
				<div class="dashboard_anlyflexchd_shimmer shimmer"></div>
				<div class="dashboard_anlyflexchd_shimmer shimmer"></div>
				<div class="dashboard_anlyflexchd_shimmer shimmer"></div>
				<div class="dashboard_anlyflexchd_shimmer shimmer"></div>
				<div class="dashboard_anlyflexchd_shimmer shimmer"></div>
				<div class="dashboard_anlyflexchd_shimmer shimmer"></div>
			</div>
		</div>
	</div>
	<div class="statusbox_statuswpr_shimmer">
		<div class="statusbox_statuscard_shimmer">
			<div class="shimmer"></div>
		</div>
		<div class="statusbox_statuscard_shimmer">
			<div class="shimmer"></div>
		</div>
		<div class="statusbox_statuscard_shimmer">
			<div class="shimmer"></div>
		</div>
		<div class="statusbox_statuscard_shimmer">
			<div class="shimmer"></div>
		</div>
		<div class="statusbox_statuscard_shimmer">
			<div class="shimmer"></div>
		</div>
	</div>
</div>
        )
}


export default Shimmer