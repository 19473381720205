import React, {useState, useEffect} from "react";
import {TimeSlotsConsts} from "../../../config/timeSlots";

function timingDropdown() {
    let hours, minutes, ampm;
    let tmpHrs = [];
    //   tmpHrs = [{ time: "Select Time" }];
    for (let i = 0; i <= 1430; i += 15) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes; // adding leading zero
      }
      ampm = hours % 24 < 12 ? "am" : "pm";
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      const time = hours + ":" + minutes + " " + ampm;
      const extraTimeDict = {
        am: ["12:00", "12:15", "12:30"],
        pm: ["12:00", "12:15", "12:30"],
      };
      const extraTextDict = { am: "(MidNight)", pm: "(Noon)" };
      const extraText = extraTimeDict[ampm].includes(hours + ":" + minutes)
        ? extraTextDict[ampm]
        : "";
      tmpHrs.push({
        time,
        extraText,
      });
    }
    tmpHrs.push({ time: "11:59 pm" });
    console.log("*******************************heeee");
    console.log(tmpHrs);
    return tmpHrs;
  }
  const TimeSlots = timingDropdown();

const TimeComp = ({times, selectTime, timeInd, dayInd, handleDeleteTimeSlot, setShowIndex, showIndex}) => {
    const [showTimeDropDown1, setShowTimeDropDown1] = useState(false);
    const [showTimeDropDown2, setShowTimeDropDown2] = useState(false);
    const handleSelectTime = (sETime, time, index) => {
        let x = selectTime({sETime, time, index, timeInd, dayInd});
        if(x){
            setShowTimeDropDown1(false);
            setShowTimeDropDown2(false);
        }
    }

    const handleStartTimeShow = () =>{
        const indd=times.startIndex;
        const id =
        !indd && indd!==0?`${dayInd}${timeInd}startTime39`
          : `${dayInd}${timeInd}startTime${indd + 4}`;
      setTimeout(() => {
        const lastStep = document.getElementById(id);
        lastStep &&
          lastStep.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
      }, 500);
        setShowIndex(timeInd+"_"+dayInd);
        setShowTimeDropDown1(!showTimeDropDown1);
        setShowTimeDropDown2(false);
    }

    const handleEndTimeShow = () =>{
        const indd=times.endIndex;
        const id =
        !indd && indd!==0?`${dayInd}${timeInd}endTime79`
          : `${dayInd}${timeInd}endTime${indd + 4}`;
      setTimeout(() => {
        const lastStep = document.getElementById(id);
        lastStep &&
          lastStep.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
      }, 500);
      setShowIndex(timeInd+"_"+dayInd);
      setShowTimeDropDown2(!showTimeDropDown2);
        setShowTimeDropDown1(false);
    }
    return (
        <div className="timinpwrp mb-15">
        <div className="inpBx">
            <div className="inrbx">
                <input
                className="inputMaterial font14 color1a1"
                type="text"
                required
                name="storeTime1"
                autoComplete="off"
                value={times.startTime}
                onClick={(e) => handleStartTimeShow()}
                label="Select time"
                placeholder="select time"
                readOnly
                />
                <span className="highlight"></span>
                <span className="bar"></span>
                <ul className="selctA">
                    {showTimeDropDown1  &&  showIndex==timeInd+"_"+dayInd &&
                    TimeSlots.map((time, ind) => {
                      let startIndexConst = times.startIndex;
                      if(!startIndexConst)
                      startIndexConst = TimeSlotsConsts.indexOf(times.startTime);
                    
                      return(
                      <li
                            onClick={(e) => handleSelectTime("startTime", time, ind)}
                            key={"slot" + ind + Math.random()}
                            className={ind === startIndexConst ? "act" : ""}
                            id={`${dayInd}${timeInd}startTime${ind}`}
                        >
                        {time.time} {time.extraText}
                        </li>
                        );
                    })}
                </ul>
            </div>
       </div>
        <div className="inpBx">
            <div className="inrbx">
                <input
                className="inputMaterial font14 color1a1"
                type="text"
                required
                name="storeTime2"
                autoComplete="off"
                value={times.endTime}
                onClick={(e) => handleEndTimeShow()}
                label="Select time"
                placeholder="select time"
                readOnly
                />
                <span className="highlight"></span>
                <span className="bar"></span>
                <ul className="selctA">
                    {showTimeDropDown2 && showIndex==timeInd+"_"+dayInd &&
                    TimeSlots.map((time, ind) => {
                      let endIndexConst = times.endIndex;
                      if(!endIndexConst)
                      endIndexConst = TimeSlotsConsts.indexOf(times.endTime);

                     return (
                      <li
                        onClick={(e) => handleSelectTime("endTime", time, ind)}
                        key={"slot" + ind + Math.random()}
                        className={ind === endIndexConst ? "act" : ""}
                        id={`${dayInd}${timeInd}endTime${ind}`}
                        >
                        {time.time} {time.extraText}
                        </li>
                        );
                  })}
                </ul>
            </div>
       </div>
    {timeInd!=0 && 
        <div class="closeBx" onClick={()=>handleDeleteTimeSlot(timeInd, dayInd)}> <i class="time_sob_closeicn"></i></div>
    }
       </div>
    );
}

export default TimeComp;