import PriceCalLeft from '../common/PricingCalculator/pricecalleft';
import PriceCalRight from '../common/PricingCalculator/pricecalright';
import '../common/PricingCalculator/commoncalc.css';
// import {DASHBOARD_API,DASHBOARD_IP} from '../../constants';
import {URI} from '../common/PricingCalculator/configs';

import axios from "axios";
// import NotUser from '../../components/utils/notuser';
// import {nativeLogin,redirectLogin,loginData, curriedClickTracker,getUserInfo} from '../../libs/common';
import { useEffect, useState, useRef} from 'react';
import {useDispatch,useSelector} from 'react-redux';
// import { userInfoAdd } from "../../store/actions/user";
import PricingCalShimmer from '../common/PricingCalculator/pricingcalshimmer';


export const getCurrentURIParams = () => {
    let stNeed = [
      "sid"
    ];
    let objParams = {};
    let urlParams = window.location.href.split("?")[1];
    let urlParamsArr = [];
    if (urlParams) {
      urlParamsArr = urlParams.split("&");
    }
    urlParamsArr.forEach((element) => {
      let elName = element.split("=")[0];
      let elValue = element.split("=")[1];
      if (stNeed.indexOf(elName) > -1) {
        objParams[elName] = elValue;
      }
    });
    return objParams;
  };


 function PriceCal(props) {
	const dispatch = useDispatch();
  	const user = useSelector(store => store.user);
  	const currentStore = useSelector(store => store.currentStore)
  	const [loggedIn,setLoggedIn] = useState(true);
	const [closePopUp, setClosePopUp] = useState(false);
	const [noListing, setNoListing] = useState(false);
	const [loader, setLoader] = useState(true);
  	const [toastMsg, setToastMsg ] = useState("");
	const [ srchTxt, setSrchTxt ] = useState("");
  	const [ results, setResults ] = useState([]);
	const [ selCat, setSelCat ] = useState({});
	const [ selCatComm, setSelCatComm ]=useState({});
	const [ sp, setSp ] = useState(500)
	const [ autoSuggestOn, setAutoSuggestOn ] = useState(false)
	const [showShimmer, setShowShimmer ] = useState(true);
	const initialLoad = useRef(true)
	const loadShimmer = useRef(true);
	const [diffLoginPopUp, setDiffLoginPopUp] = useState(false);
	if(typeof window != "undefined"){
		// window.globalLogin = nativeLogin.bind(this);
		// window.getLoginData = loginData(router.query.source,dispatch,userInfoAdd,setLoggedIn);
	}

	//useEffect(()=>{
		// if(Object.keys(user).length === 0){
		// 	if(parseInt(router.query.source) === 51){
		// 		var logjson = JdLiteInterface.getLoginData();
		// 		if(logjson && logjson != ''){
		// 			var logresponse = JSON.parse(logjson);
		// 			let  userInfo = {
		// 				mobile:logresponse.mobile,
		// 				source:router.query.source,
		// 				sid:logresponse.sid,
		// 				deviceId:logresponse.device_details.deviceid,
		// 				version :logresponse.devicebuildversion,
		// 			} 
		// 			dispatch(userInfoAdd(userInfo));
		// 			setLoggedIn(true);
		// 		} else {
		// 			var logjson = JSON.stringify({'fn':'globalLogin'});
		// 			JdLiteInterface.verticalLogin(logjson);
		// 		}
		// 	} else if(parseInt(router.query.source) === 53){
		// 		var logjson = JSON.stringify({'type':'verticalLogin','fn':'getLoginData'});
		// 		window.webkit.messageHandlers.callbackHandler.postMessage(logjson);
		//   } else {
		// 		let userdetails = getUserInfo(router.query.source);
		// 		console.log(userdetails);
		// 		let  userInfo = {
		// 			mobile:userdetails.usermobile,
		// 			source:router.query.source,
		// 			sid:userdetails.usersid ? userdetails.usersid :"",
		// 			deviceId:userdetails.deviceid,
		// 			version:router.query.version ? router.query.version :"",
		// 		} 
		// 		dispatch(userInfoAdd(userInfo));
		// 		if(parseInt(router.query.source) === 3 && userdetails.usermobile){
		// 			setLoggedIn(true);
		// 		} else {
		// 			let newQs = { ...router.query }
		// 			newQs = new URLSearchParams(newQs)
		// 			const { origin, pathname } = window.location
		// 			const routeURL = `${origin}${pathname}`
		// 			if(!newQs.get("source")){
		// 				newQs.set("source", "2")
		// 				window.location.href = `${routeURL}?${newQs.toString()}`
		// 				return;
		// 			}
		// 			setLoggedIn(props.data.results.loggedIn);
		// 		}
		// 	}
		// }
//},[router,dispatch,props.data.results.loggedIn,setLoggedIn,user])

	useEffect(() => {
	   async function fetch(){
		try {
            const formData = new FormData();
            formData.append("case", "commissionInfo"); //append the values with key, value pair
            formData.append("action", "autoSugg"); 
            formData.append("srchtrm", "mobi");
            formData.append("type", "1");
            const productBankList = await axios.post(URI, formData);
			// const productBankList = await axios.get(`${URI}/api/getProductBankAutoSuggest`)
			const product = productBankList.data.results.products[0] 
      setSrchTxt(product.name)
			initialLoad.current = true
			setSelCat(product)
		} catch (error) {
			console.log(error)
		}
	   }
	   fetch()
	}, [])

	useEffect(() => {
		if(!sp || Object.keys(selCat).length === 0){
			setSelCatComm({})
		}
		if(!sp){
			return;
		}
		async function fetch(){
			try {
				const { id, kflag, psv_node_id } = selCat;
				let type = !kflag ? "products" : ( kflag === 2 ? "categories": "keywords" );
				let params= {};
				if(type === "products") {
					params = {
						type,
						sp,
						product_id:id
					}
				}else {
					params = {
						type,
						sp,
						psv_node_id,
						keyword_id:id
					}
				}
				let productCommission, comCommission;
				if(type === "products") {
					const formData = new FormData();
                formData.append("case", "commissionInfo"); //append the values with key, value pair
                formData.append("action", "comprod"); 
                formData.append("product_id", id);
                formData.append("sp", sp);
                 productCommission = await axios.post(URI, formData);
				 const formData2 = new FormData();

					formData2.append("case", "commissionInfo"); //append the values with key, value pair
					formData2.append("action", "comcp"); 
					formData2.append("sp", sp);
					formData2.append("psv_node_id", "");
					formData2.append("ptype", type);
					formData2.append("keyword_id", productCommission.data.data.kid);
					comCommission = await axios.post(URI, formData2);
				}else{
					const formData2 = new FormData();

					formData2.append("case", "commissionInfo"); //append the values with key, value pair
					formData2.append("action", "comcp"); 
					formData2.append("sp", sp);
					formData2.append("psv_node_id", psv_node_id);
					formData2.append("ptype", type);
					formData2.append("keyword_id", id);
					comCommission = await axios.post(URI, formData2);
				}

			    comCommission = comCommission.data;
				setSelCatComm(comCommission);
				if(loadShimmer.current){
					loadShimmer.current = false;
					setShowShimmer(false);
				}
			} catch (error) {
				console.log(error)
			}
		}
		if(selCat && Object.keys(selCat).length > 0){
			fetch()
		}
	}, [ selCat, sp ])

	// if(loggedIn !== true){
	// 	let url = `${DASHBOARD_API}?${qs}`;
	// 	redirectLogin(url, router.query.source);
	// 	return(
	// 		<NotUser 
	// 			loggedin={false}
	// 		/>
	// 	)
	// }
    let r = getCurrentURIParams();
  return (
    <div className={(parseInt(r.source) == 51 || parseInt(r.source) == 53)? `${"container"} ${"appclass"}`:`${"container"}`}>
			{!noListing && !showShimmer && 
			<div className={`${"subcontainer"}`}>
				<div className={`${"font26"} ${"color111"} ${"fw500"} ${"prclfthdng"}`}>Pricing Calculator</div>
				<div className={"spmainwrapper"}>
					<PriceCalLeft 
						srchTxt ={srchTxt}
						setSrchTxt={setSrchTxt}
						results={results}
						setResults={setResults}
						selCat={selCat}
						setSelCat={setSelCat}
						initialLoad={initialLoad}
						sp={sp}
						setSp={setSp}
						autoSuggestOn={autoSuggestOn}
						setAutoSuggestOn={setAutoSuggestOn}
					//	saveClick={saveClick}
					/>
					<PriceCalRight 
						selCatComm={selCatComm}
						sp={sp}
					/>
				</div>  
			</div>
			}
      {showShimmer && 
        <PricingCalShimmer/>
      }
      {/* {
				closePopUp && <PopUps setClosePopUp={setClosePopUp}/>
			} */}
	  	{/* {
			diffLoginPopUp && <DiffDocidPopUp 
								setDiffLoginPopUp={setDiffLoginPopUp}
							  />
		} */}
	</div>
  )
}

export default PriceCal;
