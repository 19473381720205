
let init = {
    currentStep: 'businessDet'
};

const StepsArr = [
    "businessDet",
    "locationDet",
    "contactDet",
    "commissionDet",
    "pickupDet",
    "deliveryDet",
    "storeTimeDet",
  ];

const stepArrMap = {
    'SAVE_BUSINESS_DETAILS':"businessDet",
    'SAVE_LOCATION_DETAILS':"locationDet",
    'SAVE_PICKUP_DETAILS':"pickupDet",
    'SAVE_CONTACT_DETAILS':"contactDet",
    'SET_STORE_TIMING':"storeTimeDet"   
}

const locationReducer = (state = init, action) => {
    const {maxUrlFlag} = state;
    switch (action.type) {
    case 'SET_LOCATION': 
        return {
            ...state, currentStep: action.data
        }
    case 'LOADED_PRE_FILLED_BUSINESS_DATA': 
        return {
            ...state, loadedPreFilledData: action.data
        }
    case "LOAD_PREFILLED_BUSINESS_DATA":
        let dataPathMap = {
            businessDetails: "businessDet",
            locationDetails:"locationDet", 
            pickupDetails:"pickupDet", 
            contactDetails:"contactDet", 
            storeTimingDetails:"storeTimeDet"   
        };
        let newFlag2 = "";
        if(action.data["storeTimingDetails"] && action.data["storeTimingDetails"]["storeTimings"]){
            newFlag2 = dataPathMap["storeTimingDetails"];
        }else if(action.data["pickupDetails"] && action.data["pickupDetails"]["storeArea"]){
            newFlag2 = dataPathMap["pickupDetails"];
        }else if(action.data["contactDetails"] && action.data["contactDetails"]["contactPersonName"]){
            newFlag2 = dataPathMap["contactDetails"];
        }else if(action.data["locationDetails"] && action.data["locationDetails"]["storeArea"]){
            newFlag2 = dataPathMap["locationDetails"];
        }else if(action.data["businessDetails"] && action.data["businessDetails"]["legalCompanyName"]){
            newFlag2 = dataPathMap["businessDetails"];
        }
        return {
            ...state,
            ...action.data.locationReducer,
            maxUrlFlag: newFlag2
        };
    case "CLEAR_INITIAL_DATA":
        return {
            ...state,
            maxUrlFlag: ""
        };
    case 'SAVE_URL_PARAMS':
        return {
            ...state, urlParam: action.data
        }
    case 'SAVE_URL_PARAMS_OBJ':
        return {
            ...state, urlParamObj: action.data
        }
    case 'SET_LOGGED_IN': 
        return {
            ...state, loggedIn: true, ln_mobile: action.data.mobile //Don't remove this as API functionality is dependent on this node. 
        }
    case 'SET_BUSINESS_SELECT_FLAG':
        return {
            ...state, businessSelectedFlag: true
        }
    case 'SET_BUSINESS_CREATE_FLAG':
        return {
            ...state, businessCreateFlag: true
        }
    case 'SAVE_BUSINESS_DETAILS':
        case 'SAVE_LOCATION_DETAILS':
            case 'SAVE_PICKUP_DETAILS':
                case 'SAVE_CONTACT_DETAILS':
                    case 'SET_STORE_TIMING':   
    let newFlag = "";
    let curStep = stepArrMap[action.type];
        if(StepsArr.indexOf(maxUrlFlag)<=StepsArr.indexOf(curStep)){
            newFlag=curStep;
        }else{
            newFlag=maxUrlFlag;
        }
        if(!maxUrlFlag){
            newFlag=curStep;
        }
    return {
        ...state, maxUrlFlag: newFlag
        }
    case "SET_INITIAL_BUSINESS_DATA":
        return {
            ...state, businessesLoadedFlag: true
        }
    case "CLEAR_INITIAL_DATA":
    return {
        ...state, urlParam: undefined, urlParamObj: undefined, maxUrlFlag: undefined
        };
    case "NO_BUSS_DET":
    return {
        ...state, noBussData: true
    };
    case "LOADED_BUSS_DET":
    return {
        ...state, yesBussData: true
    };
    case "LOADED_TEMP_DET":
    return {
        ...state, yesTempData: true
    };
    case "NO_TEMP_DET":
        return {
            ...state, noTempData: true
        };
    case "SET_GENIO_REFER_FLAG": 
        return {
           ...state, genioReferFlag: 1
        }
    case "SET_EMPLOYEE_CODE": 
        return {
           ...state, employeeCode: action.data
        }
    case "RESET_GENIO_REFER_FLAG": 
        return {
           ...state, genioReferFlag: 0
        }
    case "IS_PAID_CONTRACT":
        return {
            ...state, isPaidContract: 1
        }
    case "IS_NOT_PAID_CONTRACT":
        return {
            ...state, isPaidContract: 0
        }
    case "IS_OUTLET_CONTRACT":
        return {
             ...state, isOutletContractFlag: action.isOutlet
        }
    case "IS_LOGISTIC_DELIVERY":
        return {
            ...state, isDeliveryFlag: "2"
        }
    case "IS_NOT_LOGISTIC_DELIVERY":
        return {
            ...state, isDeliveryFlag: "0"
        }
    case "CLEAR_INITIAL_DATA":
    return {
        ...state, urlParam: undefined, urlParamObj: undefined, maxUrlFlag: undefined
        };
    case "SET_DELIVERY_CHARGE_DATA":
        return {
            ...state, deliveryChargeData: action.data
        }
    case "SAVE_JD_UID": 
        return {
            ...state, jduid: action.data
        }
    default:
        return state;
    }
}

export default locationReducer;
