import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

import { getURIParams, getGetRedirectAPI, getCurrentURIParams, getSource } from "../../services/common";
import { useNavigate, useLocation } from "react-router-dom";

import {getSteps, showBreadCrumbs} from "../../config/verticalConfig";
import {StepTabWLabels} from "../../config/consts";
const StepTab = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
 const [Steps, setSteps] = useState([]);
 /* locationReducer,maxUrlFlag */
 let {maxUrlFlag, isDeliveryFlag} = useSelector((state)=>state.locationReducer);
  const compData = useSelector((state) => {
    return state;
  });

  const MandatoryCheckArr = [
    {fields: ["storeName", "legalCompanyName", "businessCat"], name: "businessDetails"}, 
    {fields: ["buildingName", "pincode", "city", "state"], name: "locationDetails"}, 
    {fields: ["contactPersonName", "mobileNumber", "businessEmail"], name: "contactDetails"},
    {fields: [], name: "offerDet"}
  ]
/*
storeName
legalCompanyName
businessCat

buildingName
pincode
city
state

contactPersonName
mobileNumber
businessEmail

*/


 useEffect(()=>{
  const teps = getSteps(isDeliveryFlag);
  setSteps(teps);
 }, [isDeliveryFlag])

  const [stepPath, setStepPath] = useState(Steps);
  const [currentStep, setCurrentStep] = useState();
  
  useEffect(()=>{
    let pn = location.pathname.split("/")[2];
    setCurrentStep(pn);
  },[location.pathname]);
  
  
  useEffect(() => {
    let stepClone = [...Steps];
     let newArr = stepClone.slice(Steps.indexOf(currentStep));
     setStepPath(newArr);
  }, [currentStep, Steps]);

  const handleBreadCrumbNavigate = (nTo) => {
    let urlParams = getURIParams();
    let indOfNto = Steps.indexOf(nTo);
    let checkMandatoryTill = indOfNto;
    let notN;
      for(let i=0; i<MandatoryCheckArr.length;i++){
        const r = MandatoryCheckArr[i];
        if(i<=checkMandatoryTill){
          let data = compData[r.name];
          let empty = false;
          r.fields.forEach((field, i)=>{
            if(!data[field] && data[field]==""){
              empty = true;
            }
          })
          if(!empty){
            notN = i; 
          }else{
            break;
          }
        }
      }






    if(indOfNto-1<=notN || notN>=3){
        if(nTo=="commissionDet"){
          window.location.href = getGetRedirectAPI();
        }else if(nTo=="qrDet"){
          window.location.replace(getGetRedirectAPI(undefined, true));
        }else{
          navigate(`/businessDetails/${nTo}?${urlParams}`);
        }
    }
  }

  return (
    showBreadCrumbs &&
    <>
    <div className="step_tab_wpr mt-30" id="breadCrumbs">
    <ul className="font11 color788">
      {Steps && Steps.map((row, i)=>
       <li
       className={
         stepPath.indexOf(row) == 0
           ? "active"
           : stepPath.indexOf(row) < 0
           ? "active_complete"
           : ""
       }
       id={i==0?"firstStep":i==Steps.length-1?"lastStep":""}
       onClick={()=> handleBreadCrumbNavigate(row)}
     >
       {StepTabWLabels[row].split("~")[0]}<span className="nextline">{StepTabWLabels[row].split("~")[1]}</span>
       <span className="step_circle">
         <span className="white_circle"></span>
       </span>
     </li>
      
      )
    }
    </ul>
  </div>
  </>
  );
};

export default StepTab;
