import React from 'react';

const OffersTextFields = ({handleChange, fieldName, fieldLabel, fieldVal, mandatory, type, maxLength, sMediaText}) =>{

    return (
        <div className="group mb-20">
            <input 
                className="inputMaterial font16 fw600"
                id={fieldName+"_id"}
                type={type=="number"?"tel":"text"} 
                required="" 
                onChange={(e)=>handleChange(e)} 
                name={fieldName} 
                value={fieldVal}
                onClick={()=>{
                    const idFocus = document.getElementById(fieldName+"_id")
                setTimeout(() => {
                    idFocus && idFocus.scrollIntoView({behavior: "smooth", block: "center"});
                },300)
                }} 
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label className="label-wrap font16">{fieldLabel}{mandatory?" *":""}</label>
            <span>{sMediaText}</span>
         </div>
    )
}

export default OffersTextFields;
