import { useDispatch } from "react-redux";

import './commoncalc.css';
import './pricecaleft.css';
import AutoSuggest from './autosuggest/autosuggest';
import SearchBar from './searchbar/searchbar';

import { Fragment, useEffect, useRef, useState } from 'react';
import {
    showToastAction,
    hideToastAction
  } from "../../../actions/actions";
// import {Toaster} from '../../components/utils/common';

function PriceCalLeft(props){
    const dispatch = useDispatch();
    const { srchTxt, setSrchTxt, results, setResults, selCat, setSelCat, initialLoad, sp, setSp, autoSuggestOn, setAutoSuggestOn } = props
    const [ localSp, setLocalSp ] = useState(sp);
    const onBlurMsg = useRef(null)
    const [ toastMsg, setToastMsg ] = useState("");
    const autoSuggestClose = useRef(false)
    const generateMsg = (msg, delay) => {
        setToastMsg(msg)
        setTimeout(() => {
            setToastMsg("")
        }, delay)
    }

    const formatInput = (e,dec) => {
		// Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
        if(e.key === 'Enter' || e.keyCode === 13){
            e.preventDefault();
            e.target.blur()
            return;
        }
		let checkIfNum;
		if (e.key !== undefined) {
			// Check if it's a "e", ".", "+" or "-"
			if(dec === true){
				checkIfNum = e.key === "e" || e.key === "+" || e.key === "-" ;
			} else {
				checkIfNum = e.key === "e" ||  e.key === "." || e.key === "+" || e.key === "-" ;
			} 
		}
		else if (e.keyCode !== undefined) {
			// Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
			if(dec === true){
				checkIfNum = e.keyCode === 69 ||  e.keyCode === 187 || e.keyCode === 189;
			} else {
				checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
			}
        }
		return checkIfNum && e.preventDefault();
    }
    
    const valPriceLen = (val) => {
        if(onBlurMsg.current){
            generateMsg(onBlurMsg.current, 4000)
            return
        }
    }

    const onSPChange = (evt) => {
      const val = evt.target.value
      let msg = false;
      if(val === ""){
        msg = "Price should be greater than 20";
         setLocalSp(val)
         setSp(0)
         return;
      }

      const newVal = parseFloat(val);
      if(newVal>= 20 && newVal <= 1000000){
        msg = false;
        setLocalSp(newVal)
        setSp(newVal);
      }else if(newVal < 20) {
        msg = "Price should be greater than 20";
        setLocalSp(newVal)
        setSp(0)
      }else{
        msg = "Price should be less than 1000000";
        evt.target.blur()
      }
      if(msg){
        dispatch(showToastAction(msg));
        setTimeout(function () {
          dispatch(hideToastAction());
        }, 3000);
      }
    }

    useEffect(() => {
        if(autoSuggestOn){
            document.getElementById("pricecalsearch").focus();
        }
    }, [ autoSuggestOn ])

    return(
        <Fragment>
        <div className="spleftwrapper vm">
            <div className="inner_spleftwrap">
                <div>
                    {autoSuggestOn ? <AutoSuggest srchTxt ={srchTxt}
                                            setSrchTxt={setSrchTxt}
                                            results={results}
                                            setResults={setResults}
                                            setSelCat={setSelCat}
                                            initialLoad={initialLoad}
                                            autoSuggestOn={autoSuggestOn}
                                            setAutoSuggestOn={setAutoSuggestOn}
                                            autoSuggestClose={autoSuggestClose}
                                            // saveClick={saveClick}
                                        /> :               
                                        <SearchBar  
                                            setSrchTxt={setSrchTxt} 
                                            srchTxt={srchTxt} 
                                            setResults={setResults} 
                                            setSelCat={setSelCat}
                                            results={results}
                                            autoSuggestClose={autoSuggestClose}
                                            autoSuggestOn={autoSuggestOn}
                                            setAutoSuggestOn={setAutoSuggestOn}
                                          //  saveClick={saveClick}
                                        />
                    }            
                </div> 
            </div>
            <div className="inner_sprightwrap">
                <div className="pb10 mb25">
                    <div className="font12 color777 mb5">Selling Price (Incl of Shipping charges)</div>
                    {
                        <input className="borderbottom font16 fw500 color111 inputarrow_dn" type="number" value={localSp} onChange={(e)=> {onSPChange(e);}}  onKeyDown={(event)=>{formatInput(event,true)}}
                        onBlur={(e)=>valPriceLen(e.target.value)}
                        />
                    }
                </div>
            </div>
        </div>
        {/* {
            toastMsg && <Toaster msg={toastMsg}/>
        } */}
        </Fragment>
    )
}

export default PriceCalLeft;
