import React, { useEffect, useState } from "react";
// apiservice
import {
  createOrderId,
  getCurrentURIParams,
  getURIParams,
  getSecurePGURL,
  redirectToDashboard,
  redirectToTaxDetails,
  getPackageDetails,
} from "../../services/common";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { hideShimmerAction } from "../../actions/actions";
import MyHeader from "../common/MyHeader";
import Alert from "../common/alert";

const ChoosePlan = (props) => {
  const [currentPlan, setPlan] = useState(1);
  const [showButtonLoader, setButtonLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [lbtn, setLbtn] = useState(false);
  const [lbtnText, setLbtnText] = useState("");
  const [rbtn, setRbtn] = useState(false);
  const [rbtnText, setRbtnText] = useState("");
  const [goToDashboard, setGoToDashboard] = useState(true);
  const [showAPIError, setAPIError] = useState(false);
  const [APIErrorText, setAPIErrorText] = useState("");
  const [attributeMaster, setAttributeMaster] = useState([]);
  const [packageInfo, setPackageInfo] = useState([]);
  const locationDetails = useSelector((state) => state.locationDetails);

  // componentDidMount
  useEffect(() => {
    if(showAlert){
      setShowAlert(false);
      setAlertMsg("");
    }
    window.addEventListener("click", (e) => {
      if (e.target.classList.contains("detail_popup_wpr")) {
        setShowAlert(false);
        setAlertMsg("");
        window.history.go(-1);
        document.getElementsByTagName("body")[0].classList.remove("bodyfixed");
      }
    });
    window.addEventListener("popstate", hideAlert);
    getPackageDetails().then((resp) => {
      // setPackageInfo(resp.data.package_info);
      let active_plan = {};
      if (resp.data && resp.data.package_info) {
        active_plan = resp.data.package_info.filter((plan, idx) => {
          return plan.active == 1;
        });
        // ||  window.location.host.includes("172.29.86.26")
        // if (window.location.host.includes("staging")) {
        //   active_plan = resp.data.package_info.filter((plan, idx) => {
        //     return plan.package_amount == 1;
        //   });
        // } else {
        //   active_plan = resp.data.package_info.filter((plan, idx) => {
        //     return plan.active == 1;
        //   });
        // }
      }

      setPackageInfo(active_plan);
      setAttributeMaster(resp.data.attributes_master);
    });
  }, []);

  // componentWillUnmount
  useEffect(() => {
    return () => {    
      window.removeEventListener("popstate", hideAlert);
    };
  }, []);

  const hideAlert = () => {
    setShowAlert(false);
    document.getElementsByTagName("body")[0].classList.remove("bodyfixed");
  };
  // choose plan and proceed
  const handleSave = () => {
    setButtonLoader(true);
    setAPIError(false);
    setAPIErrorText("");
    let r = getCurrentURIParams();
    let source = r.source;
    // createOrderId(deposit_amount, package_amount, gst_amount)
    createOrderId(
      packageInfo[0].deposit_amount,
      packageInfo[0].package_amount,
      packageInfo[0].gst_amount,
      packageInfo[0].amount_including_gst,
      packageInfo[0].package_id
    )
      .then((resp) => {
        // resp.data.error = { code: 5, msg: "test" };
        if (resp.data.error.code === 0 || resp.data.error.code === "0") {
          //   Finally redirect to securePG

          let redirect_url = getSecurePGURL(resp.data.results.orderid);
          window.location.href = redirect_url;
        } else if (resp.data.error.code === 1 || resp.data.error.code === "1") {
          setShowAlert(true);
          setAlertMsg(
            resp.data.error.msg
              ? resp.data.error.msg + " " + locationDetails.companyname
              : ""
          );
          window.history.pushState(
            null,
            null,
            window.location.pathname + window.location.search
          );
          document.getElementsByTagName("body")[0].classList.add("bodyfixed");
          setGoToDashboard(true);
          setLbtn(false);
          setRbtn(true);
          setRbtnText("Click here to go to Dashboard");
        } else if (resp.data.error.code === 5 || resp.data.error.code === "5") {
          setShowAlert(true);
          setAlertMsg(
            resp.data.error.msg
              ? resp.data.error.msg + " " + locationDetails.companyname
              : ""
          );
          window.history.pushState(
            null,
            null,
            window.location.pathname + window.location.search
          );
          document.getElementsByTagName("body")[0].classList.add("bodyfixed");
          setGoToDashboard(false);
          setLbtn(true);
          setLbtnText("Skip");
          setRbtn(true);
          setRbtnText("Go to Tax details");
        } else {
          setAPIError(true);
          setAPIErrorText(resp.data.error.msg);
        }
      })
      .catch((err) => {})
      .finally(() => setButtonLoader(false));
  };

  const getPlans = () => {
    return packageInfo.map((item, index) => {
      return (
        <div
          key={`${item}${index}`}
          className={"planselbx " + (currentPlan === index ? "active" : "")}
          onClick={() => setPlan(index)}
        >
          <div className="headbx text_uppercase font8 color111 fw500">
            Upgrade to
          </div>
          <div className="contbx">
            <div className="font11 color777 fw500 mb-10 titl">
              {item.package_name}
            </div>
            <div className="font20 color111 fw700 totlbx">
              <span className="font11 fw600 rsicn">&#8377; </span>
              {item.total_amount}
              <span className="font10  color111 fw500 gstTx">+GST </span>
            </div>
          </div>
        </div>
      );
    });
  };

  const getPlanDetails = () => {
    return packageInfo.map((item, index) => {
      return (
        <div
          key={`${item}${index}`}
          className={
            "chsplndcell colomt2 " + (currentPlan === index ? "active" : "")
          }
          onClick={() => setPlan(index)}
        >
          <div className="headtd"> {item.package_name}</div>
          {attributeMaster.map((attr, attridx) => {
            if (item.attribute_list.includes(parseInt(attr.id))) {
              if (attr.key) {
                return (
                  <div
                    key={`${attr}${attridx}`}
                    className={`conttd roww${attridx + 1}`}
                  >
                    <div className="font12 color111 fw500">
                      &#8377;{item[attr.key]}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    key={`${attr}${attridx}`}
                    className={`conttd roww${attridx + 1}`}
                  >
                    <i className="green_tick_icon"> </i>
                  </div>
                );
              }
            } else {
              return (
                <div
                  key={`${attr}${attridx}`}
                  className={`conttd roww${attridx + 1}`}
                >
                  <i className="closelgrey-icn"> </i>
                </div>
              );
            }
          })}
        </div>
      );
    });
  };

  const getAttributes = () => {
    return attributeMaster.map((item, index) => {
      return (
        <div key={`${item}${index}`} className={`conttd roww${index + 1}`}>
          {item.atttibute_name}
        </div>
      );
    });
  };

  return (
    <>
      <MyHeader headerText="Payment Plan" />

      <div class="choose_single_plan_wpr p-20 pb-70">
        <div class="text_center font22 fw500 color1a1">
          Make payment to start selling on Justdial
        </div>

        <div class="setupplan_wpr pl-24 pr-24 mt-20 pb-20">
          <div class="setupplan_outer text_center">
            <div class="setupplan_text font12 fw500 colorfff">Setup Plan</div>
            <div class="setupplan_amnt fw700 colorfff">
              <span class="font24 fw500">
                &#8377;{packageInfo[0]?.amount_including_gst}
              </span>
            </div>
          </div>
          <div class="benefitstext font13 fw500 color111 mt-20">
            Benefits are here:
          </div>
          <ul class="planlist mt-15 font13 fw500 color1a1">
            <li>Quick catalog creation from exhaustive catalog library</li>
            <li>Justdial takes care of delivery</li>
            <li>Lowest commission as low as 2%</li>
            <li>No payment gateway charges</li>
          </ul>
        </div>

        <div class="notetext mt-20">
          <ul class="color777 font12">
            <li>Security deposit fee can be refunded anytime</li>
            <li>Setup fee cannot be refunded</li>
            <li>18% gst is applied on only setup fee</li>
          </ul>
        </div>

        <div class="planbrakeup_wpr mt-20">
          <div class="font12 fw500 color777">Plan Brakeup</div>
          <ul class="font14 color111 mt-10">
            <li>
              <div>Security Deposit</div>
              <div class="fw600">&#8377;{packageInfo[0]?.deposit_amount}</div>
            </li>
            <li class="mt-10">
              <div>
                One time setup fee
                <span class="font11 color777 nextline mt-5">
                  GST on Setup fee (18%)
                </span>
              </div>
              <div class="fw600 text_right">
                &#8377;{packageInfo[0]?.package_amount}
                <span class="font11 color777 nextline  mt-5">
                  &#8377;{packageInfo[0]?.gst_amount}
                </span>
              </div>
            </li>
          </ul>
          <div class="planbrakeup_final mt-20 pt-15 pb-15">
            <div class="font16 fw600 color111">Amount Payable</div>
            <div class="font16 fw700 color111">
              &#8377;{packageInfo[0]?.amount_including_gst}
            </div>
          </div>
        </div>
      </div>
      {!!(showAPIError && APIErrorText) && (
        <div className="apierrortxt">{APIErrorText}</div>
      )}

      <div className="ftr_btn skip_save_ftr_btn">
        <button
          className="skipbtn font14 fw600 color414"
          onClick={redirectToDashboard}
        >
          Maybe Later
        </button>
        <button
          className="savenext_btn font14 fw600 colorfff"
          onClick={handleSave}
        >
          {!showButtonLoader ? (
            <>
              Proceed to Pay<i className="white_right_icn"> </i>
            </>
          ) : (
            <span class="dotloader">
              <span />
              <span />
              <span />
              <span />
              <span />
            </span>
          )}
        </button>
      </div>

      {/* <div className="choose_plan_wrp">
        <div className="font18 color1a1 fw700 mt-20 mb-30 pl-20 pr-20 text_center">
          Upgrade to get more out of your Jd Business subscription
        </div>
        <div className="chsplnwrp">
          <div className="chsplndtable">
            <div className="chsplndcell colomt1">
              <div className="headtd"> Compare Benefits</div>
              {getAttributes()}
            </div>
            {getPlanDetails()}
          </div>
        </div>
        <div className="planselbxwrp">{getPlans()}</div>
        {!!(showAPIError && APIErrorText) && (
          <div className="apierrortxt">{APIErrorText}</div>
        )}
        <div className="ftr_btn skip_save_ftr_btn posRelBtn">
          <button
            className="skipbtn font14 fw600 color414"
            onClick={redirectToDashboard}
          >
            Skip
          </button>
          <button
            className="savenext_btn font14 fw600 colorfff"
            onClick={handleSave}
          >
            {!showButtonLoader ? (
              <>
                Proceed<i className="white_right_icn"> </i>
              </>
            ) : (
              <span class="dotloader">
                <span />
                <span />
                <span />
                <span />
                <span />
              </span>
            )}
          </button>
        </div>
        <div className="notebxwrp font12 color777">
          <div className="mb-5"> Note: </div>
          <div>
            * Security deposit fee can be refunded anytime by accessing payment
            settings from business dashboard. <br />
            * Setup fee cannot be refunded
            <br />
            * 18% gst is applied to the total amount being paid
            <br />
          </div>
        </div>
      </div> */}
      {/* Alert */}
      <div id="chooseplanalert">
        <Alert
          show={showAlert}
          msg={alertMsg}
          rbtn={rbtn}
          rbtnText={rbtnText}
          rbtnAction={()=>{
            if(showAlert){
              setShowAlert(false);
              setAlertMsg("");
              document.getElementsByTagName("body")[0].classList.remove("bodyfixed");
            }
            goToDashboard ? redirectToDashboard() : redirectToTaxDetails()
          }
          }
          lbtn={lbtn}
          lbtnText={lbtnText}
          lbtnAction={()=>{
            if(showAlert){
              setShowAlert(false);
              setAlertMsg("");
              document.getElementsByTagName("body")[0].classList.remove("bodyfixed");
            }
            redirectToDashboard()
          }}
        />
      </div>
      {/* Alert */}
    </>
  );
};

export default ChoosePlan;
