import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showShimmerAction } from "../../actions/actions";

import { redirectToDashboard } from "../../services/common";

const ThankYou = () => {
  const LoaderReducer = useSelector((state) => state.LoaderReducer);
  const LoaderDispatcher = useDispatch();

  //   useEffect(() => {
  //     LoaderDispatcher(showShimmerAction());
  //     alert("hide shrim");
  //   }, []);

  return (
    <>
      <div className="thankuwpr">
        <div className="thankuiconwpr">
          <span className="helpsucess animated zoomIn"></span>
        </div>
        <div className="thankutextprnt">
          <span className="thankutext font24 color19c fw600">
            Congratulations!
          </span>
          <span className="thankusum font14 color1a1 fw600">
            Your payment successfully done
          </span>
          <div
            className="font14 color007 curs_pointer mt-20"
            onClick={redirectToDashboard}
          >
            Click here to go to dashboard
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
