import axios from "axios";
import { getCurrentURIParams, getCookie, getSid, getJDUID} from "./common";
import store from "../MyStore";
import { ONBOARDING_VERTICAL} from "../config/consts"; 

import { NOTIFY_URI, URI, SELF_URI } from "./configs";

export const getVerticalId = () => {
  let uriParams = getCurrentURIParams();
  return uriParams["vertical"]?uriParams["vertical"]: ONBOARDING_VERTICAL;
}

export const addDeliveryBoy = (name, mobile, img_url, edit) => {
    let r = getCurrentURIParams();
    let sid = getSid();
    let docid = r.docid;
    let { contactDetails } = store.getState();
    let formData = new FormData()
      const config = {
        headers: { "content-type": "multipart/form-data" }
      }
      if(edit){
        formData.append("case", "editAgent");
      }else{
        formData.append("case", "addAgent");
      }
      formData.append("name", name);
      formData.append("mobile", mobile);
      formData.append("img", img_url);
      formData.append("sid", sid);
      formData.append("docid", docid);
      const verticalId = getVerticalId();
      formData.append("verticalId", verticalId);
      const jduid = getJDUID();
      formData.append("jduid", jduid);
      return axios.post(URI, formData, config);
}

export const getDeliveryAgentList = (name, mobile, img_url) => {
    let r = getCurrentURIParams();
    let sid = getSid();
    let docid = r.docid;
    let { contactDetails } = store.getState();
    let formData = new FormData()
      const config = {
        headers: { "content-type": "multipart/form-data" }
      }
      formData.append("case", "agentList");
      formData.append("sid", sid);
      formData.append("docid", docid);
      const verticalId = getVerticalId();
      formData.append("verticalId", verticalId);
      const jduid = getJDUID();
      formData.append("jduid", jduid);
      return axios.post(URI, formData, config);
}

export const deleteAgentFromList = (mobile)  => {
  let r = getCurrentURIParams();
  let sid = getSid();
  let docid = r.docid;
  let { contactDetails } = store.getState();
  let formData = new FormData()
    const config = {
      headers: { "content-type": "multipart/form-data" }
    }
    formData.append("case", "delAgent");
    formData.append("mobile", mobile);
    formData.append("sid", sid);
    formData.append("docid", docid);
    const verticalId = getVerticalId();
    formData.append("verticalId", verticalId);
    const jduid = getJDUID();
    formData.append("jduid", jduid);
    return axios.post(URI, formData, config);
}

export const getDeliveryChargeDetails = () => {
 let r = getCurrentURIParams();
  let sid = getSid();
  let docid = r.docid;
  let source = r.source && r.source !== "undefined" ? r.source : 2;
  let formData = new FormData()
  const config = {
    headers: { "content-type": "multipart/form-data" }
  }
  formData.append("case", "getDelCharges");
  formData.append("sid", sid);
  formData.append("docid", docid);
  formData.append("wap", source);
  const verticalId = getVerticalId();
  formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData, config);
}

export const saveDeliveryChargeDetails = (data) => {
  let r = getCurrentURIParams();
   let sid = getSid();
   let docid = r.docid;
   let source = r.source && r.source !== "undefined" ? r.source : 2;
   let formData = new FormData()
   const config = {
     headers: { "content-type": "multipart/form-data" }
   }
   formData.append("case", "saveDelCharges");
   formData.append("sid", sid);
   formData.append("docid", docid);
   formData.append("wap", source);
   formData.append("json_data", JSON.stringify(data));
   const verticalId = getVerticalId();
    formData.append("verticalId", verticalId);
    const jduid = getJDUID();
    formData.append("jduid", jduid);
    return axios.post(URI, formData, config);
 }
