import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
	showToastAction,
	hideToastAction,
	setDeliveryChargeData
  } from "../../actions/actions";

import { getDeliveryChargeDetails, saveDeliveryChargeDetails } from "../../services/delivery";
import { getURIParams } from "../../services/common";

const UPPER_NINES = ["9999999999.00", "999999.99", "999999.00", "9999999999.99"];

const DeliveryPriceDet = ({}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	useEffect(()=>{
		getDeliveryChargeDetails().then((res)=>{
				let resdata = res.data;
				if(resdata.length>0 && Array.isArray(resdata)){
					let infFlag = false;
					let dpArCompile = resdata.map((r)=>{
						if(!infFlag){
							let uppAmtDisp = r['threshold_ub']["new"]?r['threshold_ub']["new"]:r['threshold_ub']["old"];
							if(UPPER_NINES.includes(uppAmtDisp)){
								uppAmtDisp="& Above";
								infFlag=true;
							}
							return {
								"minOrdAmt": r['threshold_lb']["new"]?r['threshold_lb']["new"]:r['threshold_lb']["old"],
								"maxOrdAmt": uppAmtDisp,
								"dCharges": r["discount_value"]["new"]?r["discount_value"]["new"]:r["discount_value"]["old"]
							}
						}
					}).filter((r)=>r);
					setDpArray(dpArCompile);
				}
		})

	},[0])


	const [dpArray, setDpArray] = useState([{
		minOrdAmt: "1",
		maxOrdAmt: "& Above",
		dCharges: "0"
	}])
	const [helpShow, setHelpShow] = useState(false);

	const handleAddRange = () => {
		let dpArrClone = [...dpArray];
		let prevMaxOrdAmt = dpArrClone[dpArrClone.length-1]["maxOrdAmt"];
		dpArrClone[dpArrClone.length-1]["maxOrdAmt"] = "";
		dpArrClone.push({
			minOrdAmt: "",
			maxOrdAmt: "& Above",
			dCharges: "0"
		});
		setDpArray(dpArrClone);
	}

	const handleRemoveRange = (ind) => {
		let dpArrClone = [...dpArray];
	//	if(dpArrClone[ind-1]["maxOrdAmt"] && (dpArrClone[ind+1] && dpArrClone[ind+1]["minOrdAmt"]!=="")){
	//	}
		if(dpArrClone[ind+1]){
			dpArrClone[ind+1]["minOrdAmt"]=parseInt(dpArrClone[ind-1]["maxOrdAmt"])?parseInt(dpArrClone[ind-1]["maxOrdAmt"])+0.01: "";
		}
		dpArrClone.splice(ind, 1);
		dpArrClone[dpArrClone.length-1]["maxOrdAmt"] = "& Above";
		setDpArray(dpArrClone);
	}

	const handleChangeMaxRange = (e, ind) => {
		let dpArrClone = [...dpArray];
		if(e.target.value){
			setErrorMaxShow(false);
			let val = parseInt(e.target.value);
			dpArrClone[ind]["maxOrdAmt"] = val;
			if(ind<dpArrClone.length-1){
				dpArrClone.forEach((r,i)=>{
					if(i>ind && i!=dpArrClone.length-1){
						dpArrClone[i]["maxOrdAmt"] = "";
					}
					if(i>ind){
						dpArrClone[i]["minOrdAmt"] = "";
					}
				})
			}
			setDpArray(dpArrClone);
		}else{
			dpArrClone[ind]["maxOrdAmt"] = "";
			setDpArray(dpArrClone);
		}
	}

	const handleBlurMaxRange = (e, ind) => {
		let dpArrClone = [...dpArray];
		setErrorMaxShow(false);
		let val = parseInt(e.target.value);
		if(val>dpArrClone[ind]["minOrdAmt"]){
			dpArrClone[ind]["maxOrdAmt"] = val;
			dpArrClone[ind+1]["minOrdAmt"] = val+0.01;
			setDpArray(dpArrClone);
		}else{
			dpArrClone[ind]["maxOrdAmt"] = "";
			dpArrClone[ind+1]["minOrdAmt"] = "";
			setDpArray(dpArrClone);
			dispatch(showToastAction("Max Amount Cannot be less than Min"));
				setTimeout(function () {
				  dispatch(hideToastAction());
				}, 3000);
		}
	}
	const handleChangeDCharges = (e, ind) => {
		let dpArrClone = [...dpArray];
		let value = parseInt(e.target.value);
		if(value || (ind==dpArrClone.length-1 && value==0)){
			if(value<=200){
				dpArrClone[ind]["dCharges"] = value;
				setDpArray(dpArrClone);
			}else{
				dispatch(showToastAction("Delivery Charges cannot be more than Rs.200"));
				setTimeout(function () {
				  dispatch(hideToastAction());
				}, 3000);
			}
		}else{
			dpArrClone[ind]["dCharges"] = "";
				setDpArray(dpArrClone);
		}
	}

	const [errorMaxShow, setErrorMaxShow] = useState(false);
	const [errorDCShow, setErrorDCShow] = useState(false);

	const handleSaveDCharges = () => {
		let errorMaxFlag = false;
		let errorDCFlag = false;

		dpArray.forEach((r, i)=>{
			if(r.maxOrdAmt==""){
				errorMaxFlag = true;
			}
			if(i!=dpArray.length-1 && (r.dCharges=="" || r.dCharges==0)){
				errorDCFlag = true;
			}
		});
		if(errorMaxFlag){
			setErrorMaxShow(true);
			return false;
		}
		if(errorDCFlag){
			dispatch(showToastAction("Please enter the delivery charge."));
			setTimeout(function () {
			  dispatch(hideToastAction());
			}, 3000);
			setErrorDCShow(true);
			return false;
		}
		let compJSONData = dpArray.map((r)=>{
			let maxAmt = r.maxOrdAmt;
			if(r.maxOrdAmt=="& Above"){
				maxAmt=UPPER_NINES[0];
			}
			return {
				"orderRange": `${r.minOrdAmt}-${maxAmt}`,
				"Amount": r.dCharges
			}
		})
		saveDeliveryChargeDetails(compJSONData).then((res)=>{
			let uriParams = getURIParams();
			dispatch(setDeliveryChargeData(compJSONData));
                navigate(`/businessDetails/storeTimeDet?${uriParams}`);
		})
	}

    return (
        <>
    	
    		<div class="deliverycharge_wpr p-20 pt-30 pb-70">
    			<div className="dflex">
    				<div className="font12 fw500 color111">Define Range for Delivery Charge</div>
    				<div><span className="ob_faqicon"  onClick={()=>setHelpShow(true)}/></div>
    			</div>

    			<div className="delcharge_chart">
    				<div className="delcharge_chart_hdr font12 color777">
    					<div className="delcharge_chart_hdr_col">Minimum Order Amount</div>
    					<div className="delcharge_chart_hdr_col">Maximum Order Amount</div>
    					<div className="delcharge_chart_hdr_col">Delivery Charge Excl. GST</div>
    					<div className="delcharge_chart_hdr_col">&nbsp;</div>
    				</div>
					{dpArray.map((row, i)=>
						<div className="delcharge_chart_row font16 color111 fw500">
						<div className="delcharge_chart_row_col">
						<input type="text" name="" className={"disabled"} disabled={true} value={row.minOrdAmt} />
						</div>
						<div className="delcharge_chart_row_col">
							<input type="text" name="" onChange={(e)=>handleChangeMaxRange(e, i)} className={`${row.maxOrdAmt=="& Above" || row.minOrdAmt==""?"disabled":""} ${row.maxOrdAmt=="" && errorMaxShow? "errorinpt":""}`} disabled={row.maxOrdAmt=="& Above" || row.minOrdAmt==""} onBlur={(e)=>handleBlurMaxRange(e, i)}  value={row.maxOrdAmt} />
						</div>
						<div className="delcharge_chart_row_col rupeesign_wpr">
						<span className="rupeesign">&#8377;</span><input type="text" name="" className={((row.dCharges=="" || row.dCharges==0) && errorDCShow && i!=dpArray.length-1? "errorinpt":"")} onChange={(e)=>handleChangeDCharges(e, i)} value={row.dCharges} />
						</div>
						<div className="delcharge_chart_row_col" onClick={()=>handleRemoveRange(i)} disabled={i==0}><span className={"ob_delete"+(i==0?" disabled":"")} /></div>
						</div>
					)}
    				{/* <div className="delcharge_chart_row font16 color111 fw500">
    					<div className="delcharge_chart_row_col">1</div>
    					<div className="delcharge_chart_row_col">
    						<input type="text" name="" value="299.99" />
    					</div>
    					<div className="delcharge_chart_row_col">
    						<input type="text" name="" value="&#8377;50" />
    					</div>
    					<div className="delcharge_chart_row_col"><span className="ob_delete disabled" /></div>
    				</div>
    				<div className="delcharge_chart_row font16 color111 fw500">
    					<div className="delcharge_chart_row_col">300</div>
    					<div className="delcharge_chart_row_col">
    						& Above
    					</div>
    					<div className="delcharge_chart_row_col">
    						<input type="text" name="" value="&#8377;20" />
    					</div>
    					<div className="delcharge_chart_row_col"><span className="ob_delete" /></div>
    				</div> */}
    				<div className="addrange_btn mt-15">
    					<button className="font14 fw500 color007" onClick={()=>handleAddRange()}> + Add Another Range </button>
    				</div>
    			</div>


    			{/* <div className="group mt-30">
    				<input className="inputMaterial font16 fw600" type="text" required="" name="" autocomplete="off" value="18%" />
    				<span className="highlight"></span>
    				<span className="bar"></span>
    				<label className="label-wrap font16"> Delivery Charge GST</label>
    				<span className="of_downarrow"></span>
    			</div> */}

    		</div>

    		<div className="ftr_btn animated fast fadeInUp">
    			<button className="font14 fw600 colorfff" onClick={()=>handleSaveDCharges()}>Save &amp; Next</button>
    		</div>

				{
					helpShow &&
    		<div className="delcharge_popup">
    			<div className="delcharge_popup_outer">
    				<div className="delcharge_popup_header font18 fw700 color1a1 p-20">
    					Delivery Charge
    					<span className="seller_ob_cls_icon" onClick={()=>setHelpShow(false)}/>
    				</div>
    				<div className="p-20">
    					<div className="font13 color111">The configured delivery charges will be added to the total cart value and will be collected from the buyer. However, the payout of delivery charge to the seller will be based on the shipping mode and total order.</div>

    					<div className="dflex flexbaseline">
    						<div className="font18 fw700 color111">-</div>
    						<div className="pl-10">
    							<div className="font13 fw500 color111">When total order value is below &#8377;300</div>
    							<div className="font13 color111 mt-10">In case the seller selects self ship option to fulfils the order successfully, then the entire delivery charges collected along with GST will credited in the seller's account as part of payout schedule.</div>
    							<div className="font13 color111 mt-10">In case the seller selects Jd logistics option to fulfil the order, then the entire delivery charge along with GST will be excluded from the order payout.</div>
    						</div>
    					</div>
    					<div className="dflex flexbaseline mt-10">
    						<div className="font18 fw700 color111">-</div>
    						<div className="pl-10">
    							<div className="font13 fw500 color111">When total order value is same or above &#8377;300</div>
    							<div className="font13 color111 mt-10">In case the seller selects self ship option to fulfils the order successfully, then the entire delivery charges collected along with GST will credited in the seller's account as part of payout schedule.</div>
    							<div className="font13 color111 mt-10">In case the seller selects Jd logistics option to fulfil the order, then we will additionally check if the configured delivery charge is equal or more than ₹ 50 - If true, then the entire delivery charge along with GST will be excluded from the order payout.</div>
    							<div className="font13 color111 mt-10">If the configured delivery charge is less than ₹ 50, then the difference between ₹ 50 and the configured delivery charge will be additionally deducted from the order payout.</div>
    						</div>
    					</div>
    				</div>
    			</div>
    		</div>
			}




        </>
    )


}

export default DeliveryPriceDet;