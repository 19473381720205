import '../commoncalc.css';
import './autosuggest.css';
import axios from "axios";
import SearchBar from '../searchbar/searchbar';
import { useEffect, useRef } from 'react';
import {URI} from "../../../../services/configs";


const CancelToken = axios.CancelToken 
let cancel;

function AutoSuggest(props){
    const { srchTxt, setSrchTxt, results, setResults, setSelCat, autoSuggestOn, setAutoSuggestOn, autoSuggestClose, saveClick } = props;
    useEffect(()=>{
        // if(initialLoad.current) {
        //     initialLoad.current = false
        //     return;
        // }
        if(srchTxt && srchTxt.trim()!=""){
            if(cancel!== undefined){
                cancel()
            }
            const formData = new FormData();
            formData.append("case", "commissionInfo"); //append the values with key, value pair
            formData.append("action", "autoSugg"); 
            formData.append("srchtrm", srchTxt);
            formData.append("type", "2");
            const productBankList = axios.post(URI, formData, { cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            }),}).then((resp)=>{
               let respe = resp.data.results;
               if(!autoSuggestOn || !autoSuggestClose.current){
                   setResults({})
                   return;
               }
               if(respe){
                  setResults(respe)
               }
            })
            .catch((err)=>{
                console.log(err)
            })
        }
    }, [srchTxt, setResults, autoSuggestOn, autoSuggestClose ])
    
    useEffect(() => {
        const eventHandler = (e) => {
            const autoSuggest = document.getElementById("autosuggest");
            if (autoSuggest && !autoSuggest.contains(e.target)) 
            {
                console.log("event called")
                autoSuggestClose.current = false;
                setResults({})
                setAutoSuggestOn(false);
            }
        }
        document.addEventListener("mouseup", eventHandler)
        return () => {
            autoSuggestClose.current = false;
            setResults({});
            setAutoSuggestOn(false);
            document.removeEventListener("mouseup", eventHandler)
        }
    },[setAutoSuggestOn, setResults, autoSuggestClose])
    

    const { keywords, categories, products} = results
    return(
      <div className="autosuggest_autosgtulwpr" id="autosuggest">
         {/* <div className={`tright autosuggest_autosgtcrosswp`} onClick={()=>{setResults([]); setAutoSuggestOn(false); autoSuggestClose.current= false}}>
              <div className="autosuggest_autosgtcross" />
    </div>*/}
          <div className={"autosuggest_srchpad"}>
                <SearchBar  
                    setSrchTxt={setSrchTxt} 
                    srchTxt={srchTxt} 
                    setResults={setResults} 
                    setSelCat={setSelCat} 
                    results={results} 
                    autoSuggestOn={autoSuggestOn}
                    setAutoSuggestOn={setAutoSuggestOn}
                    autoSuggestClose={autoSuggestClose}
                    saveClick={saveClick}
                />
          </div>  
          { srchTxt && (keywords?.length > 0 || categories?.length > 0 || products?.length > 0) &&
            <div className="autosuggest_autosgtul">
                {
                    keywords?.length > 0 &&
                        <div>
                            <div className={`wdth100 font13 color777 fw500 autosuggest_autosgtheading`}>KEYWORDS</div>
                            <ul>
                                {
                                    keywords.map((result)=>{
                                        return (
                                            <li className={`wdth100`} key={result.id} onClick={() =>  {/*saveClick("pcalc_hmpg",`cat_search_${result.psv_node_id}`);*/ setSelCat(result); setResults({}); setSrchTxt(result.name); setAutoSuggestOn(false);autoSuggestClose.current=false}}>
                                                <span className={`wdth100 fw500 font15 color111}`}>{result.name}</span>
                                                {/* <className={`${mt5 wdth100  font12 color777}`}>{result.psv_node_id}</span> */}
                                            </li>
                                        )
                                    }) 
                                }
                            </ul>
                        </div>
                }
                {
                    categories?.length > 0 && <div>
                        <div className={`wdth100 font13 color777 fw500 autosuggest_autosgtheading`}>CATEGORIES</div>
                            <ul>
                                {
                                    categories.map((result)=>{
                                        return (
                                            <li className={`wdth100 `} key={result.id} onClick={() =>  {/* saveClick("pcalc_hmpg",`cat_search_${result.psv_node_id}`); */ setSelCat(result); setResults({}); setSrchTxt(result.name); setAutoSuggestOn(false);autoSuggestClose.current=false}}>
                                                <span className={`wdth100 fw500 font15 color111`}>{result.name}</span>
                                                {/* <className={`${mt5 wdth100  font12 color777}`}>{result.psv_node_id}</span> */}
                                            </li>
                                        )
                                    }) 
                                }
                            </ul>
                        </div>
                }
                {
                    products?.length > 0 && 
                    <div>
                        <div className={`wdth100  font13 color777 fw500 autosuggest_autosgtheading`}>PRODUCTS</div>
                        <ul>
                            {
                                products.map((result)=>{
                                    return (
                                        <li className={`wdth100`} key={result.id} onClick={() =>  { /* saveClick("pcalc_hmpg",`cat_search_${result.psv_node_id}`); */ setSelCat(result); setResults({}); setSrchTxt(result.name); setAutoSuggestOn(false);autoSuggestClose.current=false}}>
                                            <span className={`wdth100  fw500 font15 color111`}>{result.name}</span>
                                            {/* <className={`${mt5 wdth100  font12 color777}`}>{result.psv_node_id}</span> */}
                                        </li>
                                    )
                                }) 
                            }
                        </ul>
                    </div>
                }
                
            </div>
          }  
      </div>
    )
}

export default AutoSuggest;
