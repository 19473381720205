import axios from "axios";
import {checkForLive, getCurrentURIParams, getCookie, getSid, getJDUID} from "./common";
import {ONBOARDING_VERTICAL, JDCASHVERTICAL} from "../config/consts"; 
import {IsJDCash} from "../config/verticalConfig"; 

import { NOTIFY_URI, URI, SELF_URI } from "./configs";

export const getVerticalId = () => {
  let uriParams = getCurrentURIParams();
  return uriParams["vertical"]?uriParams["vertical"]: ONBOARDING_VERTICAL;
}

export const getListOfChanges = (cid) => {
    let r = getCurrentURIParams();
    let docid = r.docid;
    let sid = getSid();
    let formData = new FormData()
      const config = {
        headers: { "content-type": "multipart/form-data" }
      }
      formData.append("case", "fetchChanges");
      formData.append("docid", docid);
      formData.append("sid", sid);
      const verticalId = getVerticalId();
  formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(NOTIFY_URI, formData, config);
}

export const approveListOfChanges = (cid) => {
    let r = getCurrentURIParams();
    let docid = r.docid;
    let sid = getSid();
    let formData = new FormData()
      const config = {
        headers: { "content-type": "multipart/form-data" }
      }
      formData.append("case", "approveChange");
      formData.append("docid", docid);
      formData.append("sid", sid);
      const verticalId = getVerticalId();
  formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(NOTIFY_URI, formData, config);
}

export const sendForApproval = () => {
  let r = getCurrentURIParams();
  let sid = getSid();
  const verticalId = getVerticalId();
  const mobile = r.mobile;
  const docid = r.docid;
  const name = r.name;
  let formData = new FormData()
  const config = {
    headers: { "content-type": "multipart/form-data" }
  }
  formData.append("case", "sendLink");
  let link = encodeURIComponent(`${SELF_URI}/auditApproval?name=${name}&source=2&mobile=${mobile}&docid=${docid}`);
  if(IsJDCash){
    link = encodeURIComponent(`${SELF_URI}/auditApproval?name=${name}&source=2&mobile=${mobile}&docid=${docid}&vertical=${JDCASHVERTICAL}`);
  }
  formData.append("link", link);
  formData.append("name", name);
  formData.append("docid", docid);
  formData.append("sid", sid);
  formData.append("mobile", mobile);
  formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(NOTIFY_URI, formData, config);
}

export const checkLinkExpire = () => {
  const formData = new FormData();
  let r = {};
  r = getCurrentURIParams();
  let docid = r.docid ? r.docid : getCookie("docid");
  const mobile = r.mobile;
  formData.append("case", "expiryCheck"); 
  formData.append("action", "validate"); 
  formData.append("docid", docid);
  formData.append("mobile", mobile);
  const verticalId = getVerticalId();
  formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(NOTIFY_URI, formData);
}

export const expireLink = (vis) => {
  const formData = new FormData();
  let r = {};
  r = getCurrentURIParams();
  let docid = r.docid ? r.docid : getCookie("docid");
  const mobile = r.mobile;
  formData.append("case", "expiryCheck"); 
  formData.append("action", "update"); 
  formData.append("visited", vis); 
  formData.append("docid", docid);
  formData.append("mobile", mobile);
  const verticalId = getVerticalId();
  formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(NOTIFY_URI, formData);
}

export const updateGenioForCat = () => {
  const formData = new FormData();
  let r = {};
  r = getCurrentURIParams();
  let sid = getSid();
  let docid = r.docid ? r.docid : getCookie("docid");
  formData.append("case", "updateGenio"); 
  formData.append("docid", docid);
  formData.append("sid", sid);
  const verticalId = getVerticalId();
  formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData);
}
