import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import _, { cloneDeep } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

import AddOffers from "../common/Offers/AddOffers";
import OffersListDisp from "../common/Offers/OffersListDisp";
import {SaveBusinessDetails, getCurrentURIParams, getURIParams, UpdateDocId, getGetRedirectAPI} from "../../services/common";
import { addOffersTemplate, setOffersDataToEdit, clearOffersData, deleteOffer, showLoaderAction, hideLoaderAction, setTimingsDataFromStoreTime } from "../../actions/actions";
import BottomConfirmPop from "../common/BottomConfirmPop";

const DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const CloneWeekDays = () =>{
    return DAYS.map((d)=>{
        return {
            displayText: d,
            timings: [],
            checked: false,
            respText: d.substring(0, 3)
        }
    });
}

const OffersDet = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showAddOffer, setShowAddOffer] = useState(false);
    const [editOffer, setEditOffer] = useState(false);
    const [editIndex, setEditIndex] = useState(false);
    const [delFlag, setDelFlag] = useState(false);
    const [bottomPopUp, setBottomPopUp] = useState({show: false, txt: ""});
    const [deleteIndex, setDeleteIndex] = useState();
    const  [showButtonLoader, setShowButtonLoader] = useState(false);
    const [copyFromStoreTime, setCopyFromStoreTime] = useState(false);
    const offerList = useSelector((state) => {
        return state.offerList;
      });
    
    
    const compData = useSelector((state) => {
        return state;
    });

    const handleOpenAddOffer = () => {
        setEditIndex(offerList.length);
        dispatch(clearOffersData());
        setShowAddOffer(true);
    }

    const handleCloseAddOffer = () => {
        setShowAddOffer(false);
        setEditOffer(false);
    }

    const handleEditOffer = (ind) => {
        setShowAddOffer(true);
        setEditOffer(true);
        setEditIndex(ind);
        var clonedD = _.cloneDeep(offerList[ind]);
        dispatch(setOffersDataToEdit(clonedD));
    }

    const handleDeleteOffer = (ind) => {
        setDeleteIndex(ind);
        setBottomPopUp({show: true, txt: "Are you sure you want to delete the added offer?"})
    }

    const handleDeleteOfferConfirm = () => {
        dispatch(deleteOffer(deleteIndex));
        setDelFlag(true);
    }

    useEffect(()=>{
        if(delFlag){
            dispatch(showLoaderAction());
          SaveBusinessDetails(compData, location.pathname, "offersDet").then((res)=>{
            let currentUriParams = getCurrentURIParams();
            UpdateDocId(currentUriParams.docid).then((res) => {
                setDelFlag(false);
                dispatch(hideLoaderAction());
                setBottomPopUp({show:false, txt: ""})
            }).catch((e)=>{
                console.log("Updating error ",e)
                setShowButtonLoader(false);
              })
          });
        }
      }, [delFlag]);

 
    const handleSaveNext = () =>{
        let currentUriParams = getCurrentURIParams();
        setShowButtonLoader(true);
        if(currentUriParams["source"]!=14){
            UpdateDocId(currentUriParams.docid).then((res) => {
                redirectDet(currentUriParams);
            }).catch((e)=>{
              console.log("Updating error ",e)
              setShowButtonLoader(false);
            })
          }else{
            redirectDet(currentUriParams);
          }
    }

    const redirectDet = (currentUriParams) => {
        setShowButtonLoader(false);
        let uriParams = getURIParams();
        if(currentUriParams["input"]=="account_settings"){
            if(currentUriParams["source"]==51 || currentUriParams["source"]==53){
              window.location.href = "https://jdext.php";
              return false;
            }else{
            window.location.href = decodeURIComponent(currentUriParams["backUrl"])?decodeURIComponent(currentUriParams["backUrl"]):"https://staging2.justdial.com/seller-dashboard/accountsetting";
            }
          }else{
            window.location.href = getGetRedirectAPI(currentUriParams.docid);
            // navigate(
            //     `/businessDetails/storeTimeDet?${uriParams}`
            //   );
          }
    }

    return (
        <>
    {
            !showAddOffer && 
        <section className="pl-20 pr-20 pb-120">
            {
                offerList && offerList.length==0 && 
                <div className="deliveryboy_wpr">
                <div className="mt-50"><img src="https://akam.cdn.jdmagicbox.com/images/icontent/seller/offers_banner.svg" width="230"/></div>
                <div className="font15 color111 fw600 mt-25">Deals for your customers</div>
                <div className="font14 color777 mt-10">You can add exciting offers and deals for your customers</div>
                <button className="adddeliverybtn mt-15" onClick={handleOpenAddOffer}><i className="add_plus_btn"></i> Add New Deal</button>
            </div>
            }
            {
                offerList.length>0 && 
                <>
                    <div class="deliverycount font12 color777 pb-10 mt-30">
                        {offerList.length} deal{offerList.length>1?"s":""} for your customers
                    </div>
                    <div class="deliveryboy_list deallist">
                        <ul>
                            {
                                offerList.map((offerDet, i)=>
                                    <OffersListDisp offerDet={offerDet} editOffer={handleEditOffer} deleteOffer={handleDeleteOffer} offerInd={i} key={offerDet.dealName+i}/>
                                )
                            }
                        </ul>
                    </div> 
                    
                    <div class="adddeliverybtn_outer text_center mt-15">
                        <button class="adddeliverybtn" onClick={handleOpenAddOffer}><i class="add_plus_btn"></i> Add New Deal</button>
                    </div>
                </>
            }
        </section>
    }
        {
            showAddOffer &&
                <>
                <AddOffers                     
                    handleClose={handleCloseAddOffer}
                    offerIndex={offerList.length-1}
                    editOffer={editOffer}
                    editIndex={editIndex}
                    copyFromStoreTime={copyFromStoreTime}
                />
                </>
        }
        {
            bottomPopUp.show &&
            <BottomConfirmPop handleClose={()=>setBottomPopUp({show:false, txt: ""})} handleConfirm={handleDeleteOfferConfirm} textToShow={bottomPopUp.txt}/>
        }
          
            <div className="ftr_btn ftr_offer_skip_btn">
                <button className="font14 fw600 colorfff" onClick={handleSaveNext}>
                {
                 !showButtonLoader &&
                 <>   
                 Save &amp; Next
                 </>
                }
                   {
         showButtonLoader && <span className="dotloader"><span /><span /><span /><span /><span /></span>
              }    
                </button>
            </div>
        </>
    )

}

export default OffersDet;
