import React, { useState, useEffect, useRef} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showHelpAction } from "../../actions/actions";
import {
  getGetRedirectAPI,
  getURIParams,
  getCurrentURIParams,
  getEditListCatAPI,
  getEditListCatShortURI,
  getSource,
  getEditListHomeUrl
} from "../../services/common";
import {PageLoad} from "./PageLoadSellerApp";
import {openViewExit} from "./openViewExit";
import {Arr1Head, Arr2Head} from "../../config/consts";
import {IsJDCash} from "../../config/verticalConfig";
const MyHeader = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const bussList = useSelector((state) => state.initialBusinessDataReducer);
  const {
    isPaidContract, isDeliveryFlag
  } = useSelector((state) => state.locationReducer);

  const locationDetails = useSelector((state) => {
    return state.locationDetails;
  });
  const [showBreadCrumbs, setShowBreadCrumbs] = useState(true);
  const headerText = props.headerText;

  const compData = useSelector((state) => {
    return state;
  });
  const handleBack = () => {
    let uriParams = getCurrentURIParams();
    let currentPage = location.pathname.split("/")[2];
    let urlParams = getURIParams();
    // if(bussList.length==0 && uriParams["origin"]!=="edit_listing" && uriParams["input"]!=="account_settings"){
    //   navigate(`/?${urlParams}`);
    //   return false;
    // }
    
    // if(currentPage=="contactDet"){
    //   window.location.href = getEditListCatAPI();
    //   return false;
    // }
    if((uriParams["source"]==51 || uriParams["source"]==53) && (currentPage=="businessDet" || uriParams["input"]=="account_settings")){
      if(uriParams["mobile"]=="9060775270"){
        alert("Calling device post this.");
      }
      openViewExit(uriParams["source"]);
      return false;
    }
    if (currentPage == "chooseplan") {
      window.history.go(-1);
      return false;
    }
    if(uriParams["input"]=="account_settings"){
      window.history.go(-1);
      return false;
    }else{
      let currentPath = location.pathname;
      let currentIndex = Arr1Head.indexOf(currentPage);
      let currentIndex2 = Arr2Head.indexOf(currentPage);
      // if contactpage redirect to editlisting page
      if (currentPage === "contactDet") {
        let currentUriParams = getCurrentURIParams();
        if(uriParams["source"]==14) {
          if(isPaidContract){
            navigate(`/businessDetails/locationDet?${urlParams}`);
          }else{
            window.location.href = getEditListCatShortURI(
              compData.locationDetails.city,
              compData.businessDetails.storeName,
              compData.loadDocData.docid
            );
          }
        } else {
          if(isPaidContract){
            navigate(`/businessDetails/locationDet?${urlParams}`);
          }else{
            window.location.href = getEditListCatAPI(
              currentUriParams.docid,
              locationDetails.city
            );
          }
        }
        return false;
      }

      if (currentIndex > 0) {
        let toDirect = Arr1Head[currentIndex - 1];
        navigate(`/businessDetails/${toDirect}?${urlParams}`);
      } else if (currentIndex2 > 0) {
        let toDirectD = Arr2Head[currentIndex2 - 1];
        let source = getSource();
	if(toDirectD=="deliveryPriceDet" && IsJDCash){
          // window.location.replace(getGetRedirectAPI(undefined, true));
          // return false;
          navigate(`/businessDetails/businessDet?${urlParams}`);
          return false;
        }
        if(toDirectD=="deliveryDet" && (isDeliveryFlag==undefined || isDeliveryFlag==null || isDeliveryFlag=="0" || source=="14")){
          navigate(`/businessDetails/pickupDet?${urlParams}`);
          return false;
        }
        if(toDirectD=="deliveryPriceDet" && (isDeliveryFlag==undefined || isDeliveryFlag==null || isDeliveryFlag=="0")){
          navigate(`/businessDetails/pickupDet?${urlParams}`);
          return false;
        }
        navigate(`/businessDetails/${toDirectD}?${urlParams}`);
      } else {
        if (currentIndex2 == 0) {
          if(IsJDCash){
            // window.location.replace(window.location.href = getGetRedirectAPI(undefined, true));
            // return false;
            window.history.go(-2);
            return false;
          }else{
            window.location.href = getGetRedirectAPI();
            return false;
          }
        }
        if(currentIndex==0){
          if(uriParams["origin"]=="jdBusiness"){
            window.location.href = decodeURIComponent(uriParams["backUrl"]);
            return false;
          }
          if(IsJDCash){
             window.location.href = getEditListHomeUrl(compData.locationDetails.city, compData.businessDetails.storeName);
              return false;
          }
          if(uriParams["origin"]=="edit_listing"){
            window.location.href = decodeURIComponent(uriParams["backUrl"]);
            return false;
          }else{
            navigate(`/?${urlParams}`);
            return false;
          }
          return false;
        }
        if(currentIndex==-1){
          if(uriParams["origin"]=="edit_listing"){
            window.location.href = decodeURIComponent(uriParams["backUrl"]);
            return false;
          }
        }
    }
    }
  //  navigate(-1);
  }

  const myValRef = useRef();
  const  {noBussData, yesBussData, yesTempData, noTempData}  = useSelector((state) => state.locationReducer);
  useEffect(()=>{
    //Page load for source 51, 53
    let r = getCurrentURIParams();
    let mb = r.mobile;
    try{
      if((noBussData || yesBussData) && (yesTempData || noTempData) && !myValRef.current){
        myValRef.current=true;
      if(r.source!==53){
        let logInJson = window.JdLiteInterface.getLoginData();
        if(!logInJson) return;
        logInJson = JSON.parse(logInJson);
          if(logInJson.source=="51"){
            PageLoad(logInJson.source);
         }
      }else{
        PageLoad(r.source);
      }
      }
    }catch(e){
      console.log("Page Load err ", e);
    }
    },[noBussData, yesBussData, yesTempData, noTempData]);

  return (
    <header className="seller_header header_fixed">
      <div className="header_home">
        <div className="header_home_left">
          <div
            className="seller_headback_icon seller_icon"
            onClick={() => handleBack()}
          ></div>
        </div>

        <div className="header_home_center">
          <div className="home_center text_center">
            {/* <div className="seller_jdlogo_icon seller_icon"></div> */}
            <div className="header_home_text font16 fw600 color1A1">
              {headerText ? headerText : "Business Details"}
            </div>
          </div>
          {/* <div className="seller_invoice_icon seller_icon"></div> */}
        </div>

        <div className="header_home_right">
          <div className="font13 color007 fw600 curs_pointer" 
          onClick={()=>{
            dispatch(showHelpAction());
            window.history.pushState(null, null, window.location.pathname+window.location.search);
          }}>
            {" "}
            Help{" "}
          </div>
        </div>
      </div>
    </header>
  );
};

export default MyHeader;
