import { getCurrentURIParams, getSource } from "../services/common";
import {JDCASHVERTICAL, MandatoryBusinessFieldsFieldsForJDCash, MandatoryBusinessFieldsFieldsForOnboarding, FieldsNotToCheckForJDCash, FieldsNotToCheckForOnboarding} from "./consts";
let uriParams = getCurrentURIParams();

const vertical = uriParams["vertical"];
let source = getSource();

//Params to send ahead on url

export const IsJDCash = vertical==JDCASHVERTICAL;
// Configs for Step Tabs

export const getSteps = (isDeliveryFlag) => {
    let stepR = ["businessDet", "locationDet", "contactDet", "commissionDet", "pickupDet", "storeTimeDet"]; // Normal Owner FLow

    if(vertical==JDCASHVERTICAL){
       // stepR = ["businessDet", "locationDet", "contactDet",  "commissionDet", "qrDet", "offerDet", "storeTimeDet"]; // JD Cash Flow
       stepR = ["businessDet", "offerDet", "commissionDet", "qrDet"]; // JD Cash Flow
    }else if(isDeliveryFlag==2) {
        if(source=="14"){
            stepR = ["businessDet", "locationDet", "contactDet", "commissionDet", "pickupDet", "deliveryPriceDet", "storeTimeDet"]; // Delivery Flag Flow for Genio
        }else{
            stepR = ["businessDet", "locationDet", "contactDet", "commissionDet", "pickupDet", "deliveryDet", "deliveryPriceDet", "storeTimeDet"]; // Delivery Flag Owner Flow
        }
    }
    return stepR;
}  


export const showBreadCrumbs = uriParams["input"]!="account_settings";

//Mandatory fields config in Business details page
export const MandatoryFieldsToCheck = IsJDCash?MandatoryBusinessFieldsFieldsForJDCash:MandatoryBusinessFieldsFieldsForOnboarding;
export const ExcludeToCheck = IsJDCash?FieldsNotToCheckForJDCash:FieldsNotToCheckForOnboarding;
