import React from "react";
import Calendar from "react-calendar";

const DateSelector = ({setShowCalendar, selectDates, data, minDate, handleSubmit}) => {
    const sdate = data[0] ? new Date(data[0]) : "";
    const edate = data[1] ? new Date(data[1]) : "";
    const defVal = [sdate, edate];
    return (
        <div className="towcol_flex">
        <div className="ob_popup_outer seller_calendar_wpr" >
        <div className="ob_popup_bottom seller_calendar_outer">
        <div className="popcontainer seller_calendar">
        <div className="font16 color1a1 fw700 mt-20 pl-20 pr-20 mb-30">
            {" "}
            Select Start and End Dates{" "}
            <div
              className="closeBx popup_hdr_close"
              onClick={() => setShowCalendar(false)}
            >
              {" "}
              <i className="time_sob_closeicn"></i>
            </div>
        </div>
        <Calendar
                onChange={(e) => selectDates(e)}
                minDate={minDate?minDate:new Date()}
                defaultValue={defVal}
                selectRange={true}
                allowPartialRange={true}
                returnValue={"range"}
                tileClassName={"active"}
              />
        </div>
        </div>
        <div className="ftr_btn">
          <button
            className="font14 fw600 colorfff"
            onClick={() => {setShowCalendar(false); handleSubmit()}}
          >
              Done
          </button>
        </div>
        </div>
        </div>
    )
}

export default DateSelector;
