function timingDropdown() {
    let hours, minutes, ampm;
    let tmpHrs = [];
    //   tmpHrs = [{ time: "Select Time" }];
    for (let i = 0; i <= 1430; i += 15) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes; // adding leading zero
      }
      ampm = hours % 24 < 12 ? "am" : "pm";
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      if(hours<10 && hours>=1){
        hours = "0"+hours;
      }
      const time = hours + ":" + minutes + " " + ampm;
      const extraTimeDict = {
        am: ["12:00", "12:15", "12:30"],
        pm: ["12:00", "12:15", "12:30"],
      };
      const extraTextDict = { am: "(MidNight)", pm: "(Noon)" };
      const extraText = extraTimeDict[ampm].includes(hours + ":" + minutes)
        ? extraTextDict[ampm]
        : "";
      tmpHrs.push(time);
    }
    tmpHrs.push("11:59 pm");
    console.log(tmpHrs);
    return tmpHrs;
  }
  export const TimeSlotsConsts = timingDropdown();