import React, {useState} from "react";

const DeliveryBoyList = ({image_url, name, mobile, ind, deleteAgent, handleEditDBoy, setActiveDot, activeDot}) =>{
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const handleDeleteClick = () => {
        setShowDeleteConfirm(true);
        setActiveDot("");
    }

        return (
            <>
            <li>
            <div class="deliveryboy_list_left">
                {
                    image_url==""?
                <span class="deliveryboy_prfl_pic">
                <img src="https://akam.cdn.jdmagicbox.com/images/icontent/seller/adddeliveryboy_pic.png" />
            </span>
            :
            <span class="deliveryboy_prfl_pic">
            <img src={image_url} />
        </span>
                }
                <div class="font14 fw700 color111">{name}</div>
                <div class="font12 color111">{mobile}</div>
            </div>
                <div>
                    <div onClick={()=>{
                        if(activeDot===""){
                            setActiveDot(ind);
                        }else{
                            setActiveDot("");
                        }
                    }}>
                        <span class="ob_threedots"></span>
                    </div>
                    {
                activeDot===ind &&
                    <div class="editbox">
                        <ul class="font14 fw600 color1a1">
                            <li onClick={()=>handleEditDBoy(name, mobile, image_url)}>Edit</li>
                            <li onClick={()=>handleDeleteClick()}>Delete</li>
                        </ul>
                    </div>
                    }
            </div>
        </li>
        {
            showDeleteConfirm &&
                 <div className="updatebank_dtl_wpr">
                 <div className="updatebank_dtl_outer p-20 p-30">
                     <div className="font13 color111 fw600 lineHeight22">
                     Are you sure you want to delete the delivery agent?
                     </div>
                     <div className="mt-30 proceed_btn_wpr">
                       <button className="font16 graybtn" onClick={()=>setShowDeleteConfirm(false)}>Cancel</button> <button className="font16" onClick={()=>deleteAgent(ind, mobile)}>Proceed</button>
                     </div>
                 </div>
               </div>
            }
                </>
        )
}

export default DeliveryBoyList;