import React, { useEffect, useState } from "react";

const ToastPopUp = ({ msgData, id, show }) => {
  return (
    <div id={id} className={`toastMesgcnewWrp ${show ? "show" : "dn"}`}>
      <div className="toastMesgInr font12"> {msgData} </div>
    </div>
  );
};

export default ToastPopUp;
