import _ from "lodash";
import {JDCASHVERTICAL} from "../config/consts";
import {neededURIParams} from "../config/utils";

let vertParm = neededURIParams("vertical");
const IsJDCash = vertParm["vertical"]==JDCASHVERTICAL;
let initBusinessDetails = {
  storeLogoPath: "",
  storeName: "",
  legalCompanyName: "",
  businessCat: "",
  businessNature: "",
  businessNatureId: "",
  isChecked: 0,
  nid: "",
  imgFlag: 0,
};

export const businessDetails = (state = initBusinessDetails, action) => {
  switch (action.type) {
    case "SAVE_BUSINESS_DETAILS":
      let data = action.data;
      return {
        ...data,
      };
    case "SET_SELECTED_BUSINESS_DATA":
      const {
        companyname,
        profile_pic,
        year_establishment,
        email_display,
        mk_details: [
          {
            market_place,
            legal_companyname = "",
            buz_nature = "",
            primary_cat = "",
            primary_catname = "",
          } = {},
        ] = [],
      } = action.data;
      const { type = "" } = market_place ? market_place : {};
      return {
        ...state,
        storeName: state.storeName?state.storeName:companyname,
        storeLogoPath: state.storeLogoPath?state.storeLogoPath:profile_pic,
        businessCat: state.businessCat?state.businessCat:primary_catname,
        nid: state.nid?state.nid:primary_cat,
        businessNature: state.businessNature?state.businessNature:buz_nature,
        businessNatureId: state.businessNatureId?state.businessNatureId:type,
        legalCompanyName: state.legalCompanyName ? state.legalCompanyName:legal_companyname,
        legalCompanyNameOrg: legal_companyname ? legal_companyname : "",
        yearOfEstablishment: state.yearOfEstablishment?state.yearOfEstablishment:year_establishment,
        businessEmail: email_display
      };
    case "LOAD_PREFILLED_BUSINESS_DATA":
      const isChecked =
        action.data.businessDetails.isChecked === "1" ? true : false;
      const newData = { ...action.data.businessDetails, isChecked };
      let busEmail = action.data.businessDetails.businessEmail?action.data.businessDetails.businessEmail:state.businessEmail;
      if(!busEmail){
        busEmail = action.data.contactDetails.businessEmail
      }
      return {
        ...state,
        ...newData,
        businessEmail: busEmail,
        storeName: action.data.businessDetails.storeName?action.data.businessDetails.storeName:state.storeName,
        storeLogoPath: action.data.businessDetails.storeLogoPath?action.data.businessDetails.storeLogoPath:state.storeLogoPath,
        businessCat: action.data.businessDetails.businessCat?action.data.businessDetails.businessCat:state.businessCat,
        nid: action.data.businessDetails.nid?action.data.businessDetails.nid:state.nid,
        businessNature: action.data.businessDetails.businessNature?action.data.businessDetails.businessNature:state.businessNature,
        businessNatureId: action.data.businessDetails.businessNatureId?action.data.businessDetails.businessNatureId:state.businessNatureId,
        legalCompanyName: action.data.businessDetails.legalCompanyName ? action.data.businessDetails.legalCompanyName:state.legalCompanyName,
        yearOfEstablishment: action.data.businessDetails.yearOfEstablishment? action.data.businessDetails.yearOfEstablishment: state.yearOfEstablishment,
        master_parentid: action.data.businessDetails.master_parentid || ""
      };
    case "CLEAR_INITIAL_DATA":
      return initBusinessDetails;
    default:
      return state;
  }
};

let initLocationDetails = {
  buildingName: "",
  streetName: "",
  landmark: "",
  storeArea: "",
  latitude: "",
  longitude: "",
  pincode: "",
  city: "",
  state: "",
};
export const locationDetails = (state = initLocationDetails, action) => {
  switch (action.type) {
    case "SAVE_LOCATION_DETAILS":
      let data = action.data;
      return {
        ...data,
      };
    case "SET_SELECTED_BUSINESS_DATA":
      const {
        building_name,
        street,
        landmark,
        city,
        area,
        latitude,
        longitude,
        pincode,
        companyname,
        address_search,
        state: newStateName,
      } = action.data;
      let buildingName = building_name;      
      if(!buildingName){
        let Ad_s = address_search && address_search.split(",");
        if(Ad_s){
          if(Ad_s.length>3){
            Ad_s = Ad_s.slice(0,-3);
          }else{
            Ad_s = Ad_s.slice(0,1);
          }
          buildingName = Ad_s.length>0?Ad_s.join(","):"";      
        }else{
          buildingName = area?area:"";
        }
      }
      return {
        ...state,
        buildingName: state.buildingName?state.buildingName:buildingName,
        streetName: state.streetName?state.streetName:street,
        landmark:state.landmark?state.landmark:landmark,
        storeArea:state.storeArea?state.storeArea:area,
        city: state.city?state.city:city,
        latitude: state.latitude?state.latitude:latitude,
        longitude: state.longitude?state.longitude:longitude,
        pincode: state.pincode?state.pincode:pincode,
        state:  state.state?state.state:newStateName,
        companyname:  state.companyname?state.companyname:companyname,
      };
    case "LOAD_PREFILLED_BUSINESS_DATA":
      return {
        ...state,
        ...action.data.locationDetails,
         buildingName: action.data.locationDetails.buildingName
         ? action.data.locationDetails.buildingName
         : state.buildingName,
         streetName: action.data.locationDetails.streetName
         ? action.data.locationDetails.streetName
         : state.streetName,
         landmark: action.data.locationDetails.landmark
         ? action.data.locationDetails.landmark
         : state.landmark,
        storeArea: action.data.locationDetails.storeArea
          ? action.data.locationDetails.storeArea
          : state.storeArea,
        city: action.data.locationDetails.city
          ? action.data.locationDetails.city
          : state.city,
        latitude: action.data.locationDetails.latitude
          ? action.data.locationDetails.latitude
          : state.latitude,
        longitude: action.data.locationDetails.longitude
          ? action.data.locationDetails.longitude
          : state.longitude,
        pincode: action.data.locationDetails.pincode
          ? action.data.locationDetails.pincode
          : state.pincode,
        state: action.data.locationDetails.state
          ? action.data.locationDetails.state
          : state.state,
      };
    case "CLEAR_INITIAL_DATA":
      return initLocationDetails;
    default:
      return state;
  }
};
let initPickupDetails = {
  buildingName: "",
  streetName: "",
  landmark: "",
  storeArea: "",
  latitude: "",
  longitude: "",
  pincode: "",
  city: "",
  state: "",
  sameToggle: false
};
export const pickupDetails = (state = initPickupDetails, action) => {
  console.log(state);
  switch (action.type) {
    case "SAVE_PICKUP_DETAILS":
      let data = action.data;
      return {
        ...data,
      };
    case "SET_SELECTED_BUSINESS_DATA":
      const { mk_details: [{ market_place } = {}] = [] } = action.data;
      const {
        puaddr,
      } = market_place ? market_place : {};
      const  {
        bnm: buildingName = "",
        lmrk: landmark = "",
        strt: street = "",
        a: area = "",
        ct: city = "",
        lat: latitude = "",
        long: longitude = "",
        pin: pincode = "",
        st = "",
      } = puaddr ? puaddr:{}
      return {
        ...state,
        sameToggle: state.sameToggle,
        buildingName:  state.buildingName?state.buildingName:buildingName,
        landmark:state.landmark?state.landmark:landmark,
        streetName: state.streetName?state.streetName:street,
        storeArea:state.storeArea?state.storeArea:area,
        city: state.city?state.city:city,
        latitude: state.latitude?state.latitude:latitude,
        longitude: state.longitude?state.longitude:longitude,
        pincode: state.pincode?state.pincode:pincode,
        state:  state.state?state.state:st
      };
    case "LOAD_PREFILLED_BUSINESS_DATA":
      return {
        ...state,
        ...action.data.pickupDetails,
        sameToggle: action.data.pickupDetails.sameToggle
        ? action.data.pickupDetails.sameToggle
        : state.sameToggle,
        buildingName: action.data.pickupDetails.buildingName
        ? action.data.pickupDetails.buildingName
        : state.buildingName,
        streetName: action.data.pickupDetails.streetName
        ? action.data.pickupDetails.streetName
        : state.streetName,
        landmark: action.data.pickupDetails.landmark
        ? action.data.pickupDetails.landmark
        : state.landmark,
        storeArea: action.data.pickupDetails.storeArea
          ? action.data.pickupDetails.storeArea
          : state.storeArea,
        city: action.data.pickupDetails.city
          ? action.data.pickupDetails.city
          : state.city,
        latitude: action.data.pickupDetails.latitude
          ? action.data.pickupDetails.latitude
          : state.latitude,
        longitude: action.data.pickupDetails.longitude
          ? action.data.pickupDetails.longitude
          : state.longitude,
        pincode: action.data.pickupDetails.pincode
          ? action.data.pickupDetails.pincode
          : state.pincode,
        state: action.data.pickupDetails.state
          ? action.data.pickupDetails.state
          : state.state
      };
    case "CLEAR_INITIAL_DATA":
      return initPickupDetails;
    default:
      return state;
  }
};

let initContactDetails = {
  contactPersonName: "",
  mobileNumber: "",
  businessEmail: "",
  alternateMobileNumber: "",
  landlineNumber: "",
};
export const contactDetails = (state = initContactDetails, action) => {
  switch (action.type) {
    case "SAVE_CONTACT_DETAILS":
      let data = action.data;
      return {
        ...data,
      };
    case "SET_SELECTED_BUSINESS_DATA":
      const {
        mobile,
        landline,
        contact_person,
        email_display,
      } = action.data;
      let alternateMn = "";
      let altSplit = mobile && mobile.split(",");
      if(altSplit)
      altSplit.splice(0,1);
      let emailTouse = email_display;
      return {
        ...state,
        mobileNumber: mobile && mobile.split(",")[0],
        alternateMobileNumber: state.alternateMobileNumber?state.alternateMobileNumber:(altSplit && altSplit.join(",")),
        landlineNumber: landline,
        contactPersonName: contact_person,
        businessEmail: emailTouse,
      };
    case "LOAD_PREFILLED_BUSINESS_DATA":
      const {
        alternateMobileNumber,
        businessEmail,
        mobileNumber,
        landlineNumber,
        pendingAlternateMobileNumber
      } = action.data.contactDetails;
      let altN = alternateMobileNumber;
      return {
        ...state,
        ...action.data.contactDetails,
        mobileNumber: mobileNumber
          ? mobileNumber
          :  state.mobileNumber,
        alternateMobileNumber: (altN &&altN.length>0)?
            altN
          : state.alternateMobileNumber,
        businessEmail:businessEmail?businessEmail:state.businessEmail,
        landlineNumber:landlineNumber?landlineNumber:state.landlineNumber,
        pendingAlternateMobileNumber: pendingAlternateMobileNumber?pendingAlternateMobileNumber:state.pendingAlternateMobileNumber
      };
    case "SET_LOGGED_IN": {
      return {
        ...state,
        mobileNumber: action.data.mobile,
        contactPersonName: action.data.contactPerson
      };
    }
    case "CLEAR_INITIAL_DATA":
      return initContactDetails;
    default:
      return state;
  }
};

let initCData = {};
export const loadCompanyDetails = (state = initCData, action) => {
  switch (action.type) {
    case "SET_SELECTED_BUSINESS_DATA":
      const data = action.data;
      return { ...data };
    default:
      return state;
  }
};

let initDocData = {};
export const loadDocData = (state = initDocData, action) => {
  switch (action.type) {
    case "SET_DOC_ID":
      const docid = action.data;
      return { ...state, docid: docid };
    case "SET_SELECTED_BUSINESS_DATA":
      const docIde = action.data.docid;
      return { ...state, docid: docIde };
    case "LOAD_PREFILLED_BUSINESS_DATA":
      return {
        ...state,
        ...action.data.loadDocData,
      };
    default:
      return state;
  }
};

const storeTimingNewdata = (elem) => {
  var H = +elem.substr(0, 2);
  var h = H % 12 || 12;
  var ampm = H < 12 ? " am" : " pm";
  const res = h + elem.substr(2, 3) + ampm;
  return res;
};

const mapTiming = (elem) => {
  const timing = elem[1].map((item) => [
    storeTimingNewdata(item[0]),
    storeTimingNewdata(item[1]),
  ]);
  return [elem[0], timing];
};

let initTimingDetails = {
  holidays: [],
  storeTimings: [],
  deliveryTimings: [],
  storeTimingsOrg: {},
  deliveryTimingsOrg: {},
};

export const storeTimingDetails = (state = initTimingDetails, action) => {
  switch (action.type) {
    case "SET_STORE_TIMING":
      const data = action.data;
      console.log("****************HERE_SET_STORE_TIMING");
      return { ...data };
    case "SET_SELECTED_BUSINESS_DATA":
      console.log("****************HERE_SET_SELECTED_BUSINESS_DATA");
      const { hop = {}, mk_details: [{ market_place }] = [] } = action.data;
      const { delv_tim = {}, hol = [] } = market_place ? market_place : {};
      const storeTimings = hop.val
        ? Object.entries(hop.val).map(mapTiming)
        : [];
      const deliveryTimings = delv_tim.val
        ? Object.entries(delv_tim.val).map(mapTiming)
        : [];
      const storeTimingsOrg = hop.val ? hop.val : {};
      const deliveryTimingsOrg = delv_tim.val ? delv_tim.val : {};
      return {
        ...state,
        storeTimings,
        deliveryTimings,
        storeTimingsOrg,
        deliveryTimingsOrg,
        holidays: hol,
      };
    case "LOAD_PREFILLED_BUSINESS_DATA":
      console.log("load_prefilled data", action.data.storeTimingDetails);
      return {
        ...state,
        ...action.data.storeTimingDetails,
      };
    case "CLEAR_INITIAL_DATA":
      return initTimingDetails;
    default:
      return state;
  }
};

const DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const CloneWeekDays = () =>{
  return DAYS.map((d)=>{
      return {
          displayText: d,
          // timings: [{ startTime: "", endTime: "", startIndex: "", endIndex: "" }],
          checked: false,
          respText: d.substring(0, 3)
      }
  });
}
const initOffersDet = {
  dealName: "",
  description: "",
  dealPeriod: "",
  startDate: "",
  endDate: "",
  minOrderVal: "",
  rupeeVal: "",
  maxDiscVal: "",
  percentageVal: "",
  disOnDeal: "",
  applyAll: false,
  dayTimeList: CloneWeekDays(),
  termsList: [{}]
}
export const offersDetails = (state = initOffersDet, action) => {
  switch(action.type){
    case "SAVE_OFFERS_DAYTIME_LIST":
      return {
        ...state, dayTimeList: action.data
      }
    case "SAVE_OFFERS_FIELDS_DATA":
      return {
        ...state, ...action.data
      }
    case "SAVE_OFFERS_TERMS":
      return {
        ...state, termsList: action.data
      }
    case "SET_OFFERS_DATA_EDIT":
    let dtList = action.data.dayTimeList?action.data.dayTimeList: CloneWeekDays();
      return {
        ...state, ...action.data , dayTimeList: dtList //, termsList: [...action.data.termsList]
      }
    case "CLEAR_OFFERS_DATA":
      return {
        ...initOffersDet
      }
    case "APPLY_TIME_OFFERS_ALL": 
      return {
        ...state, applyAll: action.flag
      }
    case "SET_TIMINGS_FROM_STORETIME":
      console.log({ ...state, dayTimeList: action.data});
      return {
        ...state, dayTimeList: action.data
      }
    default:
      return state;
  }
}

export const offerList = (state=[], action) => {
  switch(action.type){
    case "ADD_OFFER":
      return [
        ...state, {...action.data, dayTimeList: action.data.dayTimeList}
      ]
    case "EDIT_OFFER":
      let stateClone = [...state];
      stateClone[action.index] = action.data;
      return [
        ...stateClone
      ]
    case "DELETE_OFFER":
      var clonedD = [...state];
      clonedD.splice(action.ind,1);
      return [
        ...clonedD
      ]
    case "LOAD_PREFILLED_BUSINESS_DATA":
      if(action.data.offerList){
        return [
          ...action.data.offerList
        ]
      }else{
        return [];
      }
    default:
      return state;
  }
}

export const discOfferNode = (state={}, action) => {
  switch(action.type){
    case "LOAD_PREFILLED_BUSINESS_DATA":
      if(action.data.discOfferNode){
        return {
          ...action.data.discOfferNode
        }
      }else{
        return {};
      }
    default:
      return state;
  }
}
