import axios from "axios";
import store from "../MyStore";
import {URI, redirectJDAPI, DashboardUri, DashboardUri2, taxDetailsUri, URI_TOKEN_VERIFICATION, URI_TOKEN_CHECK, EDIT_LIST_CAT_CHECK, SECURE_PG, CREATE_ORDER_ID, EDIT_LIST_HOME_URL_CAT, EDIT_LIST_HOME_URL, URI_FOR_VERIFY_OTP} from "./configs";
import {STNeed, ONBOARDING_VERTICAL, JDCASHVERTICAL} from "../config/consts"; 

export const checkForLive = () => {
  if (
    window.location.host.includes("www") ||
    window.location.host.includes("business") ||
    window.location.host.includes("win") ||
    window.location.host.includes("wap")
  ) {
    return true;
  } else {
    return false;
  }
};


const currentHost = window.location.protocol + "//" + window.location.host;

export const getSid = () => {
  let r = getCurrentURIParams();
  let sid = r.sid ? r.sid : getCookie("sid");
  if(!sid){
    try{
      let logInJson = window.JdLiteInterface.getLoginData();
      if(!logInJson) return;
      logInJson = JSON.parse(logInJson);
      sid = logInJson.sid;
    }catch(e){
      console.log("App.js getting sid error ",e);
    }
  }
  return sid;
}

const urlFormatName = (str) => {
  str = str.replace(/\\\\/, "");
  str = str.replaceAll("'", "");
  str = str.replaceAll(".", "");
  str = str.replace(/[^a-zA-Z\s0-9(),]+/, "");
  str = str.trim();
  str = str.replace(/[\\t\\n\\r]+/, "");
  str = str.replaceAll(" ", "-");
  str = str.replaceAll("--", "-");	
  return str;
}

export const clickTrack = (page) => {
  let Ob = {
    businessDet: "Business Details",
    locationDet: "Location Details",
    contactDet: "Contact Details",
    pickupDet: "Pickup Details",
    storeTimeDet: "Store Time Details",
    sellerInt: "Seller Intro Page",
  };
  let li = Ob[page];

  let r = getCurrentURIParams();
  let { locationDetails } = store.getState();

  let mobile = r.mobile ? r.mobile : getMobile();
  let sid = getSid();
  let city = r.city ? r.city : locationDetails.city;
  let wap = 2;
  let source = r.source && r.source !== "undefined" ? r.source : 2;
  let docid = r.docid;
  let url = `${process.env.REACT_APP_URL_CLICK_TRACKER}?ll=onboarding&li=${li}&mobile=${mobile}&sid=${sid}&city=${city}&wap=${source}&docid=${docid}`;
  return axios.get(url);
};

export const getCookie = (name) => {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return "";
};

export const getDboardLink = () => {
  let { businessDetails, contactDetails, locationDetails, loadDocData } =
    store.getState();
  const { contactPersonName, mobileNumber } = contactDetails;
  let { locationReducer } = store.getState();
  let r = {};
  if (locationReducer.urlParamObj) {
    r = locationReducer.urlParamObj;
  }
  let sid = getSid();
  const source = r.source && r.source !== "undefined" ? r.source : 2; //"2";
  let docid = r.docid ? r.docid : loadDocData.docid; //"080PXX80.XX80.160211113116.I4J7";
  // const backUrl = window.location.href;
  const vertical = 37;
  let urlParams = getURIParams(docid);
  let mobileNtoUse = getMobile();
  if (!mobileNtoUse) {
    mobileNtoUse = mobileNumber;
  }
  let URICompiled;
  if(source==2 || source==7 || source==52 || source==57){
    if (docid && docid !== "undefined") {
      URICompiled = `${DashboardUri}?name=${contactPersonName}&source=${source}&mobile=${mobileNtoUse}&docid=${docid}&redirectFrm=onboarding`;
    } else {
      URICompiled = `${DashboardUri}?name=${contactPersonName}&source=${source}&mobile=${mobileNtoUse}&redirectFrm=onboarding`;
    }
  }else{
    if (docid && docid !== "undefined") {
      URICompiled = `${DashboardUri}?name=${contactPersonName}&source=${source}&sid=${sid}&mobile=${mobileNtoUse}&docid=${docid}&redirectFrm=onboarding`;
    } else {
      URICompiled = `${DashboardUri}?name=${contactPersonName}&source=${source}&sid=${sid}&mobile=${mobileNtoUse}&redirectFrm=onboarding`;
    }
  }
  return URICompiled;
};

export const getGetRedirectAPI = (id, qrRedirect) => {
  let { businessDetails, contactDetails, locationDetails, loadDocData } =
    store.getState();
  const { contactPersonName, mobileNumber } = contactDetails;
  let { locationReducer } = store.getState();
  let r = {};
  if (locationReducer.urlParamObj) {
    r = locationReducer.urlParamObj;
  }
  let s = getCurrentURIParams();
  let sid = s.sid && s.sid !== "undefined" ? s.sid : getCookie("sid");
  let source = s.source && s.source !== "undefined" ? s.source : 2;
  let docid = id;
  if (!docid) {
    docid = s.docid ? s.docid : loadDocData.docid; //"080PXX80.XX80.160211113116.I4J7";
  }
  // const backUrl = window.location.href;
  const vertical = getVerticalId();
  let urlParams = getURIParams(docid);
  let mobileNtoUse = getMobile();
  if (!mobileNtoUse) {
    mobileNtoUse = mobileNumber;
  }
  let backUrl = encodeURIComponent(
    `${currentHost}/onboarding/businessDetails/contactDet?${urlParams}`
  );
  let successUrl = encodeURIComponent(
    `${currentHost}/onboarding/businessDetails/pickupDet?${urlParams}`
  );
  if(vertical==JDCASHVERTICAL){
    // successUrl = encodeURIComponent(
    //   `${currentHost}/onboarding/businessDetails/offerDet?${urlParams}`
    // );
      backUrl = encodeURIComponent(
    `${currentHost}/onboarding/businessDetails/offerDet?${urlParams}`
      );
     successUrl =  encodeURIComponent(getEditListHomeUrl(locationDetails.city, businessDetails.storeName));
  }
  if(qrRedirect){
    let URICs = `${redirectJDAPI}?${source==14?"flow=executive&":""}${source==14?"eid="+s.empCode+"&":""}name=${contactPersonName}&source=${source}&sid=${sid}&mobile=${mobileNtoUse}&docid=${docid}&vertical=${vertical}&backUrl=${backUrl}&successUrl=${successUrl}#/qr/scanqr`;
    return URICs;
  }else{
    let URICompiled = `${redirectJDAPI}?${source==14?"flow=executive&":""}${source==14?"eid="+s.empCode+"&":""}name=${contactPersonName}&source=${source}&sid=${sid}&mobile=${mobileNtoUse}&docid=${docid}&vertical=${vertical}&backUrl=${backUrl}&successUrl=${successUrl}`;
    return URICompiled;
  }
};

export const getCurrentURIParams = () => {
  let objParams = {};
  let urlParams = window.location.href.split("?")[1];
  let urlParamsArr = [];
  if (urlParams) {
    urlParamsArr = urlParams.split("&");
  }
  urlParamsArr.forEach((element) => {
    let elName = element.split("=")[0];
    let elValue = element.split("=")[1];
    if (STNeed.indexOf(elName) > -1) {
      objParams[elName] = elValue;
    }
  });
  return objParams;
};
export const getURIParams = (docidPassed, isNewDocId, refId) => {
  let { contactDetails, loadDocData } = store.getState();
  let { contactPersonName, mobileNumber } = contactDetails;
  let { locationReducer } = store.getState();
  let r = {};
  r = getCurrentURIParams();
  let sid = getSid();
  if(!contactPersonName){
    contactPersonName = r.name;
  }
  const source = r.source && r.source !== "undefined" ? r.source : getSource();
  const vertical = r.vertical && r.vertical !== "undefined" ? r.vertical: ONBOARDING_VERTICAL;
  const docid = docidPassed
    ? docidPassed
    : r.docid
    ? r.docid
    : loadDocData.docid; //"080PXX80.XX80.160211113116.I4J7";
  let mobileNtoUse = getMobile();
  if(source==14){
    mobileNtoUse = r.mobile;
  } else if (!mobileNtoUse) {
    mobileNtoUse = mobileNumber;
  }
  let origin, backUrl;
  if (r.origin && (r.origin == "edit_listing" || r.origin == "jdBusiness")) {
    origin = r.origin;
    backUrl = r.backUrl;
  }
  let refIdTorefer = refId && refId !== "undefined" ? refId : r.refId;
  //No sid to be passed in url for sources 2,7
  let noSid=false;
  if(source=='2' || source=='7'){
    noSid=true;
  } 
  let empCode = r.empCode;
  if (origin) {
    if (isNewDocId) {
      return `name=${contactPersonName}&${empCode?`empCode=${empCode}&`:""}source=${source}${noSid?``:`&sid=${sid}`}&mobile=${mobileNtoUse}&origin=${origin}&refId=${refIdTorefer}&backUrl=${backUrl}&vertical=${vertical}`;
    } else {
      if (docid && docid !== "undefined") {
        return `name=${contactPersonName}&${empCode?`empCode=${empCode}&`:""}source=${source}${noSid?``:`&sid=${sid}`}&mobile=${mobileNtoUse}&docid=${docid}&origin=${origin}&refId=${refIdTorefer}&backUrl=${backUrl}&vertical=${vertical}`;
      } else {
        return `name=${contactPersonName}&${empCode?`empCode=${empCode}&`:""}source=${source}${noSid?``:`&sid=${sid}`}&mobile=${mobileNtoUse}&origin=${origin}&refId=${refIdTorefer}&backUrl=${backUrl}&vertical=${vertical}`;
      }
    }
  }
  if (isNewDocId || (docid && docid !== "undefined")) {
    return `name=${contactPersonName}&${empCode?`empCode=${empCode}&`:""}source=${source}${noSid?``:`&sid=${sid}`}&mobile=${mobileNtoUse}&refId=${refIdTorefer}&docid=${docid}&vertical=${vertical}`;
  }
  return `name=${contactPersonName}&${empCode?`empCode=${empCode}&`:""}source=${source}${noSid?``:`&sid=${sid}`}&refId=${refIdTorefer}&mobile=${mobileNtoUse}&vertical=${vertical}`;
};

export const getMobile = () => {
  let mobile = getCookie("mobile");
  if (mobile) {
    return mobile;
  }
  const userDet =
    getCookie("ln_user") &&
    JSON.parse(decodeURIComponent(getCookie("ln_user")));
  return userDet.mb;
};
export const getSource = () => {
  let src;
  let r = getCurrentURIParams();
  src = r.source;
  if (!src) {
    let isMobi = /iphone|ipod|android|ie|blackberry|fennec/.test(
      navigator.userAgent.toLowerCase()
    );
    if (isMobi) {
      src = 2;
    } else {
      src = 7;
    }
  }
  return src;
};

export const getVerticalId = () => {
  let uriParams = getCurrentURIParams();
  return uriParams["vertical"]?uriParams["vertical"]: ONBOARDING_VERTICAL;
}

export const getJDUID = () => {
  let { locationReducer } = store.getState();
  return locationReducer.jduid;
}

export const LoadBusinesses = () => {
  let { locationReducer } = store.getState();
  let r = {};
  r = getCurrentURIParams();
  let bcase = "blist";
  let mobile = getMobile();
  if (!mobile) {
    mobile = r.mobile;
  }
  let wap = r.wap ? r.wap : "2"; //"2";
  let source = r.source && r.source !== "undefined" ? r.source : "2"; //"2";
  let version = r.version ? r.version : "101"; //"2.5";
  let sid = getSid();
  const formData = new FormData();
  formData.append("case", "blist"); //append the values with key, value pair
  formData.append("mobile", mobile);
  formData.append("sid", sid); //append the values with key, value pair
  formData.append("wap", wap);
  formData.append("source", source); //append the values with key, value pair
  formData.append("version", version);
  const verticalId = getVerticalId();
  formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData);
};

export const businessDetails = (docid) => {
  const formData = new FormData();
  let r = {};
  r = getCurrentURIParams();
  let sid = (r.source==2 || r.source==7)?getCookie("sid"):r.sid;
  if(!sid){
    sid = getSid();
  }
  formData.append("case", "compDetails"); //append the values with key, value pair
  formData.append("sid", sid); 
  formData.append("docid", docid);
  const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData);
};

export const categoryAutoSuggest = (srchtrm) => {
  //   console.log("seachtrm", srchtrm);
  const formData = new FormData();
  //   const config = {
  //     headers: { "content-type": "multipart/form-data" },
  //   };
  formData.append("case", "catAuto"); //append the values with key, value pair
  formData.append("wap", 2);
  formData.append("source", 2);
  formData.append("version", 2.5);
  formData.append("city", "mumbai");
  formData.append("srchtrm", srchtrm);
  const verticalId = getVerticalId();
  formData.append("verticalId", verticalId);
  return axios.post(URI, formData);
};

export const natureOfBusiness = (param) => {
  const params = {
    case: "buzNature",
  };
  return axios.get(URI, { params });
};

export const imageUpload = (file) => {
  const formData = new FormData();
  formData.append("case", "imgUpload");
  formData.append("files[]", file);
  const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData);
};

export const SaveBusinessDetails = (data, urlLocation, onPage, docIdToUse) => {
  let { locationReducer, loadDocData } = store.getState();
  let r = getCurrentURIParams();
  let sid = getSid();
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  formData.append("case", "setResult");
  formData.append("sid", sid);
  const docid = docIdToUse ? docIdToUse : r.docid ? r.docid : loadDocData.docid;
  if (docid) formData.append("docid", docid);
  else formData.append("docid", "");
  if (r.refId && r.refId !== "undefined") {
    formData.append("id", r.refId);
  }
  let urlParams = window.location.href.split("?")[1];
  let urlToUse = urlLocation;
  formData.append("url", urlToUse);
  formData.append("json_data", JSON.stringify(data));
  const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  clickTrack(onPage);
  return axios.post(URI, formData, config);
};

export const LoadPrefilledData = (data, srcA) => {
  let { locationReducer, loadDocData } = store.getState();
  let r = getCurrentURIParams();
  let sid = getSid();
  let source = srcA?srcA:r.source? r.source:2;
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  formData.append("case", "getResult");
  formData.append("sid", sid);
  formData.append("source", source);
  const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  const docid = data ? data : r.docid ? r.docid : loadDocData.docid;
  if (docid) formData.append("docid", docid);
  else formData.append("docid", "");

  console.log(docid);
  return axios.post(URI, formData, config);
};

export const getLocationDetails = (latlongObj) => {
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  formData.append("case", "locDetails");
  formData.append("wap", 2);
  formData.append("source", 2);
  formData.append("version", 2.5);
  formData.append("lat", latlongObj.lat);
  formData.append("long", latlongObj.long);
  const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData, config);
};

export const SendOtp = (mob, contactPersonName, resendOtp) => {
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  formData.append("case", "verifyVcode");
  formData.append("mobile", mob);
  formData.append("name", contactPersonName);
  const verticalId = getVerticalId();
  formData.append("verticalId", verticalId); 
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  if (resendOtp == 1) {
    formData.append("resend", 1);
  }
  return axios.post(URI, formData, config);
};

export const VerifyOtp = (mob, contactPersonName, vcode) => {
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  formData.append("case", "verifyVcode");
  formData.append("mobile", mob);
  formData.append("name", contactPersonName);
  formData.append("vcode", vcode);
  const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData, config);
};

export const CheckLogin = (mob, sid, source, token) => {
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  formData.append("case", "loginCheck");
  formData.append("mobile", mob);
  formData.append("sid", sid);
  formData.append("source", source);
  formData.append("token", token);
  const verticalId = getVerticalId();
  formData.append("verticalId", verticalId); 
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  console.log(URI);
  return axios.post(URI, formData, config);
};

export const CheckLoginWToken = (source) => {
  // const formData = new FormData();
  // const config = {
  //   headers: {
  //     "content-type": "multipart/form-data"
  //   },
  // };
  // return axios.get(`${URI_TOKEN_CHECK}?token=${token}`, config);
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  formData.append("case", "loginCheck");
  formData.append("source", source);
  const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData, config);
};

export const CreateDocId = (city) => {
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  let { locationReducer } = store.getState();
  let r = getCurrentURIParams();
  let sid = getSid();
  formData.append("case", "docCreation");
  formData.append("data_city", city);
  formData.append("sid", sid);
  const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData, config);
};

export const UpdateDocId = (docid) => {
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  let { locationReducer } = store.getState();
  let r = getCurrentURIParams();
  let sid = getSid();
  let source = r.source && r.source !== "undefined" ? r.source : "2"; //"2";
  formData.append("case", "updateDoc");
  formData.append("sid", sid);
  formData.append("source", source);
  formData.append("docid", docid);
  const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData, config);
};

export const checkBusinessName = (data) => {
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  let r = getCurrentURIParams();
  let source = r.source && r.source !== "undefined" ? r.source : "2"; //"2";
  formData.append("case", "validation");
  formData.append("source", source);
  formData.append("type", "companyName");
  formData.append("compname", data.storeName);
//  formData.append("legalcompanyname", data.legalCompanyName);
  const verticalId = getVerticalId();
 formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
return axios.post(URI, formData, config);
};

export const getLocationDetailsFromArea = (
  areaVal,
  pinCode,
  city,
  data_city
) => {
  //source.cancel('Operation canceled by the user.');
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
    //  cancelToken: source.token
  };
  formData.append("case", "locAuto");
  formData.append("srchtrm", areaVal);
  if (data_city) {
    formData.append("data_city", data_city);
  }
  //  formData.append("pincode", pinCode);
  formData.append("city", city);
  const verticalId = getVerticalId();
 formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
return axios.post(URI, formData, config);
};

export const FinalUpdateDocId = (docid) => {
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  let { locationReducer } = store.getState();
  let r = getCurrentURIParams();
  if (!r) {
    r = locationReducer.urlParamObj;
  }
  let sid = getSid();
  const source = r.source && r.source !== "undefined" ? r.source : 2;
  formData.append("case", "finalUpdate");
  formData.append("sid", sid);
  formData.append("docid", docid);
  formData.append("source", source);
  let urlToUse = window.location.href;
  formData.append("url", urlToUse);
  const verticalId = getVerticalId();
 formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
return axios.post(URI, formData, config);
};

export const imageUploadToUpdateDoc = (docidPassed) => {
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  let r = getCurrentURIParams();
  let sid = getSid();
  const docid = docidPassed ? docidPassed : r.docid;
  formData.append("case", "docImg");
  formData.append("sid", sid);
  formData.append("docid", docid);
  const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData, config);
};

export const getCitySuggestions = (areaVal) => {
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  formData.append("case", "cityAuto");
  formData.append("srchtrm", areaVal);
  const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData, config);
};

export const generateUIID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === "x" ? r : r & (0x3 | 0x8);
    return v.toString(16);
  });
};
export const getBuildingSuggestions = (buildingVal, token) => {
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  formData.append("case", "buildAuto");
  formData.append("srchtrm", buildingVal);
  formData.append("wap", 2);
  formData.append("token", token);

  const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData, config);
};
export const getBuildingAddress = (place_id, token) => {
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  /*
case:fetchLocDetail
wap:2
source:2
version:2.6
token:2ec674f9-085a-43ca-2558-7086ec1766ef
place_id:ChIJ331OlGEXrjsRrQP8TnDT7oM

    */
  let r = getCurrentURIParams();
  const source = r.source && r.source !== "undefined" ? r.source : 2;
  const wap = r.wap && r.wap !== "undefined" ? r.wap : 2;
  const version = r.version && r.version !== "undefined" ? r.version : 2.6;
  formData.append("case", "fetchLocDetail");
  formData.append("version", version);
  formData.append("wap", 2);
  formData.append("source", source);
  formData.append("token", token);
  formData.append("place_id", place_id);
  return axios.post(URI, formData, config);
};

export const getStdCode = (city) => {
  //source.cancel('Operation canceled by the user.');
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
    //  cancelToken: source.token
  };
  formData.append("case", "stdCode");
  formData.append("city", city);
  return axios.post(URI, formData, config);
};

export const sendDesktopOtpAPI = ({ mobile, userName }, resend) => {
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  formData.append("case", "sendDeskOTP");
  formData.append("mobile", mobile);
  formData.append("name", userName);
  formData.append("resend", resend);
  const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData, config);
};

export const verifyDesktopOtpAPI = ({ mobile, userName }, vcode) => {
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  return axios.get(
    `${URI_TOKEN_VERIFICATION}?login=${mobile}&password=&ocode=${vcode}&nm=${userName}`,
    config
  );
};

export const getCitySynList = () => {
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  formData.append("case", "citySynList");
  const verticalId = getVerticalId();
  formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData, config);
};

export const getResultsByRefId = (refId) => {
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  let r = getCurrentURIParams();
  let sid = getSid();
  formData.append("sid", sid);
  formData.append("case", "getResultById");
  formData.append("id", refId);
  const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData, config);
};

export const checkValidCity = (city, pincode) => {
  //source.cancel('Operation canceled by the user.');
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
    //  cancelToken: source.token
  };
  formData.append("case", "validateCity");
  formData.append("city", city);
  formData.append("pincode", pincode);

  const verticalId = getVerticalId();
  formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData, config);
};

export const getEditListCatAPI = (docid, city) => {
  let urlParams = getURIParams(docid);
  const backUrl = encodeURIComponent(
    `${currentHost}/onboarding/businessDetails/locationDet?${urlParams}`
  );
  const successUrl = encodeURIComponent(
    `${currentHost}/onboarding/businessDetails/contactDet?${urlParams}&el=1`
  );
  const source = getSource();
  let r = getCurrentURIParams();
  let mobile = r.mobile ? r.mobile : getMobile();
  let sid = getSid();
  sid = encodeURIComponent(sid);
  if (!docid) {
    docid = r.docid;
  }
  let uri = `${EDIT_LIST_CAT_CHECK}?vertical=mkponboard&docid=${docid}&sid=${sid}&owner=1&hide_header=1&city=${city}&login_mobile=${mobile}&mobile=${mobile}&source=${source}&version=0&editlist=category&editname=category&vBackUrl=${backUrl}&vSuccessUrl=${successUrl}`;
  return uri;
};

export const getEditListHomeAPI = (docid, city) => {
  let urlParams = getURIParams(docid);
  const backUrl = encodeURIComponent(
    `${currentHost}/onboarding/businessDetails/locationDet?${urlParams}`
  );
  const successUrl = encodeURIComponent(
    `${currentHost}/onboarding/businessDetails/contactDet?${urlParams}&el=1`
  );
  const source = getSource();
  let r = getCurrentURIParams();
  let mobile = r.mobile ? r.mobile : getMobile();
  let sid = r.sid ? r.sid : getCookie("sid");
  sid = encodeURIComponent(sid);
  if (!docid) {
    docid = r.docid;
  }
  let uri = `${EDIT_LIST_CAT_CHECK}?vertical=mkponboard&docid=${docid}&sid=${sid}&owner=1&hide_header=1&city=${city}&login_mobile=${mobile}&mobile=${mobile}&source=${source}&version=0&vBackUrl=${backUrl}&vSuccessUrl=${successUrl}`;
  return uri;
};

export const getProfileImage = () => {
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
    //  cancelToken: source.token
  };
  let r = getCurrentURIParams();
  let mobile = r.mobile ? r.mobile : getMobile();
  formData.append("case", "userInfo");
  formData.append("mobile", mobile);
  const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData, config);
};

// export const createOrderId = (
//   deposit_amount,
//   package_amount,
//   gst_amount,
//   amount_including_gst,
//   package_id
// ) => {
//   let r = getCurrentURIParams();
//   let mobile = r.mobile ? r.mobile : getMobile();
//   let sid = r.sid ? r.sid : getCookie("sid");
//   let docid = r.docid;
//   const formData = new FormData();
//   const config = {
//     headers: { "content-type": "multipart/form-data" },
//   };
//   formData.append("case", "create_orderid");
//   formData.append("receiver_docid", "JD%20Shopping");
//   // formData.append("deposit_amount", deposit_amount);
//   // formData.append("package_amount", package_amount);
//   // formData.append("gst_amount", gst_amount);
//   // formData.append("amount_including_gst", amount_including_gst);
//   formData.append("package_id", package_id);
//   formData.append("payment_flag", "send");
//   formData.append("vertical", 37);
//   formData.append("source", "jd_shopping");
//   formData.append("server", "prod");
//   formData.append("jdpay_login_sid", sid);
//   formData.append("jdpay_login_mobile", mobile);
//   formData.append("payer_docid", docid);
//   return axios.post(CREATE_ORDER_ID, formData, config);
// };

export const getSecurePGURL = (orderid) => {
  let r = getCurrentURIParams();
  const source = r.source && r.source !== "undefined" ? r.source : 2;
  let url = `${SECURE_PG}?order_id=${orderid}&jdlite=0&source=${source}&version=485&wap=${source}&qrorder=0`;
  return url;
};

export const redirectToDashboard = () => {
  let { contactDetails, loadDocData } = store.getState();
  const { contactPersonName, mobileNumber } = contactDetails;
  let { locationReducer } = store.getState();
  let r = getCurrentURIParams();
  // if (locationReducer.urlParamObj) {
  //   r = locationReducer.urlParamObj;
  // }
  let sid = getSid();
  const source = r.source && r.source !== "undefined" ? r.source : 2; //"2";
  let docid = r.docid ? r.docid : loadDocData.docid;
  const vertical = 37;
  let urlParams = getURIParams(docid);
  let mobileNtoUse = getMobile();
  if (!mobileNtoUse) {
    mobileNtoUse = mobileNumber;
  }
  if(source==2 || source==7 || source==52 || source==57){
    let dashboardurl = `${DashboardUri2}?name=${contactPersonName}&source=${source}&mobile=${mobileNtoUse}&docid=${docid}&redirectFrm=onboarding`;
    window.location.href = dashboardurl;
  }else{
    let dashboardurl1 = `${DashboardUri2}?name=${contactPersonName}&source=${source}&sid=${sid}&mobile=${mobileNtoUse}&docid=${docid}&redirectFrm=onboarding`;
    window.location.href = dashboardurl1;
  }  
};

export const redirectToTaxDetails = () => {
  let r = getCurrentURIParams();

  let mobile = r.mobile ? r.mobile : getMobile();
  let sid = getSid();
  let docid = r.docid;
  let { contactDetails } = store.getState();
  const { contactPersonName } = contactDetails;
  const source = r.source && r.source !== "undefined" ? r.source : 2;
  const successUrl = encodeURIComponent(window.location.href);
  let taxdetailsurl = `${taxDetailsUri}?name=${contactPersonName}&source=${source}&sid=${sid}&mobile=${mobile}&docid=${docid}&input=chooseplan&successUrl=${successUrl}`;
  window.location.href = taxdetailsurl;
};

export const logoutCall = (source) => {
  return axios(URI, {
    params: {
      case: "logout",
      source: source || "",
    },
  });
};

export const getPackageDetails = () => {
  let r = getCurrentURIParams();
  let mobile = r.mobile ? r.mobile : getMobile();
  let sid = getSid();
  let docid = r.docid;
  const REACT_APP_PACKAGE_LIST_URL = "https://business.justdial.com/proc/market_onboard/xhr.php";
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  formData.append("case", "package_details");
  formData.append("server", "prod");
  formData.append("jdpay_login_sid", sid);
  formData.append("jdpay_login_mobile", mobile);
  formData.append("payer_docid", docid);
  return axios.post(REACT_APP_PACKAGE_LIST_URL, formData, config);
};

export const getEditListHomeUrl = (city, companyName) => {
  let r = getCurrentURIParams();
  let docIdD = r.docid.replaceAll(".", "-");
  let compNa = urlFormatName(companyName);
  let url = `${EDIT_LIST_HOME_URL}/${city}/${compNa}/${docIdD}`;
  return url;
};

export const getEditListCatShortURI = (city, companyName, dId) => {
  let r = getCurrentURIParams();
  let docid = dId?dId:r.docid;
  let docIdD = docid.replaceAll(".", "-");
  let compNa = urlFormatName(companyName);
  let urlParams = getURIParams(docid);
  const verticalId = getVerticalId();
  const backUrl = encodeURIComponent(
    `${currentHost}/onboarding/businessDetails/locationDet?${urlParams}`
  );
  const successUrl = encodeURIComponent(
    `${currentHost}/onboarding/businessDetails/contactDet?${urlParams}&el=1`
  );
  let url = `${EDIT_LIST_HOME_URL_CAT}/edit-category/category/${city}/${compNa}/${docIdD}?page=external&origin=genioOnboarding&vSuccessUrl=${successUrl}&vBackUrl=${backUrl}&vertical=${verticalId}`;
  return url;
}

export const checkPlanEligibility = ()=>{
  // check if user eligible for plan or not
  let r = getCurrentURIParams();
  let mobile = r.mobile ? r.mobile : getMobile();
  let sid = r.sid ? r.sid : getCookie("sid");
  let docid = r.docid;
  let formData = new FormData()
  const config = {
    headers: { "content-type": "multipart/form-data" }
  }
  formData.append("case", "CheckEligibilityForPayment");
  formData.append("server", "prod");
  formData.append("jdpay_login_sid", sid);
  formData.append("jdpay_login_mobile", mobile);
  formData.append("payer_docid", docid);
  const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(CREATE_ORDER_ID, formData, config);
}
// export const getLimCompDetails = () => {
//   let r = getCurrentURIParams();
//   let docid = r.docid;
//   const formData = new FormData();
//   const config = {
//     headers: { "content-type": "multipart/form-data" },
//   };
//   formData.append("case", "compDetailsForOutlet");
//   formData.append("docid", docid);
//   return axios.post(URI, formData, config);
// }

export const createOrderId = (pId, tId)=>{
  // check if user eligible for plan or not
  let r = getCurrentURIParams();
  let mobile = r.mobile ? r.mobile : getMobile();
  let sid = r.sid ? r.sid : getCookie("sid");
  let docid = r.docid;
  let formData = new FormData()
  const config = {
    headers: { "content-type": "multipart/form-data" }
  }
  formData.append("case", "omniSignupPayment");
  formData.append("source", "jd_shopping");
  formData.append("jdpay_login_sid", sid);
  formData.append("jdpay_login_mobile", mobile);
  formData.append("payer_docid", docid);
  formData.append("package_id", pId);
  formData.append("tenure_id", tId);
  const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(CREATE_ORDER_ID, formData, config);
}

export const getCommissionStrLvl0 = () => {
  let { locationDetails } = store.getState();
  let r = getCurrentURIParams();
    let formData = new FormData()
    const config = {
      headers: { "content-type": "multipart/form-data" }
    }
    formData.append("case", "commissionInfo");
    formData.append("action", "root");
    let city = r.city ? r.city : locationDetails.city;
    formData.append("city", city);
  const verticalId = getVerticalId();
  formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
    return axios.post(URI, formData, config);
  }
  
export const getCommissionStrLvl2 = (catId) => {
    let { locationDetails } = store.getState();
    let r = getCurrentURIParams();
      let formData = new FormData()
      const config = {
        headers: { "content-type": "multipart/form-data" }
      }
      formData.append("case", "commissionInfo");
      formData.append("action", "intermediate");
      let city = r.city ? r.city : locationDetails.city;
      formData.append("city", city);
      formData.append("catid", catId);
      formData.append("level", 2);
  const verticalId = getVerticalId();
  formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
      return axios.post(URI, formData, config);
}
  
export const getCommissionStrLvl1 = (cid) => {
    let { locationDetails } = store.getState();
    let r = getCurrentURIParams();
      let formData = new FormData()
        const config = {
          headers: { "content-type": "multipart/form-data" }
        }
        formData.append("case", "commissionInfo");
        formData.append("action", "child");
        let city = r.city ? r.city : locationDetails.city;
        formData.append("city", city);
        formData.append("cid", cid);
        formData.append("level", 1);
        return axios.post(URI, formData, config);
}
  export const getUserDet = (mobile, sid) => {
    const formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    formData.append("case", "usrDetails");
    formData.append("sid", sid);
    formData.append("mobile", mobile);
    const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData, config);
  }

  export const businessDetailsForOutlet = (docid) => {
    const formData = new FormData();
    let r = {};
    r = getCurrentURIParams();
    let sid = r.sid ? r.sid : getCookie("sid");
    formData.append("case", "compDetailsForOutlet"); //append the values with key, value pair
    formData.append("docid", docid);
    const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData);
  } 


export const getbussinessNames = (search, city = "Mumbai") => {
  let r = getCurrentURIParams();
  let docid = r.docid;
  const data = {
    case: "buzSrc",
    srch: search,
    city,
    docid
  };
  return axios.get(URI, { params: data });
};

export const distCheck = ({slat, slong, plat, plong}) => {
  const formData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" }
  }
  formData.append("case", "distCheck");
    formData.append("slat", slat);
    formData.append("slong", slong);
    formData.append("plat", plat);
    formData.append("plong", plong);
    const verticalId = getVerticalId();
   formData.append("verticalId", verticalId);
  const jduid = getJDUID();
  formData.append("jduid", jduid);
  return axios.post(URI, formData, config);
    return axios.post(URI, formData, config);
}

export const getUserInf = () => {
  let r = getCurrentURIParams();
  let mobile = r.mobile ? r.mobile : getMobile();
  let sid = (r.source==2 || r.source==7)?getCookie("sid"):r.sid;
  if(!sid){
    sid = getSid();
  }
  let formData = new FormData()
  const config = {
    headers: { "content-type": "multipart/form-data" }
  }
  formData.append("case", "getUserInfo");
  formData.append("mobile", mobile);
  formData.append("sid", sid);
  return axios.post(URI, formData, config);
}
