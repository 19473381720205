import { useEffect, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { MapContainer, Marker, Popup, TileLayer, Tooltip, useMap } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { showToastAction, hideToastAction} from '../../actions/actions';
import { getCurrentURIParams } from "../../services/common";
let curPos = {};

function showPosition(position) {
  curPos = {
    latitude: position.coords.latitude,
    longitude: position.coords.longitude
  }
}
function getLocation() {
  let r = getCurrentURIParams();
  if(r.source!=53){
    if (navigator.geolocation) {
      return navigator.geolocation.getCurrentPosition(showPosition);
     } else { 
       alert("Geolocation is not supported by this browser.");
     }
  }
}
const homeIcon = new L.Icon({
    iconUrl: 'https://akam.cdn.jdmagicbox.com/images/icontent/seller/map-icon.svg',
    iconSize: [24, 38]
  });

const MapMoveComp = ({lat, long}) =>{
  const mapView = useMap();
  mapView.setView([lat, long], mapView.getZoom());
 return null;
}

const MapComponent = ({lat, long, setLatLong, handleResetMap, isPaidContract}) => {
  const dispatch = useDispatch();
  getLocation();
  console.log(curPos);
  const [map, setMap] = useState(null);
  const [currentPosition, setCurrentPosition] = useState({
    latitude: lat?lat:curPos.latitude?curPos.latitude:12.9550077,
    longitude: long?long:curPos.longitude?curPos.longitude:77.7078062
  });
  useEffect(()=>{
    if(lat && long){
      setCurrentPosition({
        latitude: lat,
        longitude: long
      })
    }else{
      if(curPos.latitude && curPos.longitude){
        setCurrentPosition({
          latitude: curPos.latitude,
          longitude: curPos.longitude
        })
      }
    }
    if(map){
      map.setView([lat, long], map.getZoom());
    }
  },[lat, long])
  useEffect(() => {
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });
  }, []);

  const markerRef = useRef();

  const loadMap = mapInst => {
    mapInst.flyTo([currentPosition.latitude, currentPosition.longitude], 12);
    setMap(mapInst)
  }
  
  const handleResetMapTale = () => { 
    if(isPaidContract){
      dispatch(showToastAction("Cannot change location in paid contract"));
      setTimeout(function () {
        dispatch(hideToastAction());
      }, 3000);
      return false;
    }
    handleResetMap();
    map.flyTo([lat, long]);
  }

  return (
    <>
    <button title={"Current Location"} onClick={()=>handleResetMapTale()} className="mylocation_icon" />
    <MapContainer
    center={[currentPosition.latitude, currentPosition.longitude]}
      zoom={15}
      scrollWheelZoom={true}
      zoomControl={true}
      style={{ height: 180, width: "100%" }}
      whenCreated={loadMap}
    >
      <TileLayer
        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker
        position={[currentPosition.latitude, currentPosition.longitude]}
        ref={markerRef}
        icon={homeIcon}
        eventHandlers={{
          click: e => console.log("CLICKED!!!", e),
          dragend: e => setLatLong(markerRef.current.getLatLng()),
        }}
        draggable={!isPaidContract}
      >
      </Marker>
      {/* <MapComponent lat={currentPosition.latitude} long={currentPosition.longitude}/> */}
    </MapContainer>
    </>
  )
}

export default MapComponent
