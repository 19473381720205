import './searchbar.css';
import { Fragment } from 'react';
import '../pricecaleft.css';
import '../commoncalc.css';

function SearchBar({setSrchTxt, srchTxt, setResults, setSelCat, results, autoSuggestOn, setAutoSuggestOn, autoSuggestClose }){
    return(
        <Fragment>
        <div className={`searchbar_inputblock`}>
            <input type="text" id="pricecalsearch" className={`searchbar_searchInputBox font14`} placeholder="Search Text" onChange={e => { setSrchTxt(e.target.value);}} value={srchTxt} onClick={()=>{setAutoSuggestOn(true); autoSuggestClose.current = true;}} autoComplete="off"/>
            <i className={`searchbar_iconbox_srch searchbar_searchicon searchbar_dib vm`}></i>
            <span className={`searchbar_srchrosicon`} onClick={()=>{ setSrchTxt(""); setResults([]); setSelCat({}); setAutoSuggestOn(false); autoSuggestClose.current = false;}}/>
        </div>
        {/*(!autoSuggestOn) &&*/}
         <div className={`slrimgwpr`}>
            {/* <div className={`common.font15} common.fw500} common.color111} common.mb30`}>Package Volume &amp; Weight</div> */}
            <div className={`slrimg`}>
                <img src="https://akam.cdn.jdmagicbox.com/images/icontent/seller/packageboxnew.png" className={`sellerw_package_spec`} alt=""  layout="fill" priority="false" />
            </div>
        </div>
        </Fragment>

    )
}

export default SearchBar;
