export function PageLoad(source){
    var logjson = JSON.stringify({"pageload":"1"});
    console.log(logjson);
    try{
        if(parseInt(source) == 51){
            window.JdLiteInterface.verticalHandler(logjson);
        } else {
            window.webkit.messageHandlers.callbackHandler.postMessage(logjson); 
        }
    }catch(e){
        console.log("Page load error for 51, 53");
        console.log(e);
    } 
}

export function HideIOSAppheader(){
            /*Hide app header instance */
            let strJson = JSON.stringify({ "type": "hideAppheader" });
            window.webkit.messageHandlers.callbackHandler.postMessage(strJson); 
}