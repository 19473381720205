import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  saveBusinessDetAction,
  showToastAction,
  hideToastAction,
  showLoaderAction,
  hideLoaderAction,
  showMainHeader,
  hideMainHeader,
  setGenioReferFlag,
  setEmployeeCode,
  isOutletContract,
  setIsNotPaidContract
} from "../../actions/actions";
// apiservice
import {
  getURIParams,
  natureOfBusiness,
  imageUpload,
  SaveBusinessDetails,
  checkBusinessName,
  getCurrentURIParams,
  UpdateDocId,
  imageUploadToUpdateDoc,
  getLocationDetails,
  getbussinessNames,
  LoadBusinesses,
  businessDetailsForOutlet as companyDetails,
} from "../../services/common";
import { updateGenioForCat } from "../../services/audit";
import CategorySearch from "./categorySearch";
import {openViewExit} from "../common/openViewExit";
import {IsJDCash, MandatoryFieldsToCheck, ExcludeToCheck} from "../../config/verticalConfig";
// shows buisness nature list
function NatureList(props) {
  const data = props.data;
  return (
    <ul>
      {data.map((item, index) => (
        <li
          key={index}
          onClick={(e) => props.setNature(item)}
          dangerouslySetInnerHTML={{ __html: item.val }}
        ></li>
      ))}
    </ul>
  );
}

function BusinessList(props) {
  const data = props.data;
  console.log(data);
  return (
    <ul onBlur={(e) => console.log("ul blue")} onFocus={(e) => console.log("focus ul")}>
    {data &&
      data.map((item, index) => {
        let itemToDisplay = `${item.display}`;
        if(item.isjdpay){
           itemToDisplay = `${item.display}<span class="gstverified"></span>`;
        }
        return(
        <li
          key={index}
          onClick={(e) => props.setbusinessList(item)}
          dangerouslySetInnerHTML={{ __html: itemToDisplay }}
        >
        </li>
      )})}
  </ul>
  );
}

function ConfirmPopUp(props) {
  return (
    <div className="freshuser_popup_wpr">
      <div className="freshuser_popup_outer">
        <div className="freshuse_text font16 color111">
          You have selected Established business:
          <span className="fw500 pl-5">{props.data}</span>
        </div>

        <div className="font15 color111 pt-15">
          Do you wish to create an outlet?
        </div>

        <div className="createnew_btn mt-30">
          <button onClick={(e) => props.submit(false)}>
            No, I want to create a new
          </button>
          <button onClick={(e) => props.submit(true)}>Yes</button>
        </div>
      </div>
    </div>
  );
}

const BusinessDet = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLegalCompanyNamePopUp, setShowLegalCompanyNamePopUp] = useState(false);
  const [bussList, setBussList] = useState([]);
  const [invalidEmail, setInvalidEmail] = useState(false);

  useEffect(()=>{
    LoadBusinesses()
    .then((res) => {
      let urlParamsClone = getURIParams();
      if (res.data.results) {
        setBussList(res.data.result);
      }
    }).catch((e)=>{
      console.log("Load business error in businessDet", e);
    })
  },[0])
  const redirectToLogin = () => {
    // document.cookie = "sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // document.cookie = "ln_user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    navigate("/introPage");
  };
  const showNavError = (error) => {
    let err;
    switch (error.code) {
      case error.PERMISSION_DENIED:
        err = "User denied the request for Geolocation.";
        break;
      case error.POSITION_UNAVAILABLE:
        err = "Location information is unavailable.";
        break;
      case error.TIMEOUT:
        err = "The request to get user location timed out.";
        break;
      case error.UNKNOWN_ERROR:
        err = "An unknown error occurred.";
        break;
      default:
        err = "An unknown error occurred.";
        break;
    }
   console.log("Nav permission err, ", err);
  };
  // default city as Mumbai
  const [storeCity, setstoreCity] = useState("Mumbai");

  useEffect(async () => {
    setTimeout(() => {
      navigator.geolocation.getCurrentPosition((position) => {
        getLocationDetails({
          lat: position.coords.latitude,
          long: position.coords.longitude,
        }).then((res) => {
          // console.log("res", res, position);
          const city =
            res.data && res.data.result ? res.data.result.city : storeCity;
          setstoreCity(city);
        });
      }, showNavError);
    }, 400);
  }, [0]);

  if(typeof window != "undefined"){
    window.appHandler = appAndroidHandler.bind(this);
}
function appAndroidHandler(logjson){
  let r = getCurrentURIParams();
  if(r.source==51  || r.source==53){
  if(logjson && logjson != "undefined" && logjson != ""){
      var logresponse = JSON.parse(logjson);
    if(logresponse['eventType'] && logresponse['eventType'] == "deviceback"){
      deviceBack();
      }
    }
  }
}
function deviceBack(){
var logjson = JSON.stringify({"action":"exit"});
window.JdLiteInterface.verticalHandler(logjson); 
}
    useEffect(() => {
      const func = (e) =>{
        e.preventDefault();
        let r = getCurrentURIParams();
        let showing = document.getElementsByClassName("selectcat_autosug_wrp");
        if((!showing || showing.length==0) && bussList && bussList.length==0){
          if(r.source==51){
            try{
              var logjson = JSON.stringify({"action":"exit"});
              window.JdLiteInterface.verticalHandler(logjson);
              return false; 
            }catch(e){
              console.log("Back err");
              console.log(e);
            }  
          }else{
            redirectToLogin();
          }
        }
          setHideCategory(false);
      }
        window.addEventListener('popstate', func, false);
      return () => {
        window.removeEventListener('popstate', func);  
      };
    },[bussList]);

  // initialize dat from store
  const businessDetails = useSelector((state) => {
    return state.businessDetails;
  });
  const {isPaidContract, isOutletContractFlag} = useSelector((state) => {
    return state.locationReducer;
  });


  const [showButtonLoader, setShowButtonLoader] = useState(false);
  useEffect(() => {
    //Hardcoding restaurants as default category for jd case flow
    // if(IsJDCash){
    //   setValObj({...businessDetails, businessCat: "Restaurants", nid: "10408936"});
    // }else{
      setValObj(businessDetails);
    // }
  }, [businessDetails]);
  
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[businessDetails]);

  // get state values
  const [valObj, setValObj] = useState(businessDetails);
  const [info, setInfo] = useState({
    legalCompanyName: false,
    storeName: false,
  });
  const [natures, setNature] = useState([]);
  const [isHideCategory, setHideCategory] = useState(false);
  const [isHideNature, setHideNature] = useState(false);
  // comnapny validation message
  const [isShowStoreNameValid, setisShowStoreNameValid] = useState(false);
  const [isShowLegalNameValid, setisShowLegalNameValid] = useState(false);

  const [isShowCategoryInValid, setisShowCategoryInValid] = useState(false);
  const [isShowNatureInValid, setisShowNatureInValid] = useState(false);

  const [isShowStoreNameApiValid, setisShowStoreNameApiValid] = useState("");
  const [isShowLegalNameApiValid, setisShowLegalNameApiValid] = useState("");

  // outlet
  const outletFlag = valObj.master_parentid ? false : true
  const [outToggle, setoutToggle] = useState(outletFlag);
  const [businessNames, setbusinessNames] = useState([]);
  const [masterParentId, setmasterParentId] = useState(
    valObj.master_parentid
  );
  // console.log('masterparent id', valObj.master_parentid, masterParentId)
  // const [businessNameSelected, setbusinessNameSelected] = useState("");
  const [openPopUp, setopenPopUp] = useState(false);

  const compData = useSelector((state) => {
    return state;
  });

  // masterparentId useeffect
 useEffect(() =>{
  setmasterParentId(valObj.master_parentid || masterParentId)
  const outletFlag = valObj.master_parentid ? false : true
  if(!outletFlag){
    setParentLegalCompName(valObj.legalCompanyName);
  }
  setoutToggle(outletFlag);
  // console.log("useEffect-------------------------",valObj.master_parentid, outletFlag)
 }, [valObj.master_parentid])

  const location = useLocation();
  useEffect(() => {
    // initially load nature data
    natureOfBusiness().then((resp) => {
      if (resp.data) {
        const data = resp.data.results.data.bussiness_type;
        setNature(data);
      }
    });
  }, [0]);

  // save & move ahead
  const [saveFlag, setSaveFlag] = useState(false);
  useEffect(() => {
    if (saveFlag == true) {
      setShowButtonLoader(true);
      SaveBusinessDetails(
        { ...compData, doneFinal: false },
        location.pathname,
        "businessDet"
      ).then((res) => {
        if(res.data.results){
          let currentUriParams = getCurrentURIParams();
          const redirect = ()=>{
            setShowButtonLoader(false);
              if(currentUriParams["source"]==51 || currentUriParams["source"]==53){
                openViewExit(currentUriParams["source"]);
                return false;
              }else{
              window.location.href = decodeURIComponent(currentUriParams["backUrl"])
              ? decodeURIComponent(currentUriParams["backUrl"])
              : "https://business.justdial.com/seller-dashboard/accountsetting";
              }
          }
          if (currentUriParams["input"] == "account_settings") {
            if(currentUriParams["source"] != 14){
              UpdateDocId(currentUriParams.docid).then((res) => {
                redirect()
            }).catch((e)=>{
              console.log("Updating error ",e)
              setShowButtonLoader(false);
            })
          } else {
              redirect();
          }
          } else {
            if(currentUriParams["source"] == 14){
              updateGenioForCat().then(()=>{
                let refId = res.data.results.data._id;
                setShowButtonLoader(false);
                let uriParams = getURIParams(undefined, undefined, refId);
                if(IsJDCash){
                  navigate(`/businessDetails/offerDet?${uriParams}`);
                }else{
                  navigate(`/businessDetails/locationDet?${uriParams}`);
                }
              });
            }else{
              if(currentUriParams.docid && currentUriParams.docid!="" && currentUriParams["source"] != 14){
                UpdateDocId(currentUriParams.docid).then((res) => {
                  let uriParams = getURIParams();
                  if(IsJDCash){
                    navigate(`/businessDetails/offerDet?${uriParams}`);
                  }else{
                    navigate(`/businessDetails/locationDet?${uriParams}`);
                  }
                  setShowButtonLoader(false);
               }).catch((e)=>{
                console.log("Updating error ",e)
                setShowButtonLoader(false);
              })
              }else{
                let refId = res.data.results.data._id;
                setShowButtonLoader(false);
                let uriParams = getURIParams(undefined, undefined, refId);
                if(IsJDCash){
                  navigate(`/businessDetails/offerDet?${uriParams}`);
                }else{
                  navigate(`/businessDetails/locationDet?${uriParams}`);
                }
              }
            } 
          }
        }
      });
    }
  }, [saveFlag]);

  const handleProceed = () => {
    dispatch(showLoaderAction());
    handleSave();
  }

  const handleSave = (act) => {
    let valObjClone = {...valObj};
    valObjClone.storeName = valObjClone.storeName ? valObjClone.storeName.trim():"";
    valObjClone.legalCompanyName = valObjClone.legalCompanyName ? valObjClone.legalCompanyName.trim():"";
    console.log(valObjClone);
    const requiredflag = Object.keys(valObjClone).reduce((acc, elem) => {
      if (
        ExcludeToCheck.includes(elem)
      ) {
        return acc;
      } else {
        console.log(Boolean(valObjClone[elem]), valObjClone[elem], elem);
        return acc && Boolean(valObjClone[elem]);
      }
    }, true);

    // Email validation
    let patternEmail= /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let emailArr = valObjClone["businessEmail"].split(",");
    let invalidEmail = false;
    let emailHosts = {
      "gmail":"com",
      "yahoo":["com", "co.in"],
      "outlook":"com"
    }
    emailArr.forEach((r)=>{
      let e = r.trim().split("@")[1];
      if(e){
        let dom = e.split(".")[0];
        let com = e.substring(e.indexOf(".")+1);
        
        if(emailHosts[dom] && Array.isArray(emailHosts[dom])){
          if(!emailHosts[dom].includes(com))
            invalidEmail = true;
        }else if(emailHosts[dom] && (emailHosts[dom]!=com)){
          invalidEmail = true;
        }
        if (!patternEmail.test(r.trim())) {
          invalidEmail = true;
        }
      }else{
        invalidEmail = true;
      }  
    })
    if(invalidEmail){
      setInvalidEmail(true);
      return false;
    }

    if (requiredflag) {
      checkBusinessName(valObjClone)
        .then((resp) => {
          const {
            error: { code = 1 },
            block: {
              msg: { companyname = "", legalcompanyname = "" } = {},
            } = {},
          } = resp.data ? resp.data : {};
          console.log(
            "respvalidation----",
            companyname,
            legalcompanyname,
            code
          );
          let messageP = "";
          if(code==1 && resp.data && resp.data.prompt){
            let msg = resp.data.prompt.msg.companyname;
            if(msg){
             messageP = msg.split(".")[0]+". "+"Please Contact Database Department for further detail.";
             setisShowStoreNameApiValid(messageP);
              return false;
            }
          }
          // check error code is 1 then check whci section has error and setits flag
          if (companyname || legalcompanyname) {
            if (companyname) {
              setisShowStoreNameApiValid(companyname);
            }
            if (legalcompanyname) {
              setisShowLegalNameApiValid(legalcompanyname);
            }
          } else {
            if(act=="account_settings"){
              setShowLegalCompanyNamePopUp(true);
            }else{
              setValObj(valObjClone);
              let r = getCurrentURIParams();
              if(r.source==14){
                dispatch(setEmployeeCode(r.empCode));
                dispatch(setGenioReferFlag(1));
              }
              dispatch(saveBusinessDetAction(valObjClone));
              setSaveFlag(true);
            }
          }
        })
        .catch((e) => {
          console.log("Error in validation");
          console.log(e);
        });
    } else {
      // check every value its therre or not if not set validation flags as true
      if (!valObjClone.storeName) {
        setisShowStoreNameValid(true);
      }
      if (!valObjClone.legalCompanyName) {
        setisShowLegalNameValid(true);
      }
      if (!valObjClone.businessCat || !valObjClone.nid) {
        setisShowCategoryInValid(true);
      }
      if (!valObjClone.businessNature || !valObjClone.businessNatureId) {
        setisShowNatureInValid(true);
      }
      dispatch(showToastAction("Please fill all mandatory fields."));
      setTimeout(function () {
        dispatch(hideToastAction());
      }, 3000);
    }
  };

  const checkFormat = (event) => {
    const files = event.target.files;
    let err = true;
    let type_error = "";
    let type_file = "";
    for (var i = 0; i < files.length; i++) {
      if (
        files[i].type == "image/jpeg" ||
        files[i].type == "image/jpg" ||
        files[i].type == "image/png"
      ) {
      } else {
        type_file += files[i].name + ",";
        err = false;
      }
    }
    if (files[0] && files[0].size > 6291456) {
      dispatch(showToastAction("File size limit: Exceeds 6MB."));
      setTimeout(function () {
        dispatch(hideToastAction());
      }, 3000);
      return false;
    }
    if (!err) {
      dispatch(
        showToastAction("Invalid document type. Please upload jpeg or png.")
      );
      setTimeout(function () {
        dispatch(hideToastAction());
      }, 3000);
    }
    return err;
  };

  // set image
  const selectImage = (e) => {
    const file = e.target.files[0];
    const constformatFlag = checkFormat(e);
    if (file) {
      if (!constformatFlag) {
        return false;
      }
      const image = URL.createObjectURL(file);
      setValObj({ ...valObj, storeLogoPath: image });
      dispatch(showLoaderAction());
      imageUpload(file)
        .then((resp) => {
          const respImage =
            resp.data.image_urls && resp.data.image_urls.length
              ? resp.data.image_urls[0]
              : "";
          let currentUriParams = getCurrentURIParams();
        if(currentUriParams.docid && currentUriParams.docid!=="undefined"){
          imageUploadToUpdateDoc(currentUriParams.docid).then((res)=>{
          console.log("Image upload successful");
          }).catch((e)=>{
            console.log("Image upload failed");
          })
        }
          setValObj({ ...valObj, storeLogoPath: respImage, imgFlag: 1 });
          dispatch(hideLoaderAction());
        })
        .catch((err) => {
          setValObj({ ...valObj, storeLogoPath: "" });
          dispatch(hideLoaderAction());
        });
    }
  };

  const removeImage = () => {
    setValObj({ ...valObj, storeLogoPath: "" });
  };

  // turn on/off check box
  const setCheck = () => {
    if (valObj.storeName) {
      const isChecked = !valObj.isChecked;
      const legalCompanyName = isChecked
        ? valObj.storeName
        : valObj.legalCompanyName;
      setValObj({ ...valObj, isChecked, legalCompanyName });
    }
  };

  const handleStoreNmChange = (e) => {
  /*  setValObj({
      ...valObj,
      storeName: e.target.value,
      legalCompanyName: valObj.isChecked
        ? e.target.value
        : valObj.legalCompanyName,
    }); */
    setisShowStoreNameValid(false);
    setisShowLegalNameValid(false);
    setisShowStoreNameApiValid("");
    setisShowLegalNameApiValid("");
    let r = getCurrentURIParams();
    if (r["input"] !== "account_settings" && !IsJDCash) {
      setValObj({
        ...valObj,
        storeName: e.target.value,
        legalCompanyName: valObj.isChecked
          ? e.target.value
          : valObj.legalCompanyName,
        master_parentid:""
      });
    // set current master id as empty when store name changes
    setmasterParentId("");
    setoutToggle(false);
    getbussinessNames(e.target.value, storeCity)
      .then((res) => {
        // console.log("res--------------------", res);
        const businessNames =
          res.data && res.data.results ? res.data.results : [];
        setbusinessNames(businessNames);
      })
      .catch((err) => {
        setbusinessNames([]);
      });
    }else{
      setValObj({
        ...valObj,
        storeName: e.target.value,
        legalCompanyName: valObj.isChecked
          ? e.target.value
          : valObj.legalCompanyName
      });
    }
  };

  const handleLegalCmpChange = (e) => {
    setValObj({
      ...valObj,
      legalCompanyName: e.target.value,
      isChecked: false,
    });
    // setisShowStoreNameApiValid(false);
    setisShowLegalNameValid(false);
    setisShowLegalNameApiValid("");
  };

  // get categories
  // const getCategories = (e) => {
  //   const value = e.target.value;
  //   console.log("category", value);
  //   if (value) {
  //     setHideCategory(true);
  //     categoryAutoSuggest(value).then((resp) => {
  //       const data = resp.data.results.data;
  //       setCategory(data);
  //       setValObj({ ...valObj, businessCat: value });
  //     });
  //   } else {
  //     setValObj({ ...valObj, businessCat: "", nid: "" });
  //   }
  // };

  const selectCategory = (category) => {
    if (category) {
      console.log("category", category);
      var regex = /(<([^>]+)>)/gi;
      const sanitizeNature = category.v.replace(regex, "");
      setValObj({ ...valObj, businessCat: sanitizeNature, nid: category.ncid });
    }
    setisShowCategoryInValid(false);
    setHideCategory(false);
    dispatch(showMainHeader());
  };

  const selectNature = (nature) => {
    setisShowNatureInValid(false);
    console.log("nature", nature);
    setHideNature(false);
    setValObj({
      ...valObj,
      businessNature: nature.val,
      businessNatureId: nature.key,
    });
  };

  const showNaturDrpDn = () => {
    const val = !isHideNature;
    setHideNature(val);
  };

  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);
  useEffect(()=>{
    //To check if all mandatory fields are filled and enable submit button
    let dis = false;
    MandatoryFieldsToCheck.forEach((r)=>{
      if(!valObj[r] || valObj[r]==""){
        dis = true;
      }
    })
    setDisabledSubmitButton(dis);
  }, [valObj]);
  let paramsFrom = getCurrentURIParams();

  const handleOutletToggle = (e) => {
    console.log("res==========", masterParentId);
    const val = !outToggle;
    setoutToggle(val);
    if (val) {
      const data = {
        businessCat: "",
        nid: "",
        businessNature: "",
        businessNatureId: "",
        legalCompanyName: "",
        master_parentid: "",
        masterStoreName: "",
        isChecked: false,
      };
      setValObj({
        ...valObj,
        ...data,
      });
      dispatch(isOutletContract(0));
      dispatch(setIsNotPaidContract());
    }
    else {
      companyDetails(masterParentId).then((res) => {
        const {
          companyname,
          legal_companyname,
          primary_cat,
          primary_catname,
          buz_nature       
        } = res.data[0] || {};
        setParentLegalCompName(legal_companyname);
        dispatch(isOutletContract(1));
        let type = "";
        if(buz_nature){
          type = natures.filter((row)=>row.val==buz_nature)[0]['key'];
        }
        const data = {
          storeName: companyname?companyname:valObj.storeName,
          masterStoreName: companyname?companyname:valObj.storeName,
          businessCat: isPaidContract?valObj.businessCat:primary_catname,
          nid: isPaidContract?valObj.nid:primary_cat,
          businessNature: buz_nature,
          businessNatureId: type,
          legalCompanyName: legal_companyname,
          isChecked: false,
        };
        setValObj({
          ...valObj,
          ...data,
          master_parentid: masterParentId,
        });
        // setoutToggle(false);
      });
    }
  };

  const handleSetBusinessList = (e) => {
    setmasterParentId(e.id);
    // setbusinessNameSelected(e.value);
    setValObj({
      ...valObj,
      storeName: e.value,
    });
    setopenPopUp(true);
    //empty business names
    setbusinessNames([]);
  };

  const [parentLegalCompName, setParentLegalCompName] = useState();
  const createOutlet = (val) => {
    // console.log("val-----------", val);
    if (val) {
      companyDetails(masterParentId).then((res) => {
        const {
          companyname,
          legal_companyname,
          primary_cat,
          primary_catname,
          buz_nature,
          um_parentid       
        } = res.data[0] || {};
        let type="";
        if(buz_nature)
         type = natures.filter((row)=>row.val==buz_nature)[0] && natures.filter((row)=>row.val==buz_nature)[0]['key'];
        setParentLegalCompName(legal_companyname);
        dispatch(isOutletContract(1));
        const data = {
          storeName: companyname?companyname:valObj.storeName,
          masterStoreName: companyname?companyname:valObj.storeName,
          businessCat: isPaidContract?valObj.businessCat:primary_catname,
          nid: isPaidContract?valObj.nid:primary_cat,
          businessNature: buz_nature,
          businessNatureId: type,
          legalCompanyName: legal_companyname,
          isChecked: false,
        };
        setValObj({
          ...valObj,
          ...data,
          master_parentid: masterParentId,
          um_parentid: um_parentid
        });
        setoutToggle(false);
      });
    } else {
      const data = {
        businessCat: "",
        nid: "",
        businessNature: "",
        businessNatureId: "",
        legalCompanyName: "",
        isChecked: false,
        master_parentid: "",
        um_parentid: "",
        masterStoreName: ""
      };
      setValObj({
        ...valObj,
        ...data,
        master_parentid: "",
        um_parentid: ""
      });
      console.log(valObj)
      setmasterParentId("");
      setoutToggle(true);
    }
    setopenPopUp(false);
  };

	const [yearOfEstErr, setYearOfEstErr] = useState(false);
  const handleYearOfEstChange = (e) => {
	let v = e.target.value;
	let y = parseInt(v);
	let curYear = new Date().getFullYear();
	if(v.length==4){
		if(y>1799 && y<=curYear){
 		setValObj({
     		 ...valObj,
     		 yearOfEstablishment: e.target.value
    		});
        setYearOfEstErr(false);
		}else{
		setYearOfEstErr(true);
		}
	}else if(v.length<4){
    setYearOfEstErr(true);
		setValObj({
     		 ...valObj,
     		 yearOfEstablishment: e.target.value
    		});	
	}
  };
  
  const categoryBack = (fl) => {
    setHideCategory(fl);
    dispatch(showMainHeader());
  }

  const handleChange = (e) => {
    setValObj({
      ...valObj,
      [e.target.name]: e.target.value
    });	
    if(e.target.name=="businessEmail"){
      setInvalidEmail(false);
    } 
  }

  return (
    <>
      {isHideCategory && !isPaidContract && (
        <CategorySearch
          submit={selectCategory}
          back={categoryBack}
        ></CategorySearch>
      )}
      <section className="pl-20 pr-20 pb-70">
        <span className="addphoto_prnt mt-30">
          <input
            type="file"
            name=""
            accept="image/png, image/jpeg"
            onChange={selectImage}
          />
          <span className="camera_icon"></span>
          {!valObj.storeLogoPath && (
            <span className="dblock font12 color111 mt-5 text_center">
              Add a store logo{" "}
              <span className="dblock font12 color777">
                (Max 6 MB of jpg, png image is allowed)
              </span>
            </span>
          )}
          {valObj.storeLogoPath && (
            <span className="sob_closeicn" onClick={removeImage}></span>
          )}
          {valObj.storeLogoPath && (
            <img src={valObj.storeLogoPath} alt="preview image" />
          )}
        </span>
        {!valObj.storeLogoPath &&
        <div className="font12 color007 mt-12">
        Your brand logo that is used for marketing and will be displayed to customers for your store
        </div>
        }

        <div className="seller_ob_form mt-20">
          <div className="group common_list">
            <input
              className={
                "inputMaterial font16 fw600" +
                (isShowStoreNameValid ? " redborder" : "")
              }
              type="text"
              required
              name="storeName"
              autoComplete="off"
              value={valObj.storeName}
              onChange={(e) => handleStoreNmChange(e)}
              id={"storeName_name"}
              onClick={()=>{
                const idFocus = document.getElementById("storeName_name")
                setTimeout(() => {
                  idFocus && idFocus.scrollIntoView({behavior: "smooth", block: "center"});
                }, 400);
              }}
            />
            <span className={"highlight"}></span>
            <span className="bar"></span>
            <label className="label-wrap font16"> Store Name *</label>
            <span
              className={Boolean(businessNames.length)?"gen_close_white":""}
              onClick={()=>setbusinessNames([])}
            ></span>
            {
              !Boolean(businessNames.length) &&
            <span
              className="sd_info_icon"
              onClick={() => {
                setInfo({ ...info, storeName: !info.storeName });
                setTimeout(() => {
                  setInfo({ ...info, storeName: false });
                }, 4000);
              }}
            >
            {info.storeName && (
              <div className="info_content color111 font11">This is your brand name through which your users will recognize you. Make sure it should be unique and different from store names used by other sellers. </div>
            )}
            </span>          
            }
            {isShowStoreNameApiValid && valObj.storeName && (
              <span class="errorcls font12 pt-5">
                {isShowStoreNameApiValid}
              </span>
            )}
            {
              masterParentId && paramsFrom["input"] == "account_settings" &&
              <div class="font12 color777 mt-5">
               Tagged to Parent Store: {valObj.masterStoreName}
              </div>
          }
            {Boolean(businessNames.length) && (
              <BusinessList
                data={businessNames}
                setbusinessList={handleSetBusinessList}
              />
            )}
          </div>
          {masterParentId && paramsFrom["input"] !== "account_settings" && (
            <div className="group mb-20">
              <div class="font12 color777 mt-30">
                Store is already created. Do you want to add outlet?
              </div>
              <label class="odrfdswtch mt-10">
                <input
                  type="checkbox"
                  id="togBtn"
                  checked={outToggle}
                  // оnChange={(e) => handleOutletToggle(e)}
                  onClick={(e) => handleOutletToggle(e)}
                />
                <div class="odrfdswtch round">
                  <span>Yes</span>
                  <span>No</span>
                </div>
              </label>
            </div>
          )}
          <div className="group mb-20">
            <input
              className={
                "inputMaterial font16 fw600" +
                (isShowLegalNameValid
                  ? " redborder"
                  : "") +
                  (
                    (isOutletContractFlag=="1" && parentLegalCompName)?" graytext":""
                  )
              }
              type="text"
              required
              name="legalCompanyName"
              autoComplete="off"
              disabled={(isOutletContractFlag=="1" && parentLegalCompName)?true:false}
              value={valObj.legalCompanyName}
              onChange={(e) => handleLegalCmpChange(e)}
              id={"legal_cmp_name"}
              onClick={()=>{
                const idFocus = document.getElementById("legal_cmp_name")
                setTimeout(() => {
                  idFocus && idFocus.scrollIntoView({behavior: "smooth", block: "center"});
                }, 400);
              }}
            />
            <span className={"highlight"}></span>
            <span className="bar"></span>
            <label className="label-wrap font16"> Legal Company Name *</label>
            <span
              className="sd_info_icon"
              onClick={() => {
                setInfo({ ...info, legalCompanyName: !info.legalCompanyName });
                setTimeout(() => {
                  setInfo({ ...info, legalCompanyName: false });
                }, 4000);
                setInfo({ ...info, legalCompanyName: !info.legalCompanyName });
              }}
            >
               {info.legalCompanyName && (
              <div className="info_content color111 font11">
                Registered name of the firm as per your GST certificate.
              </div>
            )}
            </span>
            <div className="font12 color007 mt-5">Should match with GST details</div>
            {isShowLegalNameApiValid && valObj.legalCompanyName && (
              <span class="errorcls font12 pt-5">
                {isShowLegalNameApiValid}
              </span>
            )}
          </div>
          {(isOutletContractFlag=="0" || !parentLegalCompName) && 
<div className="include_tax">
<label className="font12 color111">
  <input
    type="checkbox"
    name=""
    checked={valObj.isChecked}
    onChange={(e) => setCheck()}
  />
  <span className="type_checkbox mr-10"></span>
  Same as store name
</label>
</div>
          }
          {
            IsJDCash &&
            <div class="group common_list mt-25">
            <input
              class="inputMaterial font16 fw600 "
              type="text"
              required
              name="businessEmail"
              value={valObj.businessEmail}
              onChange={(e) => handleChange(e)}
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="label-wrap font16"> Business Email *</label>
            {invalidEmail && (
              <span class="errorcls font12 pt-5">
                Please enter a valid email Id
              </span>
            )}
          </div>
          }
         {
            !IsJDCash &&   
          <div className="group common_list mt-25">
            <input
              disabled={isPaidContract}
              className={
                "inputMaterial font16 fw600" +
                (isShowCategoryInValid ? " redborder" : "")+
                (isPaidContract? " graytext":"")
              }
              type="text"
              required
              name="businessCat"
              autoComplete="off"
              value={valObj.businessCat}
              onClick={(e) => {
                setHideCategory(true);
                dispatch(hideMainHeader());
                window.history.pushState(null, null, window.location.pathname+window.location.search);
              }}
              // onChange={(e) => getCategories(e)}
              // onBlur={() => {
              //   setValObj({ ...valObj, businessCat: "", nid: "" });
              //   setTimeout(() => setHideCategory(false), 200);
              // }}
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label className="label-wrap font16">
              {" "}
              Primary Business Category *{" "}
            </label>
            <span
              className="sd_info_icon"
              onClick={() => {
                setInfo({ ...info, businessCat: !info.businessCat });
                setTimeout(() => {
                  setInfo({ ...info, businessCat: false });
                }, 4000);
                setInfo({ ...info, businessCat: !info.businessCat });
              }}
            >
            {info.businessCat && (
              <div className="info_content color111 font11">
                  Please select one primary category in which your business is conducted. You can add more categories later with catalouge.              
              </div>
            )}
            </span>
          </div>
          }
          {
            !IsJDCash && 
            <div class="group common_list businessnature_list">
            {/* <input
              className={
                "inputMaterial font16 fw600" +
                (isShowNatureInValid ? " redborder" : "")
              }
              type="text"
              required
              name="businessNature"
              autoComplete="off"
              id="business_Nature"
              value={valObj.businessNature}
              //   onChange={(e) => {
              //     // dummmy warning becaue dont have onchange
              //     return;
              //   }}
              // onChange={(e) => showNaturDrpDn(e)}
              onClick={(e) => showNaturDrpDn(e)}
              onBlur={() => {
                setTimeout(() => setHideNature(false), 200);
              }}
            /> */}
            <span 
            className={`inputMaterial font16 fw600 ${valObj.businessNature?"labelTop":""}`}
             onClick={(e) => showNaturDrpDn(e)}>{valObj.businessNature}</span>
            <span className="highlight"></span>
            <span className="bar"></span>
            <label className="label-wrap font16"> Nature of Business *</label>
            <span
              className="of_downarrow uparrow"
              onClick={(e) => showNaturDrpDn(e)}
            ></span>
            {isHideNature && (
              <NatureList data={natures} setNature={selectNature} />
            )}
          </div>
          }
        </div>
      </section>

      {openPopUp && (
        <ConfirmPopUp data={valObj.storeName} submit={createOutlet} />
      )}

      <div
        className={`ftr_btn animated fast fadeInUp ${
          disabledSubmitButton ? "disabled" : ""
        }`}
      >
        <button
          className={`font14 fw600 colorfff`}
          disabled={disabledSubmitButton}
          onClick={() => {
            if (paramsFrom["input"] == "account_settings") {
              if (
                valObj.legalCompanyName !== businessDetails.legalCompanyName
              ) {
                handleSave("account_settings");
              } else {
                handleSave();
              }
            } else {
              handleSave();
            }
          }}
        >
          {!showButtonLoader && (
            <>
              Save
              {paramsFrom["input"] == "account_settings" ? (
                ""
              ) : (
                <> &amp; Next</>
              )}
            </>
          )}
          {showButtonLoader && (
            <span class="dotloader">
              <span />
              <span />
              <span />
              <span />
              <span />
            </span>
          )}
        </button>
      </div>
        {/* Update bank detail popup */}
      {
        showLegalCompanyNamePopUp &&  
        <div className="updatebank_dtl_wpr">
        <div className="updatebank_dtl_outer p-20 p-30">
            <div className="font13 color111 fw600 lineHeight22">
            Changing Legal name will lead to re-verification of GST & Bank account. 
            </div>
            <div className="mt-30 proceed_btn_wpr">
              <button className="font16 graybtn" onClick={()=>setShowLegalCompanyNamePopUp(false)}>Cancel</button> <button className="font16" onClick={()=>handleProceed()}>Proceed</button>
            </div>
        </div>
      </div>
      } 
    </>
  );
};

export default BusinessDet;
