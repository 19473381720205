import './commoncalc.css';
import './pricecalright.css';
import { get } from "lodash"
const removeDotZeros = (num) => {
    return num % 1 === 0 ? parseInt(num) : parseFloat(num).toFixed(2);
}

function PriceCalRight({selCatComm, sp: spInp}){
	let { sp: spVal, 
		  final_payout:finalPayoutVal,
		  net_taxable_value: grossTaxableVal,
		  total_commission_tax: totalCommissionTax } = selCatComm;
	const addSum = (obj , path1, path2 ) => {
		const val = ((parseFloat(get(obj, path1))? parseFloat(get(obj, path1)): 0) + (parseFloat(get(obj, path2))? parseFloat(get(obj, path2)): 0));
		return removeDotZeros(val);
	}
	
	let gst18Val = addSum(selCatComm,"order.tax", "order.tds")
	let commisionVal = addSum(selCatComm, "mktplace_fees.JDcommission.commission", "mktplace_fees.JDcommission.tax")
	let logisticsVal = addSum(selCatComm, "mktplace_fees.logistic.commission","mktplace_fees.logistic.tax")
	let fixedVal = addSum(selCatComm, "mktplace_fees.fixed.commission", "mktplace_fees.fixed.tax")
	let transactionVal = addSum(selCatComm, "mktplace_fees.pg.commission", "mktplace_fees.pg.tax")
	let tax1Per = get(selCatComm, "order.tcs") ?? 0;
	let tdsPer = get(selCatComm, "order.tds") ?? 0;
	let gstRate = addSum(selCatComm, "order.tax_rate", "order.tds_rate")
	tdsPer = removeDotZeros(tdsPer);
	tax1Per = removeDotZeros(tax1Per);
	spVal = spVal ? spVal: 0;
	spVal = removeDotZeros(spVal);
	finalPayoutVal =  finalPayoutVal ? finalPayoutVal : 0;
	finalPayoutVal = removeDotZeros(finalPayoutVal);
	grossTaxableVal = grossTaxableVal ? grossTaxableVal : 0;
	grossTaxableVal = removeDotZeros(grossTaxableVal);
	totalCommissionTax = totalCommissionTax ? totalCommissionTax : 0;
	totalCommissionTax = removeDotZeros(totalCommissionTax)


	let commisionTxt = get(selCatComm, "mktplace_fees.JDcommission.text") ?? "";
	let logisticsTxt = get(selCatComm,"mktplace_fees.logistic.text") ?? "";
	let fixedTxt = get(selCatComm, "mktplace_fees.fixed.text") ?? "";
	let transactionTxt = get(selCatComm,"mktplace_fees.pg.text") ?? "";
	
	if(!spInp){
		spVal = finalPayoutVal = grossTaxableVal = totalCommissionTax = gst18Val = commisionVal = logisticsVal = fixedVal = transactionVal =  tax1Per = tdsPer = 0;
		commisionTxt = logisticsTxt = fixedTxt = transactionTxt = "";
	}
	
    return(
        <div className="sprightwrapper">
					<div className="sellingprice_wrapper pt25 pr20 pb20 pl20">
						<div className="dflex pb20 mb25 borderbottom">
							<div className="font15 color111">Gross Taxable Price</div>
							<div className="font20 fw700 color111">₹ {grossTaxableVal}</div>
						</div>
						<div className="dflex mb25">
				    <div className="font15 color111">GST <span className="font12 color777 ml5">(@ {gstRate}%)</span></div>
							<div className="font20 fw700 color11">  ₹ {gst18Val}</div>
						</div>
						<div className="dflex mb25">
							<div className="font15 color111">Selling Price <span className="font12 color777 ml5">(Incl of GST)</span></div>
							<div className="font20 fw700 color11">₹ {spVal}</div>
						</div>
						{commisionVal || fixedVal || logisticsVal || transactionVal || tax1Per ? <div className="borderbottom mb25">
							{tax1Per ? <div className="dflex pb25">
								<div className="font15 color111">TCS @ 1%</div>
								<div className="font18 fw400 colorD61">- ₹ {tax1Per}</div>
							</div> : null}
							{commisionVal ? <div className="dflex pb25">
								<div className="dflex">
									<div className="font15 color111">Commision Fee</div>
									{
									commisionTxt ? 
										<div className="font12 color777 ml5">({commisionTxt})</div>: 
										null
									}
								</div>	
								<div className="font18 fw400 colorD61">- ₹ {commisionVal}</div>
							</div> : null}
							{fixedVal ? <div className="dflex pb25">
								<div className="dflex">
									<div className="font15 color111">Fixed Fee</div>
									{
										fixedTxt ? 
											<div className="font12 color777 ml5">({fixedTxt})</div>: 
										null
									}
								</div>
								<div className="font18 fw400 colorD61">- ₹ {fixedVal}</div>
							</div> : null}
							{logisticsVal ?
                            <div className="dflex pb25">
								<div className="dflex">
									<div className="font15 color111">Shipping Fee</div>
									{
										logisticsTxt ? 
										<div className="font12 color777 ml5">({logisticsTxt})</div>: 
										null
									}
								</div>	
								<div className="font18 fw400 colorD61">- ₹ {logisticsVal}</div>
							</div> : null}
							{tdsPer ? <div className="dflex pb25">
								<div className="font15 color111">TDS @ 1%</div>
								<div className="font18 fw400 colorD61">- ₹ {tdsPer}</div>
							</div> : null}
						</div> : null}
						<div className="dflex mb5">
							<div className="font15 fw500 color111">How much you make *</div>
							<div className="font20 fw700 color111">₹ {finalPayoutVal}</div>
						</div>						
					</div>
					<p className="font12 color111 pt10">* This may vary by category, product price, applicable taxes etc</p>
				</div>
    )
}

export default PriceCalRight;
