import React, {useState} from "react";
import {RUPEEVAL, ALLTIME} from "../../../config/consts";

const OffersListDisp = ({offerDet, editOffer, offerInd, deleteOffer}) => {
    const [showEditBox, setShowEditBox] = useState(false);

    let dateFormt;
    if(offerDet.dealPeriod != ALLTIME){
        let da = new Date(offerDet.endDate);
        dateFormt = da.getDate()+"-"+(parseInt(da.getMonth())+1)+"-"+da.getFullYear();
    }
    return (
        <li>
            <div class="deliveryboy_list_left">
                <div class="font14 fw700 color111">{offerDet.dealName}</div>
                <div class="font13 color111">{offerDet.description}</div>
                {/* <div class="font12 color777">Valid till : {offerDet.endDate}</div> */}
                <div class="font12 color777">DEAL: {offerDet.disOnDeal == RUPEEVAL? `₹${offerDet.rupeeVal} OFF`: `${offerDet.percentageVal}% OFF` }</div>
                <div class="font12 color777">{offerDet.dealPeriod != ALLTIME? `VALID TILL: ${dateFormt}`: `` }</div>
            </div>
            <div>
                <span class="ob_threedots" onClick={() => setShowEditBox(!showEditBox)}>
                    {
                        showEditBox &&
                        <div class="editbox">
                            <ul class="font14 fw600 color1a1">
                                <li onClick={()=>editOffer(offerInd)}>Edit</li>
                                <li onClick={()=>deleteOffer(offerInd)}>Delete</li>
                            </ul>
                        </div>
                    }
                </span>
            </div>
        </li>
    )
}

export default OffersListDisp;
