import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  savePickupDetAction,
  showToastAction,
  hideToastAction,
  loadPrefilledBusinessDataAction,
  setIsLogisticDelivery
} from "../../actions/actions";
import MapComponent from "../common/MapComponent";
import {
  UpdateDocId,
  SaveBusinessDetails,
  getLocationDetails,
  getLocationDetailsFromArea,
  getURIParams,
  getCurrentURIParams,
  getCitySuggestions,
  getBuildingSuggestions,
  generateUIID,
  getBuildingAddress,
  LoadPrefilledData,
  checkValidCity,
  distCheck,
  getCitySynList
} from "../../services/common";
import {openViewExit, openViewExitDeviceBack} from "../common/openViewExit";

// shows category list
function StoreAreaList(props) {
  const data = props.data;
  return (
    <ul>
      {data.map((item, index) => (
        <li key={index} onClick={(e) => {
          console.log("Here");
          props.setArea(item);
        }}>
          {item.areaname_display}, {item.city} - {item.pincode}
        </li>
      ))}
    </ul>
  );
}
function StoreCityList(props) {
  const data = props.data;
  console.log(data);
  return (
    <ul>
      {data && data.map((item, index) => (
        <li key={index} onClick={(e) => props.setCity(item)}>
          {item.ct_name}
        </li>
      ))}
    </ul>
  );
}

function BuildingAreaList(props) {
  const data = props.data;
  console.log(data);
  return (
    <ul>
      {data && data.map((item, index) => (
        <li key={index} onClick={(e) => props.setBuilding(item)}>
          {item.text}
        </li>
      ))}
    </ul>
  );
} 

const PickupDet = () => {
  const inputEl = useRef(null);
  const  [showButtonLoader, setShowButtonLoader] = useState(false);
  const [info, setInfo] = useState({
    legalCompanyName: false,
  });

  const pickupDetails = useSelector((state) => {
    return state.pickupDetails;
  });
  const locationDetails = useSelector((state) => {
    return state.locationDetails;
  });

  const mapResetFlag = useRef(false);
  
  if(typeof window != "undefined"){
    window.appHandler = appAndroidHandler.bind(this);
}

function appAndroidHandler(logjson){
  let r = getCurrentURIParams();
  if(r.source==51 || r.source==53){
  if(logjson && logjson != "undefined" && logjson != ""){
      var logresponse = JSON.parse(logjson);
    if(logresponse['eventType'] && logresponse['eventType'] == "deviceback"){
      openViewExitDeviceBack(r);
    }
    if(logresponse['eventType'] && logresponse['eventType'] == "location"){
      if(!inputEl.current || !inputEl.current.value || mapResetFlag.current){
        if(mapResetFlag.current){
          mapResetFlag.current=false;
        }
        handleSetLatLong({
          lat: logresponse.lat,
          lng: logresponse.lon
        }, true) 
        }
    }
    }
}
}


  const [valObj, setValObj] = useState(pickupDetails);

  useEffect(() => {
    if(!pickupDetails.latitude || !pickupDetails.buildingName){

    }else{
      setCityTempValue(pickupDetails.city);
      setAreaTempValue(pickupDetails.storeArea);
      setBuildingTempValue(pickupDetails.buildingName);
      if(!saveFlag)
      setSameToggle(pickupDetails.sameToggle);
      setValObj(pickupDetails);
    }
  }, [pickupDetails]);

  const dispatch = useDispatch();
  const [isValidPincode, setisValidPincode] = useState(false);
  const [isBSLInValid, setisBSLInValid] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const compData = useSelector((state) => {
    return state;
  });
  // save & move ahead
  const [saveFlag, setSaveFlag] = useState(false);
  useEffect(() => {
    if (saveFlag == true) {
      setShowButtonLoader(true);
      setShowKMAlert({show: false, txt: ""});
      SaveBusinessDetails(compData, location.pathname, "pickupDet").then((res) => {
        let currentUriParams = getCurrentURIParams();
        const redirect  = () => {
          if(currentUriParams["source"]==51 || currentUriParams["source"]==53){
              openViewExit(currentUriParams["source"]);
            return false;
          }else{
          window.location.href = decodeURIComponent(currentUriParams["backUrl"])?decodeURIComponent(currentUriParams["backUrl"]):"https://business.justdial.com/seller-dashboard/accountsetting";
          }
        }
        if(currentUriParams["input"]=="account_settings"){
          if(currentUriParams["source"] != 14){
            UpdateDocId(currentUriParams.docid).then((res) => {
              redirect()
            }).catch((e)=>{
              console.log("Updating error ",e)
              setShowButtonLoader(false);
            })
          } else {
            redirect()
          }
       }else{
          let uriParams = getURIParams();
          if(currentUriParams.docid && currentUriParams.docid!="" && currentUriParams.source!="14"){
            UpdateDocId(currentUriParams.docid).then((res) => {
              if(isDeliveryFlag=="2"){
                  navigate(
                    `/businessDetails/deliveryDet?${uriParams}`
                  );
              }else{
                navigate(
                  `/businessDetails/storeTimeDet?${uriParams}`
                );
              }
              setShowButtonLoader(false);
              return false;
            }).catch((e)=>{
              console.log("Updating error ",e)
              setShowButtonLoader(false);
            })
          }else{
            if(isDeliveryFlag=="2"){
              if(currentUriParams.source=="14"){
                navigate(
                  `/businessDetails/deliveryPriceDet?${uriParams}`
                );
              }else{
                navigate(
                  `/businessDetails/deliveryDet?${uriParams}`
                );
              }
            }else{
              navigate(
                `/businessDetails/storeTimeDet?${uriParams}`
              );
            }
          }
        }
      });
    }
  }, [saveFlag]);

  const [isEmptyFieldErr, setisEmptyFieldErr] = useState({
    city: false,
    storeArea: false,
    buildingName: false
  });
  useEffect(()=>{
    if(isEmptyFieldErr.buildingName){
      let errSpan = document.getElementById("buildingErrorSpan");
      errSpan && errSpan.scrollIntoView({behavior: "smooth", block: "center"});
    }
    if(isEmptyFieldErr.storeArea){
      let errSpan = document.getElementById("storeAreaErrorSpan");
      errSpan && errSpan.scrollIntoView({behavior: "smooth", block: "center"});
    }
    if(isEmptyFieldErr.city){
      let errSpan = document.getElementById("cityErrorSpan");
      errSpan && errSpan.scrollIntoView({behavior: "smooth", block: "center"});
    }
  },[isBSLInValid, isEmptyFieldErr]);
  const handleSave = () => {
    let valObjClone = Object.entries({...valObj})
    .reduce((acc, elem) =>{
     acc[elem[0]] = String(elem[1]).trim()
     return acc
    }, {});

    if(!Boolean(valObjClone["city"])){
      setisEmptyFieldErr({
        city: true
      });
      return false;
    }
    if(!Boolean(valObjClone["buildingName"])){
      setisEmptyFieldErr({
        buildingName: true
      });
      return false;
    }
    const checkFlag = [
      "latitude",
      "longitude",
      "city",
      "state",
    ].every((item) => Boolean(valObjClone[item]));
    if (checkFlag) {
      setValObj(valObjClone);
      dispatch(savePickupDetAction(valObjClone));
      setSaveFlag(true);
    } else {
      dispatch(showToastAction("Please fill all mandatory fields."));
      setTimeout(function () {
        dispatch(hideToastAction());
      }, 3000);
    }
  };

  const handleChange = (e) => {
    setSameToggle(true);
    setValObj({ ...valObj, [e.target.name]: e.target.value });
    // true for No its ulta imean opposite
    setSameToggle(true);
    if (e.target.name === "pincode") {
      setisValidPincode(false);
    }
    setisBSLInValid(false)
  };
  const [defaultLL, setDefaultLL] = useState({
    latitude:"",
    longitude: ""
  });
  const [apiCallCityVal, setApiCallCityVal] = useState(valObj.city);
  const [showCityDropdown, setShowCityDropdown] = useState(false);

  const handleChangeCity = (e) => {
    setSameToggle(true);
    setCityChanging(true);
    setisEmptyFieldErr({
      city: false
    });
    let arVal = e.target.value;
    setCityTempValue(arVal);
    let currentTimer = setTimeout(() => {
      ApiCallForCity(arVal);
    }, 1000);
    if (apiCallCityVal) {
      clearTimeout(apiCallCityVal);
    }
    setApiCallCityVal(currentTimer);
    setisValidPincode(false);
    setDefaultLL({
      latitude: valObj.latitude || defaultLL.latitude,
      longitude: valObj.longitude || defaultLL.longitude
    })
  }
  const [changeAreaVal, setChangeAreaVal] = useState(valObj.storeArea);
  const [showAreaDropdown, setShowAreaDropdown] = useState(false);
  const [showBuildingDropdown, setShowBuildingDropdown] = useState(false);
  const [areaList, setAreaList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [buildingList, setBuildingList] = useState([]);
  const [cityObj, setCityObj] = useState({});

  const ApiCallForLocation = (val) => {
    getLocationDetailsFromArea(val, valObj.pincode, valObj.city, cityObj.data_city)
      .then((res) => {
        if(res.data.result){
          if(res.data.result.areaname && res.data.result.areaname.length>0){
            setShowAreaDropdown(true);
            setAreaList(res.data.result.areaname);
          }
        }else{
          setShowAreaDropdown(false);
          setAreaList([]);
        }
      })
      .catch((e) => {
        console.log("Check area error");
      });
  };
  const ApiCallForCity = (val) => {
    getCitySuggestions(val.trim())
    .then((res) => {
      if(res.data.result && res.data.result.length>0){
        setShowCityDropdown(true);
        setCityList(res.data.result);
      }
    })
    .catch((e) => {
      console.log("Check area error");
    });
  }
 
  const ApiCallForBuilding = (val) => {
    getBuildingSuggestions(val.trim(), generateUIID())
    .then((res) => {
      if(res.data && res.data.data && res.data.data.length>0){
        setShowBuildingDropdown(true);
        setBuildingList(res.data.data);
      }else{
        setBuildingChanging(false);    
        setShowBuildingDropdown(false);
      }
    })
    .catch((e) => {
      console.log("Check area error");
    });
  }
  const [apiCallVal, setApiCallVal] = useState();
  const [areaChanging, setAreaChanging] = useState(false);
  const [cityChanging, setCityChanging] = useState(false);
  const [buildingChanging, setBuildingChanging] = useState(false);
  const [cityTempValue, setCityTempValue] = useState();
  const [areaTempValue, setAreaTempValue] = useState();
  const [buildingTempValue, setBuildingTempValue] = useState();
  useEffect(()=>{
    console.log("Changing city");
    if(cityTempValue && cityTempValue!==valObj.city && !showCityDropdown){
      setisEmptyFieldErr({...isEmptyFieldErr, city: true});
    }else{
      setisEmptyFieldErr({...isEmptyFieldErr, city: false});
    }
  },[cityTempValue]);
  const {
    loadedPreFilledData,
    isDeliveryFlag
  } = useSelector((state) => state.locationReducer);
  useEffect(()=>{
    if (!loadedPreFilledData && (compData && !compData.loadDocData || !compData.loadDocData.docid)) {
      LoadPrefilledData().then((res)=>{
        if(res.data.results && res.data.results.data && res.data.results.data[0]){
          dispatch(
            loadPrefilledBusinessDataAction(res.data.results.data[0])
          );
        }else{
          dispatch(showToastAction("Error in processing the document. Please try again later."));
          setTimeout(function () {
            dispatch(hideToastAction());
          }, 3000)
        }
      })
  }
  },[0])
  useEffect(()=>{
    console.log("Changing building");
    if(buildingTempValue && buildingTempValue!==valObj.buildingName && !showBuildingDropdown){
      setisEmptyFieldErr({...isEmptyFieldErr, buildingName: true});
    }else{
      setisEmptyFieldErr({...isEmptyFieldErr, buildingName: false});
    }
  },[buildingTempValue, showBuildingDropdown]);

  useEffect(()=>{
    console.log("Changing area");
    if(areaTempValue && areaTempValue!==valObj.storeArea && !showAreaDropdown){
      setisEmptyFieldErr({...isEmptyFieldErr, storeArea: true});
    }else{
      setisEmptyFieldErr({...isEmptyFieldErr, storeArea: false});
    }
  },[areaTempValue, showAreaDropdown]);

  const handleChangeBuilding = (e) => {
    let arVal = e.target.value;
    setSameToggle(true);
    setBuildingTempValue(arVal);
    setBuildingChanging(true);    
    if(arVal){
      let currentTimer = setTimeout(() => {
        ApiCallForBuilding(arVal);
      }, 1000);
      if (apiCallVal) {
        clearTimeout(apiCallVal);
      }
      setApiCallVal(currentTimer);
      setisValidPincode(false);
    }else{
      setBuildingList([]);
    }
  };

  const handleChangeArea = (e) => {
    setSameToggle(true);
    setAreaChanging(true);
    let arVal = e.target.value;
    setisEmptyFieldErr({
      storeArea: false
    });
    setAreaTempValue(arVal);
    let currentTimer = setTimeout(() => {
      ApiCallForLocation(arVal);
    }, 1000);
    if (apiCallVal) {
      clearTimeout(apiCallVal);
    }
    setApiCallVal(currentTimer);
    // true for No its ulta imean opposite
    setSameToggle(true);
    setisValidPincode(false);
  };
  const handleBlur = (e) => {
    setValObj({ ...valObj, [e.target.name]: "" });
    setTimeout(() => {
      setShowAreaDropdown(false);
      setShowCityDropdown(false);
    }, 400);
  };
  const handleCloseDropdown = (name) => {
    if(name=="city" && cityTempValue!==valObj.city){
      setValObj({ ...valObj, [name]: "" });
      setCityTempValue("");
    }
    if(name=="storeArea" && cityTempValue!==valObj.storeArea){
      setValObj({ ...valObj, [name]: "" });
      setAreaTempValue("");
    }
    if(name=="buildingName" && buildingTempValue!==valObj.buildingName){
      setValObj({ ...valObj, [name]: "" });
      setBuildingTempValue("");
    }
      setShowBuildingDropdown(false);
      setShowAreaDropdown(false);
      setShowCityDropdown(false);
  };
  const handleSetCity = (obj) => {
    setShowCityDropdown(false);
    setCityChanging(false);
    setCityObj(obj);
    setAreaTempValue("");
    setBuildingTempValue("");
    setValObj({
      ...valObj,
      latitude: "",
      longitude: "",
      buildingName: "",
      streetName: "",
      landmark: "",
      pincode: "",
      state: "",
      storeArea: "",
      city: obj.ct_name}); //Reset all values when city is changed 
    setCityTempValue(obj.ct_name);
  }
  const handleSetArea = (obj) => {
    setShowAreaDropdown(false);
    setAreaChanging(false);
    setAreaTempValue(obj.areaname_display);
    setValObj({
      ...valObj,
      storeArea: obj.areaname_display
    });
  };

  const [citySynList, setCitySynList] = useState([]);
  useEffect(()=>{
    getCitySynList().then(res=>setCitySynList(res.data));
  },[0])
  const setBuldingCheckCity = (obj, data, dCity) => {
    let sameCity = false;
    let synCity = citySynList[valObj.city];
    let synKey = locationDetails.city;
    if(synCity){
      if(dCity==synCity || dCity==synKey){
        sameCity=true;
      }
    }else{
      Object.keys(citySynList).forEach((k)=>{
        if(citySynList[k]==valObj.city){
          if(dCity==citySynList[k] || dCity==k){
            sameCity=true;
          }
        }
      })
    }
    if(locationDetails.city.toLowerCase()==dCity.toLowerCase()){
      sameCity=true;
    }
    if(!locationDetails.city){
      sameCity=true;
    }
    if(!paramsFrom.docid || paramsFrom.docid=="undefined"){
      sameCity=true;
     }

     if(sameCity)
     {
        setShowBuildingDropdown(false);
        setBuildingChanging(false);
        let buildingName = getFormatBuilding(data, true);
          setValObj({
            ...valObj,
            buildingName: buildingName,
            streetName: data.street,
            storeArea: data.area,
            latitude: data.lat,
            longitude: data.lng,
            pincode: data.pin,
            city: dCity,
            state: data.state,
            sameToggle: true
          });
          setAreaTempValue(data.area);
          setBuildingTempValue(buildingName);
          setCityTempValue(dCity);
        }else{
          setShowBuildingDropdown(false);
      setBuildingChanging(false);
          dispatch(showToastAction("City should be same as store city."));
          setTimeout(function () {
            dispatch(hideToastAction());
          }, 3000);
              setValObj({...valObj});  
        }
  }
  const handleSetBuilding = async (obj) => {
    getBuildingAddress(obj.place_id, generateUIID()).then((res)=>{
      let data = res.data && res.data.result;
      let dCity = data.city;
      if(!dCity){
        checkValidCity(valObj.city, data.pin).then((res)=>{
          dCity = res.data.result.city;
          setBuldingCheckCity(obj, data, dCity);
        });
      }else{
        setBuldingCheckCity(obj, data, dCity);
      }
    })
  };

  let latLong = { latitude: valObj.latitude, longitude: valObj.longitude };
  const getFormatBuilding = (data, bNameChange) => {
    let bdAdd = data.formatted_address;
    bdAdd = bdAdd && bdAdd.split(",");
    if(bdAdd.length>3){
      bdAdd = bdAdd.slice(0,-3);
    }else if(!bNameChange){
      bdAdd = bdAdd.slice(0,-2);
    }
    let buildingName = bdAdd.length>0?bdAdd.join(","):"";
    return buildingName;
  }

  const handleSetLatLong = (obj, initialLoad) => {
    console.log("Drag end+++++++++++++++++++>", obj);
    latLong = { latitude: obj.lat, longitude: obj.lng };
    getLocationDetails({ lat: obj.lat, long: obj.lng }).then((res) => {
      let data = res.data && res.data.result;
      if(locationDetails.city==data.city_syn || locationDetails.city==data.city){
        if (res.data) {
          let bName = getFormatBuilding(data);
          setValObj({
            ...valObj,
            storeArea: data.area,
            pincode: data.pin,
            city: data.city,
            state: data.state,
            latitude: obj.lat,
            longitude: obj.lng,
            streetName: data.street,
            buildingName: bName,
            landmark: "",
            sameToggle: true
          });
          setBuildingTempValue(bName);
          setCityTempValue(data.city);
          setAreaTempValue(data.area);
          setSameToggle(true);
        }
      }else if(!initialLoad){
        dispatch(showToastAction("City should be same as store city."));
        setTimeout(function () {
          dispatch(hideToastAction());
        }, 3000);
            setValObj({...valObj});
      }
    });
  };
  const [sameToggle, setSameToggle] = useState(true);

  useEffect(() => {
    if ((!sameToggle  || sameToggle=="false") && !saveFlag) {
      setValObj({...locationDetails, sameToggle: sameToggle});
  setAreaTempValue(locationDetails.storeArea);
  setCityTempValue(locationDetails.city);
  setBuildingTempValue(locationDetails.buildingName);
    }else{
  //     setValObj({...pickupDetails, sameToggle: sameToggle});
  // setAreaTempValue(pickupDetails.storeArea);
  // setCityTempValue(pickupDetails.city);
  // setBuildingTempValue(pickupDetails.buildingName);
    }
  }, [sameToggle]);

  const handleSameToggle = () => {
    let sameToggleC = (!sameToggle || sameToggle=="false")?true:false;
    setSameToggle(sameToggleC);
if(!sameToggle || sameToggle=="false"){
  setDefaultLL({
    latitude: valObj.latitude || defaultLL.latitude,
    longitude: valObj.longitude || defaultLL.longitude
  })
  setAreaTempValue("");
  setCityTempValue("");
  setBuildingTempValue("");
  setValObj({
    sameToggle: sameToggleC,
    latitude: "",
    longitude: "",
    buildingName: "",
    streetName: "",
    landmark: "",
    pincode: "",
    state: "",
    storeArea: "",
    city:""
  }); //Reset all values when city is changed 
}
  };

  useEffect(async ()=>{
    if(!valObj.latitude || !valObj.longitude){
      let r = getCurrentURIParams();
      if(r.source==51 || r.source==53){
        try{
          if(r.source==51){
            var logjson = JSON.stringify({ action: "location", fn: "locationPermissionCB" });
            window.JdLiteInterface.verticalHandler(logjson);
          }else{
            var logjson = JSON.stringify({ action: "locationPermission"});
            window.webkit.messageHandlers.callbackHandler.postMessage(logjson);
          }      
        }catch(e){
          console.log("Location permission vertical handler");
          console.log(e);
        }
      }
      if(r.source!=53){
        setTimeout(()=>{
          navigator.geolocation.getCurrentPosition((position)=>{
            if(!inputEl.current || !inputEl.current.value){
            handleSetLatLong({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }) 
          }
          }, showNavError);
       },400)
     }
    }
    },[0])

    const showNavError = (error) => {
      let err;
      switch(error.code) {
        case error.PERMISSION_DENIED:
          err= "Request for Geolocation is blocked."
          break;
        case error.POSITION_UNAVAILABLE:
          err= "Location information is unavailable."
          break;
        case error.TIMEOUT:
          err= "The request to get user location timed out."
          break;
        case error.UNKNOWN_ERROR:
          err= "An unknown error occurred."
          break;
      }
      dispatch(showToastAction(err));
        setTimeout(function () {
          dispatch(hideToastAction());
        }, 3000);
    }

    const handleResetMap = () => {
      let r = getCurrentURIParams();
      if(r.source==51 || r.source==53){
        try{
          if(r.source==51){
            var logjson = JSON.stringify({ action: "location", fn: "locationPermissionCB" });
            window.JdLiteInterface.verticalHandler(logjson);
          }else{
            console.log("LOCATION TEST - Reset map on Click - action: locationPermission");
            mapResetFlag.current = true;
            var logjson = JSON.stringify({ action: "locationPermission"});
            window.webkit.messageHandlers.callbackHandler.postMessage(logjson);
          }      
        }catch(e){
          console.log("Location permission vertical handler");
          console.log(e);
        }
      }
      console.log("LOCATION TEST - Reset map on Click - outside calling navigator", r.source);
      if(r.source!=53){
        console.log("LOCATION TEST - Reset map on Click - inside calling navigator", r.source);
        navigator.geolocation.getCurrentPosition((position)=>{
          handleSetLatLong({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }, true) 
           },
           showNavError)
      }
    }
     const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);
    useEffect(()=>{
      //To check if all mandatory fields are filled and enable submit button
      let dis = false;

    if(cityChanging){
      dis=true;
    }else if(!valObj["city"] || valObj["city"]==""){
      dis = true;
    }
    if(buildingChanging){
      dis=true;
    }else if(!valObj["buildingName"] || valObj["buildingName"]==""){
      dis = true;
    }
    if(inputEl.current.value==valObj["buildingName"]){
      setDisabledSubmitButton(dis);
    }
    },[valObj, buildingTempValue, cityTempValue, areaTempValue])
  
  const [showKMAlert, setShowKMAlert] = useState({show: false, txt: ""});
  const handleCheckKM = () => {
    distCheck({slat: locationDetails.latitude, slong: locationDetails.longitude, plat: valObj.latitude, plong: valObj.longitude}).then((res)=>{
      if(parseInt(res.data.distance)>1){
        setShowKMAlert({show: true, txt: "Your store location and pick up location are more than 1 kilometer apart. Do you wish to Continue?"});
      }else{
        handleSave();
      }
    })
//    handleSetLatLong(obj);
  }


    let paramsFrom = getCurrentURIParams(); 
    let showBuildingSelErr = valObj.buildingName && (!showBuildingDropdown && valObj.buildingName!==buildingTempValue && !buildingChanging);
    let disabledBtn = !valObj["buildingName"] || valObj.buildingName!==buildingTempValue || showBuildingSelErr;
    console.log(showBuildingSelErr);
  
  return (
    <>
      <section class="pl-20 pr-20 pb-70">
        <div class="font12 color777 mt-30">
          Pickup address is same as store address
        </div>
        <label class="odrfdswtch mt-10">
          <input
            type="checkbox"
            id="togBtn"
            checked={(sameToggle=="false" || !sameToggle)?false:true}
            onChange={(e) => handleSameToggle(e)}
          />
          <div class="odrfdswtch round">
            <span>Yes</span>
            <span>No</span>
          </div>
        </label>
        <div class="lctn_map mt-30">
          {
            <MapComponent
              lat={valObj.latitude || defaultLL.latitude}
              long={valObj.longitude || defaultLL.longitude}
              setLatLong={handleSetLatLong}
              handleResetMap={handleResetMap}
            />
          }
        </div>
        <div class="mt-10 font11 color777 text_center">
          Adjust pin as per store location{" "}
        </div>

        <div class="seller_ob_form mt-30">
        <div class="group mb-20 common_list">
            <input
              class="inputMaterial font16 fw600"
              type="text"
              required
              name="buildingName"
              value={buildingTempValue}
              ref={inputEl}
              onChange={(e) => handleChangeBuilding(e)}
              autoComplete="off"
              id={"buildingName"}
              onClick={()=>{
                const idFocus = document.getElementById("buildingName")
              setTimeout(() => {
                idFocus && idFocus.scrollIntoView({behavior: "smooth", block: "center"});
              },300)
              }}
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="label-wrap font16"> Pickup Location * </label>  
            <span
              className={showBuildingDropdown?"of_downarrow uparrow":""}
              onClick={()=>handleCloseDropdown("buildingName")}
            ></span>
            {showBuildingDropdown  && buildingTempValue && (
              <BuildingAreaList data={buildingList} setBuilding={handleSetBuilding} />
            )}
            { showBuildingSelErr &&  (
              <span class="errorcls font12 pt-5" id="buildingErrorSpan">
               Please enter valid building name before proceeding
              </span>
            )}
          </div>
          <div class="group">
            <input
              class="inputMaterial font16 fw600"
              type="text"
              required
              name="streetName"
              value={valObj.streetName}
              onChange={(e) => handleChange(e)}           
              id={"streetName"}
              onClick={()=>{
                const idFocus = document.getElementById("streetName");
                setTimeout(()=>{
                idFocus && idFocus.scrollIntoView({behavior: "smooth", block: "center"});
              },400);
              }}
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="label-wrap font16"> Shop No, Building Name / Street Name </label>
          </div>
          <div class="group">
            <input
              class="inputMaterial font16 fw600"
              type="text"
              required
              name="landmark"
              value={valObj.landmark}
              onChange={(e) => handleChange(e)}            
              id={"landmark"}
              onClick={()=>{
                const idFocus = document.getElementById("landmark")
                idFocus && idFocus.scrollIntoView({behavior: "smooth", block: "center"});
              }}
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="label-wrap font16"> Landmark </label>
          </div>
          {/* <div class="group mb-20 common_list">
            <input
              class="inputMaterial font16 fw600"
              type="text"
              required
              name="storeArea"
              disabled={true}
              value={areaTempValue}
              onChange={(e) => handleChangeArea(e)}
              autoComplete="off"           
              id={"storeArea"}
              onClick={()=>{
                const idFocus = document.getElementById("storeArea")
                idFocus && idFocus.scrollIntoViewIfNeeded();
              }}
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="label-wrap font16"> Enter Store Area * </label>
            <span
              className={showAreaDropdown?"of_downarrow uparrow":""}
              onClick={()=>handleCloseDropdown("storeArea")}
            ></span>
            {showAreaDropdown && (
              <StoreAreaList data={areaList} setArea={handleSetArea} />
            )}
            {isEmptyFieldErr.storeArea &&  (
            <span class="errorcls font12 pt-5" id="storeAreaErrorSpan">
              Please select/enter valid area from dropdown.
            </span>
          )}
          </div> */}
          <div class="group">
            <input
              class="inputMaterial font16 fw600 graytext"
              type="text"
              required
              name="pincode"
              value={valObj.pincode}
              onChange={(e) => handleChange(e)}
              disabled={(valObj.pincode || !valObj.buildingName)?true:false}
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="label-wrap font16"> Pincode </label>
            {/* {isValidPincode && (
              <span class="errorcls font12 pt-5">
                 Please enter a valid pincode
              </span>
            )} */}
          </div>
          <div class="group mb-20 common_list">
            <input
              class="inputMaterial font16 fw600"
              type="text"
              required
              name="city"
              value={cityTempValue}
              disabled={true}
              onChange={(e) => handleChangeCity(e)}
              autoComplete="off"      
                id={"city"}
              onClick={()=>{
                const idFocus = document.getElementById("city")
              setTimeout(() => {
                idFocus && idFocus.scrollIntoView({behavior: "smooth", block: "center"});
              },100)
              }}
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="label-wrap font16"> City *</label>
            <span
              className={showCityDropdown?"of_downarrow uparrow":""}
              onClick={()=>handleCloseDropdown("city")}
            ></span>
            {showCityDropdown && (
              <StoreCityList data={cityList} setCity={handleSetCity} />
            )}
              {/* {isEmptyFieldErr.city &&  (
              <span class="errorcls font12 pt-5" id="cityErrorSpan">
               Please enter/select valid city from drop down
              </span>
            )} */}
          </div>
          <div class="group">
            <input
              class="inputMaterial font16 fw600 graytext"
              type="text"
              required
              name="state"
              value={valObj.state}
              onChange={(e) => handleChange(e)}
              disabled={true}
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="label-wrap font16"> State </label>
          </div>
        </div>

        {/* <div class="font12 color777 mt-30">How would you like to fulfill your orders?</div>
        <ul class="order_status font16 fw500 color111">
          <li onClick={(e) => {dispatch(setIsLogisticDelivery(false))}}>
            <label>
              <span class="radiobtn_wpr">
                <input type="radio" name="00" checked={isDeliveryFlag=="0"} />
                <span class="radiobtn_circle"></span>
              </span>
              Using Jd Logistics
            </label>
          </li>
          <li onClick={(e) => {dispatch(setIsLogisticDelivery(true))}}>
            <label>
              <span class="radiobtn_wpr">
                <input type="radio" name="00" checked={isDeliveryFlag=="2"} />
                <span class="radiobtn_circle"></span>
              </span>
              Self Ship + JD logistics
            </label>
          </li>
        </ul> */}

      </section>
      <div class={`ftr_btn animated fast fadeInUp ${disabledBtn?"disabled":""}`}>
        <button class="font14 fw600 colorfff" disabled={disabledBtn} onClick={() => handleCheckKM()}>
        {!showButtonLoader && <>Save{paramsFrom["input"] == "account_settings"?"":<> &amp; Next</>}</>}
       {
         showButtonLoader && <span class="dotloader"><span /><span /><span /><span /><span /></span>
       }  
        </button>
      </div>
	{
        showKMAlert.show &&  
        <div className="updatebank_dtl_wpr">
        <div className="updatebank_dtl_outer p-20 p-30">
            <div className="font13 color111 fw600 lineHeight22">
            {showKMAlert.txt}
            </div>
            <div className="mt-30 proceed_btn_wpr">
              <button className="font16 graybtn" onClick={()=>setShowKMAlert({show: false, txt:""})}>Cancel</button> 
              <button className="font16" onClick={()=>handleSave()}>Continue</button>
            </div>
        </div>
      </div>
      } 
    </>
  );
};

export default PickupDet;
