export const saveBusinessAction = (payload) => {
  return {
    type: "SAVE_BUSINESS",
    data: payload,
  };
};

export const saveBusinessDetAction = (payload) => {
  return {
    type: "SAVE_BUSINESS_DETAILS",
    data: payload,
  };
};

export const saveLocationDetAction = (payload) => {
  return {
    type: "SAVE_LOCATION_DETAILS",
    data: payload,
  };
};

export const savePickupDetAction = (payload) => {
  return {
    type: "SAVE_PICKUP_DETAILS",
    data: payload,
  };
};

export const saveContactDataAction = (payload) => {
  return {
    type: "SAVE_CONTACT_DETAILS",
    data: payload,
  };
};

export const setCurrentLocationAction = (payload) => {
  return {
    type: "SET_LOCATION",
    data: payload,
  };
};

export const loadInitialBusinessDataAction = (payload) => {
  return {
    type: "SET_INITIAL_BUSINESS_DATA",
    data: payload,
  };
};

export const setSelectedBusinessDataAction = (payload) => {
  return {
    type: "SET_SELECTED_BUSINESS_DATA",
    data: payload,
  };
};

export const setStoreTimingAction = (payload) => {
  return {
    type: "SET_STORE_TIMING",
    data: payload,
  };
};

export const loadPrefilledBusinessDataAction = (payload) => {
  return {
    type: "LOAD_PREFILLED_BUSINESS_DATA",
    data: payload,
  };
};

export const setLoadedDataAction = (payload) => {
  return {
    type: "LOADED_PRE_FILLED_BUSINESS_DATA",
    data: payload,
  };
};

export const saveUrlParamsAction = (payload) => {
  return {
    type: "SAVE_URL_PARAMS",
    data: payload,
  };
};

export const saveUrlParamsObjAction = (payload) => {
  return {
    type: "SAVE_URL_PARAMS_OBJ",
    data: payload,
  };
};

export const setLoggedinAction = (payload) => {
  return {
    type: "SET_LOGGED_IN",
    data: payload,
  };
};

export const setDocIdAction = (payload) => {
  return {
    type: "SET_DOC_ID",
    data: payload,
  };
};

export const showToastAction = (payload) => {
  return {
    type: "SET_TOAST_SHOW",
    data: payload,
  };
};
export const hideToastAction = (payload) => {
  return {
    type: "SET_TOAST_HIDE",
    data: payload,
  };
};
export const showLoaderAction = () => {
  return {
    type: "SET_LOADER_SHOW",
  };
};
export const hideLoaderAction = () => {
  return {
    type: "SET_LOADER_HIDE",
  };
};
export const showShimmerAction = () => {
  return {
    type: "SET_SHIMMER_SHOW",
  };
}
export const hideShimmerAction = () => {
  return {
    type: "SET_SHIMMER_HIDE",
  };
};
export const showHelpAction = () => {
  return {
    type: "SET_HELP_SHOW",
  };
};
export const hideHelpAction = () => {
  return {
    type: "SET_HELP_HIDE",
  };
};
export const setBusinessSelectedFlagAction = () => {
  return {
    type: "SET_BUSINESS_SELECT_FLAG",
  };
};
export const setBusinessCreateFlagAction = () => {
  return {
    type: "SET_BUSINESS_CREATE_FLAG",
  };
};
export const clearInitialDataAction = () => {
  return {
    type: "CLEAR_INITIAL_DATA",
  };
};
export const hideMainHeader = () => {
  return {
    type: "HIDE_HEADER"
  }
};
export const showMainHeader = () => {
  return {
    type: "SHOW_HEADER"
  }
}
export const setNoBussDetails = () => {
  return {
    type: "NO_BUSS_DET",
  };
}
export const setLoadedBussDetails = () => {
  return {
    type: "LOADED_BUSS_DET",
  };
}
export const setLoadedTemData = () => {
  return {
    type: "LOADED_TEMP_DET",
  };
}
export const setNoTemData = () => {
  return {
    type: "NO_TEMP_DET",
  };
}
export const setGenioReferFlag = () => {
  return {
    type: "SET_GENIO_REFER_FLAG"
  }
}
export const resetGenioReferFlag = () => {
  return {
    type: "RESET_GENIO_REFER_FLAG"
  }
}
export const setIsPaidContract = () => {
  return {
    type: "IS_PAID_CONTRACT"
  }
}
export const setEmployeeCode = (d) =>{
  return {
    type: "SET_EMPLOYEE_CODE",
    data: d
  }
}
export const isOutletContract = (d) =>{
  return {
    type: "IS_OUTLET_CONTRACT",
    isOutlet: d
  }
}
export const setIsNotPaidContract = () => {
  return {
    type: "IS_NOT_PAID_CONTRACT"
  }
}
export const setIsLogisticDelivery = (flag) => {
  if(flag){
    return {
      type: "IS_LOGISTIC_DELIVERY"
    }
  }else {
    return {
      type: "IS_NOT_LOGISTIC_DELIVERY"
    }
  }
}
export const setDeliveryChargeData = (data) => {
  return {
    type: "SET_DELIVERY_CHARGE_DATA",
    data
  }
}
export const saveJDUID = (data) => {
  return {
    type: "SAVE_JD_UID",
    data
  }
}

export const setOffersDayTimeList = (data) => {
  return {
    type: "SAVE_OFFERS_DAYTIME_LIST",
    data
  }
}

export const setOffersFieldsData = (data) => {
  return {
    type: "SAVE_OFFERS_FIELDS_DATA",
    data
  }
}

export const setOffersTermsData = (data) => {
  return {
    type: "SAVE_OFFERS_TERMS",
    data
  }
}

export const setOffersDataToEdit = (data) => {
  return {
    type: "SET_OFFERS_DATA_EDIT",
    data
  }
}

export const addOffers = (data) => {
  return {
    type: "ADD_OFFER",
    data
  }
}

export const editOffers = (data, index) => {
  return {
    type: "EDIT_OFFER",
    data,
    index
  }
}

export const clearOffersData = () => {
  return {
    type: "CLEAR_OFFERS_DATA"
  }
}

export const setOffersDayTimeApplyAll = (flag) => {
  return {
    type: "APPLY_TIME_OFFERS_ALL",
    flag
  }
}

export const deleteOffer = (ind) => {
  return {
    type: "DELETE_OFFER", 
    ind
  }
}

export const setTimingsDataFromStoreTime = (data) => {
  return {
    type: "SET_TIMINGS_FROM_STORETIME",
    data
  }
}
