import {getCurrentURIParams} from "../config/utils";
import {JDCASHVERTICAL} from "../config/consts";

let uriParams = getCurrentURIParams();
const vertical = uriParams["vertical"];

export const checkForLive = () => {
    if (
      window.location.host.includes("www") ||
      window.location.host.includes("business") ||
      window.location.host.includes("win") ||
      window.location.host.includes("wap")
    ) {
      return true;
    } else {
      return false;
    }
  };

export const checkForStaging = () => {
    if(window.location.host.includes("staging")){
        return true;
    }else{
        return true; //for test
    }
}

export const ONBOARDING_URI = process.env.REACT_APP_URL_DEV_TEST_PROD_HOST;// process.env.REACT_APP_URL_STAGING_PROTOCOL + window.location.host + "/" + process.env.REACT_APP_URL_STAGING_PATH;

export const JDCASH_URI = process.env.REACT_APP_URL_JDCASH_TEST_PROD_HOST;

export const redirectJDAPI = process.env.REACT_APP_URL_REDIRECT_PROD;

export const DashboardUri = process.env.REACT_APP_DBRD_URL_PROD;

export const DashboardUri2 = process.env.REACT_APP_DBRD_URL_PROD;

export const taxDetailsUri = process.env.REACT_APP_TAX_DETAILS_URL_PROD;

export const URI_TOKEN_VERIFICATION = process.env.REACT_APP_URI_TOKEN_VERIFICATION_PROD;

export const URI_TOKEN_CHECK = process.env.REACT_APP_URI_TOKEN_CHECK_PROD;

export const EDIT_LIST_CAT_CHECK = process.env.REACT_APP_URL_EDITLISTING_CAT_PROD;

export const SECURE_PG = process.env.REACT_APP_URL_SECUREPG_PROD;

export const CREATE_ORDER_ID = process.env.REACT_APP_URL_CREATE_ORDER_ID_PROD;

export const EDIT_LIST_HOME_URL_CAT = process.env.REACT_APP_EDIT_LIST_CAT_HOME_PROD;

export const EDIT_LIST_HOME_URL = process.env.REACT_APP_EDIT_LIST_HOME_PROD;

export const ONBOARDING_NOTIFY_URI = process.env.REACT_APP_URI_NOTIFY_LIVE;

export const JDCASH_NOTIFY_URI = process.env.REACT_APP_URI_JDCASH_NOTIFY_LIVE;

export const SELF_URI = process.env.REACT_APP_SELF_LINK_LIVE;

export const URI = vertical == JDCASHVERTICAL?JDCASH_URI:ONBOARDING_URI;

export const NOTIFY_URI = vertical == JDCASHVERTICAL?JDCASH_NOTIFY_URI:ONBOARDING_NOTIFY_URI;
