import React, {useState, useEffect, useRef} from "react";

import {
    useNavigate,
  } from "react-router-dom";
  import { useDispatch } from "react-redux";
  import {
    setCurrentLocationAction,
    loadPrefilledBusinessDataAction,
    saveUrlParamsAction,
    setLoadedDataAction,
    showLoaderAction,
    hideLoaderAction,
    loadInitialBusinessDataAction,
    setDocIdAction,
    setSelectedBusinessDataAction
  } from "../../actions/actions";
import {
    getURIParams,
    LoadBusinesses,
    businessDetails,
    getCurrentURIParams,
    getDboardLink,
    getCookie,
    getProfileImage,
    logoutCall,
    getSource
  } from "../../services/common";
import {IsJDCash} from "../../config/verticalConfig";
import {PageLoad} from "./PageLoadSellerApp";
import useOnScreen from "./useOnScreen";
import CommissionStructure from "./CommissionStructure";

const IntroPage = ({Login, loggedIn, checkedLoggedIn}) =>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const ref_1 = useRef();
    // const isVisible_1 = useOnScreen(ref_1);
    // const ref_2 = useRef();
    // const isVisible_2 = useOnScreen(ref_2);
    // const ref_3 = useRef();
    // const isVisible_3 = useOnScreen(ref_3);    
    // const ref_4 = useRef();
    // const isVisible_4 = useOnScreen(ref_4);    
    const [profileImage, setProfileImage] = useState();
    useEffect(()=>{
        if(loggedIn){
            getProfileImage().then((res)=>{
                if(res.data && res.data.results)
                setProfileImage(res.data.results.lmeimage);
            })
        }  
    },[loggedIn]);

    useEffect(()=>{
        if(IsJDCash){
            handleLogin(); 
        }
    }, [0])

    const [showLoginDropdown, setShowLoginDropdown] = useState(false);
    const [showBurgerItems, setshowBurgerItems] = useState(false);

    // handle click out side to close the dropdown opened 
    const handleClickOutside = e => {
        const data = document.getElementById("test321")
        // console.log("test", data)
        if (data){
            setTimeout(() =>{
                setShowLoginDropdown(false)
            }, 400)
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    const handleLogin = () =>{
        if(loggedIn){
        dispatch(showLoaderAction());
      LoadBusinesses().then((res) => {
        let urlParamsClone = getURIParams();
        if (res.data.results){
      //    dispatch(loadInitialBusinessDataAction(res.data.results));
          if(res.data.results.length>0){
            let dUri = getDboardLink();
            console.log(dUri);
            window.location.href = dUri;
          }else{
            navigate(`/businessDetails/businessDet?${urlParamsClone}`);
          }
        }else{
          navigate(`/businessDetails/businessDet?${urlParamsClone}`);
        }
        dispatch(hideLoaderAction());
       });
        }else{
            Login();
        }
    }

    const userDet =
        getCookie("ln_user") &&
        JSON.parse(decodeURIComponent(getCookie("ln_user")));
    let UserName = userDet.nm;
    let umobile = '';
    try{
    if(getCookie("ln_user") && typeof getCookie("ln_user")!='undefined' && getCookie("ln_user")!=null && getCookie("ln_user")!=''){
        let d = JSON.parse(decodeURIComponent(getCookie("ln_user")));
        umobile = d.mb;
    }else if(getCookie("mobile") && typeof getCookie("mobile")!='undefined' && getCookie("mobile")!=null && getCookie("mobile")!=''){
        umobile =getCookie("mobile");
    }
    }catch(e){
        console.log(e);
    }
    
    const redirectToDUri = () =>{
        let dUri = getDboardLink();
        console.log(dUri);
        window.location.href = dUri;
    }

    const redirectToAddNewStore = () => {
        let urlParamsClone = getURIParams();
        navigate(`/businessDetails/businessDet?${urlParamsClone}`);
    }

    const scrollViewComm = () => {
        let cStr = document.getElementById("commission_structure");
        cStr.scrollIntoView();
    }

    const jumpToview = (id) => {
        let cStr = document.getElementById(id);
        cStr.scrollIntoView();
        setshowBurgerItems(false)
    }

    const logOut = () =>{
       let gtprm = new URL(window.location.href);
       if(gtprm.searchParams.get("source")!='undefined' && gtprm.searchParams.get("source")!=null && gtprm.searchParams.get("source")!=''){
        if(gtprm.searchParams.get("source")=='51'){
            var logjson = JSON.stringify({"action":"logout"});
            window.JdLiteInterface.verticalHandler(logjson);
        }else{
            logoutCall(gtprm.searchParams.get("source")).then(res=> window.location.reload())
        }
       }else{
        logoutCall("").then(res=> window.location.reload())
       }
    }
    const redirectUrls = (section) =>{
        const source = getSource();
        let url = '/';
        switch(section){
            case 'policy':
                url = source === 7 ? 'https://www.justdial.com/Privacy-Policy'
                : 'https://www.justdial.com/mobileTC?touch=1&wap=2&source=2&nh=1&hide_header=1&nd=1&ln=en&ver=1.0'
                break
            case 'infri':
                url = source == 7 ? "https://www.justdial.com/Infringement-Policy" 
                : "https://www.justdial.com/sideMenu/pages/infringement"
                break
            case 'feedback':
                url = source == 7 ? `https://wap.justdial.com/analytics/enquiries?el=0&nh=1&rootvc=0&hide_header=1&m=1&mobile=${umobile}&old=1&source=${source || ""}&tab=enquiries&tab=enquiries`
                :`https://www.justdial.com/cms/feedback-menu?touch=1&mobile=${umobile}&hide_header=1&wap=2&source=${source || ""}&nh=1&hide_header=1&nd=1&ln=en&ver=1.0`;
                break
            default:
                break
        }
        window.open(url)
    }

    useEffect(()=>{
         //Page load for source 51, 53
    let r = getCurrentURIParams();
    let mb = r.mobile;
    try{
      if(checkedLoggedIn){
      if(r.source!==53){
        let logInJson = window.JdLiteInterface.getLoginData();
        if(!logInJson) return;
        logInJson = JSON.parse(logInJson);
          if(logInJson.source=="51"){
            PageLoad(logInJson.source);
         }
      }else{
        PageLoad(r.source);
      }
      }
    }catch(e){
      console.log("Page Load err ", e);
    }
    },[checkedLoggedIn])

    const src_var = getSource();

    return (
        <>
        {
            !IsJDCash && 
            <>
    <section className="main_nav_wpr header">
        <div className="containernew">
            <div className="main_nav_outer">
                <div className="logoSubnavbx">
                    <span className=" burgericon" onClick={() =>{
                        // do toggle
                        const val = showBurgerItems
                        setshowBurgerItems(!val)
                        // setShowLoginDropdown(false)
                    }}></span>
                    <span className="justdial_logo"></span>
                    <div className={"headsubnav " +(showBurgerItems ? "active": "")}> 
                        <span className="txbx" onClick={()=>jumpToview("why_sell_on_jd")}>Why sell on Jd?</span>
                        <span className="txbx" onClick={()=>jumpToview("steps_to_sell")}>Steps to sell</span>
                        <span className="txbx" onClick={()=>jumpToview("commission_structure")}>Fees & Commisions</span>
                    </div>
                </div>
                <div className="rightbx">
                  {!loggedIn &&  <span className="loginlink mob_login" onClick={()=>handleLogin()}>Login</span>} 
                  {!loggedIn && <span className="create_account_link" onClick={()=>handleLogin()}> Create Free account</span>}
                {/* logged in section start */}
                { loggedIn &&
                    <div className="hl_login_section dn_">
                        <div className=" hl_login_text" onClick={()=>{
                                setShowLoginDropdown(!showLoginDropdown)
                                setshowBurgerItems(false)
                            }} >
                            {
                                profileImage?                           
                                <img width="30px" height="30px" src={profileImage} alt="Profile Image" className="profile_image dn_" />
                                :
                                <img width="30px" height="30px" src="https://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/userdefault.svg" alt="Profile Image" className="profile_image dn_" />
                            }
                            <span className="profile_name font12 fw600 color111 mr-5" >{UserName}</span><span className="hl_drop_icon "></span>
                        </div>
                        {
                            (showLoginDropdown) &&
                            <div 
                            className="hl_login_dropdown "
                            id="test321"
                            >
                            <ul className="hl_login_items">
                                <li className="login_item">
                                    <div className="login_item_row">
                                        <div className="login_item_text color111" onClick={()=>redirectToDUri()}>Dashboard</div>
                                    </div>
                                </li>
                                <li className="login_item">
                                    <div className="login_item_row">
                                        <div className="login_item_text color111" onClick={()=>redirectToAddNewStore()}> Add New Store </div>
                                    </div>
                                </li>
                                <li className="login_item">
                                    <div className="login_item_row">
                                        <div className="login_item_text color111" onClick={()=>logOut()}> Logout </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        }     
                    </div>
                }
                </div>
                
            </div>
        </div>
    </section>
    <section className="banner_wpr newdes">
        <div className="containernew">
            <div className="bnrleftprt">
                 <div className="bnrtextbox"> 
                    <div className="h1text color111 fw700 mb-15">A great way to sell <br/>your Products </div> 
                    <div className="font30 ">Only with Justdial</div> 
                  {!loggedIn && <button className="orngbtn font18" onClick={(e)=> handleLogin(e)}> Start Selling</button>}
                 </div> 
                <div className="bgimg"> </div> 
            </div>
            <div className="bnrrightprt"> 
                <div className="bnrtextbox"> 
                    <div className="h1text color111 fw700 mb-15">Commission as low as <span className="color007">2%</span> </div> 
                    <div className="sublistul">
                        <div className="subli">
                            <div className="imgbx"><i className="nocolchrgicn"> </i></div> 
                            <div className="conbx">No Collection Charges</div> 
                        </div> 
                        <div className="subli">
                            <div className="imgbx"><i className="freeshipicn"> </i></div> 
                            <div className="conbx">Free Shipping</div> 
                        </div>
                    </div> 
                    <button className="blkbtn font18" onClick={()=>scrollViewComm()}> Know more </button>
                 </div> 
            </div>
        </div>
        {/* <div className="containernew">
        {!loggedIn &&
            <div className="startselling_search_wpr dn">
                <div className="startselling_search_outer">
                    <span className="cntrycode">+91</span>
                    <input type="text" name="" placeholder="Enter Mobile Number" />
                    <button onClick={()=>handleLogin()}>Start Selling</button>
                </div>
                <div className="regtext hidden_mob">It takes less than 10 mins to register </div>
            </div>
        }
        </div> */}
    </section>
    {/* <section className="customerstories_wpr" id="what_our_seller_say">
        <div className="containernew">
            <div className="customerstories_outer">
                <div className="cs_title">CUSTOMER STORIES</div>
                <div className="sellertext">What our Sellers says</div>
            <div className="sellercard_wpr">
                    <div className="sellercard" ref={ref_1}>
                        <div className="sellerimg_wpr">
                            <div>
                                <span className="sellerimg"><img src="https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/testimonial_01.png" /></span>
                            </div>
                            <div>
                                <span className="rightquotationsign"></span>
                            </div>
                        </div>
                        <div className="seller_comment">
                            “Our new insights into online and offline attribution pave the way to future conversations about expanding our digital strategy and promoting local inventory through online channels.”
                        </div>
                        <div className="seller_dtl_text">
                            <div className="seller_name">Sachin Ghare</div>
                            <div className="seller_store">Top 10 Mobile Shop</div>
                        </div>
                    </div>
                    <div className="sellercard"  ref={ref_2}>
                        <div className="sellerimg_wpr">
                            <div>
                                <span className="sellerimg"> <img src="https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/testimonial_03.png" /></span>
                            </div>
                            <div>
                                <span className="rightquotationsign"></span>
                            </div>
                        </div>
                        <div className="seller_comment">
                            “Our new insights into online and offline attribution pave the way to future conversations about expanding our digital strategy and promoting local inventory through online channels.”
                        </div>
                        <div className="seller_dtl_text">
                            <div className="seller_name">Deepak Sharma</div>
                            <div className="seller_store">21 Pharmacy</div>
                        </div>
                    </div>
                    <div className="sellercard"  ref={ref_3}>
                        <div className="sellerimg_wpr">
                            <div>
                                <span className="sellerimg"><img src="https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/testimonial_02.png" /></span>
                            </div>
                            <div>
                                <span className="rightquotationsign"></span>
                            </div>
                        </div>
                        <div className="seller_comment">
                            “Our new insights into online and offline attribution pave the way to future conversations about expanding our digital strategy and promoting local inventory through online channels.”
                        </div>
                        <div className="seller_dtl_text">
                            <div className="seller_name">Vishaka Subedar</div>
                            <div className="seller_store">Fashion Store</div>
                        </div>
                    </div>
                    <div className="sellercard"  ref={ref_4}>
                        <div className="sellerimg_wpr">
                            <div>
                                <span className="sellerimg"><img src="https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/testimonial_04.png" /></span>
                            </div>
                            <div>
                                <span className="rightquotationsign"></span>
                            </div>
                        </div>
                        <div className="seller_comment">
                            “Our new insights into online and offline attribution pave the way to future conversations about expanding our digital strategy and promoting local inventory through online channels.”
                        </div>
                        <div className="seller_dtl_text">
                            <div className="seller_name">Vishaka Subedar</div>
                            <div className="seller_store">Fashion Store</div>
                        </div>
                    </div>
                </div>
                <div className="carousel_nav">
                    <span className={isVisible_1?"act":""}></span>
                    <span className={isVisible_2?"act":""}></span>
                    <span className={isVisible_3?"act":""}></span>
                    <span className={isVisible_4?"act":""}></span>
                </div>
            </div>
        </div>
    </section> */}
   
    <section className="seller_info_wpr sellonjdmarketplce" id="why_sell_on_jd">
        <div className="container">
            <div className="seller_info_outer">
                <div className="seller_info">
                    <div className="seller_info_boldtext">Why sell on Justdial Marketplace?</div>
                    <div className="sellerbig_img"></div>
                    <div className="ultemplwrp">
                        <ul className="ultempl">
                            <li className="litempl">
                                <div className="imgbxwrp">
                                    <div className="imgbx"> <img src="https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/ws-dobusiness.svg" /></div>
                                </div>
                                <div className="contwrp pl-20">
                                    <div className="font22 color111 fw500 mb-5">Ease of doing Business </div>
                                    <div className="font18 color111">3 steps to just Setup your local store on Justdial</div>
                                </div>
                            </li>
                            <li className="litempl">
                                <div className="imgbxwrp">
                                    <div className="imgbx"> <img src="https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/ws-fastdel.svg" /></div>
                                </div>
                                <div className="contwrp pl-20">
                                    <div className="font22 color111 fw500 mb-5">Faster Delivery </div>
                                    <div className="font18 color111">Hassle free same day deliveries* </div>
                                </div>
                            </li>
                            <li className="litempl">
                                <div className="imgbxwrp">
                                    <div className="imgbx"> <img src="https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/ws-lowcomm.svg" /></div>
                                </div>
                                <div className="contwrp pl-20">
                                    <div className="font22 color111 fw500 mb-5">Lowest commission fees </div>
                                    <div className="font18 color111">Zero collection fee along with category led lower commission structure   </div>
                                </div>
                            </li>
                            <li className="litempl">
                                <div className="imgbxwrp">
                                    <div className="imgbx"> <img src="https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/ws-grow.svg" /></div>
                                </div>
                                <div className="contwrp pl-20">
                                    <div className="font22 color111 fw500 mb-5">Grow with us  </div>
                                    <div className="font18 color111">Get access to crores of customers on India's No 1 local search engine </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    
                    {!loggedIn &&
                    <div className="startselling_btn">
                        <button onClick={(e)=> handleLogin(e)}>Start Selling</button>
                    </div>
                    }
                </div>
            </div>
        </div>
    </section>
    

     <section className="howtosell_wpr newdes " id="steps_to_sell">
        <div className="containernew">
            <div className="howtosell_outer">
                <div className="howtosell_title">
                    SIGN UP. STAND OUT.
                </div>
                <div className="howtosell_text">How to sell on Justdial?</div>

                <div className="ultemplwrp">
                        <ul className="ultempl">
                            <li className="litempl">
                                <div className="imgbxwrp">
                                    <div className="imgbx"> <img src="https://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/seller-business_2x.png" /></div>
                                </div>
                                <div className="contwrp pl-20">
                                    <div className="font25 color111 fw500 mb-10">1. Create shop online</div>
                                    <div className="font20 color777">Add business details and operational timing  Provide GST, PAN and bank account to activate </div>
                                </div>
                            </li>
                            <li className="litempl">
                                <div className="imgbxwrp">
                                    <div className="imgbx"> <img src="https://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/seller-add-product_2x.png" />
                                    </div>
                                </div>
                                <div className="contwrp pl-20">
                                    <div className="font25 color111 fw500 mb-10">2. Add Products </div>
                                    <div className="font20 color777">Minimal product info to list your products, Bulk upload of custom products </div>
                                </div>
                            </li>
                            <li className="litempl">
                                <div className="imgbxwrp">
                                    <div className="imgbx"> <img src="https://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/seller-monitor_2x.png" /></div>
                                </div>
                                <div className="contwrp pl-20">
                                    <div className="font25 color111 fw500 mb-10">3. Manage Orders & Inventory </div>
                                    <div className="font20 color777">Track orders, sales and payments from seller dashboard, Communication updates for order statuses </div>
                                </div>
                            </li>
                            <li className="litempl">
                                <div className="imgbxwrp">
                                    <div className="imgbx"> <img src="https://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/seller-easypay_2x.png" /></div>
                                </div>
                                <div className="contwrp pl-20">
                                    <div className="font25 color111 fw500 mb-10">4. Manage Payments  </div>
                                    <div className="font20 color777">Daily payout settlements, Payment reports & invoices</div>
                                </div>
                            </li>
                        </ul>
                    </div>

                
            </div>
        </div>
    </section>


    
    <section className="seller_info_wpr customerexp_wpr">
        <div className="container">
            <div className="seller_info_outer">
                <div className="seller_info">
                    
                    <div className="seller_info_boldtext">Centralized Jd Seller app</div>
                    <div className="sellerbig_img customerexp_img">
                        <img className="web_img" src="https://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/monitor_img.png" />
                        <img className="mob_img" src="https://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/monitor_img_mob.png" />
                        <div className="video_container">
                            <video autoplay="autoplay" muted loop playsinline preload="metadata">
                                <source src="https://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/jdseller-video.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                    <div className="seller_info_medtext">End-to-end customer experience.</div>
                    <div className="ultemplwrp">
                        <ul className="ultempl">
                            <li className="litempl">
                                <div className="imgbxwrp">
                                    <div className="imgbx"> <img src="https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/eec-trackorder.svg" /></div>
                                </div>
                                <div className="contwrp pl-20">
                                    <div className="font22 color111 fw500 mb-10">Track Orders</div>
                                    <div className="font18 color111">Order tracking allows sellers to monitor online order/shipment along with realtime order status updates. </div>
                                </div>
                            </li>
                            <li className="litempl">
                                <div className="imgbxwrp">
                                    <div className="imgbx"> <img src="https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/eec-sales.svg" />
                                    </div>
                                </div>
                                <div className="contwrp pl-20">
                                    <div className="font22 color111 fw500 mb-10">Sales Growth</div>
                                    <div className="font18 color111">Analytics tools with charts to help sellers with everything from year-end reporting, to better supply chain decision-making.</div>
                                </div>
                            </li>
                            <li className="litempl">
                                <div className="imgbxwrp">
                                    <div className="imgbx"> <img src="https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/eec-payment.svg" /></div>
                                </div>
                                <div className="contwrp pl-20">
                                    <div className="font22 color111 fw500 mb-10">Manage Payments</div>
                                    <div className="font18 color111">Payment dashboard to provide overview of recent payments, settlements, refunds & invoices. </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="downloadselling_btn">
                        <button onClick={(e) => {
                            window.location.href = "https://play.google.com/apps/internaltest/4700765180625101423"}}>Download Seller App</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="seller_info_wpr shopFeeStruct" id="commission_structure">
        <div className="container">
            <div className="seller_info_outer">
                <div className="seller_info">
                    <div className="seller_info_boldtext">Sell on Jd shopping with as low as 2% Commission.</div>
                    <div className="sellerbig_img"></div>
                    <div className="font20 color111 fw500 mt-12">The lowest cost of doing business in the industry</div>
                    
                   <div className="ultemplwrp"> 
                        <ul className="ultempl"> 
                            <li className="litempl"> 
                                <div className="imgbxwrp">
                                    <div className="imgbx"> <img src="https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/sc-collectionfee.svg" /></div> 
                                </div>
                                <div className="contwrp pl-20">
                                    <div className="font22 color111 fw500 mb-10">Collection Fee </div> 
                                    <div className="font18 color111">Zero payment gateway charges </div>
                                </div>
                            </li>
                            <li className="litempl"> 
                                <div className="imgbxwrp">
                                    <div className="imgbx"> <img src="https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/sc-productfee.svg" /></div> 
                                </div>
                                <div className="contwrp pl-20">
                                    <div className="font22 color111 fw500 mb-10">Product Commission fee </div> 
                                    <div className="font18 color111">Slab wise lowest percentage of order item amount based on product category</div>
                                </div>
                            </li>
                            <li className="litempl"> 
                                <div className="imgbxwrp">
                                    <div className="imgbx"> <img src="https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/sc-shippingfee.svg" /></div> 
                                </div>
                                <div className="contwrp pl-20">
                                    <div className="font22 color111 fw500 mb-10">Shipping fee </div> 
                                    <div className="font18 color111">Free for order values less than &#8377; 300.
If order values more than &#8377;300, Flat &#8377;50 will be applied. </div>
                                </div>
                            </li>
                            <li className="litempl"> 
                                <div className="imgbxwrp">
                                    <div className="imgbx"> <img src="https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/sc-clossingfee.svg" /></div> 
                                </div>
                                <div className="contwrp pl-20">
                                    <div className="font22 color111 fw500 mb-10">Closing fee </div> 
                                    <div className="font18 color111">Slab wise lowest percentage based on order amount </div>
                                </div>
                            </li>

                        </ul>
                   </div>
                   {!loggedIn &&
                    <div className="startselling_btn">
                        <button onClick={()=>handleLogin()}>Know more</button>
                    </div>
                    }
                </div>
            </div>
        </div>
    </section>
    
    <section className="commonlinks_wpr dn">
        <div className="container">
            <div className="commonlinks_outer">
                <ul>
                    <li onClick={(e) => {
                        window.location.href = "https://business.justdial.com/seller-support"
                    }}>Seller Support <span className="rightarrow"></span></li>
                    {/* <li>FAQ <span className="rightarrow"></span></li> */}
                    {/* <li>Contact us <span className="rightarrow"></span></li> */}
                </ul>
            </div>
        </div>
    </section>
    <CommissionStructure />
    {!loggedIn &&
    <div className="register_wpr">
        <div className="container">
            <div className="register_outer">
                <div>
                    <div className="register_title">Start your Seller Journey</div>
                    <div className="register_text">Boost your business and increase your sales in the lucrative and growing Indian market.</div>
                </div>
                <div className="register_btn">
                    <button onClick={()=>handleLogin()}>Register Now</button>
                </div>
            </div>
        </div>
    </div>
    }

    <section className="footer_section">
    <div className="container_cms">
        <div className="footerbox">
            <div className="inner_footerbox">
                <div className="jdbusiness_img">Search for Anything . Anywhere</div>
                <div className="footer_row1">Search for Anything . Anywhere</div>
                <div className="footer_row2">India's No.1 Local Search Engine</div>
                <div className="footer_row3">
                    <a className="ctwrap" target="_blank" href="https://www.justdial.com/support"><i className="contactusicon cnfeediconBx" /> Contact Us</a>
                    <a className="ctwrap" onClick={() =>redirectUrls('feedback')}><i className="feedbackicon cnfeediconBx" /> Feedback</a>
                </div>
            </div>
            <div className="inner_footerbox">
                <div className="footer_row1">Connect With Us</div>
                <div className="socialicon_wrapper"> 
                <span className="socialicon_box facebookicon" onClick={() =>{
                    window.open("https://www.facebook.com/JustDial")
                }}/> 
                {/* <span className="socialicon_box twittericon" onClick={() => {
                    // window.open("https://www.youtube.com/user/justdialind")
                }}/>  */}
                <span className="socialicon_box instagramicon" onClick={() => {
                    window.open("https://www.instagram.com/jd_justdial")
                }}/> 
                <span className="socialicon_box youtubeicon" onClick={() => {
                    window.open("https://www.youtube.com/user/justdialind")
                }}/> 
            </div>
                <div className="copyright_wrapper"> Copyrights 2008-22. All Rights Reserved.
                    <br /> <a onClick={() =>redirectUrls('policy')}>Privacy</a> | <a target="_blank" href="https://www.justdial.com/Terms-of-Use">Terms</a> | <a onClick={() =>redirectUrls('infri')}>Infringement</a> </div>
            </div>
            <div className="inner_footerbox">
                <div className="footer_row1">Links</div>
                <ul className="linklist">
                    <li className="linklist_li"><a target="_blank" href="https://business.justdial.com/seller-support">Seller Help Center</a></li>
                    <li className="linklist_li"><a target="_blank" href="https://www.justdial.com/Terms-of-Use/JD-Shopping-return-policy">Returns</a></li>
                    <li className="linklist_li"><a target="_blank" href="https://business.justdial.com/seller-support/onboarding/onboarding-guide">How to Start Selling</a></li>
                    <li className="linklist_li"><a target="_blank" href={src_var==2?"https://business.justdial.com/seller-dashboard/terms_of_use/jd_shopping_terms_and_condition":"https://justdial.com/Terms-of-Use/JD-Shopping-Terms-and-Condition"}>Terms & Conditions</a></li>
                    <li className="linklist_li"><a target="_blank" href="https://business.justdial.com/seller-support/product/guide">Add Catalogue</a></li>
                    <li className="linklist_li" onClick={() =>redirectUrls('policy')}><a>Privacy Policy</a></li>



                </ul>
            </div>
        </div>
    </div>
</section>



            </>
}

        </>
    )
}

export default IntroPage;
