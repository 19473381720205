import React, { useState } from "react";
import Calendar from "react-calendar";

export const CalendarStore = (props) => {
  const sdate = props.data.sdate ? new Date(props.data.sdate) : "";
  const edate = props.data.edate ? new Date(props.data.edate) : "";
  const reason = props.data.reason ? props.data.reason : "";
  const index = props.index;
  const data = [sdate, edate];
  const [info, setCalendarData] = useState({ sdate, edate, reason });

  // select date and format it to MM-DD-YYYY
  const selectDates = (e) => {
    const sdate = e[0].toLocaleDateString("en-CA");
    const edate = e[1]
      ? e[1].toLocaleDateString("en-CA")
      : e[0].toLocaleDateString("en-CA");
    setCalendarData({ ...info, sdate, edate });
  };

  // add holiday reason
  const changeHlReason = (e) => {
    const reason = e.target.value || "";
    setCalendarData({ ...info, reason });
  };

  return (
    <div className="ob_popup_outer" >
      <div className="ob_popup_bottom seller_calendar_outer">
        <div className="popcontainer seller_calendar">
          <div className="font16 color1a1 fw700 mt-20 pl-20 pr-20 mb-30">
            {" "}
            Holidays{" "}
            <div
              className="closeBx popup_hdr_close"
              onClick={props.closeHoliday}
            >
              {" "}
              <i className="time_sob_closeicn"></i>
            </div>
          </div>

          <div className="dividerB"> </div>
          {/* <!-- calendar  --> */}
          <Calendar
            onChange={(e) => selectDates(e)}
            minDate={new Date()}
            defaultValue={data}
            selectRange={true}
            allowPartialRange={true}
            returnValue={"range"}
            tileClassName={"active"}
          />
          {/* <!-- calendar --> */}
          <div className="pl-20 pr-20">
            <div className="group mt-25 ">
              <input
                className="inputMaterial font16 fw600"
                type="text"
                required
                value={info.reason}
                onChange={(e) => changeHlReason(e)}
                id={"h_reason"}
              onClick={()=>{
                const idFocus = document.getElementById("h_reason");
           //     const ob_popup_bottom = document.getElementsByClassName("ob_popup_bottom")[0];
              setTimeout(() => {
                // if(ob_popup_bottom){
                //   ob_popup_bottom.style.paddingBottom = "100px"
                // }
                idFocus && idFocus.scrollIntoView(true);
              },300)
              }}
                maxLength="50"
              />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label className="label-wrap font16 color777"> Reason </label>
              {/* <span className="of_downarrow"></span> */}
            </div>
          </div>
        </div>
        <div className="ftr_btn">
          <button
            className="font14 fw600 colorfff"
            onClick={(e) => props.submit(info, index)}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default CalendarStore;
