import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideHelpAction } from "../../actions/actions";
import { useLocation } from "react-router-dom";

const HelpSec = () => {
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const func = (e) => {
      e.preventDefault();
      dispatch(hideHelpAction());
    };
    // if(isHideCategory){
    window.addEventListener("popstate", func);
    //  }
    //  window.removeEventListener('popstate', func);

    return () => {
      window.removeEventListener("popstate", func);
    };
  }, []);

  useEffect(() => {
    let pn = location.pathname.split("/")[2];
    setCurrentStep(pn);
  }, [location.pathname]);

  useEffect(() => {
    let scr = document.getElementById("helpWrapper");
    scr && scr.scrollIntoView(false);
  }, [0]);
  const openDiv = (e) => {
    if (e.currentTarget.classList.contains("actv")) {
      e.currentTarget.classList.remove("actv");
      e.currentTarget.nextSibling.classList.add("dn");
    } else {
      e.currentTarget.classList.add("actv");
      e.currentTarget.nextSibling.classList.remove("dn");
    }
  };

  return (
    <>
      <div className="help_wrapper">
        <header className="seller_header header_fixed">
          <div className="header_home">
            <div className="header_home_left">
              <div
                className="seller_headback_icon seller_icon"
                onClick={() => dispatch(hideHelpAction())}
              ></div>
            </div>

            <div className="header_home_center">
              <div className="home_center text_center">
                {/* <div className="seller_jdlogo_icon seller_icon"></div> */}
                <div className="header_home_text font16 fw600 color1A1">
                  Help
                </div>
              </div>
              {/* <div className="seller_invoice_icon seller_icon"></div> */}
            </div>
          </div>
        </header>
        <div className="ob_divider" id="helpWrapper" />
        <section className="pl-20 pr-20 pb-170 pt-15">
          {currentStep == "businessDet" && (
            <>
              <div className="help_block pt-15 pb-15">
                <div
                  className="font14 color111 fw500 pb-10  pr-15 help-qst"
                  onClick={(e) => openDiv(e)}
                >
                  Why I'm unable to upload logo?
                </div>
                <div className="font13 color111 dn">
                  Only jpg & png formats are allowed & the image size should not
                  be more than 6 MB.
                </div>
              </div>

              <div className="help_block pt-15 pb-15">
                <div
                  className="font14 color111 fw500 pb-10  pr-15 help-qst"
                  onClick={(e) => openDiv(e)}
                >
                  I deal in multiple products. Why I'm allowed to select only
                  one category?
                </div>
                <div className="font13 color111 dn">
                  Primary category is the main type in which you're doing your
                  business. Basically, it represents the type of product in
                  which your business is conducted. You can add more categories
                  later with catalouge.
                </div>
              </div>

              <div className="help_block pt-15 pb-15">
                <div
                  className="font14 color111 fw500 pb-10  pr-15 help-qst"
                  onClick={(e) => openDiv(e)}
                >
                  What is nature of business?
                </div>
                <div className="font13 color111 dn">
                  It refers to the dealing category of your business,
                  like Retailer, Wholesaler, Manufacturer or Exporter.
                </div>
              </div>

              <div className="help_block pt-15 pb-15">
                <div
                  className="font14 color111 fw500 pb-10  pr-15 help-qst "
                  onClick={(e) => openDiv(e)}
                >
                  Why I'm not able to see my other business for marketplace?
                </div>
                <div className="font13 color111 dn">
                  Business categories which are enabled for B2C selling, can
                  only be seen on marketplace.
                </div>
              </div>
            </>
          )}
          {currentStep == "contactDet" && (
            <>
              <div className="help_block pt-15 pb-15">
                <div
                  className="font14 color111 fw500 pb-10  pr-15 help-qst"
                  onClick={(e) => openDiv(e)}
                >
                  Why do I need to enter an alternate mobile number?
                </div>
                <div className="font13 color111 dn">
                  You can use this number for logging into the app.
                </div>
              </div>
            </>
          )}
          {currentStep == "storeTimeDet" && (
            <>
              <div className="help_block pt-15 pb-15">
                <div
                  className="font14 color111 fw500 pb-10  pr-15 help-qst"
                  onClick={(e) => openDiv(e)}
                >
                  Why do I need to add store timings?
                </div>
                <div className="font13 color111 dn">
                  Your store timings will help your customers to have better
                  clarity regarding business dealings, accordingly they can
                  approach you for orders.
                </div>
              </div>

              <div className="help_block pt-15 pb-15">
                <div
                  className="font14 color111 fw500 pb-10  pr-15 help-qst"
                  onClick={(e) => openDiv(e)}
                >
                  What is delivery timing?
                </div>
                <div className="font13 color111 dn">
                  The operational hours during which you're able to deliver the
                  order to your customer.
                </div>
              </div>

              <div className="help_block pt-15 pb-15">
                <div
                  className="font14 color111 fw500 pb-10  pr-15 help-qst"
                  onClick={(e) => openDiv(e)}
                >
                  What is vacation plan?
                </div>
                <div className="font13 color111 dn">
                  Those days on which the store remains closed, or in case
                  you're not operating your business for a while.This will help
                  your customers to be aware for your unavailability.
                </div>
              </div>
            </>
          )}
          {currentStep == "chooseplan" && (
            <>
              <div className="help_block pt-15 pb-15">
                <div
                  className="font14 color111 fw500 pb-10  pr-15 help-qst"
                  onClick={(e) => openDiv(e)}
                >
                  What is setup fee?
                </div>
                <div className="font13 color111 dn">
                  One time setup fee is charged for creating your digital shop
                  along with ready catalog for selling products on Justdial.
                </div>
              </div>
              <div className="help_block pt-15 pb-15">
                <div
                  className="font14 color111 fw500 pb-10  pr-15 help-qst"
                  onClick={(e) => openDiv(e)}
                >
                  Why is deposit fee needed?
                </div>
                <div className="font13 color111 dn">
                  Deposit fee works as a holding amount to ensure sellers are
                  providing top services to their customers. This amount will be
                  used as security amount against any kind of penalization for
                  not fulfilling the orders.
                </div>
              </div>
              <div className="help_block pt-15 pb-15">
                <div
                  className="font14 color111 fw500 pb-10  pr-15 help-qst"
                  onClick={(e) => openDiv(e)}
                >
                  Cases when the seller would be penalized:
                </div>
                <div className="font13 color111 dn">
                  <ol>
                    <li>
                      If seller accepts the order but does not fulfill same for
                      1st time or in recurring manner
                    </li>
                    <li>
                      If seller accepts and provides defected/damaged/duplicate
                      product for 1st or in recurring manner
                    </li>
                  </ol>
                </div>
              </div>
              <div className="help_block pt-15 pb-15">
                <div
                  className="font14 color111 fw500 pb-10  pr-15 help-qst"
                  onClick={(e) => openDiv(e)}
                >
                  Why do I need to pay GST on setup fee?
                </div>
                <div className="font13 color111 dn">
                  As per marketplace rule, payments done for using online /
                  ecommerce services 18% GST must be paid.
                </div>
              </div>{" "}
              <div className="help_block pt-15 pb-15">
                <div
                  className="font14 color111 fw500 pb-10  pr-15 help-qst"
                  onClick={(e) => openDiv(e)}
                >
                  What happens when deposit amount is debited and is consumed?
                </div>
                <div className="font13 color111 dn">
                  Once seller reaches minimum amount threshold of Rs. 5000 ,
                  seller will need to topup the amount in order to keep selling
                  on Justdial.
                </div>
              </div>
              <div className="help_block pt-15 pb-15">
                <div
                  className="font14 color111 fw500 pb-10  pr-15 help-qst"
                  onClick={(e) => openDiv(e)}
                >
                  Can I withdraw deposit amount?
                </div>
                <div className="font13 color111 dn">
                  Seller can anytime withdraw the amount from Jd business
                  portal. In case of debit remaining amount can be withdrawn to
                  the bank account added while onboarding. After withdrawal,
                  Seller's services will be stopped from Justdial. In order to
                  restart the services, seller needs to again make payment.
                </div>
              </div>
            </>
          )}
          <div className="help_block pt-15 pb-15">
            <div
              className="font14 color111 fw500 pb-10  pr-15 help-qst"
              onClick={(e) => openDiv(e)}
            >
              My query is not listed here. How can I reach support team?
            </div>
            <div className="font13 color111 dn">
              For any queries or support needed, you can call us at{" "}
              <a href="tel:8888888888">88888 88888</a> or write us at{" "}
              <a href="mailto:support@justdial.com">support@justdial.com.</a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default HelpSec;
