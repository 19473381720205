import React, { useEffect, useState } from "react";

export default function Alert(props) {
  const [msg, handleMsg] = useState(props.msg);
  const [showalert, handleAlert] = useState(props.show);
  const [rbtnText, setrbtnText] = useState(props.rbtnText);
  const [lbtnText, setlbtnText] = useState(props.lbtnText);

  useEffect(() => {
    handleMsg(props.msg);
    handleAlert(props.show);
    setrbtnText(props.rbtnText);
    setlbtnText(props.lbtnText);
  }, [props.msg, props.show, props.rbtnText, props.lbtnText]);
  return (
    <>
      {/* pop up alert*/}
      <div
        className="detail_popup_wpr"
        style={
          showalert
            ? {
                display: "block",
              }
            : {
                display: "none",
              }
        }
      >
        <div className="detail_popup_outer paymentplan_btn p-30 ">
          <div className="font14 text_center color414">{msg}</div>
          <div
            className={
              "detail_popup_btn mt-30 text_center " +
              (props.lbtn == !props.rbtn ? "jcc" : "")
            }
          >
            {props.lbtn && (
              <button
                className={
                  "font16 colorfff lbtn " +
                  (props.lbtn && props.rbtn ? "rlbtn" : "")
                }
                onClick={() => {
                  handleAlert(false);
                  props.lbtnAction();
                }}
              >
                {lbtnText}
              </button>
            )}
            {props.rbtn && (
              <button
                className={
                  "font16 colorfff " + (props.lbtn && props.rbtn ? "rlbtn" : "")
                }
                onClick={() => {
                  handleAlert(false);
                  props.rbtnAction();
                }}
              >
                {rbtnText}
              </button>
            )}
          </div>
        </div>
      </div>
      {/* pop up alert*/}
    </>
  );
}
