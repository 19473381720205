export const checkForLive = () => {
    if (
      window.location.host.includes("www") ||
      window.location.host.includes("business") ||
      window.location.host.includes("win") ||
      window.location.host.includes("wap")
    ) {
      return true;
    } else {
      return false;
    }
  };

export const checkForStaging = () => {
    if(window.location.host.includes("staging")){
        return true;
    }else{
        return true; //for test
    }
}

export const URI = process.env.REACT_APP_URL_DEV_TEST_PROD_HOST;

