import React, {useState} from "react";
import CommissionStructureCompsIn from "./commissionStructureCompsIn";
import {getCommissionStrLvl1} from "../../services/common";

const CommissionStructureComps = ({title, cid}) => {

    const [act, setAct] = useState(false);
    const [childCommissions, setChildCommissions] = useState([]);
    const handleClick = () => {
        if(!act){
            getCommissionStrLvl1(cid).then((res)=>{
                console.log(res);
                if(res.data && res.data.data && res.data.data.catIDList){
                    setChildCommissions(res.data.data.catIDList);
                    setAct(true);
                }
            })
        }else{
            setAct(false);
        }
    }
    return (
    <div className={`comisn_according_outer ${act?"act":""}`}>
    <div className="comisn_according_title" onClick={handleClick}>{title}<span className="downarrow"></span></div>
    {/*Call Inner */}
    {
        act &&   
        <div className="comisn_according_content">
        {childCommissions && childCommissions.map((row)=>{

            if(row.data.length>0){
                return (
                    <CommissionStructureCompsIn key={row.cid} title={row.cnamed} data={row.data}/>
                )
            }else{
                let ret = {};
                let rangeArr = [[0, 300], [301, 500], [501, 1000], [1001,5000], [5000]];
                if(row.commission_info && row.commission_info.length>0){
                    ret[row["cnamed"]] = [];
                    row.commission_info.forEach((r)=>{
                        const lb = r.lb;//0
                        const ub = r.ub;//3000 
                        rangeArr.forEach(([ll, ul],i)=>{
                            if(ll>=lb && ul<=ub){
                                if(ret[row["cnamed"]]){
                                                ret[row["cnamed"]][i]=r.value;
                                            }else{
                                                ret[row["cnamed"]]={
                                                    [i]:r.value
                                                };
                                            }
                            }
                            if(ll>=lb && ub=="INF"){
                                ret[row["cnamed"]][i]=r.value;
                            }
                            if(ub=="INF" && i==4){
                                ret[row["cnamed"]][i]=r.value;
                            }
                        })
                    })
                }
                return (
                    <CommissionStructureCompsIn key={row.cid} title={row.cnamed} comInfoNoPar={ret}/>
                )
            }
        }
        )}
        </div>
    }
    </div>
    )
}

export default CommissionStructureComps;
