import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import {
  saveLocationDetAction,
  showToastAction,
  hideToastAction,
  hideLoaderAction,
  setDocIdAction
} from "../../actions/actions";
import MapComponent from "../common/MapComponent";

import {
  UpdateDocId,
  SaveBusinessDetails,
  getLocationDetails,
  getLocationDetailsFromArea,
  getURIParams,
  getCurrentURIParams,
  getCitySuggestions,
  getBuildingSuggestions,
  generateUIID,
  getBuildingAddress,
  getCitySynList,
  checkValidCity,
  CreateDocId,
  getGetRedirectAPI,
  imageUploadToUpdateDoc,
  getEditListCatAPI,
  getEditListCatShortURI
} from "../../services/common";
import {openViewExit, openViewExitDeviceBack} from "../common/openViewExit";

// shows category list

function StoreAreaList(props) {
  const data = props.data;
  return (
    <ul>
      {data.map((item, index) => (
        <li key={index} onClick={(e) => props.setArea(item)}>
          {item.areaname_display}, {item.city} - {item.pincode}
        </li>
      ))}
    </ul>
  );
}

function StoreCityList(props) {
  const data = props.data;
  console.log(data);
  return (
    <ul>
      {data && data.map((item, index) => (
        <li key={index} onClick={(e) => props.setCity(item)}>
          {item.ct_name}
        </li>
      ))}
    </ul>
  );
}

function BuildingAreaList(props) {
  const data = props.data;
  console.log(data);
  return (
    <ul>
      {data && data.map((item, index) => (
        <li key={index} onClick={(e) => props.setBuilding(item)}>
          {item.text}
        </li>
      ))}
    </ul>
  );
} 

const LocationDet = () => {
  const mapResetFlag = useRef(false);
  const inputEl = useRef(null);

  const [info, setInfo] = useState({
    legalCompanyName: false,
  });

  const locationDetails = useSelector((state) => {
    return state.locationDetails;
  });

   if(typeof window != "undefined"){
    window.appHandler = appAndroidHandler.bind(this);
}
function appAndroidHandler(logjson){
  let r = getCurrentURIParams();
  if(r.source==51 || r.source==53){
  if(logjson && logjson != "undefined" && logjson != ""){
      var logresponse = JSON.parse(logjson);
      if(logresponse['eventType'] && logresponse['eventType'] == "deviceback"){
       openViewExitDeviceBack(r);
      }
      console.log("LOCATION TEST - App Handler  - event response - ");
      console.log(logresponse);
      if(logresponse['eventType'] && logresponse['eventType'] == "location"){
        if(!inputEl.current || !inputEl.current.value || mapResetFlag.current){
          if(mapResetFlag.current){
            mapResetFlag.current = false;
          }
          handleSetLatLong({
            lat: logresponse.lat,
            lng: logresponse.lon
          }, true) 
          }
      }
    }
}
}

  useEffect(() => {
    let r = getCurrentURIParams();
    if(r.source==14){
      setCityTempValue(locationDetails.city);
      setAreaTempValue(locationDetails.storeArea);
      setBuildingTempValue(locationDetails.buildingName);
      setValObj(locationDetails);
      console.log("CURRRRRRRRRRRRRRRRRRRRBUI USE EFFECT ");
      console.log(locationDetails);
    }else{
      if(!locationDetails.latitude || !locationDetails.buildingName){ 
      }else{
      setCityTempValue(locationDetails.city);
      setAreaTempValue(locationDetails.storeArea);
      setBuildingTempValue(locationDetails.buildingName);
      setValObj(locationDetails);
      console.log("CURRRRRRRRRRRRRRRRRRRRBUI USE EFFECT ");
      console.log(locationDetails);
      }
    }
  }, [locationDetails]);
  const dispatch = useDispatch();
  const [valObj, setValObj] = useState(locationDetails);
  const [isValidPincode, setisValidPincode] = useState(false);
  const [isBSLInValid, setisBSLInValid] = useState(false);
  const  [showButtonLoader, setShowButtonLoader] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const compData = useSelector((state) => {
    return state;
  });
  // save & move ahead
  const [saveFlag, setSaveFlag] = useState(false);

  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);
  const [showCityValidPopup, setShowCityValidPopup] = useState();
  const [selectedAlternateCity, setSelectedAlternateCity] = useState();
  
  const {
    loadedPreFilledData, isPaidContract
  } = useSelector((state) => state.locationReducer);
  useEffect(() => {
    if (saveFlag == true) {
      if (!loadedPreFilledData && (compData && !compData.loadDocData || !compData.loadDocData.docid)) {
        setShowButtonLoader(true);
        dispatch(showToastAction("Please wait, while we add your business."));
        SaveBusinessDetails(compData, location.pathname, "contactDet")
          .then((res) => {
            CreateDocId(compData.locationDetails.city)
              .then((res) => {
                setShowButtonLoader(false);
                if (res.data && res.data) {
                  let docid = res.data.docid;
                  console.log(res);
                  SaveBusinessDetails(
                    { ...compData, loadDocData: { docid: docid } },
                    location.pathname,
                    "contactDet",
                    docid
                  ).then((res) => {
                    console.log("Updating business doc Id");
                    let currentUriParams = getCurrentURIParams();
                    const successDet = () => {
                    if(currentUriParams["input"]=="account_settings"){
                      if(currentUriParams["source"]==51 || currentUriParams["source"]==53){
                          openViewExit(currentUriParams["source"]);
                        setShowButtonLoader(false);
                        return false;
                      }else{
                      window.location.href = decodeURIComponent(currentUriParams["backUrl"])?decodeURIComponent(currentUriParams["backUrl"]):"https://business.justdial.com/seller-dashboard/accountsetting";
                      setShowButtonLoader(false);
                    }
                   }else{
                    let uriParams = getURIParams(docid);
                    if(currentUriParams["source"]==14){
                      if(isPaidContract){
                        dispatch(hideToastAction());
                        navigate(`/businessDetails/contactDet?${uriParams}`);
                      }else{
                        window.location.href = getEditListCatShortURI(
                          valObj.city,
                          compData.businessDetails.storeName,
                          docid
                        );
                      }
                    }else{
                      if(isPaidContract){
                        dispatch(hideToastAction());
                        navigate(`/businessDetails/contactDet?${uriParams}`);
                      }else{
                         window.location.href = getEditListCatAPI(docid, valObj.city);
                      }
                    }
                      setShowButtonLoader(false);
                    }
                  }
                  if(currentUriParams["source"]!=14){
                    UpdateDocId(docid).then((res) => {
                      successDet();
                    });
                  }else{
                    successDet();
                  }
                  });
                  dispatch(setDocIdAction(docid));

                  //Upload image to main
                  imageUploadToUpdateDoc(docid).then((res)=>{
                    console.log("Image upload successful");
                  }).catch((e)=>{
                    console.log("Image upload failed");
                  })

                  // navigate(`/businessDetails/pickupDet?${compData.locationReducer.urlParam}`);
                }
              })
              .catch((e) => {
                setSaveFlag(false);
                console.log("Create doc Error");
                console.log(e);
                dispatch(showToastAction("Error Creating document."));
                setTimeout(function () {
                  dispatch(hideToastAction());
                }, 3000);
                setShowButtonLoader(false);
              });
          })
          .catch((er) => {
            setSaveFlag(false);
            console.log(er);
            dispatch(showToastAction("Error Creating document."));
                setTimeout(function () {
                  dispatch(hideToastAction());
                }, 3000);
            setShowButtonLoader(false);
          });
      } else {
        setShowButtonLoader(true);
        SaveBusinessDetails(compData, location.pathname, "contactDet")
          .then((res) => {
            let currentUriParams = getCurrentURIParams();
            const redirect = () => {
                setShowButtonLoader(false);
                if(currentUriParams["input"]=="account_settings"){
                    if(currentUriParams["source"]==51 || currentUriParams["source"]==53){
                          openViewExit(currentUriParams["source"]);
                      return false;
                    }else{
                  window.location.href = decodeURIComponent(currentUriParams["backUrl"])?decodeURIComponent(currentUriParams["backUrl"]):"https://business.justdial.com/seller-dashboard/accountsetting";
                    }
                } else {
                  let uriParams = getURIParams(compData.loadDocData.docid);
                  if(currentUriParams["source"]==14){
                    if(isPaidContract){
                      dispatch(hideToastAction());
                      navigate(`/businessDetails/contactDet?${uriParams}`);
                    }else{
                    window.location.href = getEditListCatShortURI(
                      valObj.city,
                      compData.businessDetails.storeName,
                      compData.loadDocData.docid
                    );
                    }
                  }else{
                    if(isPaidContract){
                      dispatch(hideToastAction());
                      navigate(`/businessDetails/contactDet?${uriParams}`);
                    }else{
                    window.location.href = getEditListCatAPI(
                      compData.loadDocData.docid, valObj.city
                    );
                    }
                  }
                }
                //    navigate(`/businessDetails/pickupDet?${compData.locationReducer.urlParam}`);
            }
            if(currentUriParams["source"] != 14){
              UpdateDocId(compData.loadDocData.docid).then((res) => {
                redirect();
              });

            } else{
              redirect();
            }
            //Upload image to main
          })
          .catch((e) => {
            dispatch(showToastAction("Error Creating document."));
                setTimeout(function () {
                  dispatch(hideToastAction());
                }, 3000);
            setSaveFlag(false);
            console.log(e);
            setShowButtonLoader(false);
          });
      }
    }
  }, [saveFlag]);

  const locationPermissionCB = (resp) => {
    let r = getCurrentURIParams();
    try{
      console.log(resp);
      let respo = JSON.parse(resp);
      console.log(respo);
      if(!inputEl.current || !inputEl.current.value){
      handleSetLatLong({
        lat: resp.lat,
        lng: resp.long
      }, true) 
      }
    }catch(e){
      console.log("Location permission error");
      console.log(e);
    }
  }

  useEffect(async ()=>{
    if(!valObj.latitude || !valObj.longitude){
      let r = getCurrentURIParams();
      if(r.source==51 || r.source==53){
        try{
          if(r.source==51){
            var logjson = JSON.stringify({ action: "location", fn: "locationPermissionCB" });
            window.JdLiteInterface.verticalHandler(logjson);
          }else{
            console.log("LOCATION TEST - Initial Load - action: locationPermission");
            var logjson = JSON.stringify({ action: "locationPermission"});
            window.webkit.messageHandlers.callbackHandler.postMessage(logjson);
          }      
        }catch(e){
          console.log("Location permission vertical handler");
          console.log(e);
        }
      }
      console.log("LOCATION TEST - Initial Load - outside calling navigator", r.source);
        if(r.source!=53){
          console.log("LOCATION TEST - Initial Load - inside calling navigator", r.source);
          setTimeout(()=>{
          navigator.geolocation.getCurrentPosition((position)=>{
              console.log("CURRRRRENT LOGFGFFF***************", inputEl.current.value);
            if(!inputEl.current || !inputEl.current.value){
              handleSetLatLong({
                lat: position.coords.latitude,
                lng: position.coords.longitude
              }, true) 
              }
            }, showNavError);
          },400)
        }
      } 
   },[0])

  const [isEmptyFieldErr, setisEmptyFieldErr] = useState({
    city: false,
    storeArea: false,
    buildingName: false
  });
   useEffect(()=>{
    if(isEmptyFieldErr.buildingName){
      let errSpan = document.getElementById("buildingErrorSpan");
      errSpan && errSpan.scrollIntoView({behavior: "smooth", block: "center"});
    }
    if(isEmptyFieldErr.storeArea){
      let errSpan = document.getElementById("storeAreaErrorSpan");
      errSpan && errSpan.scrollIntoView();
    }
    if(isEmptyFieldErr.city){
      let errSpan = document.getElementById("cityErrorSpan");
      errSpan && errSpan.scrollIntoView(false);
    }
  },[isBSLInValid, isEmptyFieldErr]);
  const handleSave = (isAlternate, selectedAlternateCityA) => {
    let valObjClone = Object.entries({...valObj})
    .reduce((acc, elem) =>{
      if(elem[1])
     acc[elem[0]] = String(elem[1]).trim()
     return acc
    }, {});
    if(!Boolean(valObjClone["city"])){
      setisEmptyFieldErr({
        city: true
      });
      return false;
    }
    if(!Boolean(valObjClone["buildingName"])){
      setisEmptyFieldErr({
        buildingName: true
      });
      return false;
    }
    const checkFlag = [
      "latitude",
      "longitude",
      "city",
      "state",
    ].every((item) => Boolean(valObjClone[item]));
    if (checkFlag) {
      if(isAlternate){
        setValObj({...valObjClone, city: selectedAlternateCityA});
        dispatch(saveLocationDetAction({...valObjClone, city: selectedAlternateCityA}));
      }else{
        setValObj(valObjClone);
        dispatch(saveLocationDetAction(valObjClone));
      }
      setSaveFlag(true);     
    } else {
      dispatch(showToastAction("Please fill all mandatory fields."));
      setTimeout(function () {
        dispatch(hideToastAction());
      }, 3000);
    }
  };

  const handleChange = (e) => {
    setValObj({ ...valObj, [e.target.name]: e.target.value });
    if (e.target.name === "pincode") {
      setisValidPincode(false);
    }
    setisBSLInValid(false);
    setisBSLInValid(false)
  };
  const [defaultLL, setDefaultLL] = useState({
    latitude:"",
    longitude: ""
  });
  const [apiCallCityVal, setApiCallCityVal] = useState(valObj.city);
  const [showCityDropdown, setShowCityDropdown] = useState(false);

  const handleChangeCity = (e) => {
    setisEmptyFieldErr({
      city: false
    });
    setCityChanging(true);
    let arVal = e.target.value;
    setCityTempValue(arVal);
    let currentTimer = setTimeout(() => {
      ApiCallForCity(arVal);
    }, 1000);
    if (apiCallCityVal) {
      clearTimeout(apiCallCityVal);
    }
    setApiCallCityVal(currentTimer);
    setisValidPincode(false);
    setDefaultLL({
      latitude: valObj.latitude || defaultLL.latitude,
      longitude: valObj.longitude || defaultLL.longitude
    })
  }
  const [changeAreaVal, setChangeAreaVal] = useState(valObj.storeArea);
  const [showAreaDropdown, setShowAreaDropdown] = useState(false);
  const [showBuildingDropdown, setShowBuildingDropdown] = useState(false);

  const [areaList, setAreaList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [buildingList, setBuildingList] = useState([]);

  const [cityObj, setCityObj] = useState({});
  const [citySynList, setCitySynList] = useState([]);
  useEffect(()=>{
    getCitySynList().then(res=>setCitySynList(res.data));
  },[0])
  const ApiCallForLocation = (val) => {
    getLocationDetailsFromArea(val, valObj.pincode, valObj.city, cityObj.data_city)
      .then((res) => {
        if(res.data.result){
          if(res.data.result.areaname && res.data.result.areaname.length>0){
            setShowAreaDropdown(true);
            setAreaList(res.data.result.areaname);
          }
        }else{
          setShowAreaDropdown(false);
          setAreaList([]);
        }
      })
      .catch((e) => {
        console.log("Check area error");
      });
  };
  const ApiCallForCity = (val) => {
    getCitySuggestions(val.trim())
    .then((res) => {
      if(res.data.result && res.data.result.length>0){
        setShowCityDropdown(true);
        setCityList(res.data.result);
      }
    })
    .catch((e) => {
      console.log("Check area error");
    });
  }
  const ApiCallForBuilding = (val) => {
    getBuildingSuggestions(val.trim(), generateUIID())
    .then((res) => {
      if(res.data && res.data.data && res.data.data.length>0){
        setShowBuildingDropdown(true);
        setBuildingList(res.data.data);
      }else{
        setBuildingChanging(false);    
        setShowBuildingDropdown(false);
      }
    })
    .catch((e) => {
      console.log("Check area error");
    });
  }
  const [apiCallVal, setApiCallVal] = useState();
  const [areaChanging, setAreaChanging] = useState(false);
  const [cityChanging, setCityChanging] = useState(false);
  const [buildingChanging, setBuildingChanging] = useState(false);
  const [cityTempValue, setCityTempValue] = useState();
  const [buildingTempValue, setBuildingTempValue] = useState();
  const [areaTempValue, setAreaTempValue] = useState();
  useEffect(()=>{
    console.log("Changing city");
    if(cityTempValue && cityTempValue!==valObj.city && !showCityDropdown){
      setisEmptyFieldErr({...isEmptyFieldErr, city: true});
    }else{
      setisEmptyFieldErr({...isEmptyFieldErr, city: false});
    }
  },[cityTempValue]);

  useEffect(()=>{
    console.log("Changing area");
    if(areaTempValue && areaTempValue!==valObj.storeArea && !showAreaDropdown){
      setisEmptyFieldErr({...isEmptyFieldErr, storeArea: true});
    }else{
      setisEmptyFieldErr({...isEmptyFieldErr, storeArea: false});
    }
  },[areaTempValue, showAreaDropdown]);

  const handleChangeBuilding = (e) => {
    let arVal = e.target.value;
    setBuildingTempValue(arVal);
    setBuildingChanging(true);    
    if(arVal){
      let currentTimer = setTimeout(() => {
        ApiCallForBuilding(arVal);
      }, 1000);
      if (apiCallVal) {
        clearTimeout(apiCallVal);
      }
      setApiCallVal(currentTimer);
      setisValidPincode(false);
    }else{
      setBuildingList([]);
    }
  };

  const handleChangeArea = (e) => {
    let arVal = e.target.value;
    setAreaTempValue(arVal);
    setAreaChanging(true);
    setisEmptyFieldErr({
      storeArea: false
    });
    let currentTimer = setTimeout(() => {
      ApiCallForLocation(arVal);
    }, 1000);
    if (apiCallVal) {
      clearTimeout(apiCallVal);
    }
    setApiCallVal(currentTimer);
    setisValidPincode(false);
  };

  const handleBlur = (e) => {
    setValObj({ ...valObj, [e.target.name]: "" });
    setTimeout(() => {
      setShowAreaDropdown(false);
    }, 400);
  };
  const handleCloseDropdown = (name) => {
    if(name=="city" && cityTempValue!==valObj.city){
      setValObj({ ...valObj, [name]: "" });
      setCityTempValue("");
    }
    if(name=="storeArea" && cityTempValue!==valObj.storeArea){
      setValObj({ ...valObj, [name]: "" });
      setAreaTempValue("");
    }
    if(name=="buildingName" && buildingTempValue!==valObj.buildingName){
      setValObj({ ...valObj, [name]: "" });
      setBuildingTempValue("");
    }
      setShowBuildingDropdown(false);
      setShowAreaDropdown(false);
      setShowCityDropdown(false);
  };
  const handleSetCity = (obj) => {
    setShowCityDropdown(false);
    setCityChanging(false);
    setCityObj(obj);
    setCityTempValue(obj.ct_name);
    setAreaTempValue("");
    setValObj({
      latitude: "",
      longitude: "",
      buildingName: "",
      streetName: "",
      landmark: "",
      pincode: "",
      state: "",
      storeArea: "",
      city: obj.ct_name}); //Reset all values when city is changed 
  }
  const handleSetArea = (obj) => {
    setShowAreaDropdown(false);
    setAreaChanging(false);
    setAreaTempValue(obj.areaname_display);
    setValObj({
      ...valObj,
      storeArea: obj.areaname_display
    });
  };
  
  const setBuldingCheckCity = (obj, data, dCity) => {
    let sameCity = false;
      let synCity = citySynList[valObj.city];
      let synKey = valObj.city;
      if(synCity){
        if(dCity==synCity || dCity==synKey){
          sameCity=true;
        }
      }else{
        Object.keys(citySynList).forEach((k)=>{
          if(citySynList[k]==valObj.city){
            if(dCity==citySynList[k] || dCity==k){
              sameCity=true;
            }
          }
        })
      }
      if(valObj.city.toLowerCase()==dCity.toLowerCase()){
        sameCity=true;
      }
      if(!valObj.city){
        sameCity=true;
      }
      if(!paramsFrom.docid || paramsFrom.docid=="undefined"){
        sameCity=true;
       }
        if(sameCity)
      {
        setShowBuildingDropdown(false);
    setBuildingChanging(false);
    let buildingName = getFormatBuilding(data);
    //  let buildingName = obj.text && obj.text.split(",")[0];
      setValObj({
        buildingName: buildingName,
        streetName: data.street,
        storeArea: data.area,
        latitude: data.lat,
        longitude: data.lng,
        pincode: data.pin,
        city: dCity,
        state: data.state,
      });
      setAreaTempValue(data.area);
      setBuildingTempValue(buildingName); 
      setCityTempValue(dCity);
      }else{
        setShowBuildingDropdown(false);
    setBuildingChanging(false);
        dispatch(showToastAction("Cannot change the city."));
        setTimeout(function () {
          dispatch(hideToastAction());
        }, 3000);
            setValObj({...valObj});  
      }
  }
  const handleSetBuilding = async (obj) => {
    getBuildingAddress(obj.place_id, generateUIID()).then((res)=>{
      let data = res.data && res.data.result;
      let dCity = data.city;
      if(isPaidContract){
        if(data.pin!=valObj.pincode){
          dispatch(showToastAction("Cannot select a different pincode in a paid contract."));
          setTimeout(function () {
            dispatch(hideToastAction());
          }, 3000);
        }else{
          if(!dCity){
            checkValidCity(valObj.city, data.pin).then((res)=>{
              dCity = res.data.result.city;
              setBuldingCheckCity(obj, data, dCity);
            });
          }else{
            setBuldingCheckCity(obj, data, dCity);
          }
        }
      }else{
        if(!dCity){
          checkValidCity(valObj.city, data.pin).then((res)=>{
            dCity = res.data.result.city;
            setBuldingCheckCity(obj, data, dCity);
          });
        }else{
          setBuldingCheckCity(obj, data, dCity);
        }
      }
    })
  };

  let latLong = { latitude: valObj.latitude, longitude: valObj.longitude };
  const getFormatBuilding = (data, bNameChange) => {
    let bdAdd = data.formatted_address;
    bdAdd = bdAdd && bdAdd.split(",");
    if(bdAdd && bdAdd.length>3){
      bdAdd = bdAdd.slice(0,-3);
    }else if(bdAdd && !bNameChange){
      bdAdd = bdAdd.slice(0,-2);
    }
    let buildingName = bdAdd && bdAdd.length>0?bdAdd.join(","):"";
    return buildingName;
  }
  const handleSetLatLong = (obj, initialLoad) => {
    console.log("Drag end+++++++++++++++++++>", obj);
    latLong = { latitude: obj.lat, longitude: obj.lng };
    getLocationDetails({ lat: obj.lat, long: obj.lng }).then((res) => {
      let data = res.data && res.data.result;
      if (res.data) {
        let paramsFrom = getCurrentURIParams(); 
      if(paramsFrom.docid && paramsFrom.docid!=="undefined"){
        if(isPaidContract){
          if(data.pin==valObj.pincode){
            let bName = getFormatBuilding(data);
            setValObj({
              storeArea: data.area,
              pincode: data.pin,
              city: data.city,
              state: data.state,
              latitude: obj.lat,
              longitude: obj.lng,
              streetName: data.street,
              buildingName: bName,
              landmark: ""
            });
            setBuildingTempValue(bName);
            setAreaTempValue(data.area);
          }else{
            dispatch(showToastAction("Cannot select a different pincode location in a paid contract."));
            setTimeout(function () {
              dispatch(hideToastAction());
            }, 3000);
                setValObj({...valObj});
          }
        }else{
          if(valObj.city==data.city_syn || valObj.city==data.city){
            let bName = getFormatBuilding(data);
            setValObj({
              storeArea: data.area,
              pincode: data.pin,
              city: data.city,
              state: data.state,
              latitude: obj.lat,
              longitude: obj.lng,
              streetName: data.street,
              buildingName: bName,
              landmark: ""
            });
            setBuildingTempValue(bName);
            setAreaTempValue(data.area);
          }else if(!initialLoad){
        dispatch(showToastAction("Cannot select a different city."));
        setTimeout(function () {
          dispatch(hideToastAction());
        }, 3000);
            setValObj({...valObj});
          }
        }
      }else{
        let bName2 = getFormatBuilding(data, true);
        setValObj({
          storeArea: data.area,
          pincode: data.pin,
          city: data.city,
          state: data.state,
          latitude: obj.lat,
          longitude: obj.lng,
          streetName: data.street,
          buildingName: bName2,
          landmark: ""
        });
        setBuildingTempValue(bName2);
        setCityTempValue(data.city);
        setAreaTempValue(data.area);
      }
      }
    });
  };

  const showNavError = (error) => {
    let err;
    switch(error.code) {
      case error.PERMISSION_DENIED:
        err= "User denied the request for Geolocation."
        break;
      case error.POSITION_UNAVAILABLE:
        err= "Location information is unavailable."
        break;
      case error.TIMEOUT:
        err= "The request to get user location timed out."
        break;
      case error.UNKNOWN_ERROR:
        err= "An unknown error occurred."
        break;
    }
    dispatch(showToastAction(err));
      setTimeout(function () {
        dispatch(hideToastAction());
      }, 3000);
  }

  const handleResetMap = () => {
    let r = getCurrentURIParams();
    if(r.source==51 || r.source==53){
      try{
        if(r.source==51){
          var logjson = JSON.stringify({ action: "location", fn: "locationPermissionCB" });
          window.JdLiteInterface.verticalHandler(logjson);
        }else{
          console.log("LOCATION TEST - Reset map on Click - action: locationPermission");
          mapResetFlag.current = true;
          var logjson = JSON.stringify({ action: "locationPermission"});
          window.webkit.messageHandlers.callbackHandler.postMessage(logjson);
        }      
      }catch(e){
        console.log("Location permission vertical handler");
        console.log(e);
      }
    }
    console.log("LOCATION TEST - Reset map on Click - outside calling navigator", r.source);
    if(r.source!=53){
      console.log("LOCATION TEST - Reset map on Click - inside calling navigator", r.source);
      navigator.geolocation.getCurrentPosition((position)=>{
      handleSetLatLong({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }, true) 
       },
       showNavError)
      }
  }
  const handleSaveCheck = () => {   
     checkValidCity(valObj.city, valObj.pincode).then((res)=>{
        let cityD = res.data.result.city;
        let citySyn = citySynList[cityD];
        if(!citySyn){
         citySyn = Object.keys(citySynList).find(key => citySynList[key] === cityD);
        }
        if(cityD!==valObj.city && citySyn!==valObj.city){
          setSelectedAlternateCity(cityD);
      //    setShowCityValidPopup(true);
      dispatch(showToastAction(`${valObj.city} comes under city ${cityD}, we are modifying your location accordingly.`));
      handleSaveConfirmCityChange(cityD);
      setTimeout(function () {
        dispatch(hideToastAction());
      }, 3000);
        }else{
          handleSave();
        }
    }).catch((e)=>{
        console.log(e);
     })
  }

  const handleSaveConfirmCityChange = (cityD) => {
    setValObj({...valObj, city: cityD});
    setCityTempValue(cityD);
    handleSave(true, cityD);
  }
  useEffect(()=>{
    //To check if all mandatory fields are filled and enable submit button
    console.log("Curren check check check", valObj);
    let dis = false;
    if(cityChanging){
      dis=true;
    }else if(!valObj["city"] || valObj["city"]==""){
      dis = true;
    }
    if(buildingChanging){
      dis=true;
    }else if(!valObj["buildingName"] || valObj["buildingName"]==""){
      dis = true;
    }
    if(inputEl.current.value==valObj["buildingName"]){
      setDisabledSubmitButton(dis);
    }
  },[valObj, buildingTempValue, cityTempValue, areaTempValue])

  let paramsFrom = getCurrentURIParams(); 
  let showBuildingSelErr = valObj.buildingName && (!showBuildingDropdown && valObj.buildingName!==buildingTempValue && !buildingChanging) ;

  let disabledBtn = !valObj["buildingName"] || valObj.buildingName!==buildingTempValue || showBuildingSelErr;
  console.log(showBuildingSelErr);
  return (
    <>
      <section class="pl-20 pr-20 pb-70">
        <div class="lctn_map mt-30">
          {
            <MapComponent
              lat={valObj.latitude || defaultLL.latitude}
              long={valObj.longitude || defaultLL.longitude}
              setLatLong={handleSetLatLong}
              handleResetMap={handleResetMap}
            //  isPaidContract={isPaidContract}
            />
          }
        </div>
        <div class="mt-10 font11 color777 text_center">
          Adjust pin as per store location{" "}
        </div>

        <div class="seller_ob_form mt-30">
        <div class="group mb-20 common_list">
            <input
              class={"inputMaterial font16 fw600"/*+ (isPaidContract? " graytext":"")*/}
              type="text"
              required
              name="buildingName"
              value={buildingTempValue}
              ref={inputEl}
              onChange={(e) => handleChangeBuilding(e)}
              autoComplete="off"       
              id={"buildingName"}
              onClick={()=>{
                const idFocus = document.getElementById("buildingName");
                setTimeout(()=>{
                idFocus && idFocus.scrollIntoView({behavior: "smooth", block: "center"});
              },300);
              }}
             // disabled={isPaidContract}
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="label-wrap font16"> Store Location * </label>  
            <span
              className={showBuildingDropdown?"of_downarrow uparrow":""}
              onClick={()=>handleCloseDropdown("buildingName")}
            ></span>
            {showBuildingDropdown && buildingTempValue && (
              <BuildingAreaList data={buildingList} setBuilding={handleSetBuilding} />
            )}
            { showBuildingSelErr &&  (
              <span class="errorcls font12 pt-5" id="buildingErrorSpan">
               Please enter valid building name before proceeding
              </span>
            )}
          </div>
          <div class="group">
            <input
              class="inputMaterial font16 fw600"
              type="text"
              required
              name="streetName"
              value={valObj.streetName}
              onChange={(e) => handleChange(e)}
              id={"streetName"}
              onClick={()=>{
                const idFocus = document.getElementById("streetName")
                setTimeout(()=>{
                  idFocus && idFocus.scrollIntoView({behavior: "smooth", block: "center"});
                },400);
              }}
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="label-wrap font16"> Shop No, Building Name / Street Name </label>
          </div>
          <div class="group">
            <input
              class="inputMaterial font16 fw600"
              type="text"
              required
              name="landmark"
              value={valObj.landmark}
              onChange={(e) => handleChange(e)}             
              id={"landmark"}
              onClick={()=>{
                const idFocus = document.getElementById("landmark")
                idFocus && idFocus.scrollIntoView({behavior: "smooth", block: "center"});
              }}
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="label-wrap font16"> Landmark </label>
          </div>
          {/* <div class="group mb-20 common_list">
            <input
              class="inputMaterial font16 fw600"
              type="text"
              required
              name="storeArea"
              value={areaTempValue}
              onChange={(e) => handleChangeArea(e)}
              autoComplete="off"
              disabled={true}
              id={"storeArea"}
              onClick={()=>{
                const idFocus = document.getElementById("storeArea")
              setTimeout(() => {
                idFocus && idFocus.scrollIntoView({behavior: "smooth", block: "center"});
              },100)
              }}
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="label-wrap font16"> Enter Store Area *</label>
            <span
              className={showAreaDropdown?"of_downarrow uparrow":""}
              onClick={()=>handleCloseDropdown("storeArea")}
            ></span>
            {showAreaDropdown && (
              <StoreAreaList data={areaList} setArea={handleSetArea} />
            )}
            {isEmptyFieldErr.storeArea &&  (
              <span class="errorcls font12 pt-5" id="storeAreaErrorSpan">
               Please select/enter valid area from dropdown. 
              </span>
            )}
          </div> */}
          <div class="group">
            <input
              class="inputMaterial font16 fw600"
              type="text"
              required
              name="pincode"
              value={valObj.pincode}
              onChange={(e) => handleChange(e)}
              disabled={(valObj.pincode || !valObj.buildingName)?true:false}
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="label-wrap font16"> Pincode </label>
            {/* {isValidPincode && (
              <span class="errorcls font12 pt-5">
                Please enter a valid pincode
              </span>
            )} */}
          </div>
          <div class="group mb-20 common_list">
            <input
              class="inputMaterial font16 fw600"
              type="text"
              required
              name="city"
              value={cityTempValue}
              onChange={(e) => handleChangeCity(e)}
              autoComplete="off"
              disabled={false}         
              id={"cityName"}
              disabled={true}
              onClick={()=>{
                const idFocus = document.getElementById("cityName")
              setTimeout(() => {
                idFocus && idFocus.scrollIntoView({behavior: "smooth", block: "center"});
              },100)
              }}
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="label-wrap font16"> City *</label>
            <span
              className={showCityDropdown?"of_downarrow uparrow":""}
              onClick={()=>handleCloseDropdown("city")}
            ></span>
            {showCityDropdown && (
              <StoreCityList data={cityList} setCity={handleSetCity} />
            )}
            {/* {isEmptyFieldErr.city &&  (
              <span class="errorcls font12 pt-5" id="cityErrorSpan">
               Please enter/select valid city from drop down
              </span>
            )} */}
          </div>
          <div class="group">
            <input
              class="inputMaterial font16 fw600"
              type="text"
              required
              name="state"
              value={valObj.state}
              onChange={(e) => handleChange(e)}
              disabled={true}
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="label-wrap font16"> State </label>
          </div>
        </div>
      </section>
      <div class={`ftr_btn animated fast fadeInUp ${disabledBtn?"disabled":""}`}>
        <button class="font14 fw600 colorfff" disabled={disabledBtn} onClick={() => handleSaveCheck()}>
        {!showButtonLoader && <>Save{paramsFrom["input"] == "account_settings"?"":<> &amp; Next</>}</>}
       {
         showButtonLoader && <span class="dotloader"><span /><span /><span /><span /><span /></span>
       }  
        </button>
      </div>
      {
        showCityValidPopup &&  
        <div className="updatebank_dtl_wpr">
        <div className="updatebank_dtl_outer p-20 p-30">
            <div className="font13 color111 fw600 lineHeight22">
            The selected city isn't available in Justdial database. Do you wish to proceed with {selectedAlternateCity} instead? 
            </div>
            <div className="mt-30 proceed_btn_wpr">
              <button className="font16 graybtn" onClick={()=>setShowCityValidPopup(false)}>Cancel</button> <button className="font16" onClick={()=>handleSaveConfirmCityChange()}>Proceed</button>
            </div>
        </div>
      </div>
      }
    </>
  );
};

export default LocationDet;
