import React, { useState, useRef, useEffect } from "react";

import {
   sendDesktopOtpAPI, verifyDesktopOtpAPI, getURIParams
  } from "../../services/common";

const LoginPopup = ({closePopup, successLoggedIn}) => {
	const [value, setValue] = useState({});
	const [sentOtp, setSentOtp] = useState(false);
	const [verifyOtpEnable, setVerifyOtpEnable] = useState(false);
	const [otpNum, setOtpNum] = useState({});
	const input_0 = useRef(null);
	const input_1 = useRef(null);
	const input_2 = useRef(null);
	const input_3 = useRef(null);
	const input_4 = useRef(null);
	const input_5 = useRef(null);

	const handleChange = (e) => {
		setValue({...value, [e.target.name]: e.target.value});
	}

	const sendDesktopOtp = (resend) =>{
		sendDesktopOtpAPI(value, resend).then((res)=>{
			setSentOtp(true);
		})
	}

	const verifyDesktopOtp = () =>{
		let strV;
		Object.values(otpNum).forEach((v, i)=>{
			let str = String(v);
		if(i==0){
			strV=str;
		}else{
			strV = strV+str;
		  }
		})
		verifyDesktopOtpAPI(value, strV).then((res)=>{
		//	successLoggedIn(value.userName, value.mobile);
		let urlParamsClone = getURIParams();
		const currentHost = window.location.protocol + "//" + window.location.host + "/onboarding/businessDetails/?" + urlParamsClone;
	     window.location.href = currentHost;
		})
	}

	const handleEnterOtp = (e, index) => {
		let val = e.target.value;
		let backSpace=false;
		if(e.keyCode === 8 || val==""){
			backSpace=true;
		}
		  setOtpNum({ ...otpNum, [index]: val });
		  switch (index) {
			case 0:
			if(backSpace){
				input_0.current.focus();
			}else{
				input_1.current.focus();
			}
			  break;
			case 1:
				if(backSpace){
					input_0.current.focus();
				}else{
			  input_2.current.focus();
				}
			  break;
			case 2:
				if(backSpace){
					input_1.current.focus();
				}else{
			  input_3.current.focus();
				}
			  break;
			case 3:
				if(backSpace){
					input_2.current.focus();
				}else{
			  input_4.current.focus();
				}
			  break;
			case 4:
				if(backSpace){
					input_3.current.focus();
				}else{
			  input_5.current.focus();
				}
				break;
			case 5:
				if(backSpace){
					input_4.current.focus();
				}else{
				}
			  break;
			default:
			  break;
		  }
		}

		useEffect(()=>{
			let count=0;
			Object.values(otpNum).forEach((v)=>{
			  if(v){
				count++;
			  }
			})
			if(count==6){
				setVerifyOtpEnable(true);
			}else{
				setVerifyOtpEnable(false);
			}
		  },[otpNum]);
    return (
    	<>
		{!sentOtp &&
 <div className="login_wpr">
 <div className="login_outer">
	 <div className="login_header">
		 <button className="close" onClick={closePopup} />
		 <span className="popup_title">
			 <span className="pop-logo" />
			 <span className="pop-subtext">Login for Seamless experience</span>
		 </span>
	 </div>
	 <div className="popup_body">
		 <div className="mb-10 pt-10">
			 <input type="text" size="20" name="userName" className="popup_input" placeholder="Name" value={value["userName"]} onChange={(e)=>handleChange(e)} />
		 </div>
		 <div className="mb-10 pt-10">
			 <input type="text" size="20" name="mobile" className="popup_input" placeholder="Mobile Number" value={value["mobile"]} onChange={(e)=>handleChange(e)} />
		 </div>
		 <div className="mb-25 pt-10">
			 <button className="send_otp_btn" onClick={()=>sendDesktopOtp()}>Send OTP</button>
		 </div>
	 </div>
 </div>
</div>

		}
		   {
			   sentOtp &&
			   				<div className="login_wpr">
					<div className="login_outer">
						<div className="login_header">
							<button className="close" onClick={closePopup}/>
							<span className="popup_title">
								<span className="pop-logo" />
								<span className="pop-subtext">Login for Seamless experience</span>
							</span>
						</div>
						<div className="popup_body">
							<div className="otp_text pb-10">OTP is sent on number</div>
							<div className="pb-10">
								<span className="otp_number">+91 {value.mobile}</span>
								<span className="modal_edit_icon" onClick={()=>setSentOtp(false)}></span>
							</div>
							<div class="otp_input pb-30 pt-5">
							<input
									ref={input_0}
									onChange={(e) => {
										handleEnterOtp(e, 0);
									}}
									value={otpNum[0]}
									type="tel"
									maxlength="1" 
           						 />
									<input
									ref={input_1}
									onChange={(e) => {
										handleEnterOtp(e, 1);
									}}
									value={otpNum[1]}
									type="tel"
									maxlength="1" 
           						 />
									<input
									ref={input_2}
									onChange={(e) => {
										handleEnterOtp(e, 2);
									}}
									value={otpNum[2]}
									type="tel"
									maxlength="1" 
           						 />
									<input
									ref={input_3}
									onChange={(e) => {
										handleEnterOtp(e, 3);
									}}
									value={otpNum[3]}
									type="tel"
									maxlength="1" 
           						 />
									<input
									ref={input_4}
									onChange={(e) => {
										handleEnterOtp(e, 4);
									}}
									value={otpNum[4]}
									type="tel"
									maxlength="1" 
           						 />
									<input
									ref={input_5}
									onChange={(e) => {
										handleEnterOtp(e, 5);
									}}
									value={otpNum[5]}
									type="tel"
									maxlength="1" 
           						 />
								{/* <input type="tel" id="pin_0" maxlength="1" onChange={()=>handleChangeOtp}/>
								<input type="tel" id="pin_1" maxlength="1" />
								<input type="tel" id="pin_2" maxlength="1" />
								<input type="tel" id="pin_3" maxlength="1" />
								<input type="tel" id="pin_4" maxlength="1" />
								<input type="tel" id="pin_5" maxlength="1" /> */}
							</div>
							<div className="resend_otp_wpr pb-25">
								<div className="resend_text">Didn't Receive the OTP?</div>
								<div className="resend_text" onClick={()=>sendDesktopOtp()}><a>Resend OTP</a></div>
							</div>
							
							<div className={`mb-25 pt-10 ${!verifyOtpEnable?"disabled":""}`}>
								<button className="send_otp_btn" disabled={!verifyOtpEnable?"true":""} onClick={()=>verifyDesktopOtp()}>Proceed</button>
							</div>
						</div>
					</div>
				</div>
}
		</>

    )
}

export default LoginPopup;