
let init = {
    msg:"",
    show:false
};

const ToastReducer = (state = init, action) => {
    const data = action.data;
    switch (action.type) {
    case 'SET_TOAST_SHOW': 
        return {...data, show: true, msg:data};
    case 'SET_TOAST_HIDE': 
        return {...data, show: false, msg:data};
    default:
        return state;
    }
}

export default ToastReducer;