import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {getListOfChanges, approveListOfChanges, checkLinkExpire, expireLink} from "../../services/audit";
import { redirectToDashboard, SaveBusinessDetails, LoadPrefilledData, getCurrentURIParams, getEditListHomeUrl, getEditListHomeAPI} from "../../services/common";
import { hideLoaderAction, resetGenioReferFlag, showLoaderAction, loadPrefilledBusinessDataAction, showToastAction, hideToastAction} from "../../actions/actions";
import { useNavigate } from "react-router-dom";
import {PageLoad} from "../common/PageLoadSellerApp";
import {IsJDCash} from "../../config/verticalConfig";

function ConfirmPopUp(props) {
	return (
	  <div className="freshuser_popup_wpr">
		<div className="freshuser_popup_outer">
		  <div className="freshuse_text font16 color111">
		 	 Thanks for the confirmation. Your business details are saved successfully
		  </div>
		  <div className="createnew_btn mt-30">
			<button onClick={(e) => props.submit(false)}>
				OK
			</button>
		  </div>
		</div>
	  </div>
	);
  }
const AuditApprovalPage = () => {
	const [listData, setListData] = useState([]);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [showTC, setShowTC] = useState(false);
	const [tcAccept, setTcAccept] = useState(false);
	const [tcPopUp, setTcPopUp] = useState(false);
	const [directTC, setDirectTC] = useState(true);

	const [apiLoad, setApiLoad] = useState(false);
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const compData = useSelector((state) => {
		return state;
	  });
	  useEffect(()=>{
        //Page load for source 51, 53
        try{
        if(apiLoad){
            let r = getCurrentURIParams();
              if(r.source!=53){
                let logInJson = window.JdLiteInterface.getLoginData();
                if(!logInJson) return;
                logInJson = JSON.parse(logInJson);
                  if(logInJson.source=="51"){
                    PageLoad(logInJson.source);
                 }
              }else{
                PageLoad(r.source);
          //      HideIOSAppheader();
              }
        }
        }catch(e){
          console.log("Page Load err for approval...", e);
        }
        },[apiLoad]);

	const { jduid } = useSelector((state) => state.locationReducer);
	useEffect(()=>{
		if(jduid){
			LoadPrefilledData(undefined, "14").then((res)=>{
				if (res.data.results.data[0] && (res.data.results.data[0].sid || res.data.results.data[0].jduid)) {
					dispatch(
					  loadPrefilledBusinessDataAction(res.data.results.data[0])
					);
					setDataLoaded(true);
				  }else{
					navigate(`/infoPages/notAuthorized`);
				  }
			  setApiLoad(true);
			});
		}
	},[jduid]);

	useEffect(()=>{
		if(dataLoaded){
			let r = getCurrentURIParams();
			dispatch(showLoaderAction());
			checkLinkExpire().then((res)=>{
				if(res.data.expired==0){
					getListOfChanges().then((res2)=>{
						if(res2.data && res2.data.length>0){
							setListData(res2.data);
							dispatch(hideLoaderAction());
						}else{
							dispatch(hideLoaderAction());
							navigate(`/infoPages/noDataLink`);
						}
					})
				}else{
					if(r.source==51 && (r.mobile=="7892197015" || r.mobile=="9226108471")){
						navigate(`/infoPages/invalidLink?source=${r.source}&mobile=${r.mobile}`);
					}else{
						navigate(`/infoPages/invalidLink`);
					}
				}
			})
		}
	},[dataLoaded]);

	const [rejectFlag, setRejectFlag] = useState(false);
	const [approveFlag, setApproveFlag] = useState(false);
	const [popUp, setPopUp] = useState(false);
	useEffect(()=>{
		if(approveFlag){
			SaveBusinessDetails({ ...compData, doneFinal: false }).then(()=>{
				expireLink(1).then(()=>{
				dispatch(hideLoaderAction());
			 		setPopUp(true);
				})		
			});
		}
		if(rejectFlag){
			 SaveBusinessDetails({ ...compData, doneFinal: false }).then(()=>{
			 	expireLink(2).then(()=>{
					dispatch(hideLoaderAction());
					navigate(`/infoPages/thankyouLink`);
			 	})		
			 });
		}
	},[rejectFlag, approveFlag])
	
const handleApproval = () => {
	 dispatch(showLoaderAction());
		 approveListOfChanges().then((res)=>{
	 	dispatch(resetGenioReferFlag());
		setApproveFlag(true);
	 });
}

const handleRejection = () => {
	dispatch(showLoaderAction());
	setRejectFlag(true);
	dispatch(resetGenioReferFlag());
}
const handleOkay = () => {
	if(IsJDCash){
		handleELRedirect();
	}else{
		redirectToDashboard();
	}
}

const handleELRedirect = () => {
    window.location.href = getEditListHomeAPI(
		compData.loadDocData.docid,
    	compData.locationDetails.city
    );
  }

const handleTCRedirect = () => {
	let r = getCurrentURIParams();
	if(r.source==7 || r.source==77 || r.source==27){
		window.open("https://www.justdial.com/Terms-of-Use/JD-Cash-Terms-and-Condition", "__blank");
	}else{
		window.open("https://www.justdial.com/mobileTC?wap=2&source=2&nh=1&hide_header=1&nd=1&ln=en&ver=1.0", "__blank");
	}
}

const handleAcceptTC = () => {
	if(directTC){
		setTcAccept(true);
		setShowTC(!showTC);
	}else{
		setShowTC(false);
		handleApproval();
	}
}

const handleRejectTC = () => {
	setTcAccept(false);
	setDirectTC(true);
	setShowTC(!showTC);
}

const handleApprovalTC = () =>{
	if(tcAccept){
		setDirectTC(true);
		handleApproval();
	}else{
		setShowTC(true);
		setDirectTC(false);
	}
}

    return (
		<>
        <div class="wrpr">
	<header class="headerBx">
		<span class="middle-title font16">Changes in Contract</span>
	</header>

	<div class="container auditApproval pb100"> 	
	<div class="subtitle font13">   We require your approval to update your Store Detail changes. </div>	
		{
			listData && listData.map((row)=>
			{
				if(Object.keys(row)[0]=="Offers"){
					const OldArr = row[Object.keys(row)[0]]["old"];
					const NewArr = row[Object.keys(row)[0]]["new"];
					return (
						<div class="mobileNo font13"> Offers : 
					   {OldArr && <span className="ml-4">	Old:</span> }
						{OldArr && OldArr.map((r)=>
							<div className={`m-5`}>
							{Object.keys(r).map((kv, i)=>
							<div className={`numbr ml-15 ${i==0?"fw800":"weight500"}`}> {kv}: {r[kv]}</div>
							)}
							<hr className={"hr hr_audit"}></hr>
							</div>
						)}
						{NewArr && 
						 <span className="ml-4"> New:</span> }
						{NewArr && NewArr.map((r)=>
							<div className="m-5">
							{Object.keys(r).map((kv, i)=>{
								if(kv=="dealDetail"){
									return (
										<div className={`numbr ml-15 break-word ${i==0?"fw800":"weight500"}`}> {kv}: {r[kv].join(", ")}</div>
									)
								}else{
									return (
										<div className={`numbr ml-15 ${i==0?"fw800":"weight500"}`}> {kv}: {r[kv]}</div>
									)
								}
							}
							)}
							<hr className={"hr hr_audit"}></hr>
							</div>
						)}
						</div>
					)
				}else{
					return (
				<>
				<div class="mobileNo font13"> {Object.keys(row)[0]} : 
										<div class="numbr weight500 "> OLD : {row[Object.keys(row)[0]]["old"]} </div>
				<div class="numbr weight500">NEW :  {row[Object.keys(row)[0]]["new"]}</div> 
								</div>

				</>
					)
				}
			}
			)
		}
		{/* <div class="subtitle font13">   We require your approval to update your Store Detail changes. </div>
							<div class="mobileNo font13">  Year of Establishment : 
													<div class="numbr weight500 "> OLD : </div>
							<div class="numbr weight500">NEW :  2019</div> 
											</div> */}

		{/* <div class="tnc_wpr" onClick={()=>setShowTC(!showTC)}>
			<a onClick={()=>setShowTC(!showTC)} className={`tnc_link ${tcAccept?"tick_icon":""}  font13 color007`}> Terms and Conditions </a>
		</div> */}

		
		<div class="plzNoteTxt font13">*Changes will reflect immediately after the approval.</div>
		<div class="t_and_c_link color414 font11 fw400">On approving, I hereby accept JD Cash <a href="#" onClick={handleTCRedirect}>Terms and Conditions</a> </div>

	</div>

	<div class="btmFixBtn "><button class="ftrBlueBtn2 font16 dsblesave" onClick={handleApproval}> Approve </button>
	<button class="ftrGreyBtn2 font16" onClick={handleRejection}> Reject </button></div>
 </div>
	{popUp && (
			<ConfirmPopUp submit={handleOkay} />
		)}
		{
            showTC &&
            <div className="tnc_popup_wpr">
	            	<div className="tnc_popup_outer">
	                <iframe src={(process.source==7||process.source==27)?
						"https://www.justdial.com/Terms-of-Use/JD-Cash-Terms-and-Condition?hide_back=1"
						:
						"https://www.justdial.com/mobileTC?wap=2&source=2&nh=1&hide_header=1&nd=1&ln=en&ver=1.0&hide_back=1"} 
					title="Terms and Conditions"></iframe>
					<div className="delecte_btn p-20"><button onClick={handleAcceptTC}> Accept </button>
		<button onClick={handleRejectTC}> Reject </button></div>
					</div>
            </div>
        }
		{
        tcPopUp &&  
        <div className="updatebank_dtl_wpr">
        <div className="updatebank_dtl_outer p-20 p-30">
            <div className="font13 color111 fw600 lineHeight22">
            Please accept terms and conditions. 
            </div>
            <div className="mt-30 proceed_btn_wpr">
 					<button className="font16" onClick={()=>setTcPopUp(false)}>Okay</button>
            </div>
        </div>
      </div>
      } 

	  </>
    )
}

export default AuditApprovalPage;
