export const openViewExit = (source) => {
    console.log("In device back, source: ", source);
    if(source==51){
        var logjson = JSON.stringify({ action: "exit" });
        try {
          window.JdLiteInterface.verticalHandler(logjson);
        } catch {
          console.log("deviceBack: JdLiteInterface Back error");
        }
    }else if(source==53){
        window.location.href = "https://jdext.php";
    }
}

export const openViewExitDeviceBack = (r) => {
  if(r["input"]=="account_settings"){
        openViewExit(r.source);
  }else{
    window.history.go(-1);
  }
}