import React, { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SelectBusiness from "./components/SelectBusiness";
import BusinessDetails from "./components/BusinessDetails";
import {
  saveUrlParamsAction,
  saveUrlParamsObjAction,
  setLoggedinAction,
  showLoaderAction,
  hideLoaderAction,
  loadInitialBusinessDataAction,
  saveJDUID
} from "./actions/actions";
import {
  CheckLogin,
  CheckLoginWToken,
  clickTrack,
  getSource,
  LoadBusinesses,
  getURIParams,
  getDboardLink,
  getCurrentURIParams,
  getUserDet,
  logoutCall,
  getUserInf
} from "./services/common";
import LogInRedirect from "./components/LogInRedirect";
import ToastPopUp from "./components/common/ToastPopUp";
import Loader from "./components/common/Loader";
import Shimmer from "./components/common/Shimmer";
import HelpSection from "./components/common/HelpSec";
import IntroPage from "./components/common/IntroPage";
import { setCookie, setCookieWDomain } from "./components/common/setCookie";
import LoginPopup from "./components/common/LoginPopup";
import ThankYou from "./components/pages/thankyou";
import ComissionStructureForEL from "./components/common/ComissionStructureForEL";
import InvalidLink from "./components/pages/Infopages/InvalidLink";
import ThankYouLink from "./components/pages/Infopages/ThankYouLink";
import NoDataLink from "./components/pages/Infopages/NoDataLink";
import NotAuthorized from "./components/pages/Infopages/NotAuthorized";
import AuditApprovalPage from "./components/pages/AuditApprovalPage";

import { IsJDCash } from "./config/verticalConfig";
// const getCookie = (name) => {
//   const value = `; ${document.cookie}`;
//   const parts = value.split(`; ${name}=`);
//   if (parts.length === 2) return parts.pop().split(';').shift();
// }

const detectSource = () => {
  try{
    if(window.JdLiteInterface){
      let logInJson = window.JdLiteInterface.getLoginData();
      if(logInJson){
        logInJson = JSON.parse(logInJson);
        return logInJson.source;
      }
    }
  }catch(e){
    console.log("Not jdliteinterface part",e);
  }
  let isMobi = /iphone|ipod|android|ie|blackberry|fennec/.test(
    navigator.userAgent.toLowerCase()
  );
  if (isMobi) {
    return 2;
  } else {
    return 7;
  }
};
const getCookie = (name) => {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return "";
};
function App() {
  const dispatch = useDispatch();
  //Initially load and check if logged in. if logged in, check the data.
  //if user has already filled some data, go to that specific path.
  //Else just show the create business page from where the user wud be routed ahead.
  const { loggedIn, jduid } = useSelector((state) => state.locationReducer);
  const ToastReducer = useSelector((state) => state.ToastReducer);
  const LoaderReducer = useSelector((state) => state.LoaderReducer);
  const HelpReducer = useSelector((state) => state.HelpReducer);
  const [checkedLoggedIn, setCheckedLoggedIn] = useState(false);
  const [showLoginPop, setShowLoginPop] = useState(false);

  const nativeLogin = (logjson) => {
    var dataJSON = JSON.parse(logjson);
    var mobile = dataJSON["mobile"];
    var sid = dataJSON["sid"];
    setCookie("mobile", mobile, 1);
    setCookie("sid", sid, 1);
  };

  useEffect(() => {
    //Pick up data and dispatch...
    let urlParams = window.location.href.split("?")[1];
    let urlParamsArr = [];
    if (urlParams) {
      urlParamsArr = urlParams.split("&");
    }
    let stNeed = ["source", "sid", "mobile", "wap", "docid"];
    let objParams = {};
    urlParamsArr.forEach((element) => {
      let elName = element.split("=")[0];
      let elValue = element.split("=")[1];
      if (stNeed.indexOf(elName) > -1) {
        objParams[elName] = elValue;
      }
    });
    let srcValidArr = ["1", "2", "3", "7", "51", "53", "14"];
    if (!objParams.source || !srcValidArr.includes(objParams.source)) {
      objParams.source = detectSource();
    }
    let src = objParams.source;
    let mobile = objParams["mobile"]
      ? objParams["mobile"]
      : getCookie("mobile");
    if (!mobile) {
      const userDet =
        getCookie("ln_user") &&
        JSON.parse(decodeURIComponent(getCookie("ln_user")));
      mobile = userDet.mb;
    }
    let sid = objParams["sid"] ? objParams["sid"] : getCookie("sid");
    if (src == 1 || src == 3 || src == 53 || src == 14) {
      sid = objParams["sid"];
    } else if (src == 2 || src == 7) {
      sid = getCookie("sid");
    } else if(src == 51){
        try{
          let logInJson = window.JdLiteInterface.getLoginData();
          if(!logInJson) return;
          logInJson = JSON.parse(logInJson);
          sid = logInJson.sid;
        }catch(e){
          console.log("App.js getting sid error ",e);
        }
    }
    console.log(sid);
    if (sid && mobile) {
      dispatch(showLoaderAction());
    }else{
      if(src!=7)
      logoutCall(src);
    }
    switch (true) {
      case src == 1 || src == 3 || src == 51 || src == 53 || src == 14:
        try {
          if (sid && mobile) {
            CheckLogin(mobile, sid, src).then((res) => {
              if (res.data && res.data.data && res.data.data.results[0]) {
                dispatch(setLoggedinAction({mobile: mobile, contactPerson: res.data.data.results[0].full_name}));
                dispatch(hideLoaderAction());
                setCheckedLoggedIn(true);
              } else {
                setCheckedLoggedIn(true);
                dispatch(hideLoaderAction());
                logoutCall(src);
              }
            });
          } else {
            setCheckedLoggedIn(true);
          }
        } catch (e) {
          console.log("Source 1 or 3, api issue");
          console.log(e);
        }
        break;
      case src == 2:
        if (sid && mobile) {
          CheckLogin(mobile, sid, src).then((res) => {
            if (res.data && res.data.data && res.data.data.results[0]){
              dispatch(setLoggedinAction({mobile: mobile, contactPerson: res.data.data.results[0].full_name}));
            }else{
              logoutCall(src);
            }
            dispatch(hideLoaderAction());
            setCheckedLoggedIn(true);
          });
        } else {
          dispatch(hideLoaderAction());
          setCheckedLoggedIn(true);
          logoutCall(src);
        }
        break;
      case src == 7:
        CheckLoginWToken(src)
          .then((res) => {
            if (res.data && res.data.data && res.data.data.results[0]) {
              mobile = mobile ? mobile : res.data.data.results[0].mobile;
              const username = res.data.data.results[0].full_name;
              const sid = res.data.data.results[0].sid;
              const ln_user = JSON.stringify({
                mb: mobile,
                nm: username,
                ow: 1,
              });
              setCookieWDomain("mobile", mobile, 1, ".justdial.com");
              setCookieWDomain("sid", encodeURIComponent(sid), 1, ".justdial.com");
              setCookieWDomain("ln_user", encodeURIComponent(ln_user), 1, ".justdial.com");
              dispatch(setLoggedinAction({mobile: mobile, contactPerson: username}));
              getUserDet(mobile, sid).then((res)=>{
                if(res.data.results){
                  let data = res.data.results;
                  var userProfile = {
                    ...data, status: 1
                  };
                  setCookieWDomain("userProfile", encodeURIComponent(JSON.stringify(userProfile)), 1, ".justdial.com");
                }
              }).catch((e)=>{
                console.log(e);
              })      
              //  {"mb":"8129280521","nm":"sabari","em":"","ow":"1"}
            }else{
              logoutCall(src);
            }
            dispatch(hideLoaderAction());
            setCheckedLoggedIn(true);
          })
          .catch((e) => {
            console.log(e);
            setCheckedLoggedIn(true);
            dispatch(hideLoaderAction());
          });
        break;
      default:
        CheckLoginWToken(src)
          .then((res) => {
            if (res.data && res.data.data && res.data.data.results[0]) {
              mobile = mobile ? mobile : res.data.data.results[0].mobile;
              const username = res.data.data.results[0].full_name;
              const ln_user = JSON.stringify({
                mb: mobile,
                nm: username,
                ow: 1,
              });
              dispatch(setLoggedinAction({mobile: mobile, contactPerson: res.data.data.results[0].full_name}));
              setCookieWDomain("mobile", mobile, 1, ".justdial.com");
              setCookieWDomain("ln_user", encodeURIComponent(ln_user), 1, ".justdial.com");
            }else{
              logoutCall(src);
            }
            dispatch(hideLoaderAction());
            setCheckedLoggedIn(true);
          })
          .catch((e) => {
            console.log(e);
            setCheckedLoggedIn(true);
            dispatch(hideLoaderAction());
          });
    }

    dispatch(saveUrlParamsObjAction(objParams));
  }, [0]);

  useEffect(()=>{
    getUserInf().then((res)=>{
      if(res.data.error_code==1){
        console.log("Error in getting id");
      }else if(res.data && res.data.results && res.data.results["jduid"]){
        dispatch(saveJDUID(res.data.results["jduid"]));
      }
    });
  },[0])
  useEffect(() => {
    let urlParams = window.location.href.split("?")[1];
    dispatch(saveUrlParamsAction(urlParams));
  }, [0]);

  useEffect(() => {
    if (!loggedIn && checkedLoggedIn) {
      let redirect_url = window.location.href;
      var url =
        "https://m.justdial.com/login?redirect=jdpay&redirectionurl=" +
        encodeURIComponent(redirect_url);
      // window.location.href = url;
    }
  }, [loggedIn, checkedLoggedIn]);
  const handleLogin = () => {
    clickTrack("sellerInt");
    const source = getSource();

    if(window.location.href.includes("auditApproval")){
      var url =
      "https://m.justdial.com/login?redirect=jdpay&redirectionurl=" +
      encodeURIComponent(window.location.href)+
      "&touch=1";
      window.location.href = url;
      return false;
    }
    if (source == 1 || source == 51) {
      try {
        var logjson = JSON.stringify({ fn: "nativeLogin" });
        window.JdLiteInterface.verticalLogin(logjson);
      } catch (e) {
        console.log("Source 1 login error");
        console.log(e);
      }
    } else if (source == 3 || source == 53) {
      try {
        var logjson = JSON.stringify({
          type: "verticalLogin",
          fn: "nativeLogin",
        });
        window.webkit.messageHandlers.callbackHandler.postMessage(logjson);
      } catch (e) {
        console.log("Source 3 login error");
        console.log(e);
      }
    } else if (source == 7) {
      setShowLoginPop(true);
    } else {
      let currentPage = window.location.pathname.split("/")[3];
      let presArr = ["businessDet", "locationDet", "contactDet", "pickupDet", "storeTimeDet", "chooseplan"];
      if(presArr.indexOf(currentPage)>-1){
      var url =
        "https://m.justdial.com/login?redirect=jdpay&redirectionurl=" +
        encodeURIComponent(window.location.href)+
        "&touch=1";
        window.location.href = url;
      return false;
      }
      let urlParamsClone = getURIParams();
      const currentHost =
        window.location.protocol +
        "//" +
        window.location.host +
        "/onboarding/businessDetails/?" +
        urlParamsClone;

      let redirect_url = window.location.href;
      var url =
        "https://m.justdial.com/login?redirect=jdpay&redirectionurl=" +
        encodeURIComponent(currentHost) +
        "&touch=1";
      window.location.href = url;
    }
  };

  const handleSuccessDesktopLogin = (name, mobile) => {
    setShowLoginPop(false);
    setCookieWDomain("mobile", mobile, 1, ".justdial.com");
    const currentHost =
      window.location.protocol +
      "//" +
      window.location.host +
      "/onboarding?source=7&name=" +
      name +
      "&mobile=" +
      mobile;
    window.location.href = currentHost;
  };

  console.log("logged in??? ", loggedIn);

  return (
    <>
      {showLoginPop && (
        <LoginPopup
          closePopup={() => setShowLoginPop(false)}
          successLoggedIn={handleSuccessDesktopLogin}
        />
      )}
      {LoaderReducer.show && <Loader />}
      {LoaderReducer.showShimmer && <Shimmer />}
      <ToastPopUp
        msgData={ToastReducer.msg}
        show={ToastReducer.show}
        id="Toast_id"
      />
      <Router basename={"onboarding"}>
        {HelpReducer.show && <HelpSection />}
        {loggedIn && !IsJDCash && (
          <Routes>
            <Route
              path="/"
              element={<IntroPage Login={handleLogin} loggedIn={loggedIn} checkedLoggedIn={checkedLoggedIn}/>}
            />
            <Route
              path="/introPage"
              element={<IntroPage Login={handleLogin} loggedIn={loggedIn} checkedLoggedIn={checkedLoggedIn}/>}
            />
            <Route path="/commissionDetails" element={<ComissionStructureForEL />} />
            <Route path="/auditApproval" element={<AuditApprovalPage />} /> 
            <Route path="/businessDetails/*" element={<BusinessDetails />} />
            <Route path="/thankyou/*" element={<ThankYou />} />
          </Routes>
        )}
         {loggedIn && IsJDCash && jduid && (
          <Routes>
            <Route
              path="/"
              element={<IntroPage Login={handleLogin} loggedIn={loggedIn} checkedLoggedIn={checkedLoggedIn}/>}
            />
            <Route
              path="/introPage"
              element={<IntroPage Login={handleLogin} loggedIn={loggedIn} checkedLoggedIn={checkedLoggedIn}/>}
            />
            <Route path="/commissionDetails" element={<ComissionStructureForEL />} />
            <Route path="/auditApproval" element={<AuditApprovalPage />} /> 
            <Route path="/businessDetails/*" element={<BusinessDetails />} />
            <Route path="/thankyou/*" element={<ThankYou />} />
          </Routes>
        )}
        {!loggedIn && checkedLoggedIn && (
          <Routes>
            <Route path="/*" element={<IntroPage Login={handleLogin} checkedLoggedIn={checkedLoggedIn}/>} />
            <Route path="/commissionDetails" element={<ComissionStructureForEL />} />
          </Routes>
        )}
        {
          checkedLoggedIn && 
          <Routes>
            <Route path="/infoPages/invalidLink" element={<InvalidLink checkedLoggedIn={checkedLoggedIn}/>} />
            <Route path="/infoPages/thankyouLink" element={<ThankYouLink checkedLoggedIn={checkedLoggedIn}/>} />
            <Route path="/infoPages/noDataLink" element={<NoDataLink checkedLoggedIn={checkedLoggedIn}/>} />
            <Route path="/infoPages/notAuthorized" element={<NotAuthorized checkedLoggedIn={checkedLoggedIn}/>} />
          </Routes>
        }
      </Router>
    </>
  );
}

export default App;
