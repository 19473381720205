import React from "react";

const BottomConfirmPop = ({handleClose, handleConfirm, textToShow}) => {


    return (
        <div className="updatebank_dtl_wpr">
        <div className="updatebank_dtl_outer p-20 p-30">
            <div className="font13 color111 fw600 lineHeight22">
                {textToShow} 
            </div>
            <div className="mt-30 proceed_btn_wpr">
              <button className="font16 graybtn" onClick={()=>handleClose()}>Cancel</button> <button className="font16" onClick={()=>handleConfirm()}>Proceed</button>
            </div>
        </div>
      </div>
    )

}

export default BottomConfirmPop;