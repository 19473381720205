import React, {useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import { imageUpload, getCurrentURIParams, SendOtp, VerifyOtp } from "../../services/common";
import {
    showToastAction,
    hideToastAction,
    showLoaderAction,
    hideLoaderAction
  } from "../../actions/actions";
import OtpEnterComponent from "./OtpEnterComponent";

const AddDeliveryBoy = ({closeAddDeliveryBoyPopUp, handleAddDeliveryBoy, valuesEd}) => {
    const dispatch = useDispatch();
    useEffect(()=>{
      if(valuesEd.mobile){
        setValues(valuesEd);
        setVerifiedOtp(true);
        setDisableMobile(true);
      }
    },[0]);
    const [values, setValues] = useState({name:"", mobile: "", img_url:""});
    const [disableMobile, setDisableMobile] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [valuesErr, setValuesErr] = useState({
        mobile: {flag:false, txt:""},
        name: {flag:false, txt:""}
    });
    const [verifiedOtp, setVerifiedOtp] = useState(false);
    const [wrongOtp, setWrongOtp] = useState(false);
    const [sendOtpButton, setSendOtpButton] = useState(false);
    const checkFormat = (event) => {
        const files = event.target.files;
        let err = true;
        let type_error = "";
        let type_file = "";
        for (var i = 0; i < files.length; i++) {
          if (
            files[i].type == "image/jpeg" ||
            files[i].type == "image/jpg" ||
            files[i].type == "image/png"
          ) {
          } else {
            type_file += files[i].name + ",";
            err = false;
          }
        }
        if (files[0] && files[0].size > 4194304) {
          dispatch(showToastAction("File size limit: Exceeds 4MB."));
          setTimeout(function () {
            dispatch(hideToastAction());
          }, 3000);
          return false;
        }
        if (!err) {
          dispatch(
            showToastAction("Invalid document type. Please upload jpeg or png.")
          );
          setTimeout(function () {
            dispatch(hideToastAction());
          }, 3000);
        }
        return err;
      };

    const selectImage = (e) => {
        const file = e.target.files[0];
        const constformatFlag = checkFormat(e);
        if (file) {
          if (!constformatFlag) {
            return false;
          }
          const image = URL.createObjectURL(file);
          dispatch(showLoaderAction());
          imageUpload(file)
            .then((resp) => {
              const respImage =
                resp.data.image_urls && resp.data.image_urls.length
                  ? resp.data.image_urls[0]
                  : "";
              setValues({ ...values, img_url: respImage});
              dispatch(
                showToastAction("Image upload successful")
              );
              setTimeout(function () {
                dispatch(hideToastAction());
              }, 2000);
              dispatch(hideLoaderAction());
            })
            .catch((err) => {
                setValues({ ...values, img_url: ""});
                dispatch(hideLoaderAction());
            });
        }
      };

    const validate = () => {
        let validityFlag = true;
        let mobileErr = {};
        let nameErr = {};
        if(!values.mobile){
            mobileErr = {flag: true, txt: "Please enter mobile number."  };
            validityFlag = false;
        }
        if(!values.name){
            nameErr = {flag: true, txt: "Please enter name."  };
            validityFlag = false;
        }
        setValuesErr({mobile: mobileErr,name: nameErr});
        var mobilePattern = /^[0]?[6789]\d{9}$/;
        let patternContactName = /^[A-Za-z\s\'\‘\’\u2018\u2019]+$/;
        if(validityFlag){
            if(!mobilePattern.test(values.mobile)){
                mobileErr = {flag: true, txt: "Please enter a valid mobile number."  };
                validityFlag = false;   
            }
            if(!patternContactName.test(values.name)){
                nameErr = {flag: true, txt: "Please enter a valid name."  };
                validityFlag = false;   
            }
            setValuesErr({mobile: mobileErr,name: nameErr});
        }
        if(validityFlag && !verifiedOtp){
          validityFlag = false;
          mobileErr = {flag: true, txt: "Please verify the mobile number."  };
          validityFlag = false;   
          setValuesErr({mobile: mobileErr,name: nameErr});
        } 
        return validityFlag;
    }

    const handleChanges = (e) => {
        setValuesErr({
            mobile: {flag:false, txt:""},
            name: {flag:false, txt:""}
        });
        let val = e.target.value;
        if(e.target.name=="mobile"){
          var mobilePattern = /^[0]?[6789]\d{9}$/;
           if(val.length==10 && mobilePattern.test(val)){
             setSendOtpButton(true);
           }else{
            setSendOtpButton(false);
           }
           if(valuesEd.mobile==val){
            setVerifiedOtp(true)
           }else{
            setVerifiedOtp(false);
           }
           if(/[0-9\s]+$/.test(val) || val==""){
            setValues({...values, [e.target.name]: val});
           }
          }else{
            let patternContactName = /^[A-Za-z\s\'\‘\’\u2018\u2019]+$/;
            if(patternContactName.test(val) || val==""){
           setValues({...values, [e.target.name]: val});
          } 
        }
    }

    const handleAddDeliveryBoyBD = () => {
      if(validate() && verifiedOtp){
        let edit = false;
        if(valuesEd.mobile){
          edit = true;
        }
        handleAddDeliveryBoy(values.name, values.mobile, values.img_url, edit);
      }
    }

    const handleOTPSend = (mobType, resendOtp, index) => {
      dispatch(showLoaderAction());
      setValuesErr({
        mobile: {flag:false, txt:""},
        name: {flag:false, txt:""}
    });
     /* [{altN, altNVar, verified, invalid}] */
      SendOtp(values.mobile, values.name, resendOtp).then((res) => {
        if(res.data.result){
          dispatch(showToastAction(res.data.result));
          setTimeout(function () {
            dispatch(hideToastAction());
          }, 3000);
        }
        if(res.data.result=="Verification code sent successfully"){
          setOtpSent(true);
        }
        dispatch(hideLoaderAction());
      });
    };

  const handleVerifyOtp = (otp, mobile) => {
    VerifyOtp(mobile, values.name, otp).then((res) => {
     if (res.data.result == "SUCCESS") {
       setOtpSent(false);
       let MrespC = res.data.Mresp;
       let auditing = false;
       if(MrespC){
         let MrespRes = MrespC.results;
         if(MrespRes.alert!==0){
           auditing=true;
           setOtpSent(false);
         }
       }
       setVerifiedOtp(true);
     } else {
      dispatch(showToastAction("Please enter a valid Otp."));
      setTimeout(function () {
        dispatch(hideToastAction());
      }, 3000);
       setWrongOtp(true);
     }
    });
 };

 let notFilledAllMandatory = values.mobile!="" && values.name!="" && verifiedOtp;

    return (
        <>

        {otpSent && (
          <OtpEnterComponent
            sendOtp={handleOTPSend}
            verifyOtp={handleVerifyOtp}
            mobile={values.mobile}
            closeOtpComp={() => {
              setOtpSent(false);
              setWrongOtp(false);
            }}
          />
        )}
            <section class="pl-20 pr-20 pb-70 addDeliveryDetPopUp">
                <div class="seller_header_wpr"><div class="seller_header_outer"><header class="seller_header header_fixed"><div class="header_home"><div class="header_home_left"><div class="seller_headback_icon seller_icon" onClick={()=>closeAddDeliveryBoyPopUp()}></div><div><span class="jdseller_logo"></span></div></div><div class="header_home_center"><div class="home_center text_center hdr_middle_text"><div class="header_home_text font16 fw600 color1A1">Business Details</div></div></div><div class="header_home_right"></div></div></header></div></div>
               <div>
                <div class="deliveryboy_pic_wpr mt-30">
                    <span class="addphoto_prnt deliveryboy_pic_outer">
                    <input
                        type="file"
                        name=""
                        accept="image/png, image/jpeg"
                        onChange={selectImage}
                    />
                    {
                      (values.img_url=="" || values.img_url==undefined)?
                    <>
                      <img src="https://akam.cdn.jdmagicbox.com/images/icontent/seller/adddeliveryboy_pic.png" />
                      <span class="camera_icon_outer">
                          <img src="https://akam.cdn.jdmagicbox.com/images/icontent/seller/photocamera_icon.svg" />
                      </span>
                    </>
                    :
                    <>
                        <img src={values.img_url} />
                    </>
                    }
                    </span>
                </div>

                <div class="group mt-40">
                    <input 
                      class="inputMaterial font16 fw600" 
                      type="text" 
                      required 
                      name="name" 
                      id="dName"
                      value={values.name} 
                      onChange={handleChanges} 
                      onClick={()=>{
                        const idFocus = document.getElementById("dName")
                      setTimeout(() => {
                        idFocus && idFocus.scrollIntoView({behavior: "smooth", block: "center"});
                      },100)
                      }}  
                    />
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label class="label-wrap font16"> Name </label>
                </div>
                {valuesErr.name.flag && <div class="error_msg">{valuesErr.name.txt}</div>}

                <div class="group mb-10">
                    <input 
                      class={`inputMaterial font16 fw600 ${disableMobile?"graytext":""}`} 
                      maxLength={10} 
                      type="tel" 
                      required 
                      name="mobile" 
                      id="dMobile"
                      value={values.mobile} 
                      onChange={handleChanges}
                      disabled={disableMobile}
                      onClick={()=>{
                        const idFocus = document.getElementById("dMobile")
                      setTimeout(() => {
                        idFocus && idFocus.scrollIntoView({behavior: "smooth", block: "center"});
                      },100)
                      }}
                    />
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label class="label-wrap font16"> Mobile Number  </label>
                
           {(sendOtpButton && !verifiedOtp) && <button
              class="font14 fw500 color007 sendOtp_btn"
              onClick={() => handleOTPSend("alternateMobileNumber")}
            >
              Send OTP
            </button>
          }
          {
            (verifiedOtp) &&
            <span class="pos_green_tickmark"></span>
           }
               {valuesErr.mobile.flag && <div class="error_msg">{valuesErr.mobile.txt}</div>}
            </div>
            </div>
            </section>
            <div class={`ftr_btn ${!notFilledAllMandatory?"disabled":""}`}>
                <button class="font14 fw600 colorfff" disabled={!notFilledAllMandatory} onClick={()=>handleAddDeliveryBoyBD()}>Submit</button>
            </div>
        </>
    )
}
export default AddDeliveryBoy;