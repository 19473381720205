import React, {useState, useEffect} from "react";
const CommissionStructureCompsIn = ({data, title, comInfoNoPar}) => {

    const [act, setAct] = useState(false);
    const [act2, setAct2] = useState(false);

    const handleClick = () =>{
        setAct(!act);
    }
    const handleClickNoPar = () => {
        setAct2(!act2);
    }
    const [comInfo, setComInfo] = useState();
    useEffect(()=>{
        console.log("daaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
        if(data && data.length>0){
            let da = [...data];
            let rangeArr = [[0, 300], [301, 500], [501, 1000], [1001,5000], [5000]];
            let ret = {};
            da.forEach((row)=>{
                if(row.commission_info && row.commission_info.length>0){
                    ret[row["cnamed"]] = [];
                    row.commission_info.forEach((r)=>{
                        const lb = r.lb;//0
                        const ub = r.ub;//3000 
                        rangeArr.forEach(([ll, ul],i)=>{
                            if(ll>=lb && ul<=ub){
                                if(ret[row["cnamed"]]){
                                                ret[row["cnamed"]][i]=r.value;
                                            }else{
                                                ret[row["cnamed"]]={
                                                    [i]:r.value
                                                };
                                            }
                            }
                            if(ll>=lb && ub=="INF"){
                                ret[row["cnamed"]][i]=r.value;
                            }
                            if(ub=="INF" && i==4){
                                ret[row["cnamed"]][i]=r.value;
                            }
                        })
                    })
                }
            });
            console.log(ret);
            setComInfo(ret);
        }
    },[0])

    return (
        <div className={`comisn_inner_according_outer ${act?"act":""}`} >
            {/* {data && data.filter((row)=>row.commission_info.length>0).length>0 &&
            <>
                 <div className="comisn_inner_according_title" onClick={handleClick}>{title} <span className="downarrow"></span></div>
                   {act && data && data.filter((row)=>row.commission_info.length>0).map((row, i)=>
                                                                 <CommissionStructureCompsIn2 ind={i} key={row.cid} title={row.cnamed} catId={row.cid} commissionData={row.commission_info}/>
                                                               )
                                                           }
                                                           </>                 
                                                        } */}
                                                        {data && data.filter((row)=>row.commission_info && row.commission_info.length>0).length>0 &&
               <>
               <div className="comisn_inner_according_title" onClick={handleClick}>{title} <span className="downarrow"></span></div>
               {act && 
                                <>
                                        <div className="inner_according_hdr">
                                            <div className="inner_according_left">
                                                &nbsp;
                                            </div>
                                            <div className="inner_according_right">
                                                <ul>
                                                    <li>0-300</li>
                                                    <li>301-500</li>
                                                    <li>501-1000</li>
                                                    <li>1001-5000</li>
                                                    <li>>5000</li>
                                                </ul>
                                            </div>
                                        </div>
                                            {
                                                comInfo && Object.keys(comInfo).map((k)=>
                                                <>
                                         <div className="inner_according_row">
                                         <div className="inner_according_row_left">
                                                {k}
                                            </div>
                                            <div className="inner_according_row_right">
                                                <ul>
                                            <li>{comInfo[k][0]} %</li>
                                            <li>{comInfo[k][1]} %</li>
                                            <li>{comInfo[k][2]} %</li>
                                            <li>{comInfo[k][3]} %</li>
                                            <li>{comInfo[k][4]} %</li>
                                            </ul>
                                            </div>
                                            </div>
                                            </>
                                                )}
                                    </>
                }
                </>
}
                        {comInfoNoPar && Object.keys(comInfoNoPar).length>0 && 
                                <>
                                        {
                                                comInfoNoPar && Object.keys(comInfoNoPar).map((k)=>
                                                <>
                                  <div className="comisn_inner_according_title" onClick={handleClickNoPar}>{k} <span className="downarrow"></span></div>
                                      {
                                          act2 &&
                                          <>
                                        <div className="inner_according_hdr">
                                            <div className="inner_according_left">
                                                &nbsp;
                                            </div>
                                            <div className="inner_according_right">
                                                <ul>
                                                    <li>0-300</li>
                                                    <li>301-500</li>
                                                    <li>501-1000</li>
                                                    <li>1001-5000</li>
                                                    <li>>5000</li>
                                                </ul>
                                            </div>
                                        </div>

                                         <div className="inner_according_row">
                                         <div className="inner_according_row_left">
                                                {k}
                                            </div>
                                            <div className="inner_according_row_right">
                                                <ul>
                                            <li>{comInfoNoPar[k][0]} %</li>
                                            <li>{comInfoNoPar[k][1]} %</li>
                                            <li>{comInfoNoPar[k][2]} %</li>
                                            <li>{comInfoNoPar[k][3]} %</li>
                                            <li>{comInfoNoPar[k][4]} %</li>
                                            </ul>
                                            </div>
                                            </div>
                                          </>
                                      }  
                                            </>
                                                 )}
                                    </>
                }
                </div>
    )
}


export default CommissionStructureCompsIn;
