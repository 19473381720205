import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useLocation } from "react-router-dom";
import _ from "lodash";

import DateSelector from "../DateSelector";
import DayCompOffers from "./DayCompOffers";
import TermsCompOffers from "./TermsCompOffers";
import OffersTextFields from "./OffersTextFields";
import { setOffersFieldsData, addOffers, editOffers, showToastAction, hideToastAction, showLoaderAction, hideLoaderAction, setTimingsDataFromStoreTime } from "../../../actions/actions";
import {NumericOfferFields, ALLTIME, DPData, DiscData, MandatoryOfferFields} from "../../../config/consts";
import {SaveBusinessDetails, getSource, getCurrentURIParams, UpdateDocId} from "../../../services/common";

const DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const CloneWeekDays = () =>{
    return DAYS.map((d)=>{
        return {
            displayText: d,
            timings: [],
            checked: false,
            respText: d.substring(0, 3)
        }
    });
}

const AddOffers = ({ handleClose, offerIndex, editOffer, editIndex }) => {

  const location = useLocation();
  const dispatch = useDispatch();
  const offersDetails = useSelector((state) => {
    return state.offersDetails;
  });
  const offerList = useSelector((state) => {
    return state.offerList;
  });
  const discOfferNode = useSelector((state) => {
    return state.discOfferNode;
  });

  const [applyToAll, setApplyToAll] = useState(offersDetails.applyAll);
    const [offersData, setOffersData] = useState(offersDetails);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showDealPeriodDD, setShowDealPeriodDD] = useState(false);
  const [hideSubmit, setHideSubmit] = useState(false); // To hide submit when other submits are open
  const [disableIndexes,setDisableIndexes] = useState([]);
  const [saveFlag, setSaveFlag] = useState(false);
  const [resetDayTimeList, setResetDayTimeList] = useState(false);
  const [copyFromStoreTime, setCopyFromStoreTime] = useState(false);
  const [startDateTemp, setStartDateTemp] = useState(offersDetails.startDate);
  const [endDateTemp, setEndDateTemp] = useState(offersDetails.endDate);
  useEffect(()=>{
    dispatch(setOffersFieldsData({...offersData, dayTimeList: offersDetails.dayTimeList, termsList: offersDetails.termsList}, offerIndex))
  }, [offersData])
  
  useEffect(()=>{
    let r = getCurrentURIParams();
    if(discOfferNode.discount_value && offerIndex<0 && r.source==14){
      // const tagLabels = {
      //   "Zomato": "@Z",
      //   "EZ": "@ez",
      //   "DN": "@dn"
      // }
      let sTag = "@"+discOfferNode.tag.slice(0,1);
      setOffersData({...offersData, disOnDeal: DiscData[0]["val"], percentageVal: discOfferNode.discount_value, sMediaText: sTag});
    }
  }, [discOfferNode]);

  useEffect(() => {
    if (showCalendar) {
      document.body.className += " bodyfixed";
    } else {
      document.body.className = document.body.className.replace(
        "bodyfixed",
        ""
      );
    }
  }, [showCalendar]);
  
  useEffect(()=>{
    if(editOffer && offersData.dealPeriod != ALLTIME){
      const startDate = offersData.startDate;
      const endDate = offersData.endDate;
      handleSetDisabledIndexes(startDate, endDate);
    }else if(copyFromStoreTime && offersData.dealPeriod != ALLTIME){
      const startDate = offersData.startDate;
      const endDate = offersData.endDate;
      handleSetDisabledIndexes(startDate, endDate);
    }else if(offersData.dealPeriod == ALLTIME){
      setDisableIndexes([]);
    }
    handleTimeSetFromStore();
  }, [offersData])


  const compData = useSelector((state) => {
    return state;
  });

  const handleTimeSetFromStore = () => {
     /* Code for copying time */
     if(offerList.length==0){
      let storeTimings = _.cloneDeep(compData.storeTimingDetails.storeTimings);
      if(storeTimings && storeTimings.length>0){
        let dayTimeListC = CloneWeekDays();
        storeTimings.forEach((d, oi)=>{
            d[1].forEach((tim)=>{
              if(tim[0]!==tim[1]){
                dayTimeListC[oi]["timings"].push({ startTime: tim[0], endTime: tim[1], startIndex: "", endIndex: "" });
                dayTimeListC[oi]["checked"]=true;
              }
            })
        })
        dispatch(setTimingsDataFromStoreTime(dayTimeListC));
        setCopyFromStoreTime(true);
      }
    }
  }

  const getPropDay = (day) => {
    let dayArr = [7, 1, 2, 3, 4, 5, 6];
    return dayArr[day];
  }

  const handleSetDisabledIndexes = (startDate, endDate) => {
    let startDateCalc = new Date(startDate);
    let endDateCalc = new Date(endDate);
    let startDateTime = startDateCalc.getTime();
    let endDateTime = endDateCalc.getTime();
    let diff = endDateTime-startDateTime;
    // 1000ms * 3600*24
    let numDays = diff/(1000*3600*24);
    if(numDays<6){
      const startUTCDay = getPropDay(startDateCalc.getDay());
      const endUTCDay = endDateCalc?getPropDay(endDateCalc.getDay()):startUTCDay;
      //ex
      //2-5 - disable 1,6,7
      //5-2 - disable 3,4
      let disA = [];
      if(startUTCDay>endUTCDay){ 
        for(let i=1;i<=7;i++){
          if(i<startUTCDay && i>endUTCDay){
            disA.push(i);
          }
        }
      }
      if(endUTCDay>startUTCDay){ 
        for(let i=1;i<=7;i++){
          if(i<startUTCDay || i>endUTCDay){
            disA.push(i);
          }
        }
      }

      if(endUTCDay==startUTCDay){ 
        for(let i=1;i<=7;i++){
          if(i!=endUTCDay){
            disA.push(i);
          }
        }
      }
      setResetDayTimeList(!resetDayTimeList);
      setDisableIndexes(disA);
    }else{
      setDisableIndexes([]);
    }

  }
  // select date and format it to MM-DD-YYYY
  const selectDates = (e) => {
    const startDate = e[0].toLocaleDateString("en-CA");
    const endDate = e[1]
      ? e[1].toLocaleDateString("en-CA")
      : e[0].toLocaleDateString("en-CA");
    setStartDateTemp(startDate);
    setEndDateTemp(endDate);
  };



  const compareWeekLists = (startDateTime, endDateTime, existingDTList) => {
    

  }
 
  const validateTimeFrame = (startDateTime, endDateTime) => {
    let clonedOffList = _.cloneDeep(offerList);
    if(offersData.dealPeriod==DPData[1]["val"]){ //All time offer
      let selectedDTList = offersData.dayTimeList;
      clonedOffList.forEach((offEachDet)=>{
        if(offEachDet.dealPeriod==DPData[1]["val"]){ //All time offer of other existing offers
          let existingDTList = offEachDet.dayTimeList;
          compareWeekLists(startDateTime, endDateTime, existingDTList)
          
        }

      })



    } 
   
   
   
   
   
   
   
   
   
   
    // let falls = true;;
    // let clonedOffList = _.cloneDeep(offerList);
    // clonedOffList.forEach((offDet)=>{
    //   let startOffDate = new Date(offDet["startDate"]);
    //   let endOffDate = new Date(offDet["endDate"]);
    //   let sTime = startOffDate.getTime();
    //   let eTime = endOffDate.getTime();
    //   if(sTime<=startDateTime && eTime>=startDateTime){
    //     falls = false;
    //   }
    //   if(sTime<=endDateTime && eTime>=endDateTime){
    //     falls = false;
    //   }
    // })
    // return falls;
  }


  const handleOffersChange = (e) => {
      if(NumericOfferFields.includes(e.target.name)){
        if(e.target.name=="percentageVal"){ //percentage val
          if(e.target.value>100){
            dispatch(showToastAction("Discount too high."));
            setTimeout(function () {
              dispatch(hideToastAction());
            }, 3000);
            return false;
          }
        }
        if(e.target.name=="minOrderVal"){ //min order val
          if(e.target.value>10000){
            dispatch(showToastAction("Minimum Order value cannot be greater than 10,000"));
            setTimeout(function () {
              dispatch(hideToastAction());
            }, 3000);
            return false;
          }
        }
        if(e.target.name=="maxDiscVal"){ //max disc val
          if(e.target.value>100000){
            dispatch(showToastAction("Max Discount value cannot be greater than 1,00,000"));
            setTimeout(function () {
              dispatch(hideToastAction());
            }, 3000);
            return false;
          }
        }
        if(e.target.name=="rupeeVal"){ //rupee val
          if(e.target.value>10000){
            dispatch(showToastAction("Flat Rupee value cannot be greater than 10,000"));
            setTimeout(function () {
              dispatch(hideToastAction());
            }, 3000);
            return false;
          }
        }
        if(!isNaN(e.target.value)){
          if(e.target.value>=0)
          setOffersData({ ...offersData, [e.target.name]: e.target.value });
        }
      }else{
        if(e.target.name=="description" || e.target.name=="dealName"){ //deal name and dsecription
          if(e.target.value.length>1000){
            dispatch(showToastAction("Max length allowed - 1000 characters"));
            setTimeout(function () {
              dispatch(hideToastAction());
            }, 3000);
            return false;
          }
        }
        setOffersData({ ...offersData, [e.target.name]: e.target.value });
      }
  }

  const fieldValidations = () => {
     // Validation of the fields
     let emptyFlag = false;
    let zeroFlag = false, percentDiscLow = false, percentDiscHigh = false;
    let noNumericFlag = false;
     //Empty check individual fields
     MandatoryOfferFields.forEach((fname)=>{
       let valu = offersData[fname].trim();
       if(!valu){
         emptyFlag = true;
       }
     })

     NumericOfferFields.forEach((fname)=>{
       let va = offersData[fname];
       if(isNaN(va)){
        noNumericFlag = true;
       }
     })
     
     // IF deal period is seletced to ltd time, then date fields are mandatory
     if(offersData["dealPeriod"]==DPData[0]["val"]){ //Limited time offer
       if(!offersData.startDate || !offersData.endDate){
         emptyFlag = true;
       }
     }
       // IF disc on deeal is selected as percentage then check for percent field filled, else ruppe field. 
       if(offersData["disOnDeal"]==DiscData[0]["val"]){ //Percentage
         if(offersData["percentageVal"]<5){
          percentDiscLow = true;
         }
	 if(offersData["percentageVal"]>75){
          percentDiscHigh = true;
         }
         if(!offersData["percentageVal"]){
           emptyFlag = true;
         }
       }else if(offersData["disOnDeal"]==DiscData[1]["val"]){ //Percentage
        if(offersData["rupeeVal"]==0){
          zeroFlag = true;
        }
         if(!offersData["rupeeVal"]){
           emptyFlag = true;
         }
       }
      
    if(noNumericFlag){
      dispatch(showToastAction("Please fill valid numeric values."));
      setTimeout(function () {
        dispatch(hideToastAction());
      }, 3000);
      return false;
    }
    if(zeroFlag){
      dispatch(showToastAction("Please fill values greater than zero."));
      setTimeout(function () {
        dispatch(hideToastAction());
      }, 3000);
      return false;
    }
     if(emptyFlag){
       dispatch(showToastAction("Please fill all mandatory fields"));
       setTimeout(function () {
         dispatch(hideToastAction());
       }, 3000);
       return false;
     }
     if(percentDiscLow==true){
  	    dispatch(showToastAction("Discount too low."));
              setTimeout(function () {
              dispatch(hideToastAction());
            }, 3000);
            return false;
     }
     if(percentDiscHigh==true){
	  dispatch(showToastAction("Discount too High."));
              setTimeout(function () {
              dispatch(hideToastAction());
            }, 3000);
            return false;
     }
     return true; 
  }
  const timeValidations = () => {
    let clonedOfferData = _.cloneDeep(offersDetails["dayTimeList"]);
    let notFilled = false;
    let leastOneTrue = false;
    clonedOfferData.forEach((dayInf)=>{
      if(dayInf.checked==true){
         leastOneTrue=true;
      }
    })
  if(!leastOneTrue){
    dispatch(showToastAction("Please select at least one day."));
     setTimeout(function () {
       dispatch(hideToastAction());
     }, 3000);
     return false;
  }
  return true;
  }

  const handleSubmit = () => {
    if(!fieldValidations()){
      return false;
    }

    if(!timeValidations()){
      return false;
    }

    //Filter time fields which aren't complete filled before saving.
    let clonedOfferDetails = _.cloneDeep(offersDetails);
    let dtList = clonedOfferDetails["dayTimeList"];

    let newDtList =  _.cloneDeep(dtList);
    dtList.forEach((day, i)=>{
        if(day['checked']==true){
         
        } 
    });
    clonedOfferDetails["dayTimeList"] = newDtList;
      if(editOffer){
        dispatch(editOffers(clonedOfferDetails, editIndex));
      }else{
        dispatch(addOffers(clonedOfferDetails));
      }
      setSaveFlag(true);
  }

  useEffect(()=>{
    if(saveFlag){
      dispatch(showLoaderAction());
      SaveBusinessDetails(compData, location.pathname, "offersDet").then((res)=>{
        let source = getSource();
        let currentUriParams = getCurrentURIParams();
        if(source!=14){
          UpdateDocId(currentUriParams.docid).then((res) => {
            handleClose();
            setSaveFlag(false);
            dispatch(hideLoaderAction());
         }).catch((e)=>{
            console.log("Updating error ",e)
            dispatch(hideLoaderAction());
         })
        }else{
          handleClose();
          setSaveFlag(false);
          dispatch(hideLoaderAction());
        }
      });
    }
  }, [saveFlag]);
  
  const handleDatesSubmit = () => {
    handleSetDisabledIndexes(startDateTemp, endDateTemp);
    setOffersData({ ...offersData, startDate: startDateTemp, endDate: endDateTemp});
  }

  function getTomorrow() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // even 32 is acceptable
    return tomorrow;
  }
  return (
    <>
      <section className="addDeliveryDetPopUp">
        <div className="pl-20 pr-20"> 
          <div className="seller_header_wpr">
            <div className="seller_header_outer">
              <header className="seller_header">
                <div className="header_home">
                  <div className="header_home_left">
                    <div className="seller_headback_icon seller_icon" onClick={() => handleClose()}>
                    </div>
                    <div>
                      <span className="jdseller_logo"></span>
                    </div>
                  </div>
                  <div className="header_home_center">
                    <div className="home_center text_center hdr_middle_text">
                      <div className="header_home_text font16 fw600 color1A1">Offer Details</div>
                    </div>
                  </div>
                  <div className="header_home_right"></div>
                </div>
              </header>
            </div>
          </div>
        </div>

        <div>
          <div className="pl-20 pt-20 pr-20 pb-70">
            <OffersTextFields handleChange={handleOffersChange} mandatory={true} fieldName="dealName" fieldLabel="Deal Name" fieldVal={offersData.dealName} />
            <div className="group mb-20 pt-15 textarea_para">
              <textarea rows={1} className="inputMaterial font16 fw600" type={"text"} required="" onChange={(e)=>handleOffersChange(e)} name={"description"} value={offersData.description} />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label className="label-wrap font16">{"Description"}*</label>
            </div>
            {/* <OffersTextFields handleChange={handleOffersChange} mandatory={true} fieldName="description" fieldLabel="Description" fieldVal={offersData.description} /> */}
            <div class="dealperiod_wpr mb-25">
              <div class="color777 font12">Deal Period *</div>
              <div class="dealperiod_outer mt-10">
                <div class={`dealperiod_tab ${offersData["dealPeriod"] == DPData[0]["val"] ? "act" : ""}`} onClick={() => setOffersData({ ...offersData, "dealPeriod": DPData[0]["val"] })}>{DPData[0]["label"]}</div>
                <div class={`dealperiod_tab ${offersData["dealPeriod"] == DPData[1]["val"] ? "act" : ""}`} onClick={() => { setShowDealPeriodDD(!showDealPeriodDD); setOffersData({ ...offersData, "dealPeriod": DPData[1]["val"] }) }}>{DPData[1]["label"]}</div>
              </div>
            </div>
            {
              offersData.dealPeriod != ALLTIME && offersData["dealPeriod"] &&
              <div className="towcol_flex">
                <div className="group mb-20" onClick={() => setShowCalendar(true)}>
                  <input className="inputMaterial font16 fw600" disabled type="text" required="" value={offersData.startDate} />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label className="label-wrap font16"> Start Date *</label>
                </div>
                <div className="group mb-20" onClick={() => setShowCalendar(true)}>
                  <input className="inputMaterial font16 fw600" disabled type="text" required="" value={offersData.endDate} />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label className="label-wrap font16"> End Date *</label>
                </div>
              </div>
            }
            <div class="dealperiod_wpr mb-25">
              <div class="color777 font12">Discount on Deal *</div>
              <div class="dealperiod_outer mt-10">
                <div class={`dealperiod_tab ${offersData["disOnDeal"] == DiscData[0]["val"] ? "act" : ""}`} onClick={() => setOffersData({ ...offersData, "disOnDeal": DiscData[0]["val"] })}>{DiscData[0]["label"]}</div>
                <div class={`dealperiod_tab ${offersData["disOnDeal"] == DiscData[1]["val"] ? "act" : ""}`} onClick={() => { setShowDealPeriodDD(!showDealPeriodDD); setOffersData({ ...offersData, "disOnDeal": DiscData[1]["val"] }) }}>{DiscData[1]["label"]}</div>
              </div>
            </div>
            {
              offersData.disOnDeal == DiscData[0]["val"] ?
                <OffersTextFields handleChange={handleOffersChange} type="number" mandatory={true} fieldName="percentageVal" fieldLabel="Percentage Value" fieldVal={offersData.percentageVal} sMediaText={offersData.sMediaText} />
                 :
                <OffersTextFields handleChange={handleOffersChange} type="number"  mandatory={true} fieldName="rupeeVal" fieldLabel="Rupee Value" fieldVal={offersData.rupeeVal} />
            }
            <OffersTextFields handleChange={handleOffersChange} type="number" fieldName="minOrderVal" fieldLabel="Minimum Order Value" fieldVal={offersData.minOrderVal} />
          { offersData.disOnDeal == DiscData[0]["val"] &&  <OffersTextFields handleChange={handleOffersChange} type="number" fieldName="maxDiscVal" fieldLabel="Maximum Discount Value" fieldVal={offersData.maxDiscVal} /> }
            
            {/*Time Comp Code */}
            {
              ((offersDetails.dealPeriod && offersDetails.dealPeriod!=ALLTIME && offersData.startDate && offersData.endDate) || offersDetails.dealPeriod && offersDetails.dealPeriod==ALLTIME) &&
             <>
              <DayCompOffers editOfferIndex={editIndex} applyToAll={applyToAll} setApplyToAll={setApplyToAll} offerIndex={offerIndex} dayTimeListEdit={offersDetails.dayTimeList} editOffer={editOffer} copyFromStoreTime={copyFromStoreTime} disableIndexes={disableIndexes} resetDayTimeList={resetDayTimeList}/>
              </>
            }
            {/*End of Time Comp Code */}

            {/* Terms and conditions Code */}
            {/* <TermsCompOffers setHideSubmit={setHideSubmit} offerIndex={offerIndex} termsListEdit={offersDetails.termsList} editOffer={editOffer}/> */}

            {/*End of Terms and conditions code */}
            {
              !hideSubmit && 
              <div class="ftr_btn">
                <button class="font14 fw600 colorfff" onClick={handleSubmit}>Activate this Deal</button>
              </div>
            }
            {
              showCalendar &&
              <DateSelector
                selectDates={selectDates}
                setShowCalendar={setShowCalendar}
                data={[startDateTemp, endDateTemp]}
                minDate={getTomorrow()}
                handleSubmit={handleDatesSubmit}
              />
            }
          </div>
        </div>
      </section>
    </>
  )
}

export default AddOffers;
