import { combineReducers } from "redux";
import locationReducer from "./locationReducer";
import initialBusinessDataReducer from "./initialBusinessDataReducer";
import {
  businessDetails,
  locationDetails,
  contactDetails,
  pickupDetails,
  storeTimingDetails,
  loadDocData,
  offersDetails,
  offerList,
  discOfferNode
} from "./dataReducer";
import ToastReducer from "./ToastReducer";
import LoaderReducer from "./LoaderReducer";
import HelpReducer from "./HelpReducer";

export default combineReducers({
  locationReducer,
  businessDetails,
  contactDetails,
  locationDetails,
  initialBusinessDataReducer,
  storeTimingDetails,
  pickupDetails,
  loadDocData,
  ToastReducer,
  LoaderReducer,
  HelpReducer,
  offersDetails,
  offerList,
  discOfferNode
});
