import React, { useState, useEffect } from "react";
import TimeComp from "./TimeComp";

const DayListComp = ({ day, dayInd, selectTime, addTimeSlot, handleChecked, handleApplyToAll, applyToAll, handleDeleteTimeSlot, disabledSelection}) => {

    const [showIndex, setShowIndex] = useState();

    return (
        <>
        {
            day.checked && day.checked!="0" && !disabledSelection ?
                <li className="editDayTimeli">
                    <div class="dealdata_row">
                        <div class="dealdata_col">
                            <label class="daylbl_outer font14 fw600 color111" onClick={(e)=>handleChecked(e, dayInd)}>
                                <span class="daylbl mr-12">
                                    <input type="checkbox" name="" checked={day.checked && day.checked!="0"}/>
                                    <span class="daylbl_circle"></span>
                                </span>
                                {day.displayText}
                            </label>
                        </div>

                    </div>
                    
           </li>
                :
                <li>
                    <div class="dealdata_row">
                        <div class="dealdata_col">
                            <label class="daylbl_outer font14 fw600 color111" onClick={(e)=>handleChecked(e, dayInd, disabledSelection)}>
                                <span class="daylbl mr-12">
                                    <input type="checkbox" name="" checked={day.checked && day.checked!="0"}/>
                                    <span class="daylbl_circle"></span>
                                </span>
                                {day.displayText}
                            </label>
                        </div>
                    </div>
                </li>

        }
        </>
    )

}

export default DayListComp;
