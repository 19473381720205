
let init = [];

const initialBusinessDataReducer = (state = init, action) => {
    switch (action.type) {
    case 'SET_INITIAL_BUSINESS_DATA': 
        const data = action.data;
        return [...data];
    default:
        return state;
    }
}

export default initialBusinessDataReducer;