import React, {useState, useEffect} from "react";
import CommissionStructureComps from "./commissionStructureComps";
import {getCommissionStrLvl0} from "../../services/common";
import PricingCalculator from "../pages/PricingCalculator";

const CommissionStructure = () =>{

    const [activeCharTab, setActiveCharTab] = useState("comFee");
    const [comissionL0D, setComissionL0D]  = useState();
    useEffect(()=>{
        getCommissionStrLvl0().then((res)=>{
            console.log(res);
            if(res.data && res.data.results && res.data.results.data)
            setComissionL0D(res.data.results.data);

        })
    },[0])
    return (
      
    <section className="commission_fee_wpr">
    <div className="container">
        <div className="commission_fee_outer" data-aos="fade-up">
            <div className="commission_fee_title">Fees Charged</div>
            <div className="commission_fee_text">Find out your Justdial Selling Fees</div>
            <div className="commission_chart_outer">
                <div className="commission_chart_tabs">
                    <ul>
                        <li className={activeCharTab=="comFee"?"act":""} onClick={()=>setActiveCharTab("comFee")}>Commission Fee</li>
                        <li className={activeCharTab=="closeFee"?"act":""} onClick={()=>setActiveCharTab("closeFee")}>Fixed Fee</li>
                        <li className={activeCharTab=="pricingCalculator"?"act":""} onClick={()=>setActiveCharTab("pricingCalculator")}>Pricing Calculator</li>
                    </ul>
                </div>
                <div className="commission_chart_content">
                    <div  className={activeCharTab=="comFee"?"":"dn"}>
                        <div className="commission_chart_title">commission structure</div>
                        {/* Here lies an array.... */}
                        {
                            comissionL0D && comissionL0D.map((row)=>
                            <CommissionStructureComps title={row[0]} cid={row[8]} key={row[8]}/>
                            )
                        }
                    </div>

                    <div className={activeCharTab=="closeFee"?"":"dn"}>
                        <div className="closing_chart_title">Fixed Fee</div>
                        <div className="closefee_wpr mt-25">
                            <div className="closefee_chart">
                                <div className="closefee_hdr">
                                    <div className="closefee_th">Jd Range for Fixed Fee <span className="ml-5">&#8377;</span></div>
                                    <div className="closefee_th">Jd Fixed fee <span className="ml-5">&#8377;</span></div>
                                </div>
                                <div className="closefee_row">
                                    <div className="closefee_td">0-500</div>
                                    <div className="closefee_td">0</div>
                                </div>
                                <div className="closefee_row">
                                    <div className="closefee_td">501-1000</div>
                                    <div className="closefee_td">10</div>
                                </div>
                                <div className="closefee_row">
                                    <div className="closefee_td">1001-1500</div>
                                    <div className="closefee_td">20</div>
                                </div>
                                <div className="closefee_row">
                                    <div className="closefee_td">1501-3000</div>
                                    <div className="closefee_td">30</div>
                                </div>
                                <div className="closefee_row">
                                    <div className="closefee_td">>3001</div>
                                    <div className="closefee_td">50</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={activeCharTab=="pricingCalculator"?"":"dn"}>
                         <PricingCalculator />
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>


    )
}

export default CommissionStructure;
