import React, { useEffect, useState } from "react";
import { TimngDropDown } from "./timeDropDnCmp";
import { useDispatch } from "react-redux";
import { showToastAction, hideToastAction } from "../../actions/actions";

// convert 12 hours to 24 hour format
const convertTime = (timeStr) => {
  const [time, modifier] = timeStr.split(" ");
  let [hours, minutes] = time.split(":");
  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "pm") {
    hours = parseInt(hours, 10) + 12;
  }
  return { hours, minutes };
};

// convert startTime and endtime to date objects
const getStartTimeEndTime = (data) => {
  const tempdate = new Date();
  const startTime = convertTime(data.startTime);
  const endTime = convertTime(data.endTime);
  const startDate = new Date(tempdate);
  const endDate = new Date(tempdate);
  startDate.setHours(startTime.hours, startTime.minutes, 0);
  endDate.setHours(endTime.hours, endTime.minutes, 0);
  // if end time is less than start time eg. 8 am - 1 am(this is when you reach faster than light)
  if (endDate < startDate) {
    endDate.setDate(endDate.getDate() + 1);
  }
  return { startDate, endDate };
};

export const AddTimeList = (props) => {
  const weekDayConst = [
    {
      displayText: "Monday",
      respText: "mon",
      timings: [{ startTime: "", endTime: "", startIndex: 0, endIndex: 0 }],
      checked: false,
    },
    {
      displayText: "Tuesday",
      respText: "tue",
      timings: [{ startTime: "", endTime: "", startIndex: 0, endIndex: 0 }],
      checked: false,
    },
    {
      displayText: "Wednesday",
      respText: "wed",
      timings: [{ startTime: "", endTime: "", startIndex: 0, endIndex: 0 }],
      checked: false,
    },
    {
      displayText: "Thursday",
      respText: "thu",
      timings: [{ startTime: "", endTime: "", startIndex: 0, endIndex: 0 }],
      checked: false,
    },
    {
      displayText: "Friday",
      respText: "fri",
      timings: [{ startTime: "", endTime: "", startIndex: 0, endIndex: 0 }],
      checked: false,
    },
    {
      displayText: "Saturday",
      respText: "sat",
      timings: [{ startTime: "", endTime: "", startIndex: 0, endIndex: 0 }],
      checked: false,
    },
    {
      displayText: "Sunday",
      respText: "sun",
      timings: [{ startTime: "", endTime: "", startIndex: 0, endIndex: 0 }],
      checked: false,
    },
  ];

  // data to populate
  const data = props.data.reduce((acc, elem) => {
    acc[elem[0]] = elem[1];
    return acc;
  }, {});

  const mapWeekdays =
    data && Object.keys(data).length
      ? weekDayConst.map((item) => {
          if (
            (data[item.respText][0][0] === "00:00" &&
              data[item.respText][0][1] === "00:00") ||
            (data[item.respText][0][0] === "12:00 am" &&
              data[item.respText][0][1] === "12:00 am")
          ) {
            return {
              displayText: item.displayText,
              respText: item.respText,
              timings: [{ startTime: "", endTime: "" }],
              checked: false,
              // closedText: "Closed",
            };
          }
          if (
            (data[item.respText][0][0] === "00:00" &&
              data[item.respText][0][1] === "23:59") ||
            (data[item.respText][0][0] === "12:00 am" &&
              data[item.respText][0][1] === "11:59 pm")
          ) {
            return {
              displayText: item.displayText,
              respText: item.respText,
              timings: [{ startTime: "", endTime: "" }],
              checked: false,
            };
          }
          const timings = data[item.respText].map((dataitem) => {
            return {
              startTime: dataitem[0],
              endTime: dataitem[1],
            };
          });
          return {
            displayText: item.displayText,
            respText: item.respText,
            timings: timings,
            checked: true,
          };
        })
      : weekDayConst;

  const [openForHours, setOpenHours] = useState(false);
  useEffect(() => {
    const defaultopenForhours =
      data && Object.keys(data).length
        ? Object.keys(data).every(
            (item) =>
              (data[item][0][0] === "00:00" && data[item][0][1] === "23:59") ||
              (data[item][0][0] === "12:00 am" &&
                data[item][0][1] === "11:59 pm")
          )
        : false;
    setOpenHours(defaultopenForhours);
  }, [props.data]);
  // code start here
  const dispatch = useDispatch();

  const popupName = props.header;
  const [weekdays, setTimings] = useState([...mapWeekdays]);
  const [isApplyAll, setApplyAll] = useState(false);

  const selectTiming = (timeIndex, weekindex, defaultData, dropDownName) => {
    let data = { ...defaultData };
    let cloneweekDays = [...weekdays];
    // we can refactor this but let it be like this
    // too lazy to do it
    if (dropDownName === "first") {
      const selectedDate = getStartTimeEndTime(data);
      const isOverlaped = cloneweekDays[weekindex].timings.reduce(
        (acc, elem, index) => {
          if (timeIndex === index) return acc;
          else {
            const prevDate = getStartTimeEndTime(elem);
            // 1. currdate overlap existing
            if (
              prevDate.startDate <= selectedDate.startDate &&
              prevDate.endDate >= selectedDate.startDate
            ) {
              dispatch(showToastAction("Overlapping Timming are not Allowed"));
              setTimeout(function () {
                dispatch(hideToastAction());
              }, 3000);
              return true;
            }
            if (data.endTime) {
              // 2. existing date overlap currdate
              if (
                (selectedDate.startDate <= prevDate.startDate &&
                  selectedDate.endDate >= prevDate.startDate) ||
                (selectedDate.startDate <= prevDate.endDate &&
                  selectedDate.endDate >= prevDate.endDate)
              ) {
                dispatch(
                  showToastAction("Overlapping Timming are not Allowed")
                );
                setTimeout(function () {
                  dispatch(hideToastAction());
                }, 3000);
                return true;
              }
            }
          }
          return acc;
        },
        false
      );
      if (isOverlaped) {
        data = { startTime: "", endTime: "" };
      }
    }
    if (dropDownName === "second") {
      const selectedDate = getStartTimeEndTime(data);
      const isOverlaped = cloneweekDays[weekindex].timings.reduce(
        (acc, elem, index) => {
          if (timeIndex === index) return acc;
          else {
            const prevDate = getStartTimeEndTime(elem);
            // 1. currdate overlap existing
            if (
              prevDate.startDate <= selectedDate.endDate &&
              prevDate.endDate >= selectedDate.endDate
            ) {
              dispatch(showToastAction("Overlapping Timming are not Allowed"));
              setTimeout(function () {
                dispatch(hideToastAction());
              }, 3000);
              return true;
            }
            // 2. existing date overlap currdate
            if (
              (selectedDate.startDate <= prevDate.startDate &&
                selectedDate.endDate >= prevDate.startDate) ||
              (selectedDate.startDate <= prevDate.endDate &&
                selectedDate.endDate >= prevDate.endDate)
            ) {
              dispatch(showToastAction("Overlapping Timming are not Allowed"));
              setTimeout(function () {
                dispatch(hideToastAction());
              }, 3000);
              return true;
            }
          }
          return acc;
        },
        false
      );
      if (isOverlaped) {
        data.endTime = "";
      }
    }
    cloneweekDays[weekindex].timings[timeIndex] = {
      ...data,
    };
    setTimings([...cloneweekDays]);
  };

  // add new dropdown element
  const AddnewDrpDwn = (day, index) => {
    const cloneweekDays = [...weekdays];
    const timingLen = cloneweekDays[index].timings.length;
    const isprevFilled = cloneweekDays[index].timings.every(
      (elem) => elem.startTime && elem.endTime
    );
    if (timingLen < 2 && isprevFilled) {
      cloneweekDays[index].timings.push({
        startTime: "",
        endTime: "",
        startIndex: 0,
        endIndex: 0,
      });
      setTimings([...cloneweekDays]);
    }
  };

  // remove dropdown select
  const removeTiming = (weekindex, timeIndex) => {
    const cloneweekDays = [...weekdays];
    cloneweekDays[weekindex].timings.splice(timeIndex, 1);
    setTimings([...cloneweekDays]);
  };

  // mark check box in weekdays
  const selectWeek = (index) => {
    if (!openForHours) {
      const cloneweekDays = [...weekdays];
      const val = !cloneweekDays[index].checked;
      cloneweekDays[index].checked = val;
      // if data is already populated and then empty it by adding default values
      if (!val) {
        cloneweekDays[index].timings = [
          { startTime: "", endTime: "", startIndex: 0, endIndex: 0 },
        ];
      }
      setTimings([...cloneweekDays]);
    }
  };

  // openform 24 hours response foirmat logc
  const openFor24FormatLogic = (day) => {
    const dayText = day.respText;
    const timingData = [["00:00", "23:59"]];
    return [dayText, timingData];
  };
  // normal timing response format logic normal/closed
  const normalTimingsLogic = (day) => {
    const dayText = day.respText;
    if (!day.checked) {
      const timingData = [["00:00", "00:00"]];
      return [dayText, timingData];
    }
    const timingData = day.timings
      .filter((timing) => timing.startTime && timing.endTime)
      .map((timing) => [timing.startTime, timing.endTime]);
    return [dayText, timingData];
  };

  // submit popup
  const submit = (submitWeekdays) => {
    const storeTimings = openForHours
      ? submitWeekdays.map(openFor24FormatLogic)
      : submitWeekdays.map(normalTimingsLogic);
    const checkValid = storeTimings.every((elem) => elem[1].length);
    if (checkValid) {
      props.submit(storeTimings);
    } else {
      dispatch(showToastAction("Please Fill Selected Timings"));
      setTimeout(function () {
        dispatch(hideToastAction());
      }, 3000);
    }
  };

  // toggle open hours flag
  const changeOpenForHours = () => {
    const val = !openForHours;
    setTimings([...weekDayConst]);
    setApplyAll(false);
    setOpenHours(val);
  };

  // toggle aply chnage button
  const applyChange = () => {
    let val = !isApplyAll;
    if (val) {
      const cloneweekDays = [...weekdays];
      const mondaytiming = cloneweekDays[0].timings;
      const mondaytimingCheck =
        mondaytiming[0].startTime === "" || mondaytiming[0].endTime === "";
      if (!mondaytimingCheck) {
        const newWeekdays = cloneweekDays.map((elem) => ({
          ...elem,
          checked: true,
          timings: mondaytiming,
        }));
        setTimings([...newWeekdays]);
        setApplyAll(true);
      } else {
        setApplyAll(false);
      }
    } else {
      const cloneweekDays = [...weekdays];
      // const mondaytiming = cloneweekDays[0].timings;
      const newWeekdays = cloneweekDays.map((elem, index) => {
        if (index) {
          const data = {
            ...elem,
            checked: false,
            timings: [
              { startTime: "", endTime: "", startIndex: 0, endIndex: 0 },
            ],
          };
          return data;
        }
        return elem;
        //
      });
      setTimings([...newWeekdays]);
      setApplyAll(val);
    }
  };

  return (
    <div className="ob_popup_outer">
      <div className="ob_popup_bottom pb-70">
        <div className="popcontainer">
          <div className="font16 color1a1 fw700 mt-20 pl-20 pr-20">
            {" "}
            {popupName}{" "}
            <div
              className="closeBx popup_hdr_close"
              onClick={props.closetiming}
            >
              {" "}
              <i className="time_sob_closeicn"></i>
            </div>
          </div>
          <div className="include_tax pl-20 pr-20 mt-30">
            <label className="font12 color111">
              <input
                type="checkbox"
                name=""
                checked={openForHours}
                onClick={changeOpenForHours}
              />
              <span className="type_checkbox mr-10"></span>
              Open For 24Hours
            </label>
          </div>
          <div className="editDayTimeUl">
            {weekdays.map((days, index) => (
              <div className="editDayTimeli" key={index + days.displayText}>
                <div className="dtbl">
                  <div className="dcell tickbx">
                    <div className="include_tax">
                      <label className="font12 color111 ">
                        <input
                          type="checkbox"
                          name=""
                          checked={days.checked}
                          onChange={(e) => selectWeek(index)}
                        />{" "}
                        <span className="type_checkbox mr-10"></span>
                      </label>
                    </div>
                  </div>
                  <div className="dcell">
                    <div className="dflex">
                      <div
                        className="font14 color1a1 fw500"
                        onClick={(e) => selectWeek(index)}
                      >
                        {" "}
                        {days.displayText}
                      </div>
                      {!days.checked && (
                        <div className="font12 colord61 fw500">
                          {" "}
                          {!openForHours ? "Closed" : "Open For 24 Hours"}{" "}
                        </div>
                      )}
                      {index === 0 && days.checked && (
                        <div className="font12 color777 fw500">
                          <div className="group applytoggle">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={isApplyAll}
                                onChange={applyChange}
                              />
                              <span className="slider"></span>
                            </label>
                            <span className="aplytoalltx"> Apply to all</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {days.checked &&
                      days.timings.map((time, timeIndex) => (
                        <TimngDropDown
                          key={timeIndex + days.displayText + Math.random()}
                          day={days}
                          selectTiming={selectTiming}
                          timeIndex={timeIndex}
                          weekindex={index}
                          remove={removeTiming}
                          default={time}
                          timeSlotIndex2={
                            timeIndex
                              ? days.timings[timeIndex - 1].timeSlotIndex2
                              : 0
                          }
                        />
                      ))}
                    {days.checked && days.timings.length < 2 && (
                      <div
                        className="font13 color007 fw500 mt-20"
                        onClick={(e) => AddnewDrpDwn(days, index)}
                      >
                        {" "}
                        + Add more hours
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="ftr_btn ">
            <button
              className="font14 fw600 colorfff"
              onClick={(e) => submit(weekdays)}
            >
              {" "}
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTimeList;
