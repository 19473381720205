import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import DayListComp from "./DayListComp";
import {TimeSlotsConsts} from "../../../config/timeSlots";
import {ALLTIME} from "../../../config/consts";
import { showToastAction, hideToastAction, setOffersDayTimeList, setOffersDayTimeApplyAll } from "../../../actions/actions";
import _ from "lodash";

const DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const CloneWeekDays = () =>{
    return DAYS.map((d)=>{
        return {
            displayText: d,
          //  timings: [{ startTime: "", endTime: "", startIndex: "", endIndex: "" }],
            checked: false,
            respText: d.substring(0, 3)
        }
    });
}
const DayCompOffers = ({editOfferIndex, offerIndex, dayTimeListEdit, editOffer, applyAllP, disableIndexes, resetDayTimeList, copyFromStoreTime, applyToAll, setApplyToAll}) => {
    const dispatch = useDispatch();
    const [dayTimeList, setDayTimeList] = useState(editOffer?dayTimeListEdit:(copyFromStoreTime?dayTimeListEdit:CloneWeekDays()));
    const offerList = useSelector((state) => {
        return state.offerList;
      });

    const offersDetails = useSelector((state) => {
        return state.offersDetails;
    });

    useEffect(()=>{
        dispatch(setOffersDayTimeList(dayTimeList, offerIndex));
    },[dayTimeList]);

    useEffect(()=>{
        let cloneD = _.cloneDeep(dayTimeList);
        let constWD = CloneWeekDays();
        let dayTimeArr = [];
        cloneD.forEach((r,i)=>{
            let j=i+1;
            if(!disableIndexes.includes(j)){
                dayTimeArr.push(r)
            }else{
                dayTimeArr.push(constWD[i])
            }
        })
        setDayTimeList(dayTimeArr);
    }, [resetDayTimeList]);

    useEffect(()=>{
        //Reset if indexes change
     //   setDayTimeList(CloneWeekDays());
    }, [disableIndexes])

    const getStdTimes = (inp) =>{
        
    }

    const checkForDatesOlap = (startExDate, endExDate, startSelDate, endSelDate) => {
        startExDate = new Date(startExDate);
        endExDate = new Date(endExDate);
        startSelDate = new Date(startSelDate);
        endSelDate = new Date(endSelDate);
        let startExDateTime = startExDate.getTime();
        let endExDateTime = endExDate.getTime();
        let startSelDateTime = startSelDate.getTime();
        let endSelDateTime = endSelDate.getTime();
        if(startSelDateTime>=startExDateTime && startSelDateTime<=endExDateTime){
            return true;
        }
        if(startSelDateTime<=startExDateTime && endSelDateTime>=endExDateTime){
            return true;
        }
        return false;
    }

    const getTimeIndex = (timer) => {
        return TimeSlotsConsts.indexOf(timer);
    }
    const compareSpecificDays = (offEachDet, selectIndex, dayIndex, sETime, timeInd) => {
        let existingDTList = offEachDet.dayTimeList;
        let existingDay = existingDTList[dayIndex];
        let timingsOfExistingDay = existingDay["timings"];
        let oL = false;
        timingsOfExistingDay.forEach((timeN, i)=>{
            let startExIndex = timeN["startIndex"]?timeN["startIndex"]:getTimeIndex(timeN["startTime"]);
            let endExIndex = timeN["endIndex"]?timeN["endIndex"]:getTimeIndex(timeN["endTime"]);; 
            if(checkForBetweenIndex(selectIndex, startExIndex, endExIndex, sETime, dayIndex, i, timeInd)){
                oL = true;
            }
        })
        return oL;
    }

    const checkForBetweenIndex = (selectIndex, startExIndex, endExIndex, sETime, dayInd, exTimeInd, selectTimeInd) => {
        if(selectIndex>=parseInt(startExIndex) && selectIndex<=parseInt(endExIndex)){
            return true;
        }
        if(sETime=="startTime"){
            if(dayTimeList[dayInd]["timings"][selectTimeInd]){
                let selectEndIndex = dayTimeList[dayInd]["timings"][selectTimeInd]["endIndex"];
                if(selectIndex<startExIndex && selectEndIndex>endExIndex){
                    return true;
                }
            }
        }
        if(sETime=="endTime"){
            if(dayTimeList[dayInd]["timings"][selectTimeInd]){
                let selectStartIndex = dayTimeList[dayInd]["timings"][selectTimeInd]["startIndex"];
                if(selectStartIndex<startExIndex && selectIndex>endExIndex){
                    return true;
                }
            }
        }
        return false;
    }
   
    const checkForDaysBetween = (startDate, endDate) => {
        let startDateCalc = new Date(startDate);
        let endDateCalc = new Date(endDate);
        let startDateTime = startDateCalc.getTime();
        let endDateTime = endDateCalc.getTime();
        let diff = endDateTime-startDateTime;
        // 1000ms * 3600*24
        return diff/(1000*3600*24);
    }
    
    const validateTimeFrame = (selectIndex, dayIndex, sETime, timeInd) => {
      let clonedOffList = _.cloneDeep(offerList);
      let overLap = [];
      if(offersDetails.dealPeriod==ALLTIME){ //All time offer
        let selectedDTList = offersDetails.dayTimeList;
        clonedOffList.forEach((offEachDet, i)=>{
         if(editOfferIndex!==i){
            if(offEachDet.dealPeriod==ALLTIME){ //All time offer of other existing offers
                overLap.push(compareSpecificDays(offEachDet, selectIndex, dayIndex, sETime, timeInd));
            }else{
                let numDaysBtwn = checkForDaysBetween(offEachDet.startDate, offEachDet.endDate)
                if(numDaysBtwn<6){

                    overLap.push(compareSpecificDays(offEachDet, selectIndex, dayIndex, sETime, timeInd));

                }else{
                    overLap.push(compareSpecificDays(offEachDet, selectIndex, dayIndex, sETime, timeInd));
                }
            }
          }
        })
      }else{
        let selectedDTList = offersDetails.dayTimeList;
        clonedOffList.forEach((offEachDet,i)=>{
            if(editOfferIndex!==i){
                if(offEachDet.dealPeriod==ALLTIME){ //All time offer of other existing offers
                    overLap.push(compareSpecificDays(offEachDet, selectIndex, dayIndex, sETime, timeInd));
                }else{
                    //Check if dates over lap
                    let checkDatesOverLap = checkForDatesOlap(offEachDet.startDate, offEachDet.endDate, offersDetails.startDate, offersDetails.endDate);
                    if(checkDatesOverLap){
                        overLap.push(compareSpecificDays(offEachDet, selectIndex, dayIndex, sETime, timeInd));
                    }
                }
            }
        })
      } 
      return overLap.includes(true);
    }


  const checkOverlapTime = (index, timeInd, dayInd, timingsC) => {
    let oLap = false;
    timingsC.forEach((timing, i)=>{
        if(timing.startIndex<=index && timing.endIndex>=index && timeInd!==i){
            oLap=true;
        }
    })
    return oLap;
  }


    const selectTime = ({sETime, time, index, timeInd, dayInd}) => {
        let dayTimeListC = _.cloneDeep(dayTimeList);
        //Validate 
        let currentDayTimes = _.cloneDeep(dayTimeListC[dayInd]["timings"]);
        let oLap = false;
        currentDayTimes.forEach((timing, i)=>{
            
            let startIndexConst = timing.startIndex;
            let endIndexConst = timing.endIndex;
            if(!startIndexConst)
            startIndexConst = TimeSlotsConsts.indexOf(timing.startTime);
            
            if(!endIndexConst)
            endIndexConst = TimeSlotsConsts.indexOf(timing.endTime);

            if(startIndexConst<=index && endIndexConst>=index && timeInd!==i){
                oLap=true;
            }

            if(startIndexConst>=index && endIndexConst<=index && timeInd!==i){
                oLap=true;
            }
        })

        if(oLap){
            dispatch(showToastAction("Overlapping Timing are not Allowed"));
            setTimeout(function () {
              dispatch(hideToastAction());
            }, 3000);
            return false
        }

        
        // Time overlap against other offers check
       
        let valTimeFrame = validateTimeFrame(index, dayInd, sETime, timeInd);

        if(valTimeFrame){
            dispatch(showToastAction("Timing overlap between offers. Please verify and select other values."));
            setTimeout(function () {
              dispatch(hideToastAction());
            }, 3000);
            return false
        }














        /* End of time over lap code check */







        let sameFlag = false;
        let startGreater = false;
        let endGreater = false;
        if(sETime=="startTime"){
            dayTimeListC[dayInd]["timings"][timeInd]["startIndex"] = index;
            if(dayTimeListC[dayInd]["timings"][timeInd]["endIndex"] === index){
                sameFlag= true;
            }
            if(dayTimeListC[dayInd]["timings"][timeInd]["endIndex"] && dayTimeListC[dayInd]["timings"][timeInd]["endIndex"] < index ){
                startGreater= true;
            }
        }else{
            dayTimeListC[dayInd]["timings"][timeInd]["endIndex"] = index;
            if(dayTimeListC[dayInd]["timings"][timeInd]["startIndex"] === index){
                sameFlag= true;
            }
            if(dayTimeListC[dayInd]["timings"][timeInd]["startIndex"] && dayTimeListC[dayInd]["timings"][timeInd]["startIndex"] > index ){
                endGreater = true;
            }
        }
        
        if(sameFlag){
            dispatch(showToastAction("Timings cannot be Same"));
            setTimeout(function () {
              dispatch(hideToastAction());
            }, 3000);
            return false;
        }

        if(endGreater){
            dispatch(showToastAction("End time cannot be lesser than start time."));
            setTimeout(function () {
              dispatch(hideToastAction());
            }, 3000);
            return false;
        }

        if(startGreater){
            dispatch(showToastAction("Start time cannot be greater than end time. "));
            setTimeout(function () {
              dispatch(hideToastAction());
            }, 3000);
            return false;
        }
        let clonedDayTimeC = _.cloneDeep(dayTimeListC[dayInd]["timings"]);
        clonedDayTimeC[timeInd][sETime]= time.time; //+ time.extraText;
        dayTimeListC[dayInd]["timings"] = clonedDayTimeC;
        setDayTimeList(dayTimeListC);
        handleApplyToAllFalse();
        return true;
    }

    const addTimeSlot = ({dayInd}) => {
        let dayTimeListC = _.cloneDeep(dayTimeList);
        let timingsA = _.cloneDeep(dayTimeListC[dayInd]["timings"]);
        timingsA.push({ startTime: "", endTime: "", startIndex: "", endIndex: "" });
        dayTimeListC[dayInd]["timings"]=timingsA;
        setDayTimeList(dayTimeListC);
        handleApplyToAllFalse();
    }

    const handleDeleteTimeSlot = (timeInd, dayInd) => {
        let dayTimeListC = _.cloneDeep(dayTimeList);
        let timingsA = _.cloneDeep(dayTimeListC[dayInd]["timings"]);
        timingsA.splice(timeInd, 1);
        dayTimeListC[dayInd]["timings"]=timingsA;
        setDayTimeList(dayTimeListC);
        handleApplyToAllFalse();
    }

    const checkForDaysOverLap = (dayInd, dayTList) => {
        let yesM = false;
        dayTList && dayTList.forEach((dayD, i)=>{
            if(i==dayInd){
                if(dayD.checked==true){
                    yesM = true;
                }
            }
        })
        return yesM;
    }
    
    const validateDayFrame = (dayInd) => {
        let clonedOffList = _.cloneDeep(offerList);
        let selectedDTList = offersDetails.dayTimeList;
        let overLap = [];
        if(offersDetails.dealPeriod==ALLTIME){ //All time offer
            clonedOffList.forEach((offEachDet, i)=>{
                if(editOfferIndex!==i){
                   if(offEachDet.dealPeriod==ALLTIME){ //All time offer of other existing offers
                    overLap.push(checkForDaysOverLap(dayInd, clonedOffList[i].dayTimeList)); 
                   }else{
                    overLap.push(checkForDaysOverLap(dayInd, clonedOffList[i].dayTimeList)); 
                   }
                 }
               })
        }else{
            clonedOffList.forEach((offEachDet, i)=>{
                if(editOfferIndex!==i){
                   if(offEachDet.dealPeriod==ALLTIME){ //All time offer of other existing offers
                    overLap.push(checkForDaysOverLap(dayInd, clonedOffList[i].dayTimeList)); 
                   }else{
                    let checkDatesOverLap = checkForDatesOlap(offEachDet.startDate, offEachDet.endDate, offersDetails.startDate, offersDetails.endDate);
                    if(checkDatesOverLap){
                        overLap.push(checkForDaysOverLap(dayInd, clonedOffList[i].dayTimeList)); 
                    }
                   }
                 }
               })
        }
        return overLap.includes(true);
    }

    const handleChecked = (e, dayInd, dS) => {
        e.preventDefault();
        if(!dS){
        // Days overlap against other offers check
        let dayTimeListC = _.cloneDeep(dayTimeList);
        if(!dayTimeListC[dayInd]["checked"]){
            let valDaysFrame = validateDayFrame(dayInd);
            if(valDaysFrame){
                dispatch(showToastAction("Days overlap between offers. Please verify and select other values."));
                setTimeout(function () {
                  dispatch(hideToastAction());
                }, 3000);
                return false
            }
        }
        /* End of time over lap code check */
            dayTimeListC[dayInd]["checked"] = !dayTimeListC[dayInd]["checked"];
            setDayTimeList(dayTimeListC);
        }else{
            dispatch(showToastAction("Day is not included in selected date range."));
            setTimeout(function () {
              dispatch(hideToastAction());
            }, 3000); 
        }
        handleApplyToAllFalse();
    }

    const handleApplyToAllFalse = () =>{
        setApplyToAll(false);
        dispatch(setOffersDayTimeApplyAll(false));
    }

    const [applyAllCheckedIndex, setApplyAllCheckedIndex] = useState(0);
    const handleApplyToAll = (e) => {
        e.preventDefault();
        let dayTimeListC = _.cloneDeep(dayTimeList);
        let checkedDays = []; //dayTimeListC.filter((r)=>r.checked);
        let checkedDayIndex;
        dayTimeListC.forEach((r, io)=>{
            if(r.checked){
                checkedDays.push(r);
                if(checkedDayIndex==undefined)
                checkedDayIndex = io;
            }
        })
        setApplyAllCheckedIndex(checkedDayIndex);
        if(checkedDays.length==1 || checkedDays.length==7){
            let CopyFromDayTime = checkedDays[0]["timings"];
            let valTimeFrame = [];
            if(CopyFromDayTime[0]["startTime"] && CopyFromDayTime[0]["endTime"]){
                if(!applyToAll){
                    dayTimeList.forEach((da, i)=>{
                        CopyFromDayTime.forEach((times, timeIndex)=>{
                            valTimeFrame.push(validateTimeFrame(times.startIndex, i, "startTime"));
                            valTimeFrame.push(validateTimeFrame(times.endIndex, i, "endTime"));
                        })
                            let j = i+1;
                            if(!disableIndexes.includes(j)){
                                dayTimeListC[i]["timings"] = CopyFromDayTime;
                                dayTimeListC[i]["checked"] = true;
                            }
                    })
                    if(valTimeFrame.includes(true)){
                        dispatch(showToastAction("Timing overlap between offers. Please verify and select other values."));
                        setTimeout(function () {
                        dispatch(hideToastAction());
                        }, 3000);
                        return false
                    }else{
                        dispatch(setOffersDayTimeApplyAll(true));
                    }
                }else{
                    dispatch(setOffersDayTimeApplyAll(false));
                    let clonedDays = CloneWeekDays();
                    dayTimeList.forEach((da, i)=>{
                        if(i!=applyAllCheckedIndex)
                        dayTimeListC[i] = clonedDays[i];
                    })
                }
                setApplyToAll(!applyToAll);
                setDayTimeList(dayTimeListC);
            }
        }else if(checkedDays.length>1){
            if(applyToAll){
                setApplyToAll(!applyToAll);
                dispatch(setOffersDayTimeApplyAll(false));
                let clonedDays = CloneWeekDays();
                dayTimeList.forEach((da, i)=>{
                    if(i!=applyAllCheckedIndex)
                    dayTimeListC[i] = clonedDays[i];
                })
                setDayTimeList(dayTimeListC);
            }
        }
    }

    return (
     <>
     <div className="dealday_outer pb-15">
        <div class="deal_title">
            <div class="font12 fw600 color111">Deal Days Details</div>
        </div>
        {/* <div class="applytoggle font12 fw600 color111" onClick={(e)=>handleApplyToAll(e)}>
            <label class="switch">
                <input type="checkbox" checked={applyToAll && applyToAll!="0"}/>
                <span class="slider"></span>
            </label>
            <span class="aplytoalltx"> Apply to all</span>
        </div> */}
    </div>
     <ul class="editDayTimeUl dealdata_outer">
        {
            dayTimeList.map((row, i)=>
                <DayListComp 
                    key={row.displayText}
                    day={row}
                    dayInd={i}
                    selectTime={selectTime}
                    addTimeSlot={addTimeSlot}
                    handleChecked={handleChecked}
                    handleApplyToAll={handleApplyToAll}
                    applyToAll={applyToAll}
                    handleDeleteTimeSlot={handleDeleteTimeSlot}
                    disabledSelection={offersDetails.dealPeriod=="duration" && disableIndexes.includes(i+1)}
                />
            )
        }
     </ul>


        </>
    );
}

export default DayCompOffers;
