import axios from "axios";
import {checkForLive, getCurrentURIParams, getJDUID} from "./common";
import { URI } from "./configs";
import {ONBOARDING_VERTICAL} from "../config/consts"; 

export const getVerticalId = () => {
  let uriParams = getCurrentURIParams();
  return uriParams["vertical"]?uriParams["vertical"]: ONBOARDING_VERTICAL;
}
export const checkIfPaidContract = () => {
    let r = getCurrentURIParams();
    let docid = r.docid;
    let formData = new FormData()
      const config = {
        headers: { "content-type": "multipart/form-data" }
      }
      formData.append("case", "getPaidStatus");
      formData.append("docid", docid);
      const verticalId = getVerticalId();
      formData.append("verticalId", verticalId);
      const jduid = getJDUID();
      formData.append("jduid", jduid);
      return axios.post(URI, formData, config);
}
