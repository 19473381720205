export const JDCASHVERTICAL = 558;
export const ONBOARDING_VERTICAL = 37;
export const Steps = ["businessDet", "locationDet", "contactDet", "commissionDet", "pickupDet", "deliveryDet", "deliveryPriceDet", "offerDet", "storeTimeDet"]; // Normal Owner FLow

export const StepTabWLabels = { //labels separated by ~
    "businessDet": "Business~Details", 
    "locationDet": "Location~Details", 
    "contactDet": "Contact~Details", 
    "commissionDet": "Tax & Bank~Details", 
    "qrDet": "Scan &~Tag QR", 
    "pickupDet": "Pickup~Details", 
    "deliveryDet": "Delivery~Details", 
    "deliveryPriceDet": "Delivery~Charges",
    "storeTimeDet": "Store Timing~Details",
    "offerDet": "Offer~Details"
};

export const STNeed = [
    "source",
    "sid",
    "mobile",
    "wap",
    "docid",
    "input",
    "backUrl",
    "origin",
    "name",
    "email",
    "refId",
    "el",
    "empCode",
    "vertical"
  ];

// Path Arrays for header
export const Arr1Head = ["businessDet", "locationDet", "contactDet"];
export const Arr2Head = ["pickupDet", "deliveryDet", "deliveryPriceDet", "offerDet", "storeTimeDet", "chooseplan"];

//Mandatory fields in Business details page
export const MandatoryBusinessFieldsFieldsForJDCash = ["storeName", "legalCompanyName", "businessEmail"];
export const MandatoryBusinessFieldsFieldsForOnboarding = ["storeName", "legalCompanyName", "businessCat", "businessNature"];
//Exclude to check in Business details page
export const FieldsNotToCheckForJDCash = ["isChecked", "imgFlag" ,"storeLogoPath" ,"legalCompanyNameOrg" ,"masterStoreName" ,"master_parentid" ,"um_parentid" ,"yearOfEstablishment", "businessNature", "businessNatureId", "businessCat", "nid"];
export const FieldsNotToCheckForOnboarding = ["isChecked","imgFlag" ,"storeLogoPath" ,"legalCompanyNameOrg" ,"masterStoreName" ,"master_parentid" ,"um_parentid" ,"yearOfEstablishment"];

//Offer fieldds validate
export const NumericOfferFields = ["minOrderVal", "maxDiscVal", "percentageVal", "rupeeVal"]
export const ALLTIME = "all_time";
export const RUPEEVAL = "flat";
export const DPData = [
  {
    label: "Limited Time",
    val: "duration",
    type: "ltd"
  },
  {
    label: "All Time offer", 
    val: ALLTIME,
    type: "alt"
  }
];

export const DiscData = [
  {
    label: "Percentage Off",
    val: "percentage",
    type: "pOff"
  },
  {
    label: "Flat Rupee Off",
    val: RUPEEVAL,
    type: "fOff"
  }
]
export const MandatoryOfferFields = ["dealName", "description", "dealPeriod", "disOnDeal"]
