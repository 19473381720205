import React, { useState } from "react";
import { categoryAutoSuggest } from "../../services/common";

const CategorySearch = (props) => {
  const [selectedItem, setSelectedItem] = useState("");
  const [categories, setCategories] = useState([]);
  const [isHideCategory, setisHideCategory] = useState(false);

  // get categories
  const getCategories = (e) => {
    const value = e.target.value || "";
    setSelectedItem(value);
    if (value) {
      categoryAutoSuggest(value).then((resp) => {
        const categories = resp.data.results.data;
        setCategories(categories);
      });
    } else {
      setCategories([]);
    }
    setisHideCategory(true);
  };

  const selectItem = (category) => {
    var regex = /(<([^>]+)>)/gi;
    const sanitizeNature = category.v.replace(regex, "");
    setSelectedItem(sanitizeNature);
    setisHideCategory(false);
    props.submit(category);
  };

  return (
    <>
      {/* body start here */}
      <div className="selectcat_autosug_wrp">
        <header className="seller_header header_fixed">
          <div className="header_home">
            <div className="header_home_left">
              <div
                className="seller_headback_icon seller_icon"
                onClick={() => props.back(false)}
              ></div>
            </div>

            <div className="header_home_center">
              <div className="home_center text_center">
                {/* <div className="seller_jdlogo_icon seller_icon"></div> */}
                <div className="header_home_text font16 fw600 color1A1">
                  Category Search
                </div>
              </div>
              {/* <div className="seller_invoice_icon seller_icon"></div> */}
            </div>
          </div>
        </header>
        <div className="searchboxwrp pl-20 pr-20 pt-10 pb-10">
          <div className="searchbox">
            <span className="searchIcon"> </span>
            <input
              className="searchinputbx"
              type="text"
              onChange={(e) => getCategories(e)}
              value={selectedItem}
              placeholder="Search"
              autoFocus
            />
          </div>
        </div>
        <div className="ulwrp">
          {isHideCategory &&
            categories.map((item, index) => {
              return (
                <div className="liwrp" onClick={(e) => selectItem(item)}>
                  <div className="include_tax">
                    <label className="font15 color1a1 fw500">
                      {/* <input type="checkbox" name="" checked={true} /> */}
                      {/* <span className="type_checkbox mr-10"></span> */}
                      <span
                        className="fw400 mr-2"
                        dangerouslySetInnerHTML={{ __html: item.v }}
                      >
                        {/* {item.mcn} */}
                      </span>
                      {/* <span> & Appreals </span> */}
                    </label>
                  </div>
                </div>
              );
            })}
        </div>
        {/* <div className="ftr_btn">
          <button
            className="font14 fw600 colorfff"
            onClick={(e) => props.submit(selectedCategory)}
          >
            Submit
          </button>
        </div> */}
      </div>
    </>
  );
};

export default CategorySearch;
