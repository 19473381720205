import './commoncalc.css';
const PricingCalShimmer = () => {
  return (
    <div className="subcontainer pt30">
      <div
        className="font26 color111 fw500 prclfthdng sklanimat priceHeading"
      />
      <div className="dflex spricemobflex pt30">
        <div className="spriceLeft">
          <div className="dflex shimerflextop">
            <div className="spricesearchtop">
              <div
                className="sklanimat spricesearch mb30"
              />
              <div
                className="sklanimat spriceimgbox mb20"
              />
            </div>
            <div
              className="sklanimat spricesellprice mb30"
            />
          </div>
        </div>
        <div className="spriceRight">
          <div className="spriceRightbox">
            <div className="dflex mb20">
              <div className="sklanimat spriceRleft" />
              <div className="sklanimat spriceRRight" />
            </div>
            <div className="dflex mb20">
              <div className="sklanimat spriceRleft" />
              <div className="sklanimat spriceRRight" />
            </div>
            <div className="dflex mb20">
              <div className="sklanimat spriceRleft" />
              <div className="sklanimat spriceRRight" />
            </div>
            <div className="dflex mb20">
              <div className="sklanimat spriceRleft" />
              <div className="sklanimat spriceRRight" />
            </div>
            <div className="dflex">
              <div className="sklanimat spriceRleft" />
              <div className="sklanimat spriceRRight" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingCalShimmer;
