import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setStoreTimingAction } from "../../actions/actions";
import { AddTimeList } from "../StoreTimingHelper/addTimeCmp";
import { CalendarStore } from "../StoreTimingHelper/calendarCmp";
import {
  showToastAction,
  hideToastAction,
  showLoaderAction,
  hideLoaderAction,
} from "../../actions/actions";
// apiservice
import {
  SaveBusinessDetails,
  UpdateDocId,
  FinalUpdateDocId,
  getCurrentURIParams,
  getURIParams,
  redirectToDashboard,
  getEditListHomeUrl,
  checkPlanEligibility,
  getEditListCatAPI,
  getEditListHomeAPI
} from "../../services/common";
import {sendForApproval} from "../../services/audit";
import { setCookie } from "../common/setCookie";
import {openViewExit, openViewExitDeviceBack} from "../common/openViewExit";
import {IsJDCash} from "../../config/verticalConfig";

const formatDate = (date) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateObj = new Date(date);
  const month = monthNames[dateObj.getMonth()];
  const day = String(dateObj.getDate()).padStart(2, "0");
  const year = dateObj.getFullYear();
  const output = day + " " + month + " " + year;
  return output;
};

const valMap = {
  "00:00 - 00:00": "Closed",
  "00:00 - 23:59": "Open For 24 Hours",
  "12:00 am - 11:59 pm": "Open For 24 Hours",
  "12:00 am - 12:00 am": "Closed",
};

const showNewTimingValues = (timings) => {
  const startTime = timings[0];
  const endTime = timings[1];
  // const day = timings[0];
  const value = `${startTime} - ${endTime}`;
  return valMap[value] ? valMap[value] : value;
};

// const PrevTimeDrpDwn = (props) => {
//   const data = props.data;
//   return (
//     <ul className="selctA font13 color777">
//       {data.map((timings, index) => (
//         <li key={"weektime" + index + Math.random()}>
//           {showTimingValues(timings)}
//         </li>
//       ))}
//     </ul>
//   );
// };

const PrevTimeDrpDwn = (props) => {
  const data = props.data;
  return data.map((elem, index) => (
    <ul
      className={"selctA font13 color777" + (index === 0 ? " dn" : "")}
      key={"day" + index + Math.random()}
    >
      {elem[1].map((item, index) => (
        <li key={"weektime" + index + Math.random()}>
          {index === 0 && (
            <span className="dayspan">{`${elem[0].replace(
              elem[0][0],
              elem[0][0].toUpperCase()
            )}`}</span>
          )}
          <span className="timespan">{showNewTimingValues(item)}</span>
        </li>
      ))}
    </ul>
  ));
};

const StoreTimeDet = () => {
  // all state data
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const compData = useSelector((state) => {
    return state;
  });
  const timingDetails = useSelector((state) => {
    return {
      ...state.storeTimingDetails,
    };
  });
  const [valObj, setValObj] = useState(timingDetails);
  const [updated, setUpdated] = useState(true);
  useEffect(() => {
    if (updated && timingDetails.deliveryTimings.length > 0) {
      setValObj(timingDetails);
      setUpdated(false);
    }
  }, [timingDetails]);

  if (typeof window != "undefined") {
    window.appHandler = appAndroidHandler.bind(this);
  }
  function appAndroidHandler(logjson) {
    let r = getCurrentURIParams();
    if (r.source == 51 || r.source == 53) {
      if (logjson && logjson != "undefined" && logjson != "") {
        var logresponse = JSON.parse(logjson);
        if (
          logresponse["eventType"] &&
          logresponse["eventType"] == "deviceback"
        ) {
          openViewExitDeviceBack(r);
        }
      }
    }
  }

  const dispatch = useDispatch();
  const [isShowTimings, setShowTimings] = useState(false);
  const [isShowCalendar, setShowCalendar] = useState(false);
  const [isShowDelTimings, setShowDelTiming] = useState(false);
  const [isMarkStoreTiming, setMarkStoreTiming] = useState(false);
  const [isShowPrevTimeDrpDwn, setisShowPrevTimeDrpDwn] = useState(true);
  const [isShowDelPrevTimeDrpDwn, setisShowDelPrevTimeDrpDwn] = useState(true);
  // console.log("timingDetails", valObj);

  // set initial data to calander
  const [caledarData, setCalendarData] = useState({
    data: { sdate: "", edate: "", reason: "" },
    index: "",
  });
  const navigate = useNavigate();

  // save & move ahead
  const [saveFlag, setSaveFlag] = useState(false);

  useEffect(() => {
    if (saveFlag == true) {
      setShowButtonLoader(true);
      SaveBusinessDetails(
        { ...compData, doneFinal: true },
        undefined,
        "storeTimeDet"
      ).then((res) => {
        let currentUriParams = getCurrentURIParams();
        const successDet = () =>{
          setSaveFlag(false);
          if (currentUriParams["input"] == "account_settings") {
            if (
              currentUriParams["source"] == 51 ||
              currentUriParams["source"] == 53
            ) {
                    openViewExit(currentUriParams["source"]);
              return false;
            } else {
              window.location.href = decodeURIComponent(
                currentUriParams["backUrl"]
              )
                ? decodeURIComponent(currentUriParams["backUrl"])
                : "https://business.justdial.com/seller-dashboard/accountsetting";
            }
          } else {
            // On save, on staging navigate to chooseplan page else redirect to default url
            let uriParams = getURIParams();
            if (currentUriParams["source"] == 14) {
              window.location.href = getEditListHomeUrl(
                compData.locationDetails.city,
                compData.businessDetails.storeName,
                compData.loadDocData.docid
              );
            } else {
         if(IsJDCash){
                    window.location.href = getEditListHomeAPI(
                      compData.loadDocData.docid,
                        compData.locationDetails.city
                      );
                  } else{
                    redirectToDashboard()
                  }
            }
            // On save redirect to dashboard
            // redirectToDashboard();
          }
          setShowButtonLoader(false);
        }
        if(currentUriParams["source"]!=14){
          UpdateDocId(compData.loadDocData.docid)
            .then((res) => {
              FinalUpdateDocId(compData.loadDocData.docid)
                .then((res) => {
                  successDet();
                })
                .catch((e) => {
                  setShowButtonLoader(false);
                  dispatch(showToastAction("Error on update of document."));
                  setTimeout(function () {
                    dispatch(hideToastAction());
                  }, 3000);
                  console.log("Error on final update docId", e);
                });
            })
            .catch((e) => {
              dispatch(showToastAction("Error on update of document."));
              setTimeout(function () {
                dispatch(hideToastAction());
              }, 3000);
              setShowButtonLoader(false);
              console.log(e);
            });
        }else{
          successDet();
        }
      });
    }
  }, [saveFlag]);

  // finally save everything to store
  const handleSave = () => {
    const storeTimingsLen = valObj.storeTimings.length;
    const delTimingsLen = valObj.deliveryTimings.length;
    if ((storeTimingsLen && delTimingsLen) || IsJDCash) {
      setSaveFlag(true);
      dispatch(setStoreTimingAction(valObj));
    } else {
      if (!storeTimingsLen && !delTimingsLen) {
        dispatch(showToastAction("Please Fill Timings."));
      } else if (!storeTimingsLen) {
        dispatch(showToastAction("Please Fill Store Timings."));
      } else if (!delTimingsLen) {
        dispatch(showToastAction("Please Fill Delivery Timings."));
      }
      setTimeout(function () {
        dispatch(hideToastAction());
      }, 3000);
    }
    console.log(valObj.holidays);
  };

  // show add timing popup
  const openShowTimings = (e) => {
    const value = !isShowTimings;
    setShowTimings(value);
  };

  // open/close calendar
  const openShowCalendar = (e) => {
    const value = !isShowCalendar;
    setShowCalendar(value);
  };

  // open delivery timings
  const openDeliveryTiming = () => {
    const val = !isShowDelTimings;
    setShowDelTiming(val);
  };

  // push the value to holidays list
  const calendarSubmit = (info, index) => {
    if (info && info.sdate) {
      let cloneHoliday = [...valObj.holidays];
      // because index has zero so index>= 0 doosnt work with empty strings
      // if index is there do this
      const duplicateFlag = cloneHoliday.some(
        (elem) => elem.sdate === info.sdate && elem.edate === info.edate
      );
      if (duplicateFlag) {
        dispatch(showToastAction("Duplicate Holidays not Allowed"));
        setTimeout(function () {
          dispatch(hideToastAction());
        }, 3000);
        return false;
      }
      if (Number.isInteger(index)) {
        console.log("cloneHolidays", cloneHoliday, index);
        info.reason = info.reason ? info.reason.trim() : "";
        cloneHoliday[index] = info;
      } else {
        cloneHoliday.push(info);
      }
      setValObj({ ...valObj, holidays: cloneHoliday });
    } else {
      dispatch(showToastAction("Please Select Dates."));
      setTimeout(function () {
        dispatch(hideToastAction());
      }, 3000);
    }
    setShowCalendar(false);
  };

  // edit calendar values
  const editCalendar = (holiday, index) => {
    setCalendarData({ data: holiday, index });
    setShowCalendar(true);
  };

  // remove holiday from list
  const removeHoliday = (index) => {
    const cloneHolidays = [...valObj.holidays];
    cloneHolidays.splice(index, 1);
    setValObj({ ...valObj, holidays: cloneHolidays });
  };

  // submit details from store timings come here
  const submitStoreTimings = (StoreTimings) => {
    const value = !isShowTimings;
    setShowTimings(value);
    setValObj({
      ...valObj,
      storeTimings: StoreTimings,
      ...(isMarkStoreTiming ? { deliveryTimings: StoreTimings } : {}),
    });
    console.log(StoreTimings);
  };

  // submit delivery timigs
  const submitDeliverytimings = (deliverytimings) => {
    const value = !isShowDelTimings;
    setShowDelTiming(value);
    setValObj({ ...valObj, deliveryTimings: deliverytimings });
    // console.log("deliverytimings", deliverytimings);
  };

  // mark same as store timig for delievery timings
  const markSameStoreTime = () => {
    const val = !isMarkStoreTiming;
    const timings = val ? valObj.storeTimings : [];
    setValObj({ ...valObj, deliveryTimings: timings });
    setMarkStoreTiming(val);
  };

  useEffect(() => {
    if (isShowCalendar) {
      document.body.className += " bodyfixed";
    } else {
      document.body.className = document.body.className.replace(
        "bodyfixed",
        ""
      );
    }
  }, [isShowCalendar]);
  // close holiday calendar popup by clicking close button
  const closeHoliday = () => {
    setShowCalendar(false);
  };

  // close store timing popup by clicking close mark
  const closeStoreTiming = () => {
    setShowTimings(false);
  };

  // close delivery timing popup by clicking close button
  const closeDeltiming = () => {
    setShowDelTiming(false);
  };

  const formatHoliday = (holiday) => {
    const sdate = holiday.sdate ? formatDate(holiday.sdate) : "";
    const edate = holiday.edate ? formatDate(holiday.edate) : "";
    return [...new Set([sdate, edate])].filter(Boolean).join(" - ");
  };

  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);
  useEffect(() => {
    //To check if all mandatory fields are filled and enable submit button
    const storeTimingsLen = valObj.storeTimings.length;
    const delTimingsLen = valObj.deliveryTimings.length;
    let dis = false;
    if ((!storeTimingsLen || !delTimingsLen) && !IsJDCash) {
      dis = true;
    }
    setDisabledSubmitButton(dis);
  }, [valObj]);

  const [approvalFlag, setApprovalFlag] = useState(false);
  const [showNoChangePopup, setShowNoChangePopup] = useState(false);

  useEffect(()=>{
    if(approvalFlag){
      dispatch(showLoaderAction());
      SaveBusinessDetails(
        { ...compData, doneFinal: true },
        undefined,
        "storeTimeDet"
      ).then((resp)=>{
        sendForApproval().then((res)=>{
          if(res.data && res.data.changes==0){
            setShowNoChangePopup(true);
          }else{
            dispatch(showToastAction("Approval Link sent successfully."));
            setTimeout(function () {
              dispatch(hideToastAction());
            }, 3000);
            setApprovalFlag(false);
            if(res.data){
              window.location.href = getEditListHomeUrl(
                compData.locationDetails.city,
                compData.businessDetails.storeName,
                compData.loadDocData.docid
              );
            }
          }
          dispatch(hideLoaderAction());
        })
      })
    }
  }, [approvalFlag])
  const handleSendApproval = () => {
    dispatch(setStoreTimingAction(valObj));
    setApprovalFlag(true);
  }

  const handleELRedirect = () => {
    window.location.href = getEditListHomeUrl(
      compData.locationDetails.city,
      compData.businessDetails.storeName,
      compData.loadDocData.docid
    );
  }

  let paramsFrom = getCurrentURIParams();
  return (
    <>
      <section className="pl-20 pr-20 pb-70">
        {/* <!-- Store Timing Box  --> */}
        <div className="detailtblbx mt-30 mb-20">
          <div className="headeBx dflex ">
            <span className="font14 color111 fw500">Store Timing </span>
            {/* <span
              className="sd_info_icon"
              // onClick={() => {
              //   setInfo({ ...info, storeName: !info.storeName });
              //   setTimeout(() => {
              //     setInfo({ ...info, storeName: false });
              //   }, 4000);
              // }}
            ></span> */}
            <span
              className="font12 color007 fw500 curs_pointer"
              onClick={openShowTimings}
            >
              {" "}
              Edit{" "}
            </span>
          </div>
          <div className="contBx">
            <div className="dtbl">
              {valObj.storeTimings[0] && (
                <div className="dcell">
                  <div className="font11 color777 mb-5">Working Hours</div>
                  <div
                    className="font13 color111 fw500 dflexVcenter"
                    onClick={(e) => {
                      const val = !isShowPrevTimeDrpDwn;
                      setisShowPrevTimeDrpDwn(val);
                    }}
                  >
                    <span className="dayspan">{"Mon"}</span>
                    <span className="timespan_wpr">
                      {valObj.storeTimings[0][1].map((elem, index) => (
                        <span className="timespan">
                          {showNewTimingValues(elem)}
                        </span>
                      ))}
                    </span>
                    {/* {showTimingValues(valObj.storeTimings[0])} */}
                    <i
                      className={
                        "downarrowA ml-10 " +
                        (isShowPrevTimeDrpDwn ? "act" : "")
                      }
                    >
                      {" "}
                    </i>
                  </div>
                  {isShowPrevTimeDrpDwn && (
                    <PrevTimeDrpDwn data={valObj.storeTimings} />
                  )}
                </div>
              )}
              {!valObj.storeTimings.length && (
                <div className="dcell">
                  <span
                    className="font12 color007 fw500 curs_pointer"
                    onClick={openShowTimings}
                  >
                    {" "}
                    Add Time{" "}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <!-- Store Timing Box  --> */}
        {/* <!-- Detail Table Box  --> */}
        {
          !IsJDCash &&
        <div className="detailtblbx mb-20">
          <div className="headeBx dflex ">
            <span className="font14 color111 fw500">Pick up Timing </span>
            {/* <span
              className="sd_info_icon"
              // onClick={() => {
              //   setInfo({ ...info, storeName: !info.storeName });
              //   setTimeout(() => {
              //     setInfo({ ...info, storeName: false });
              //   }, 4000);
              // }}
            ></span> */}
            <span
              className="font12 color007 fw500 curs_pointer"
              onClick={openDeliveryTiming}
            >
              {" "}
              Edit{" "}
            </span>
          </div>
          <div className="contBx">
            <div className="dtbl">
              {valObj.deliveryTimings[0] && (
                <div className="dcell ">
                  <div className="font11 color777 mb-5">Working Hours</div>
                  <div
                    className="font13 color111 fw500 dflexVcenter"
                    onClick={(e) => {
                      const val = !isShowDelPrevTimeDrpDwn;
                      setisShowDelPrevTimeDrpDwn(val);
                    }}
                  >
                    <span className="dayspan">{"Mon"}</span>
                    <span className="timespan_wpr">
                      {valObj.deliveryTimings[0][1].map((elem, index) => (
                        <span className="timespan">
                          {showNewTimingValues(elem)}
                        </span>
                      ))}
                    </span>
                    {/* {showTimingValues(valObj.storeTimings[0])} */}
                    <i
                      className={
                        "downarrowA ml-10 " +
                        (isShowDelPrevTimeDrpDwn ? "act" : "")
                      }
                    >
                      {" "}
                    </i>
                  </div>
                  {isShowDelPrevTimeDrpDwn && (
                    <PrevTimeDrpDwn data={valObj.deliveryTimings} />
                  )}
                </div>
              )}
              {!valObj.deliveryTimings.length && (
                <div className="dcell">
                  <span
                    className="font12 color007 fw500 curs_pointer"
                    onClick={openDeliveryTiming}
                  >
                    {" "}
                    Add Time{" "}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        }
        
        {/* <!-- Detail Table Box  --> */}
        {
          !IsJDCash &&
            <div className="seller_ob_form mt-20 ">
            <div className="include_tax">
              <label className="font12 color777 alignright">
                <input
                  type="checkbox"
                  name=""
                  checked={isMarkStoreTiming}
                  onClick={markSameStoreTime}
                />
                <span className="type_checkbox mr-10"></span>
                Mark same as store timing
              </label>
            </div>
          </div>
        }
        {/* <!-- Detail Table Box  --> */}
        <div className="detailtblbx mt-20 mb-20">
          <div className="headeBx dflex ">
            <span className="font14 color111 fw500"> Vacation Plan </span>
            {/* <span
              className="sd_info_icon"
              // onClick={() => {
              //   setInfo({ ...info, storeName: !info.storeName });
              //   setTimeout(() => {
              //     setInfo({ ...info, storeName: false });
              //   }, 4000);
              // }}
            ></span> */}
            <span
              className="font12 color007 fw500 curs_pointer"
              onClick={openShowCalendar}
            >
              {" "}
              Add Holiday{" "}
            </span>
          </div>
          <div className="contBx">
            {valObj.holidays &&
              valObj.holidays.map((holiday, index) => (
                <div className="dtbl mb-20" key={index + holiday}>
                  <div
                    className="dcell "
                    onClick={(e) => {
                      editCalendar(holiday, index);
                    }}
                  >
                    <div className="font12 color111 fw500 mb-5">
                      {formatHoliday(holiday)}
                    </div>
                    <div className="font11 color777 "> {holiday.reason}</div>
                  </div>
                  <div className="dcell removecol ">
                    {" "}
                    <span
                      className="font11 color777 curs_pointer"
                      onClick={(e) => removeHoliday(index)}
                    >
                      Remove
                    </span>{" "}
                  </div>
                </div>
              ))}
            {/* <div className="dtbl ">
              <span
                className="font12 color007 fw500 curs_pointer"
                onClick={openShowCalendar}
              >
                {" "}
                Add Holiday{" "}
              </span>
            </div> */}
          </div>
        </div>
      </section>
      {isShowTimings && (
        <AddTimeList
          submit={submitStoreTimings}
          header={"Store Timing"}
          data={valObj.storeTimings}
          closetiming={closeStoreTiming}
        />
      )}
      {isShowDelTimings && (
        <AddTimeList
          submit={submitDeliverytimings}
          header={"Pick up Timing"}
          data={valObj.deliveryTimings}
          closetiming={closeDeltiming}
        />
      )}
      {isShowCalendar && (
        <CalendarStore
          submit={calendarSubmit}
          data={caledarData.data}
          index={caledarData.index}
          closeHoliday={closeHoliday}
        />
      )}
      <div
        className={`ftr_btn animated fast fadeInUp ${
          disabledSubmitButton ? "disabled" : ""
        }`}
      >
        <button
          className="font14 fw600 colorfff"
          disabled={disabledSubmitButton}
          onClick={()=>paramsFrom["source"]==14?handleSendApproval():handleSave()}
        >
          {!showButtonLoader &&  (
            <> {
              paramsFrom["source"]==14?
              <>
              Send for Approval
              </>
              :
              <>
              Save
              {paramsFrom["input"] == "account_settings" ? (
                ""
              ) : (
                <> &amp; Next</>
              )}
              </>
            }
            </>
          )}
          {showButtonLoader && (
            <span class="dotloader">
              <span />
              <span />
              <span />
              <span />
              <span />
            </span>
          )}
        </button>
      </div>
{
        showNoChangePopup &&  
        <div className="updatebank_dtl_wpr">
        <div className="updatebank_dtl_outer p-20 p-30">
            <div className="font13 color111 fw600 lineHeight22">
            No changes done. Approval link could not be sent!
            </div>
            <div className="mt-30 proceed_btn_wpr">
              <button className="font16" onClick={()=>handleELRedirect()}>OK</button>
            </div>
        </div>
      </div>
      }
    </>
  );
};

export default StoreTimeDet;
