import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  saveContactDataAction,
  setDocIdAction,
  showToastAction,
  hideToastAction,
  loadPrefilledBusinessDataAction,
  showLoaderAction,
  hideLoaderAction,
} from "../../actions/actions";
import {
  SaveBusinessDetails,
  SendOtp,
  VerifyOtp,
  getGetRedirectAPI,
  CreateDocId,
  UpdateDocId,
  getMobile,
  getURIParams,
  getCurrentURIParams,
  imageUploadToUpdateDoc,
  getStdCode,
  LoadPrefilledData,
  getCookie
} from "../../services/common";
import AlternateMobileNumber from "../common/AlternateMobileNumber";
import {openViewExit, openViewExitDeviceBack} from "../common/openViewExit";

const ContactDet = () => {
  const  [showButtonLoader, setShowButtonLoader] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidMobile, setInvalidMobile] = useState({show: false, msg:""});
  const [isLandlineNameInValid, setisLandlineNameInValid] = useState({show: false, msg:""});
  const [stdCode, setStdCode] = useState();
  const [info, setInfo] = useState({ 
    legalCompanyName: false,
  });

  if(typeof window != "undefined"){
    window.appHandler = appAndroidHandler.bind(this);
}
function appAndroidHandler(logjson){
  let r = getCurrentURIParams();
  if(r.source==51 || r.source==53){
  if(logjson && logjson != "undefined" && logjson != ""){
      var logresponse = JSON.parse(logjson);
    if(logresponse['eventType'] && logresponse['eventType'] == "deviceback"){
      openViewExitDeviceBack(r);
      }
    }
}
}

  const contactDetails = useSelector((state) => {
    return state.contactDetails;
  });

  const locationDetails = useSelector((state) => {
    return state.locationDetails;
  });
  const compData = useSelector((state) => {
    return state;
  });

  useEffect(()=>{
    if(!stdCode && locationDetails.city){
      getStdCode(locationDetails.city).then((res)=>{
        setStdCode(res.data.stdcode);
      })
    }
  },[locationDetails])

  const [altMArr, setAltMArr] = useState([]);
  const [pendingAltMArr, setPendingAltMArr] = useState([]);
  useEffect(()=>{
    if(!contactDetails.contactPersonName){  
  const userDet =
  getCookie("ln_user") &&
  JSON.parse(decodeURIComponent(getCookie("ln_user")));
  let UserName = userDet.nm;
      if(UserName){
        setValObj({...valObj, contactPersonName: UserName});
      }else {
        let currentUriParams = getCurrentURIParams();
        UserName=currentUriParams.name;
        if(UserName){
          setValObj({...valObj, contactPersonName: UserName});
        }
      }
    }
  },[contactDetails])
  useEffect(() => {
    if(!saveFlag){
      if (contactDetails.mobileNumber) {
        if(!contactDetails.contactPersonName){
          const userDet =
          getCookie("ln_user") &&
          JSON.parse(decodeURIComponent(getCookie("ln_user")));
        let UserName = userDet.nm;
              if(UserName){
                setValObj({...contactDetails, contactPersonName: UserName});
              }
        }else{
          setValObj(contactDetails);
        }
          /*
      [{altN, altNVar, verified, invalid}]
      */
     if(contactDetails["alternateMobileNumber"]){
        let contactDetailsArr = contactDetails["alternateMobileNumber"].split(",");
        let pendingContactDetailsArr = contactDetails["pendingAlternateMobileNumber"]?contactDetails["pendingAlternateMobileNumber"].split(","):[];
  
        let currentArr = [];
        contactDetailsArr.forEach((r)=>{
          let pendingVer = false;
          if(pendingContactDetailsArr.indexOf(r)>-1){
            pendingVer = true;
          }
          let obj = {
            altN: r,
            altNVar: r,
            verified: true,
            invalid: false,
            pendingVer: pendingVer
          }
          currentArr.push(obj);
        })
        pendingContactDetailsArr.forEach((r)=>{
          if(contactDetailsArr.indexOf(r)==-1){
            let obj = {
              altN: r,
              altNVar: r,
              verified: false,
              pendingVer: true,
              invalid: false
            }
            currentArr.push(obj);
          }
        })
        setAltMArr(currentArr);
        } else if(contactDetails["pendingAlternateMobileNumber"] && contactDetails["pendingAlternateMobileNumber"]!==""){
          let contactDetailsArr2 = contactDetails["alternateMobileNumber"]?contactDetails["alternateMobileNumber"].split(","):[];
          let pendingContactDetailsArr2 = contactDetails["pendingAlternateMobileNumber"].split(",");
          let currentArr = [];
          pendingContactDetailsArr2.forEach((r)=>{
            if(contactDetailsArr2.indexOf(r)==-1){
              let obj = {
                altN: r,
                altNVar: r,
                verified: false,
                pendingVer: true,
                invalid: false
              }
              currentArr.push(obj);
            }
          })
          setAltMArr(currentArr);
        } else{
          setAltMArr([{
            altN: "",
            altNVar: "",
            verified: false,
            invalid: false
          }]);
        }
      }
      else {
        if(!contactDetails.contactPersonName){
          const userDet =
          getCookie("ln_user") &&
          JSON.parse(decodeURIComponent(getCookie("ln_user")));
        let UserName = userDet.nm;
              if(UserName){
            setValObj({ ...contactDetails, contactPersonName: UserName, mobileNumber: getMobile("mobile") });
             }
        }else{
          setValObj({ ...contactDetails, mobileNumber: getMobile("mobile") });
        }
        setAltMArr([{
          altN: "",
          altNVar: "",
          verified: false,
          invalid: false
        }]);
      }
    }
  }, [contactDetails]);
  const dispatch = useDispatch();
  const [valObj, setValObj] = useState(contactDetails);
  const [alternateMobileNumberVar, setAlternateMobileNumberVar] = useState(
    contactDetails.alternateMobileNumber
  );
  const navigate = useNavigate();
  const location = useLocation();
  // save & move ahead
  const [saveFlag, setSaveFlag] = useState(false);
  const [isContactNameInValid, setisContactNameInValid] = useState({
    show: false, msg: ""
  });

  useEffect(()=>{
    if ((compData  && !compData.loadDocData) || !compData.loadDocData.docid) {
      LoadPrefilledData().then((res)=>{
        if(res.data.results.data[0]){
          dispatch(
            loadPrefilledBusinessDataAction(res.data.results.data[0])
          );
          dispatch(setDocIdAction(res.data.results.data[0].docid));
        }
      })
  }
  },[0])
  const {
    loadedPreFilledData, isPaidContract
  } = useSelector((state) => state.locationReducer);
  
  useEffect(() => {
    if (saveFlag == true) {
      setShowButtonLoader(true);
      SaveBusinessDetails(compData, location.pathname, "locationDet").then((res) => {
        let currentUriParams = getCurrentURIParams();
        const successDet = () => {
          setShowButtonLoader(false);
          setSaveFlag(false)
          if(currentUriParams["input"]=="account_settings"){
            if(currentUriParams["source"]==51 || currentUriParams["source"]==53){
		openViewExit(currentUriParams["source"]);
              return false;
            }else{
            window.location.href = decodeURIComponent(currentUriParams["backUrl"])?decodeURIComponent(currentUriParams["backUrl"]):"https://business.justdial.com/seller-dashboard/accountsetting";
            }
          }else{
            window.location.href = getGetRedirectAPI(currentUriParams.docid);
          }
        }
        if(currentUriParams["source"]!=14){
          UpdateDocId(currentUriParams.docid).then((res) => {
            successDet();
          }).catch((e)=>{
            console.log("Updating error ",e)
            setShowButtonLoader(false);
            setSaveFlag(false)
          })
        }else{
          successDet();
        }
      });
    }
  });

  const handleSave = () => {
    let patternEmail= /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let patternAltNum = /^[0]?[6789]\d{9}$/;
    let patternContactName = /^[A-Za-z\s]+$/;
    let patternLandlineValidate = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
    let valObjClone = Object.entries({...valObj}).reduce((acc, elem) =>{
      acc[elem[0]] =  elem[1] && elem[1].trim()
      return acc
    }, {});
    if (!valObjClone.contactPersonName) {
      setisContactNameInValid({
        show: true,
        msg: "Please enter a contact name."
      });
      return false;
    }
    if(valObjClone["landlineNumber"]){
      var phoneNum = valObjClone["landlineNumber"].replace(/[^\d]/g, '');
      let phoneWithStdCode = String(stdCode)+String(phoneNum);
      let firstDigitOfPhone = phoneNum.split("")[0];
if(valObjClone["landlineNumber"] && (phoneWithStdCode.length!==11 || (firstDigitOfPhone==0 || firstDigitOfPhone==1))) {  
      setisLandlineNameInValid({
        show: true,
        msg: "Please enter a valid Landline number."
      });
      return false;
     }
    }

    if (!patternContactName.test(valObjClone["contactPersonName"])) {
      setisContactNameInValid({
        show: true,
        msg: "Please enter a valid contact name."
      });
      return false;
    }
    let emailArr = valObjClone["businessEmail"].split(",");
    let invalidEmail = false;
    let emailHosts = {
      "gmail":"com",
      "yahoo":["com", "co.in"],
      "outlook":"com"
    }
    emailArr.forEach((r)=>{
      let e = r.trim().split("@")[1];
      let dom = e.split(".")[0];
      let com = e.substring(e.indexOf(".")+1);
      
      if(emailHosts[dom] && Array.isArray(emailHosts[dom])){
        if(!emailHosts[dom].includes(com))
          invalidEmail = true;
      }else if(emailHosts[dom] && (emailHosts[dom]!=com)){
        invalidEmail = true;
      }
      if (!patternEmail.test(r.trim())) {
        invalidEmail = true;
      }  
    })
    if(invalidEmail){
      setInvalidEmail(true);
      return false;
    }
   
    // Alternate mobile number validations code
    //     [{altN, altNVar, verified, invalid}]
    let clAltMArr = [...altMArr];
    let laterN = clAltMArr[clAltMArr.length-1];
    if(laterN.altNVar && !patternAltNum.test(laterN.altNVar)){
      clAltMArr[clAltMArr.length-1]["invalid"] = true;
      clAltMArr[clAltMArr.length-1]["msg"] = "Please enter a valid number.";
      setAltMArr(clAltMArr);
        return false;
    }
    if(laterN.altNVar && laterN.altNVar!==laterN.altN){
      clAltMArr[clAltMArr.length-1]["invalid"] = true;
      clAltMArr[clAltMArr.length-1]["msg"] = "Please validate the alternate Mobile number.";
      setAltMArr(clAltMArr);
        return false;
    }
    if(clAltMArr.length==0){
      valObjClone["alternateMobileNumber"]="";
    }
    let altStr = "";
    let pendingAltStr = "";

    clAltMArr.forEach((r, i)=>{
      if(i==0){
        if(r.pendingVer==true){
          pendingAltStr =  r.altN;
        }else{
          altStr = r.altN;
        }
      }else{
        if(r.pendingVer==true){
          if(pendingAltStr.indexOf(r.altN)==-1)
           pendingAltStr =  r.altN;
        }else{
        if(altStr.indexOf(r.altN)==-1)
         altStr = altStr+","+r.altN;
        }
      }
    });
    if(clAltMArr[0]["altNVar"]==""){
      valObjClone["pendingAlternateMobileNumber"] = "";
    }
    valObjClone["alternateMobileNumber"] = altStr;
    valObjClone["pendingAlternateMobileNumber"]=pendingAltStr;
    if (valObjClone["mobileNumber"] && valObjClone["contactPersonName"]) {
      setSaveFlag(true);
      dispatch(saveContactDataAction(valObjClone));
      setValObj(valObjClone);
    } else {
      dispatch(showToastAction("Please fill all mandatory fields."));
      setTimeout(function () {
        dispatch(hideToastAction());
      }, 3000);
    }
  };
  const handleChange = (e) => {
    if(e.target.name == "landlineNumber"){
      setisLandlineNameInValid({show: false, msg:""});
    }
    
    if (e.target.name == "businessEmail") {
      setInvalidEmail(false);
    } else if (e.target.name === "contactPersonName") {
      setisContactNameInValid({show:false, msg: ""});
    }
      setValObj({ ...valObj, [e.target.name]: e.target.value });
  };
  const handleAltChange = (e, index) =>{
    if (e.target.value == valObj["mobileNumber"]) {
      dispatch(
        showToastAction(
          "Mobile number and alternate mobile number shouldn't be same."
        )
      );
      setTimeout(function () {
        dispatch(hideToastAction());
      }, 3000);
      return false;
    }
    let v = e.target.value;
    if(v.length==0){
      let currentArr = [];
      let newObj = {
        altN: "",
        altNVar: "",
        verified: false,
        invalid: false
      }
      currentArr.push(newObj);
      setAltMArr(currentArr);
      return false;
    }
    if(v.length>9){
      let ret = false;
      if(altMArr.length>1){
        altMArr.forEach((r)=>{
          if(v==r.altN){
            ret = true;
          }
        })
      }
      if(ret){
        dispatch(
          showToastAction(
            "Entered number is same as already existing numbers."
          )
        );
        setTimeout(function () {
          dispatch(hideToastAction());
        }, 3000);
        return false;
      }
    }
    /*
    [{altN, altNVar, verified, invalid}]
    */
    let currentArr = [...altMArr];
    currentArr[index]["invalid"]=false;
    currentArr[index]["altNVar"]=e.target.value;
    currentArr[index]["verified"]=false;
    setAltMArr(currentArr); 
  }
  const handleVerifiedOtp = (index, auditing) =>{
    /*
    [{altN, altNVar, verified, invalid}]
    */
    let currentArr = [...altMArr];
    currentArr[index]["invalid"]=false;
    currentArr[index]["altN"]= currentArr[index]["altNVar"];
    currentArr[index]["verified"]=true;
    currentArr[index]["pendingVer"]=auditing;
    setAltMArr(currentArr); 
  }
  const [showAltNoPopUp, setShowAltNoPopUp] = useState(false);
  const [altPopUpMsg, setAltPopUpMsg] = useState("");
  const handleAltNoPopShow = (msg) => {
    setAltPopUpMsg(msg);
    setShowAltNoPopUp(true);
  }
  const [verifiedAlternateNumber, setVerifiedAlternateNumber] = useState(false);
  //Verifying 2
  //Verified 1
  //Not Verified 0
  useEffect(() => {
    if (valObj.mobileNumber) {
      setMobVer({
        ...mobVer,
        mobileNumber: 1,
      });
    }
    if (valObj.alternateMobileNumber) {
      setMobVer({
        ...mobVer,
        alternateMobileNumber: 1,
      });
    }
  }, [0]);
  const [mobVer, setMobVer] = useState({});
  const [mobileTypeOtp, setMobileTypeOtp] = useState();

  //Regex for mobile verification
  var IndNum = /^[0]?[6789]\d{9}$/;
  let paramsFrom = getCurrentURIParams(); 
  const handleAddNewNumber = () =>{
    let currentArr = [...altMArr];
    let newObj = {
      altN: "",
      altNVar: "",
      verified: false,
      invalid: false
    }
    currentArr.push(newObj);
    setAltMArr(currentArr);
  }
  
  const handleRemoveNumber = (index) => {
    let currentArr = [...altMArr];
    if(currentArr.length==1){
      currentArr.splice(index, 1);
      let newObj = {
        altN: "",
        altNVar: "",
        verified: false,
        invalid: false
      }
      currentArr.push(newObj);
      setAltMArr(currentArr);
    }else{
      currentArr.splice(index, 1);
      setAltMArr(currentArr);
    }
  }

  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);
  useEffect(()=>{
    //To check if all mandatory fields are filled and enable submit button
    let mandatoryFieldsToCheck = ["contactPersonName", "mobileNumber", "businessEmail"];
    let dis = false;
    mandatoryFieldsToCheck.forEach((r)=>{
      if(!valObj[r] || valObj[r]==""){
        dis = true;
      }
    })
    setDisabledSubmitButton(dis);
  },[valObj])

  return (
    <>
      <section class="pl-20 pr-20 pb-70">
        <div class="seller_ob_form mt-30">
          <div class="group">
            <input
              className="inputMaterial font16 fw600"
              type="text"
              required
              name="contactPersonName"
              value={valObj.contactPersonName}
              onChange={(e) => handleChange(e)}
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="label-wrap font16"> Contact Person Name *</label>
            {isContactNameInValid.show && (
              <span class="errorcls font12 pt-5">
               {isContactNameInValid.msg}
              </span>
            )}
          </div>
          <div class="group">
            <input
              disabled={valObj.mobileNumber? true : false}
              class="inputMaterial font16 fw600"
              type="text"
              required
              name="mobileNumber"
              value={valObj.mobileNumber}
              onChange={(e) => handleChange(e)}
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="label-wrap font16"> Mobile Number </label>
            {valObj.mobileNumber &&
              valObj.mobileNumber.length > 0 &&
              !IndNum.test(valObj.mobileNumber) && (
                <span class="errorcls font12 pt-5">
                  Please enter a valid number
                </span>
              )}
            {valObj.mobileNumber && (
              <span class="pos_green_tickmark"></span>
            )}
          </div>
          <div class="group">
            <input
              class="inputMaterial font16 fw600 "
              type="text"
              required
              name="businessEmail"
              value={valObj.businessEmail}
              onChange={(e) => handleChange(e)}
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="label-wrap font16"> Business Email *</label>
            {invalidEmail && (
              <span class="errorcls font12 pt-5">
                Please enter a valid email Id
              </span>
            )}
          </div>
          <div className="alt_mobile_wpr">
          {altMArr && altMArr.map((r, i)=>
            <AlternateMobileNumber 
                alternateMobileNumberVar={r.altNVar}
                invalidMobile={r.invalid}
                verified={r.verified}
                alternateMobileNumber={r.altN}
                pendingVer={r.pendingVer}
                index={i}
                key={r.altN+i}
                altNlength={altMArr.length}
                handleChange={handleAltChange}
                handleAddNewNumber={handleAddNewNumber}
                contactPersonName={valObj.contactPersonName}
                handleVerifiedOtp={handleVerifiedOtp}
                handleRemoveNumber={handleRemoveNumber}
                alertMsg={r.msg}
                handleAltNoPopShow={handleAltNoPopShow}
              />
          )
          }
          </div>
          <div class="group stdcode_wpr">
          <span class="formStdCode font16 fw600 ">{stdCode}</span>
            <input
              class="inputMaterial font16 fw600 "
              type="text"
              required
              name="landlineNumber"
              value={valObj.landlineNumber}
              onChange={(e) => handleChange(e)}
              autoComplete="off"
              maxLength={stdCode?11-stdCode.length:11}
              id={"landlineNumber"}
              onClick={()=>{
                const idFocus = document.getElementById("landlineNumber")
              setTimeout(() => {
                idFocus && idFocus.scrollIntoView({behavior: "smooth", block: "center"});
              },100)
              }}
            />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label class="label-wrap font16"> Landline (Optional) </label>
            {isLandlineNameInValid.show && (
              <span class="errorcls font12 pt-5">
               {isLandlineNameInValid.msg}
              </span>
            )}
          </div>
        </div>
      </section>
      <div class={`ftr_btn animated fast fadeInUp ${disabledSubmitButton?"disabled":""}`}>
        <button class="font14 fw600 colorfff" disabled={disabledSubmitButton} onClick={() => handleSave()}>
        {!showButtonLoader && <>Save{paramsFrom["input"] == "account_settings"?"":<> &amp; Next</>}</>}
       {
         showButtonLoader && <span class="dotloader"><span /><span /><span /><span /><span /></span>
       }  
        </button>
      </div>
      {
        showAltNoPopUp &&  
        <div className="updatebank_dtl_wpr">
        <div className="updatebank_dtl_outer p-20 p-30">
            <div className="font13 color111 fw600 lineHeight22">
            {altPopUpMsg}
            </div>
            <div className="mt-30 proceed_btn_wpr">
              <button className="font16" onClick={()=>setShowAltNoPopUp(false)}>Ok</button>
            </div>
        </div>
      </div>
      }
    </>
  );
};

export default ContactDet;
