import React, {useState} from 'react';
import { useDispatch } from "react-redux";

import OtpEnterComponent from "./OtpEnterComponent";
import {
  showLoaderAction,
  hideLoaderAction,
} from "../../actions/actions";
import {
  SendOtp, VerifyOtp
} from "../../services/common";

const AlternateMobileNumber = ({disableAll, pendingVer, alertMsg, alternateMobileNumberVar, handleChange, alternateMobileNumber, invalidMobile, index, verified, altNlength, handleAddNewNumber, contactPersonName, handleVerifiedOtp, handleRemoveNumber, handleAltNoPopShow}) => {
  const dispatch = useDispatch();
  const [otpSent, setOtpSent] = useState(false);

  const [wrongOtp, setWrongOtp] = useState(false);
  const [altInfo, setAltInfo] = useState(false);
  const handleOTPSend = (mobType, resendOtp, index) => {
    dispatch(showLoaderAction());
    setOtpSent(true);
   /* [{altN, altNVar, verified, invalid}] */
    SendOtp(alternateMobileNumberVar, contactPersonName, resendOtp).then((res) => {
      dispatch(hideLoaderAction());
    });
  };


  const handleVerifyOtp = (otp, mobile) => {
     VerifyOtp(mobile, contactPersonName, otp).then((res) => {
      if (res.data.result == "SUCCESS") {
        setOtpSent(false);
        let MrespC = res.data.Mresp;
        let auditing = false;
        if(MrespC){
          let MrespRes = MrespC.results;
          if(MrespRes.alert!==0){
            auditing=true;
            handleAltNoPopShow(MrespRes.msg);
          }
        }
        handleVerifiedOtp(index, auditing);
      } else {
        setWrongOtp(true);
      }
     });
  };

    var IndNum = /^[0]?[6789]\d{9}$/;
    return (
      <>
      {otpSent && (
        <OtpEnterComponent
          sendOtp={handleOTPSend}
          verifyOtp={handleVerifyOtp}
          mobile={alternateMobileNumberVar}
          wrongOtp={wrongOtp}
          closeOtpComp={() => {
            setOtpSent(false);
            setWrongOtp(false);
          }}
        />
      )}
        <div class="group">
        <input
          class="inputMaterial font16 fw600 "
          type="text"
          required
          name="alternateMobileNumber"
          value={alternateMobileNumberVar}
          onChange={(e) => handleChange(e, index)}
          autoComplete="off"
          maxLength={10}
          id={"alternateMobileNumber_"+index}
          placeholder={index==0?"":"Enter Mobile Number"}
          disabled={altNlength>0 && index!==altNlength-1}
          onClick={()=>{
            const idFocus = document.getElementById("alternateMobileNumber_"+index)
          setTimeout(() => {
            idFocus && idFocus.scrollIntoView();
          },100)
          }}
        />
        <span class="highlight"></span>
        <span class="bar"></span>
        {
             (index==0) && <label class="label-wrap font16"> Alternate Mobile </label>
        }
        <div className="alt_mob_outer">
       {
         !disableAll && (verified || pendingVer) && (index==altNlength-1) &&
         alternateMobileNumberVar.length > 0 &&
         <span className="color007 font12 fw500 addmore" onClick={()=>handleAddNewNumber()}>+ Add More</span>
       }
       {
             (altNlength>1) &&
            <span className="color007 font12 fw500 remove_alt" onClick={()=>handleRemoveNumber(index)}>Remove</span>
       }
       </div>
        {alternateMobileNumberVar &&
          alternateMobileNumberVar.length > 0 && 
          IndNum.test(alternateMobileNumberVar) && alternateMobileNumberVar!==alternateMobileNumber && (
            <button
              class="otpbtn font14 fw500 color007"
              onClick={() => handleOTPSend("alternateMobileNumber",undefined, index)}
            >
              Send OTP
            </button>
          )}
        {invalidMobile && (
            <span class="errorcls font12 pt-5">
             {alertMsg}
            </span>
          )}
        {alternateMobileNumberVar && alternateMobileNumberVar==alternateMobileNumber && (
          <span class="pos_green_tickmark"></span>
        )}
        {
          pendingVer &&
          alternateMobileNumberVar.length > 0 &&
          <span 
            class="orangemark" 
            title="Number pending for approval"
            onMouseOver={() => {
              setAltInfo(true);
              setTimeout(() => {
                setAltInfo(false);
              }, 4000);
            }}
            onClick={() => {
              setAltInfo(true);
              setTimeout(() => {
                setAltInfo(false);
              }, 4000);
            }}
            >
              !
            </span>
        }
        {altInfo &&
          <div className="info_content color111 font11">
            Alternate number has been sent for audit approval.
        </div>
        }
      </div>
      </>
    )
}

export default AlternateMobileNumber;