
import {STNeed} from "../config/consts"; 

export const getCurrentURIParams = () => {
    let objParams = {};
    let urlParams = window.location.href.split("?")[1];
    let urlParamsArr = [];
    if (urlParams) {
      urlParamsArr = urlParams.split("&");
    }
    urlParamsArr.forEach((element) => {
      let elName = element.split("=")[0];
      let elValue = element.split("=")[1];
      if (STNeed.indexOf(elName) > -1) {
        objParams[elName] = elValue;
      }
    });
    return objParams;
  };

  export const neededURIParams = (need) => {
    let objParams = {};
    let urlParams = window.location.href.split("?")[1];
    let urlParamsArr = [];
    if (urlParams) {
      urlParamsArr = urlParams.split("&");
    }
    urlParamsArr.forEach((element) => {
      let elName = element.split("=")[0];
      let elValue = element.split("=")[1];
      if (need=elName) {
        objParams[elName] = elValue;
      }
    });
    return objParams;
  };