import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { showToastAction, hideToastAction } from "../../actions/actions";

function timeingDropdown() {
  let hours, minutes, ampm;
  let tmpHrs = [];
  //   tmpHrs = [{ time: "Select Time" }];
  for (let i = 0; i <= 1430; i += 15) {
    hours = Math.floor(i / 60);
    minutes = i % 60;
    if (minutes < 10) {
      minutes = "0" + minutes; // adding leading zero
    }
    ampm = hours % 24 < 12 ? "am" : "pm";
    hours = hours % 12;
    if (hours === 0) {
      hours = 12;
    }
    const time = hours + ":" + minutes + " " + ampm;
    const extraTimeDict = {
      am: ["12:00", "12:15", "12:30"],
      pm: ["12:00", "12:15", "12:30"],
    };
    const extraTextDict = { am: "(MidNight)", pm: "(Noon)" };
    const extraText = extraTimeDict[ampm].includes(hours + ":" + minutes)
      ? extraTextDict[ampm]
      : "";
    tmpHrs.push({
      time,
      extraText,
    });
  }
  tmpHrs.push({ time: "11:59 pm" });
  return tmpHrs;
}

// to get the index off timeslot from selected value
function getTimslotIndex(timeslot, time) {
  const slotIndex = timeslot.findIndex((slot) => slot.time === time);
  return slotIndex;
}

export const TimngDropDown = (props) => {
  const dispatch = useDispatch();

  const day = props.day;
  const timeIndex = props.timeIndex;
  const weekindex = props.weekindex;

  // default values or previous selected values
  const [defaultData, setDefaultData] = useState({ ...props.default });

  // timesloit dropdown values
  const [timeSlots] = useState(timeingDropdown());
  const [isShowTimeSlot1, setTimeSlot1] = useState(false);
  const [isShowTimeSlot2, setTimeSlot2] = useState(false);
  const [startActiveClassIndex, setstartActiveClassIndex] = useState(-1);
  const [endActiveClassIndex, setendActiveClassIndex] = useState(-1);

  // show timedropdown first
  const showTimeDropSown1 = (weekindex, timeIndex) => {
    const val = !isShowTimeSlot1;
    setTimeSlot1(val);
    setTimeSlot2(false);
    const index = getTimslotIndex(timeSlots, defaultData.startTime);
    setstartActiveClassIndex(index);
    const id =
      index === -1
        ? `${weekindex}${timeIndex}startTime39`
        : `${weekindex}${timeIndex}startTime${index + 4}`;
    setTimeout(() => {
      const lastStep = document.getElementById(id);
      lastStep &&
        lastStep.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
    }, 500);
  };

  //showtimedropdown second
  const showTimeDropSown2 = (weekindex, timeIndex) => {
    const val = !isShowTimeSlot2;
    setTimeSlot2(val);
    setTimeSlot1(false);
    const index = getTimslotIndex(timeSlots, defaultData.endTime);
    setendActiveClassIndex(index);
    const id =
      index === -1
        ? `${weekindex}${timeIndex}endTime79`
        : `${weekindex}${timeIndex}endTime${index + 4}`;
    setTimeout(() => {
      const lastStep = document.getElementById(id);
      lastStep &&
        lastStep.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
    }, 500);
  };

  // select  left dropdown values
  const selectTime1 = (time, timeSlotIndex1) => {
    if (defaultData.endTime === time.time) {
      dispatch(showToastAction("Timmings cannot be Same"));
      setTimeout(function () {
        dispatch(hideToastAction());
      }, 3000);
      setTimeSlot1(false);
      return false;
    }
    const timingData = {
      ...defaultData,
      startTime: time.time,
    };
    setDefaultData(timingData);
    setTimeSlot1(false);
    props.selectTiming(timeIndex, weekindex, timingData, "first");
  };

  // select right dropdown values
  const selectTime2 = (time, timeSlotIndex2) => {
    if (defaultData.startTime === time.time) {
      dispatch(showToastAction("Timmings cannot be Same"));
      setTimeout(function () {
        dispatch(hideToastAction());
      }, 3000);
      setTimeSlot2(false);
      return false;
    }
    if (defaultData.startTime) {
      const timingData = {
        ...defaultData,
        endTime: time.time,
      };
      setDefaultData(timingData);
      props.selectTiming(timeIndex, weekindex, timingData, "second");
    } else {
      dispatch(showToastAction("Please Select Start Time"));
      setTimeout(function () {
        dispatch(hideToastAction());
      }, 3000);
    }
    setTimeSlot2(false);
  };

  const onBlurStartTime = (e) => {
    // const value = e.target.value;
    // const startIndex = getTimslotIndex(timeSlots, value);
    // if (startIndex === -1) {
    //   setDefaultData({ startTime: "", endTime: "" });
    // }
    setTimeout(() => {
      setTimeSlot1(false);
    }, 200);
  };

  const onBlurEndtime = (e) => {
    // const value = e.target.value;
    // const endIndex = getTimslotIndex(timeSlots, value);
    // if (endIndex === -1) {
    //   setDefaultData({ ...defaultData, endTime: "" });
    // }
    setTimeout(() => {
      setTimeSlot2(false);
    }, 200);
  };

  return (
    <div className="timinpwrp">
      <div className="inpBx">
        <div className="inrbx">
          <input
            className="inputMaterial font14 color1a1"
            type="text"
            required
            name="storeTime1"
            autoComplete="off"
            value={defaultData.startTime}
            onClick={(e) => showTimeDropSown1(weekindex, timeIndex)}
            // onChange={(e) => suggestStartTime(e)}
            label="Select time"
            placeholder="select time"
            readOnly
            // onBlur={(e) => onBlurStartTime(e)}
          />
          <span className="highlight"></span>
          <span className="bar"></span>
          <ul className="selctA">
            {isShowTimeSlot1 &&
              timeSlots.map((time, index) => (
                <li
                  onClick={(e) => selectTime1(time, index)}
                  key={"slot" + index + Math.random()}
                  id={`${weekindex}${timeIndex}startTime${index}`}
                  // now simply comment it off
                  className={index === startActiveClassIndex ? "act" : ""}
                >
                  {time.time} {time.extraText}
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div className="inpBx">
        <div className="inrbx">
          <input
            className="inputMaterial font14 color1a1"
            type="text"
            required=""
            name="storeTime2"
            autoComplete="off"
            value={defaultData.endTime}
            onClick={(e) => showTimeDropSown2(weekindex, timeIndex)}
            // onChange={(e) => suggestEndTime(e)}
            // onBlur={(e) => onBlurEndtime(e)}
            label="Select time"
            placeholder="select time"
            readOnly
          />
          <span className="highlight"></span>
          <span className="bar"></span>
          <ul className="selctA">
            {isShowTimeSlot2 &&
              timeSlots.map((time, index) => (
                <li
                  onClick={(e) => selectTime2(time, index)}
                  key={"time" + index + Math.random()}
                  id={`${weekindex}${timeIndex}endTime${index}`}
                  // now simply comment it off
                  className={index === endActiveClassIndex ? "act" : ""}
                >
                  {time.time} {time.extraText}
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div
        className="closeBx"
        onClick={(e) => props.remove(weekindex, timeIndex)}
      >
        {" "}
        {timeIndex !== 0 && <i className="time_sob_closeicn"></i>}
      </div>
    </div>
  );
};

export default TimngDropDown;
